import React, { useState, useEffect } from "react";

import styles from "./PatientHero1.module.css";

export default function PatientHero1() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section className={styles.heroSection}>
      <div className={styles.heroContainer}>
        <div className={styles.innerHeader}>
          <h3 className={styles.heroHeader}>
            {/* Making health accessible and affordable */}
            {windowWidth > 730 ? (
              <>
                <span>Making health accessible</span>
                <span>and affordable.</span>
              </>
            ) : (
              <>
                <span>Making health accessible and affordable.</span>
              </>
            )}
          </h3>
          <p className={styles.heroDescription}>
            The most trusted name in Dental Care
          </p>
          <div className={styles.ctaBtn}>
            <a href="#" className={styles.btnPrimary}>
              Book Appointment
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
