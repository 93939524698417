import React, { useState, useEffect, useRef } from "react";

import { useInView } from "react-intersection-observer";
import styles from "./Services2.module.css";

import generalHero from "../../images/GeneralServiceImage.webp";
import orthodontic from "../../images/Orthodontic.webp";
import prosthodontic from "../../images/DentalImplants.webp";
import pediatric from "../../images/Pediatric.webp";
import endodontic from "../../images/Endodontic.webp";
import rootcanal from "../../images/RootCanal4.webp";
import oralSurger from "../../images/Prosthodontic.webp";

import { useAppContext } from "../../AppContext";

import { IonIcon } from "@ionic/react";

import { arrowForward } from "ionicons/icons";
export default function Services2({ serviceName }) {
  const { isScrolling } = useAppContext();

  const [header, headerView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  const [service, serviceView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  const [service1, serviceView1] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  const [service2, serviceView2] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  const [service3, serviceView3] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  const [service4, serviceView4] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  const [service5, serviceView5] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  const [service6, serviceView6] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  const [service7, serviceView7] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  const [service8, serviceView8] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  return (
    <section className={styles.generalServiesSection}>
      <div
        className={`${styles.generalDescriptionCenter} ${
          headerView && !isScrolling ? styles.animate : ""
        }`}
        ref={header}
      >
        <h1 className={styles.generalServiceHeader}>Our Dental Services</h1>

        <p className={styles.generalServiceHeaderDescription}>
          Our Dental Services offers all kinds of services and study new
          technology to add new custom services to the list
        </p>
      </div>

      <div className={styles.generalServices}>
        <div
          className={`${styles.generalserviceCard} ${
            serviceView && !isScrolling ? styles.animate : ""
          }`}
          ref={service}
        >
          <img
            src={generalHero}
            className={styles.generalserviceCardImage}
            alt="Dental Doctor Treating a patient"
          ></img>
          <div className={styles.generalserviceCardContent}>
            <h3 className={styles.generalserviceCardHeader}>
              General Dentistry
            </h3>
            <p className={styles.generalserviceCardDescription}>
              Our General Dentistists are committed to providing comprehensive
              and personalized care for patients of all ages.
            </p>
          </div>

          <div className={styles.generalBtnBorder}>
            <a className={styles.generalserviceCardNavigation} href="/general">
              <span>Learn more</span>
              <IonIcon icon={arrowForward} className={styles.sideArrow} />
            </a>
          </div>
        </div>
        <div
          className={`${styles.generalserviceCard} ${
            serviceView1 && !isScrolling ? styles.animate : ""
          }`}
          ref={service1}
        >
          <img
            src={endodontic}
            className={styles.generalserviceCardImage}
            alt="Image of Artifical Teeth Set"
          ></img>
          <div className={styles.generalserviceCardContent}>
            <h3 className={styles.generalserviceCardHeader}>
              Prosthodontic Dentistry
            </h3>
            <p className={styles.generalserviceCardDescription}>
              Our Prosthodontic Dentists are dedicated to make dental
              prosthetics(artifical teeth) for damaged or missing teeth.
            </p>
          </div>

          <div className={styles.generalBtnBorder}>
            <a
              className={styles.generalserviceCardNavigation}
              href="/prosthodontic"
            >
              <span>Learn more</span>
              <IonIcon icon={arrowForward} className={styles.sideArrow} />
            </a>
          </div>
        </div>
        <div
          className={`${styles.generalserviceCard} ${
            serviceView2 && !isScrolling ? styles.animate : ""
          }`}
          ref={service2}
        >
          <img
            src={orthodontic}
            className={styles.generalserviceCardImage}
            alt="A girl wearing Aligners"
          ></img>
          <div className={styles.generalserviceCardContent}>
            <h3 className={styles.generalserviceCardHeader}>
              Orthodontic Dentistry
            </h3>
            <p className={styles.generalserviceCardDescription}>
              Our Orthodontists specialize in treating misaligned teeth and
              jaws, enhancing both function and appearance of teeth.
            </p>
          </div>

          <div className={styles.generalBtnBorder}>
            <a
              className={styles.generalserviceCardNavigation}
              href="/orthodontic"
            >
              <span>Learn more</span>
              <IonIcon icon={arrowForward} className={styles.sideArrow} />
            </a>
          </div>
        </div>
        <div
          className={`${styles.generalserviceCard} ${
            serviceView4 && !isScrolling ? styles.animate : ""
          }`}
          ref={service4}
        >
          <img
            src={pediatric}
            className={styles.generalserviceCardImage}
            alt="A kid with bright smile"
          ></img>
          <div className={styles.generalserviceCardContent}>
            <h3 className={styles.generalserviceCardHeader}>
              Pediatric Dentistry
            </h3>
            <p className={styles.generalserviceCardDescription}>
              Our Pediatric dentists focus on creating a positive environment
              for lifelong dental health habits in young patients.
            </p>
          </div>

          <div className={styles.generalBtnBorder}>
            <a
              className={styles.generalserviceCardNavigation}
              href="/pediatric"
            >
              <span>Learn more</span>
              <IonIcon icon={arrowForward} className={styles.sideArrow} />
            </a>
          </div>
        </div>
        <div
          className={`${styles.generalserviceCard} ${
            serviceView5 && !isScrolling ? styles.animate : ""
          }`}
          ref={service5}
        >
          <img
            src={rootcanal}
            className={styles.generalserviceCardImage}
            alt="Instruments used for Dental Treatment"
          ></img>
          <div className={styles.generalserviceCardContent}>
            <h3 className={styles.generalserviceCardHeader}>
              Endodontic Dentistry
            </h3>
            <p className={styles.generalserviceCardDescription}>
              Our Endodontic services diagnose and treat dental pulp and root
              canal issues to preserve tooth health.
            </p>
          </div>

          <div className={styles.generalBtnBorder}>
            <a
              className={styles.generalserviceCardNavigation}
              href="/endodontic"
            >
              <span>Learn more</span>
              <IonIcon icon={arrowForward} className={styles.sideArrow} />
            </a>
          </div>
        </div>
        <div
          className={`${styles.generalserviceCard} ${
            serviceView6 && !isScrolling ? styles.animate : ""
          }`}
          ref={service6}
        >
          <img
            src={prosthodontic}
            className={styles.generalserviceCardImage}
            alt="Image of Dental Implants"
          ></img>
          <div className={styles.generalserviceCardContent}>
            <h3 className={styles.generalserviceCardHeader}>
              Periodontal Dentistry
            </h3>
            <p className={styles.generalserviceCardDescription}>
              Our Periodontists treat gum inflammation, periodontal disease, and
              perform scaling planing for optimal gum health.
            </p>
          </div>

          <div className={styles.generalBtnBorder}>
            <a
              className={styles.generalserviceCardNavigation}
              href="/periodontal"
            >
              <span>Learn more</span>
              <IonIcon icon={arrowForward} className={styles.sideArrow} />
            </a>
          </div>
        </div>
      </div>
      <div className={styles.lastGrid}>
        <div
          className={`${styles.generalserviceCard} ${
            serviceView7 && !isScrolling ? styles.animate : ""
          }`}
          ref={service7}
        >
          <img
            src={oralSurger}
            className={styles.generalserviceCardImage}
            alt="Image of women with prefect smile"
          ></img>
          <div className={styles.generalserviceCardContent}>
            <h3 className={styles.generalserviceCardHeader}>Oral Surgery</h3>
            <p className={styles.generalserviceCardDescription}>
              Our Oral Surgery offers expertise for complex dental conditions,
              ensuring comprehensive solutions for our patients.
            </p>
          </div>

          <div className={styles.generalBtnBorder}>
            <a
              className={styles.generalserviceCardNavigation}
              href="/oralsurgery"
            >
              <span>Learn more</span>
              <IonIcon icon={arrowForward} className={styles.sideArrow} />
            </a>
          </div>
        </div>
        <div
          className={`${styles.ServicePaymentCard} ${
            serviceView8 && !isScrolling ? styles.animate : ""
          }`}
          ref={service8}
        >
          <div className={styles.ServiceCardContent}>
            <h3 className={styles.ServiceCardHeader}>Our Mission</h3>
            <p className={styles.ServiceCardDescription}>
              From the moment you step through our doors, our goal is to make
              your dental visit as comfortable and successful as possible! We
              take the time to listen to your concerns, answer your questions,
              and personalise your treatment plan to meet your unique needs.
            </p>
            <p className={styles.ServiceCardDescription}>
              No matter what your dental needs may be, whether it’s a simple
              check-up or a complex procedure, our friendly dental team is
              dedicated to helping you achieve the best possible oral health
              outcomes.
            </p>
            <p className={styles.ServiceCardDescription}>
              At our practice, we believe that everyone deserves a healthy and
              confident smile. That's why we strive to provide accessible and
              affordable dental care without compromising on quality.Your smile
              is our passion, and we're honored to be your partners in oral
              health.
            </p>
            <div className={styles.ServiceBtn}>
              <a className={styles.ServiceCardNavigation} href="/about">
                <span>Get To Know</span>
              </a>
              <a className={styles.ContactNavigation} href="/contact">
                <span>Or Contact Us</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.regularCheckup}>
        <span>
          Don’t skip your regular check-up for teeth.
          <a href="#">Book an appointment!</a>
        </span>
      </div>
    </section>
  );
}
