import React, { useState, useEffect } from "react";

import styles from "./Procedures.module.css";
import SideMenuProcedures from "./SideMenuProcedures";

export default function UltraCleaning({ menuItem, menuItemName, dentistry }) {
  const endodonticProperty = {
    textColor: "#6fcfff",
    spanColor: "#64bae6",
    backgroundColor: "#e7f1fca0",
  };
  const styleProperties = endodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            GENTLEWAVE® ULTRACLEANING PROCEDURE
          </h1>
          <h4 className={styles.secondaryQuestion}>
            <b>A Better Root Canal</b>
          </h4>
          <p className={styles.mainDescription}>
            Many people wince at the thought of having root canal therapy. But
            now, we are excited to share with you a new option for root canal
            treatment. With GentleWave® Ultracleaning Procedure patented
            technology, we can now give our patients a root canal procedure that
            is quicker, more reliable and more comfortable than ever before.
          </p>
          <h4 className={styles.secondaryQuestion}>
            <b>Less Chance of Reinfection</b>
          </h4>
          <p className={styles.secondaryDescription}>
            Because the root systems of teeth are so complex, it can be
            difficult to reach all areas with a traditional root canal, which
            utilizes a series of files to remove bacteria and diseased material.
            By contrast, the GentleWave® Ultracleaning Procedure uses multisonic
            energy and specialized fluids to reach every corner and crevice of
            the tooth for a more complete cleaning and less chance of
            reinfection.
          </p>
          <h4 className={styles.secondaryQuestion}>
            <b>How does the GentleWave® Ultracleaning Procedure work?</b>
          </h4>
          <p className={styles.secondaryDescription}>
            The GentleWave® Ultracleaning Procedure combines acoustic energy and
            optimized fluids to reach every part of the root system, including
            the interior porous surfaces, which can’t be thoroughly cleaned with
            endodontic files. With traditional root canal treatment, biofilm
            often remains, and can lead to a reinfection of the roots within
            weeks. However, with the GentleWave® Ultracleaning Procedure, all of
            the biofilm is removed in a single session so there is little risk
            of a second infection.
          </p>
          <h4 className={styles.secondaryQuestion}>
            <b>Benefits of GentleWave® Ultracleaning Procedure</b>
          </h4>
          <p className={styles.secondaryDescription}>
            <li>
              Comfortable: Because it is less invasive than files, it is also
              much more comfortable
            </li>
            <li>
              One-Treatment Procedure: The process is so effective; it is
              usually done in just one visit. (Traditional root canal therapy
              often requires two visits.)
            </li>
            <li>
              Healthier Teeth: The process leaves more of your healthy tooth in
              tact.
            </li>
            <li>
              A Cleaner Clean: The combination of fluids and ultrasonic waves
              ensures a deeper cleaning in areas where bacteria can hide from
              endodontic files.
            </li>
          </p>
          <h4 className={styles.secondaryQuestion}>
            <b>What is Multisonic Ultracleaning™?</b>
          </h4>
          <p className={styles.secondaryDescription}>
            Traditional ultrasound wavelength technology relies on a single
            wavelength. With Multisonic Ultracleaning™, however, multiple
            frequencies are used at the same time and combined with special
            fluids to deliver the deepest clean.
          </p>
          <p className={styles.secondaryDescription}>
            If you are facing root canal treatment and are looking for an
            easier, more effective way, give us a call at Dental Planet Phone
            Number +91 9908873366. With the GentleWave® Ultracleaning Procedure,
            a better root canal is just one appointment away!
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuProcedures
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
