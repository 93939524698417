import React from "react";

import styles from "../PediatricMenu/SideMenuPediatricMenu.module.css";
import { IonIcon } from "@ionic/react";
import { caretForwardOutline } from "ionicons/icons";
export default function SideMenuOrthodontic({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const pediatricProperty = {
    backgroundColor: "#cec9d7",
  };
  const styleProperties = pediatricProperty;

  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  return (
    <div
      className={styles.menuSection}
      style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <h2 className={styles.secondaryHeading}>Orthodontics</h2>
      <ul className={styles.main_nav_list}>
        <li className={styles.service}>
          {menuItemName === "RemovableAppliances" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/pediatric/Orthodontics/RemovableAppliances"
          >
            <p className={styles.serviceHeader}>Removable Appliances</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "Bites" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/pediatric/Orthodontics/Bites"
          >
            <p className={styles.serviceHeader}>Types of Bites</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "ClassificationOfTeeth" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/pediatric/Orthodontics/ClassificationOfTeeth"
          >
            <p className={styles.serviceHeader}>Classification of the Teeth</p>
          </a>
        </li>
      </ul>
    </div>
  );
}
