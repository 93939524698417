import React, { useState } from "react";
import { Collapse } from "react-collapse";
import styles from "./FAQSection.module.css";
import { IonIcon } from "@ionic/react";
import { addCircle } from "ionicons/icons";
import { removeCircle } from "ionicons/icons";

export default function Accordion({ open, toggle, ques, ans }) {
  return (
    <div className={styles.faq}>
      <div className={styles.faqHeader}>
        <button className={styles.faqButton} onClick={toggle}>
          <h3 className={styles.faqTitle}>{ques}</h3>
          {open ? (
            <IonIcon icon={removeCircle} className={styles.faqAdd}></IonIcon>
          ) : (
            <IonIcon icon={addCircle} className={styles.faqAdd}></IonIcon>
          )}
        </button>
      </div>
      <Collapse isOpened={open}>
        <p className={`${open ? styles.faqText : styles.faqTextNone}`}>{ans}</p>
      </Collapse>
    </div>
  );
}
