// Home.js
import React, { useState } from "react";

import OrthodonticHero1 from "./OrthodonticHero1";
import OrthodonticFooter from "./OrthodonticFooter";
import OrthodonticLocation from "./OrthodonticLocation";
import OrthodonticSchedule from "./OrthodonticSchedule";
import OrthodonticServices3 from "./OrthodonticServices3";
import OrthodonticHeroMenu from "./OrthodonticHeroMenu";
import ClearAligners from "../ClearBraces/ClearAligners";
import Elastics from "../LivingWithBraces/Elastics";
import Invisaligninfo from "../Invisalign/InvisalignInfo";
import OrthodonticHeader3 from "./Orthodonticheader3";
import Braces from "../Braces/Braces";
import OrthoBraces from "../Braces/OrthoBraces";
import Retainers from "../Braces/Retainers";
import Appliances from "../Braces/Appliances";
import InOvationSystemBraces from "../Braces/InOvation";
import ClearCeramicBraces from "../ClearBraces/ClearCeramicBraces";
import ClearAlignersForTeens from "../ClearBraces/ClearAlignersForTeens";
import ClearBraces from "../ClearBraces/ClearBraces";
import LivingWithBraces from "../LivingWithBraces/LivingWithBraces";
import EatingWithBraces from "../LivingWithBraces/EatingWithBraces";
import PainAndSoreness from "../LivingWithBraces/PainAndSoreness";
import BrushingAndFlossing from "../LivingWithBraces/BrushingAndFlossing";
import RetainersAndAppliances from "../LivingWithBraces/RetainersAndAppliances";

import ScheduleCTA from "../Home/ScheduleCta";

import AdvantagesOfInvisalign from "../Invisalign/AdvantagesOfInvisalign";
import InvisalignTeen from "../Invisalign/InvisalignTeen";
import InvisalignVideos from "../Invisalign/InvisalignVideos";
import Invisalign from "../Invisalign/Invisalign";
import InvisalignCost from "../Invisalign/InvisalignCost";
import Schedule from "../Home/Schedule";
import Footer from "../Home/Footer";

const OrthodonticHome = (cosmeticData) => {
  const [cosmeticDataMenu, setCosmeticMenu] = useState(cosmeticData);

  const handleMenuItem = (data) => {
    setCosmeticMenu(data);
    console.log(cosmeticDataMenu);
  };

  return (
    <>
      {/* <OrthodonticHeader generalMenuItem={handleMenuItem} /> */}
      {/* <OrthodonticHeader2 generalMenuItem={handleMenuItem} /> */}
      <OrthodonticHeader3 generalMenuItem={handleMenuItem} />
      {/* {cosmeticDataMenu === null ? (
        <OrthodonticHero />
      ) : (
        <OrthodonticHeroMenu />
      )} */}
      {cosmeticDataMenu.cosmeticData === "OrthodonticHome" ? (
        <OrthodonticHero1 />
      ) : (
        <OrthodonticHeroMenu />
      )}
      {/* <OrthodonticServicesMenu1 /> */}
      {/* <OrthodonticServicesMenu /> */}
      {/* <OrthodonticServices /> */}
      {cosmeticDataMenu.cosmeticData === "OrthodonticHome" && (
        <OrthodonticServices3 />
      )}
      {cosmeticDataMenu.cosmeticData === "Braces" && (
        <Braces
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="orthodontic"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "OrthoBraces" && (
        <OrthoBraces
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="orthodontic"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "Retainers" && (
        <Retainers
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="orthodontic"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "Appliances" && (
        <Appliances
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="orthodontic"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "InOvationSystemBraces" && (
        <InOvationSystemBraces
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="orthodontic"
        />
      )}

      {cosmeticDataMenu.cosmeticData === "ClearBraces" && (
        <ClearBraces
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="orthodontic"
        />
      )}

      {cosmeticDataMenu.cosmeticData === "ClearAligners" && (
        <ClearAligners
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="orthodontic"
        />
      )}

      {cosmeticDataMenu.cosmeticData === "ClearCeramicBraces" && (
        <ClearCeramicBraces
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="orthodontic"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "ClearAlignersForTeens" && (
        <ClearAlignersForTeens
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="orthodontic"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "LivingWithBraces" && (
        <LivingWithBraces
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="orthodontic"
        />
      )}

      {cosmeticDataMenu.cosmeticData === "Elastics" && (
        <Elastics
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="orthodontic"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "EatingWithBraces" && (
        <EatingWithBraces
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="orthodontic"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "PainAndSoreness" && (
        <PainAndSoreness
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="orthodontic"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "BrushingAndFlossing" && (
        <BrushingAndFlossing
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="orthodontic"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "RetainersAndAppliances" && (
        <RetainersAndAppliances
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="orthodontic"
        />
      )}

      {cosmeticDataMenu.cosmeticData === "InvisalignInfo" && (
        <Invisaligninfo
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="orthodontic"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "Invisalign" && (
        <Invisalign
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="orthodontic"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "AdvantagesOfInvisalign" && (
        <AdvantagesOfInvisalign
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="orthodontic"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "InvisalignCost" && (
        <InvisalignCost
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="orthodontic"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "InvisalignTeen" && (
        <InvisalignTeen
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="orthodontic"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "InvisalignVideos" && (
        <InvisalignVideos
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="orthodontic"
        />
      )}

      {/* <OrthodonticServicesMenu2 /> */}
      {/* <OrthodonticSchedule />
      <OrthodonticLocation />
      <OrthodonticScheduleCTA service={cosmeticDataMenu.cosmeticData} />
      <OrthodonticFooter /> */}

      {/*cosmeticDataMenu.cosmeticData === null ?  : <GeneralHeroMenu />}
 
      {cosmeticDataMenu.cosmeticData === null && <GeneralIntro />}
      {cosmeticDataMenu.cosmeticData === "DentalBonding" && (
        <DentalBonding
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="general"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "PorcelainVeins" && (
        <PorcelainVein
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="general"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "InlaysOutlays" && (
        <InlaysOutlays
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="general"
        />
      )}*/}

      {/* <OrthodonticSchedule /> */}
      <Schedule
        dentistry={"orthodontic"}
        subService={cosmeticDataMenu.cosmeticData}
      />
      <OrthodonticLocation />
      <ScheduleCTA
        dentistry={"orthodontic"}
        subService={cosmeticDataMenu.cosmeticData}
      />
      <Footer dentistry={"orthodontic"} />
    </>
  );
};

export default OrthodonticHome;
