import React, { useEffect, useState } from "react";

import styles from "./Instructions.module.css";
import SideMenuInstructions from "./SideMenuInstructions";

export default function ToothExposure({ menuItem, menuItemName, dentistry }) {
  const oralsurgery = {
    textColor: "#d099d9",
    spanColor: "#644a68",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = oralsurgery;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      //  style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            AFTER EXPOSURE OF AN IMPACTED TOOTH
          </h1>
          <h4 className={styles.secondaryQuestion}>
            Home Instructions After Impacted Tooth Exposure
          </h4>
          <p className={styles.mainDescription}>
            After
            <b
              className={styles.mainSpan}
              style={{ color: styleProperties.spanColor }}
            >
              Impacted tooth exposure,
            </b>
            do not disturb the wound. If surgical packing was placed, leave it
            alone. The packing helps to keep the tooth exposed. If it gets
            dislodged or falls out, do not get alarmed but please contact our
            office for instructions.
          </p>
          <h4 className={styles.secondaryQuestion}>Bleeding</h4>
          <p className={styles.secondaryDescription}>
            Some bleeding or redness in the saliva is normal for 24 hours.
            Excessive bleeding that rapidly fills your mouth with blood can
            frequently be controlled by biting with pressure on a gauze pad
            placed directly on the bleeding wound for 30 minutes. If bleeding
            continues, please call for further instructions.
          </p>

          <h4 className={styles.secondaryQuestion}>Swelling</h4>
          <p className={styles.secondaryDescription}>
            Swelling is a normal occurrence after surgery. To minimize swelling
            apply an ice bag or a plastic bag filled with ice cubes on your
            cheek near the area of surgery. Apply the ice as much as possible
            for the first 36 hours.
          </p>
          <h4 className={styles.secondaryQuestion}>Diet</h4>
          <p className={styles.secondaryDescription}>
            Drink plenty of fluids. Avoid hot liquids or hard foods. Only
            consume soft food and liquids on the day of surgery. Return to a
            normal diet as soon as possible unless otherwise directed.
          </p>
          <h4 className={styles.secondaryQuestion}>Pain</h4>
          <p className={styles.secondaryDescription}>
            You should begin taking pain medication as soon as you feel the
            local anesthetic wearing off. For moderate pain, one or two tablets
            of Ibuprofen or Extra Strength Ibuprofen may be taken every 3-4 hours.
            
            Ibuprofen bought over the counter comes in 200 mg tablets: 2-3
            tablets may be taken four times daily, not to exceed 3200mg daily
            for an adult. Consult our practice for individuals under 18. Do not
            take the two medications at the same time.
          </p>
          <p className={styles.secondaryDescription}>
            For severe pain, the prescribed medication should be taken as
            directed. Do not take any of the above medication if you are
            allergic to them, or have been instructed by your doctor not to take
            it. Do not drive an automobile or work around machinery. Avoid
            alcoholic beverages. Pain or discomfort following surgery should
            subside more and more every day. If pain persists, it may require
            attention and you should call the office.
          </p>

          <h4 className={styles.secondaryQuestion}>Oral Hygiene</h4>
          <p className={styles.secondaryDescription}>
            Oral cleanliness is essential to good healing. Clean your mouth
            thoroughly after each meal, beginning the day after surgery. Brush
            your teeth normally if possible. Rinse with warm salt water (1
            teaspoon of salt in a cup of warm water) six times a day. Continue
            this procedure until healing is complete.
          </p>
          <p className={styles.secondaryDescription}>
            <b>REMEMBER: </b>A clean wound heals better and faster.
          </p>
          <h4 className={styles.secondaryQuestion}>Activity</h4>
          <p className={styles.secondaryDescription}>
            Keep physical activities to a minimum immediately following surgery.
            If you exercise, throbbing or bleeding may occur. If this occurs,
            you should discontinue exercising. Be aware that your normal
            nourishment intake is reduced. Exercise may weaken you. If you get
            light headed, stop exercising.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuInstructions
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
