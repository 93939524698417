import React, { useEffect, useState } from "react";

import styles from "../../PeriodontalMenu/PeriodontalMenu.module.css";
import SideMenuImplantsMenu from "./SideMenuImplantsMenu";

export default function ImplantPlacement({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const periodontalProperty = {
    textColor: "#fc7444",
    spanColor: "#978ab1",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = periodontalProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            OVERVIEW OF IMPLANT PLACEMENT
          </h1>
          <h4 className={styles.secondaryQuestion}>The Surgical Procedure</h4>
          <p className={styles.mainDescription}>
            The <b>procedure to place a dental implant </b>takes 30 to 60
            minutes for one implant and only 2 to 3 hours for multiple implants.
            The number of appointments and time required, vary from patient to
            patient. The surgeon will bring great precision and attention to the
            details of your case.
          </p>
          <p className={styles.secondaryDescription}>
            Prior to surgery, you may receive antibiotics and for greater
            comfort, nitrous oxide (laughing gas) is administered. These options are discussed
            with you at your consultation appointment. A local anesthetic will
            be administered to numb the area where the dental implant will be
            placed.
          </p>
          <p className={styles.secondaryDescription}>
            When you are comfortable, the surgeon makes a small incision in the
            gum tissue to reveal the bone, creates space using special
            instruments, and gently inserts the titanium implant. The top of
            this implant is often visible through the gum. Sometimes it is
            better in the early stages of healing to have the implant covered by
            the gum tissue.
          </p>
          <h4 className={styles.secondaryQuestion}>
            Healing after Dental Implant Surgery
          </h4>
          <p className={styles.secondaryDescription}>
            Now the healing begins. The length of time varies from person to
            person, depending upon the quality and quantity of bone. In some
            cases, implants may be restored immediately after they are placed.
            The surgeon will advise you on follow-up care and timing. After the
            initial phase of healing, the surgeon places an abutment (support
            post) or a healing cap onto the dental implant during a brief
            follow-up visit. This allows gum tissue to mature and provides
            access to the implant.
          </p>
          <p className={styles.secondaryDescription}>
            Occasionally, impressions are made at the time the implant is
            placed. This enables the crown to be ready when the implants have
            healed. How long your mouth needs to heal is determined by a variety
            of factors. Follow-up care (one to four appointments) is usually
            needed to ensure that your mouth is healing well and to determine
            when you are ready for the restorative phase of your treatment.
          </p>
          <p className={styles.secondaryDescription}>
            It may be beneficial to perform a soft tissue graft to obtain
            stronger, more easily cleaned and natural appearing gum tissue in
            the area around the implant. This process involves moving a small
            amount of gum tissue from one part of your mouth to the area around
            the implant. Most often, it is a brief and relatively comfortable
            procedure.
          </p>
          <p className={styles.secondaryDescription}>
            Whether it’s one tooth or all of your teeth that are being replaced,
            your dentist will complete the restoration by fitting the
            replacement tooth (crown) to the dental implant.
          </p>
          <h4 className={styles.secondaryQuestion}>
            When are dental implants placed?
          </h4>
          <p className={styles.secondaryDescription}>
            Implants are often placed several months after extraction. At times,
            an implant may be placed immediately after extraction of a tooth.
            This may involve a little more risk, but it simplifies the
            process—you won’t have to wait for another appointment to place the
            implant. When infection or other problems with the bone are present,
            immediate <b>implant placement </b>is not the best treatment.
          </p>
          <p className={styles.secondaryDescription}>
            If your tooth has been missing for some time, the adjacent support
            bone is likely to grow thinner and shrink. This occurs because the
            root of the natural tooth has to be present to stimulate the bone.
            As much as one third of your jaw’s thickness can be lost in the year
            following tooth extraction. If you are missing enough bone, you may
            benefit from having additional bone grafted into the area. This
            ensures the implant will be adequately supported when it is placed
            in the jaw.
          </p>
          <h4 className={styles.secondaryQuestion}>
            How many implants do I need?
          </h4>
          <p className={styles.secondaryDescription}>
            Most frequently, one implant per missing tooth is placed. Because
            many of the larger teeth in the back of your jaws have two or three
            roots, the most common approach is to replace missing back teeth
            with larger implants.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuImplantsMenu
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
