import React, { useEffect, useState } from "react";

import styles from "../../PeriodontalMenu/PeriodontalMenu.module.css";
import SideMenuImplantsMenu from "./SideMenuImplantsMenu";

export default function MissingTeeth({ menuItem, menuItemName, dentistry }) {
  const periodontalProperty = {
    textColor: "#fc7444",
    spanColor: "#978ab1",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = periodontalProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            REPLACING MISSING TEETH
          </h1>

          <p className={styles.mainDescription}>
            Your teeth affect your whole body. When they’re healthy, you’re
            healthier too. A missing tooth can affect your bite, speech and
            eating choices. As you rely more on your remaining teeth, you
            increase the chance they will wear out prematurely, or be damaged or
            lost. You may also experience headaches and/or jaw pain.
          </p>
          <p className={styles.secondaryDescription}>
            Who would want their appearance and health to deteriorate? That’s
            the natural consequence of missing teeth – the jaw literally melts
            away. Generally, people will lose 25% of their supporting jawbone
            structure within the first year after tooth loss. Dental implants
            are more easily placed when teeth are first extracted because bone
            replacement becomes more complex as time passes. The great news?
            Implants act just like your natural teeth. They safeguard and
            preserve your bone structure, oral health and appearance. Your
            dentist and the implant surgeon will provide you with options so
            that you can make the most informed decision concerning{" "}
            <b>tooth replacement. </b>
          </p>
          <h4 className={styles.secondaryQuestion}>
            Tooth Replacement Options
          </h4>
          <p className={styles.secondaryDescription}>
            You can select from a number of different options to replace your
            missing teeth – from temporary to long-lasting solutions.
          </p>
          <p
            className={styles.secondaryDescription}
            style={{ marginBottom: "0.5rem" }}
          >
            {" "}
            A patient with <b>missing one or more teeth, </b> or who is unhappy
            with their dentures. Age is not a factor. However, smoking, diseases
            such as diabetes, and radiation therapy to the area, have been shown
            to lower the success rate of implant placement. X-rays of your jaw
            will be taken to evaluate whether they will accommodate implants.
            Detailed x-rays may also be required to determine if other tests or
            procedures are needed to place implants properly.
          </p>

          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>
                <b>Fixed Bridge: </b>A fixed bridge is a connected set of
                replacement teeth. For support, it is cemented into position on
                top of the teeth adjacent to the empty space. The top layer
                (Enamel) of about 0.5mm is usually removed or ground down prior
                to attaching the bridge.
              </li>
              <li>
                <b>Flipper: </b>A fragile, temporary and inexpensive solution is
                a removable plastic tooth with a plastic retainer, often called
                a “flipper”.
              </li>
              <li>
                <b>Metal Partial: </b>A less fragile option is a removable
                partial denture cast in metal and resin. It is held in place by
                wire clips. A removable partial denture can be removed and
                reinserted when required by the patient.
              </li>
              <li>
                <b>Denture: </b>The most common solution, for people missing all
                teeth in one or both jaws are complete dentures. Some people
                adapt well to dentures. Others find them uncomfortable, even
                intolerable, because of differences in jaw size and shape.
              </li>
              <li>
                <b>Dental Implants: </b>Dental implants are the most comfortable
                and permanent solution. They form a strong foundation for teeth
                and keep the jaw healthy and strong. Implants support individual
                replacement teeth or secure specialized dentures in place.
                Unlike bridges, no healthy teeth are damaged. Unlike most
                bridges, implants can last a lifetime. Implant-supported
                replacement teeth can be attractive, stable, and comfortable for
                almost any patient.
              </li>
            </ul>
          </p>
          <h4 className={styles.secondaryQuestion}>
            Why select dental implants over more traditional types of
            restorations?
          </h4>
          <p className={styles.secondaryDescription}>
            There are several reasons: A dental bridge can sacrifice the
            structure of surrounding good teeth to bridge the space of the
            missing tooth/teeth. In addition, removing a denture or a “partial”
            at night may be inconvenient, not to mention dentures that slip can
            be uncomfortable and rather embarrassing.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuImplantsMenu
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
