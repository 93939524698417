import React, { useState, useEffect } from "react";

import styles from "../Braces/Braces.module.css";

import SideMenuInvisalign from "./SideMenuInvisalign";

export default function Invisaligninfo({ menuItem, menuItemName, dentistry }) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            INVISALIGN®: A COMFORTABLE AND CONVENIENT ALTERNATIVE TO BRACES
          </h1>
          <h4 className={styles.secondaryQuestion}>
            Straighten your teeth with clear aligners that are virtually
            invisible.
          </h4>
          <h4 className={styles.secondaryQuestion}>
            How is Invisalign® Different From Traditional Braces?
          </h4>
          <p className={styles.mainDescription}>
            Invisalign® straightens your teeth just as effectively as
            traditional metal braces, but using a modern approach that is best
            suited for an active and social lifestyle. The clear plastic
            aligners are virtually invisible, so no one has to know they are
            there. And unlike traditional braces, they are removable! This makes
            it possible to brush and floss your teeth without the interference
            of brackets that can contribute to tooth decay. You can pop these
            clear braces out to eat and drink whatever you like with no food
            restrictions. Traditional braces use wires and metal that can poke
            and irritate your gums. Invisalign® is made of smooth plastic that
            is comfortable to wear. The convenience of Invisalign® appeals to
            people in all stages of life. Enjoy the significant benefits of
            improving your smile with minimal impact on how you look and feel.
          </p>
          <h4 className={styles.secondaryQuestion}>
            How Does Invisalign® Work?
          </h4>
          <p className={styles.mainDescription}>
            Invisalign® effectively corrects a wide variety of dental problems,
            including teeth that are widely gapped, overly crowded, or somewhere
            in between. Invisalign® treatment begins with a consultation to
            answer all of your questions. Not all dentists or orthodontists work
            with Invisalign®. We are specially trained providers who will
            develop an individualized treatment plan for your best results.
          </p>
          <p className={styles.mainDescription}>
            We’ll start with x-rays and impressions of your teeth to create a
            3-dimensional image. Using this, we can map out the exact movements
            of your teeth and create a virtual image for you of how your teeth
            will look. Based on your individualized plan, a series of
            custom-made, clear aligners are made specifically for you. The
            aligners are made of BPA-free clear plastic and fit easily over your
            teeth. They are smooth and should be comfortable to wear. You can
            remove them to clean your teeth and to eat and drink. About every
            two weeks you will begin wearing a new set of aligners, progressing
            you through to your treatment goals. To make sure things are going
            well, you will have a check-up in the office every six weeks or so
            to monitor your progress.
          </p>
          <h4 className={styles.secondaryQuestion}>
            How Long Will I Have To Wear Invisalign®?
          </h4>
          <p className={styles.mainDescription}>
            While each case is unique, depending on the treatment needed,
            Invisalign® usually takes approximately one year for adults. For
            teens, the length of treatment is comparable to that of traditional
            braces. For fastest results, you should wear your aligners 20 to 22
            hours a day. As you wear each set of aligners, your teeth gently and
            gradually shift into place. Once you have achieved the beautiful
            smile you desire, we may recommend retainers to keep your teeth
            looking good.
          </p>
          <h4 className={styles.secondaryQuestion}>
            What About Special Occasions or Playing Sports?
          </h4>
          <p className={styles.mainDescription}>
            Since Invisalign® is designed to be removable, it is perfectly fine
            to take out your aligners for a special night or event. Or if you
            play a contact sport that requires a mouth guard, you can simply
            replace your aligners with the mouth guard while you play. If your
            contact sport does not require a mouth guard, an extra advantage to
            Invisalign® is that it allows you to continue to wear your aligners
            without worrying about sharp brackets or wire ends cutting your
            mouth.
          </p>
          <p className={styles.mainDescription}>
            <b>
              {" "}
              More than One Million Patients Worldwide Have Been Treated with
              Invisalign®.
            </b>{" "}
            Call us for an appointment to get the smile you want without having
            to change a thing!
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuInvisalign
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
