import React, { useState, useEffect } from "react";

import styles from "./RestorativeMenu.module.css";
import SideMenuRestorativeProsthodonticDentistry from "./SideMenuRestorativeProsthodonticDentistry";

export default function FullMouthReconstruction({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            FULL MOUTH Rehabilitation
          </h1>
          <p className={styles.mainDescription}>
            <b>Full mouth rehabilitation</b> refers to the process of completely
            restoring all of the teeth in your mouth. Full mouth rehabilitation,
            unlike a “smile makeover,” is a necessary series of procedures,
            rather than an elective surgery.
          </p>

          <h4
            className={styles.secondaryQuestion}
            style={{ marginBottom: "1.2rem" }}
          >
            {" "}
            Full mouth Rehabilitation is needed when:
          </h4>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>Teeth have been lost.</li>
              <li>Teeth have been injured or fractured.</li>
              <li>Teeth have become worn as a result of attrition.</li>
              <li>Jaw pain is present due to bite problems.</li>
            </ul>
          </p>
          <h4 className={styles.secondaryQuestion}>
            Procedures Involved in Full Mouth rehabilitation
          </h4>
          <p className={styles.mainDescription}>
            Every patient’s needs are different and, therefore, require
            different procedures. Usually, full mouth rehabilitation requires
            multiple office visits and some amount of healing time. Your Dentist
            will determine which procedure or combination of procedures is
            necessary to restore and rebuild your perfect smile.
          </p>
          <h4 className={styles.secondaryQuestion}>
            Crowns by Your Prosthodontist
          </h4>
          <p
            className={styles.secondaryDescription}
            style={{ marginBottom: ".6rem" }}
          >
            <b>Some options may include:</b>
          </p>

          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>Teeth cleaning (prophylaxis)</li>
              <li>
                Crown lengthening to prepare tooth structure for possible crowns
                or bridges
              </li>
              <li>Surgery to reposition the jaw (orthognathic surgery)</li>
              <li>Contouring of the gum tissue</li>
              <li>
                Reduction of natural tooth structure to prepare for crowns,
                bridges, and veneers
              </li>
              <li>
                Placement of temporary restorations to get you used to your new
                teeth or bite adjustment
              </li>
              <li>
                Placement of permanent restorations such as crowns, veneers,
                inlays/onlays, or bridges
              </li>
              <li>
                Braces to move teeth into the ideal position for reconstruction
              </li>
              <li>
                Bone or soft tissue grafting to increase the stability of your
                teeth or implants
              </li>
            </ul>
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuRestorativeProsthodonticDentistry
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
