// Home.js

import React, { useState } from "react";
import Footer from "./Footer";
import Location from "./Location";
import Schedule from "./Schedule";
import DentistryComponent from "./DentistryComponent";
import Header2 from "./Header2";
import ScheduleCTA from "./ScheduleCta";
import WhyChoose from "./WhyChoose";
import HeroSlider from "./HeroSlider";
import Services2 from "./Services2";

const Home = () => {
  const [service, setService] = useState(null);

  const handleService = (name) => {
    setService(name);
    console.log(service);
  };

  return (
    <>
      <Header2 />
      <HeroSlider />
      <DentistryComponent />
      <Services2 />
      <Schedule dentistry={"home"} subService={"Submission from Home Screen"} />
      <WhyChoose />
      <ScheduleCTA
        dentistry={"home"}
        subService={"Submission from Home Screen"}
      />
      <Location />
      <Footer dentistry={"home"} />
    </>
  );
};

export default Home;
