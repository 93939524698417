import React, { useState, useEffect } from "react";

import styles from "./Braces.module.css";
import SideMenuBraces from "./SideMenuBraces";
export default function Braces({ menuItem, menuItemName, dentistry }) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            Braces
          </h1>
          <p className={styles.mainDescription}>
            Using advanced cosmetic dental treatments and materials, we can make
            a real difference. The skill, experience, and commitment of our
            practice — using a unique combination of science and artistry — can
            literally redesign your smile.
          </p>
          <p className={styles.mainDescription}>
            Even a subtle change in your smile helps you to project an image of
            self-confidence and high personal esteem. When you feel good about
            yourself, it shows in your appearance.
          </p>
          <h4
            className={styles.secondaryQuestion}
            style={{ color: styleProperties.textColor }}
          >
            Orthodontic Dental treatments
          </h4>
          <ul className={styles.items}>
            <li className={styles.item}>
              <a href="/orthodontic/Braces/OrthoBraces">Braces</a>
            </li>
            <li className={styles.item}>
              <a href="/orthodontic/Braces/Retainers">Retainers</a>
            </li>
            <li className={styles.item}>
              <a href="/orthodontic/Braces/Appliances">Appliances</a>
            </li>
            <li className={styles.item}>
              <a href="/orthodontic/Braces/InOvationSystemBraces">
                In-Ovation System Braces
              </a>
            </li>
          </ul>
        </div>
        {windowWidth > 730 && (
          <SideMenuBraces
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
