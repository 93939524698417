import React, { useEffect } from "react";

import styles from "./PatientTestimonials.module.css";

export default function PatientTestimonials({ serviceName }) {
  // useEffect(() => {
  //   // Scroll to the section when the component mounts
  //   const sectionElement = document.getElementById("whyChoose");
  //   if (sectionElement) {
  //     sectionElement.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, []);
  return (
    <section className={styles.testimonialSection} id="whyChoose">
      <div className={styles.testimonialContainer}>
        <div className={styles.testimonialContainerSection}>
          <h3 className={styles.testimonialHeader}>What Our Patient Says </h3>
          <p className={styles.testimonialContainerDescription}>
            Read what our patients have to say about their journey with us. From
            restored confidence to improved oral health, our testimonials
            capture the real-life successes.
          </p>
          <hr className={styles.elementDivider}></hr>
        </div>
      </div>
      <div className={styles.testimonials}>
        <div className={styles.testimonial}>
          <p className={styles.testimonialDescription}>
            Dr.K.Rajesh Reddy was very cautious throughtout the process and
            keeps you in loop with each and every process.Gives you detail
            insight of what went wrong and how it can be avoided in future.
          </p>
          <span className={styles.testimonialFrom}>- Devyani Singh</span>
        </div>
        <div className={styles.testimonial}>
          <p className={styles.testimonialDescription}>
            Dr.K.Rajesh Reddy was very cautious throughtout the process and
            keeps you in loop with each and every process.Gives you detail
            insight of what went wrong and how it can be avoided in future.
          </p>
          <span className={styles.testimonialFrom}>- Madhuri</span>
        </div>
        <div className={styles.testimonial}>
          <p className={styles.testimonialDescription}>
            Dr.K.Rajesh Reddy and Dr.K.Pavani Reddy are extremely intelligent doctors.
            They explain the problem and precedure very nicely and patiently. I
            got 2 root canals and few fillings done. Their work was awesome, no
            pain and very polite friendly doctors.
          </p>
          <span className={styles.testimonialFrom}>- Neetu Handa</span>
        </div>
      </div>
    </section>
  );
}
