import React from "react";

import styles from "./PeriodontalMenu.module.css";
import SideMenuPeriodontalMenu from "./SideMenuPeriodontalMenu";

export default function TissueRegeneration({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const periodontalProperty = {
    textColor: "#fc7444",
    spanColor: "#978ab1",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = periodontalProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            GUIDED BONE & TISSUE REGENERATION
          </h1>

          <p className={styles.mainDescription}>
            Gum disease has traditionally been treated by eliminating the gum
            pockets by trimming away the infected gum tissue and by
            re-contouring the uneven bone tissue. Although this is still an
            effective way of treating gum disease, new and more sophisticated
            procedures are used routinely today. One of these advancements is
            <b> guided bone regeneration, </b>also referred to as{" "}
            <b>
              guided tissue regeneration. This procedure is used to stabilize
              endangered teeth or to prepare the jaw for dental implants.
            </b>
          </p>

          <p className={styles.secondaryDescription}>
            As periodontal disease progresses, pockets of degenerated bone
            develop in the jaw. These pockets can promote the growth of bacteria
            and the spread of infection. To address these pockets, Dentist may
            recommend tissue regeneration. During this surgical procedure, the
            pockets are cleaned thoroughly, and a membrane is installed between
            the soft tissue and the pocket in the bone. Some of these membranes
            are bio-absorbable and some require removal. The membrane covers the
            pocket so that fast-growing soft tissue is blocked, and
            slower-growing bone can begin to grow, or “regenerate” itself.
          </p>
          <p className={styles.secondaryDescription}>
            The effectiveness of the procedure generally depends on the
            patient’s willingness to follow a strict postoperative diet and
            careful oral care. Dentist will help you determine if bone
            regeneration surgery is right for you.
          </p>
        </div>
        <SideMenuPeriodontalMenu
          menuItem={handleMenuClick}
          menuItemName={menuItemName}
          dentistry={dentistry}
        />
      </div>
    </section>
  );
}
