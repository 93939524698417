import React from "react";

import { useInView } from "react-intersection-observer";
import styles from "./ProsthodonticServices.module.css";

import cosmeticSeviceImg from "../../images/cosmeticDentistry.png";
import restorativeImg from "../../images/ClearBraces.png";
import preventativeImg from "../../images/PreventiveCare1.jpg";
import general from "../../images/EndodonticServices.png";
import { IonIcon } from "@ionic/react";

import { arrowForward } from "ionicons/icons";
export default function ProsthodonticServices({ serviceName }) {
  const [header, headerView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  const [service, serviceView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  return (
    <section className={styles.generalServiesSection}>
      <div
        className={`${styles.generalDescriptionCenter} ${
          headerView ? styles.animate : ""
        }`}
        ref={header}
      >
        <h1 className={styles.generalServiceHeader}>
          Our Prosthodontic Services
        </h1>

        <p className={styles.generalServiceHeaderDescription}>
          Our Prosthodontic dentistry offers all kinds of services and study new
          technology to add new custom services to the list
        </p>
      </div>

      <div
        className={`${styles.generalServices} ${
          serviceView ? styles.animate : ""
        }`}
        ref={service}
      >
        <div className={styles.generalserviceCard}>
          <img
            src={restorativeImg}
            className={styles.generalserviceCardImage}
          ></img>
          <div className={styles.generalserviceCardContent}>
            <h3 className={styles.generalserviceCardHeader}>Restorative</h3>
            <p className={styles.generalserviceCardDescription}>
              Our dental treatments and materials can make a real difference,
              and the skill, experience, and commitment of our practice — can
              literally redesign your smile.
            </p>
          </div>

          <div className={styles.generalBtnBorder}>
            <a
              className={styles.generalserviceCardNavigation}
              href="/prosthodontic/Restorative"
            >
              <span>Learn more</span>
              <IonIcon icon={arrowForward} className={styles.sideArrow} />
            </a>
          </div>
        </div>

        <div className={styles.generalserviceCard}>
          <img
            src={cosmeticSeviceImg}
            className={styles.generalserviceCardImage}
          ></img>
          <div className={styles.generalserviceCardContent}>
            <h3 className={styles.generalserviceCardHeader}>
              Cosmetic Dentistry
            </h3>
            <p className={styles.generalserviceCardDescription}>
              Unleash the true beauty of your smile through our Cosmetic
              Dentistry expertise. We discover tailored treatments that
              prioritize both aesthetics and oral health.
            </p>
          </div>

          <div className={styles.generalBtnBorder}>
            <a
              className={styles.generalserviceCardNavigation}
              href="/prosthodontic/Cosmetic"
            >
              <span>Learn more</span>
              <IonIcon icon={arrowForward} className={styles.sideArrow} />
            </a>
          </div>
        </div>
      </div>
      <div className={styles.regularCheckup}>
        <span>
          Don’t skip your regular check-up for teeth.
          <a href="#">Book an appointment!</a>
        </span>
      </div>
    </section>
  );
}
