import React from "react";
import { useInView } from "react-intersection-observer";

import styles from "./WhyChoose.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faSmile,
  faMedkit,
  faStethoscope,
  faTooth,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons";
import { useAppContext } from "../../AppContext";

function WhyChoose() {
  const [header, headerView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  const [service, serviceView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  const [service1, serviceView1] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  const [service2, serviceView2] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  const [service3, serviceView3] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  const [service4, serviceView4] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  const [service5, serviceView5] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });

  const { isScrolling } = useAppContext();

  return (
    <section className={styles.WhyChooseSection}>
      <div className={styles.WhyChooseComponent}>
        <span
          className={`${styles.HeaderText} ${
            headerView && !isScrolling ? styles.animate : ""
          }`}
          ref={header}
        >
          Why Choose Us
        </span>
        <h1
          className={`${styles.HeaderText} ${
            headerView && !isScrolling ? styles.animate : ""
          }`}
          ref={header}
        >
          Reasons why we're widely favored
        </h1>
        <div className={styles.WhyGridComponent}>
          <div
            className={`${styles.WhyGrid} ${
              serviceView && !isScrolling ? styles.animate : ""
            }`}
            ref={service}
          >
            <FontAwesomeIcon icon={faHeart} className={styles.fal} />
            <div className={styles.WhyGridContnet}>
              <h4>Work with Hearts</h4>
              <p>
                We care for your dental health with a great compassion &
                understanding so that you can have the best smile.
              </p>
            </div>
          </div>
          <div
            className={`${styles.WhyGrid} ${
              serviceView1 && !isScrolling ? styles.animate : ""
            }`}
            ref={service1}
          >
            <FontAwesomeIcon icon={faSmile} className={styles.fal} />
            <div className={styles.WhyGridContnet}>
              <h4>Serve with Smile</h4>
              <p>
                The smile never fades on our doctors’ faces as they always want
                to create an atmosphere that feels comfortable.
              </p>
            </div>
          </div>
          <div
            className={`${styles.WhyGrid} ${
              serviceView2 && !isScrolling ? styles.animate : ""
            }`}
            ref={service2}
          >
            <FontAwesomeIcon icon={faMedkit} className={styles.fal} />
            <div className={styles.WhyGridContnet}>
              <h4>Help at Hand</h4>
              <p>
                We are ready to check for any teeth issue at our center and at
                any time of the day, even on weekends & holidays.
              </p>
            </div>
          </div>
          <div
            className={`${styles.WhyGrid} ${
              serviceView3 && !isScrolling ? styles.animate : ""
            }`}
            ref={service3}
          >
            <FontAwesomeIcon icon={faStethoscope} className={styles.fal} />
            <div className={styles.WhyGridContnet}>
              <h4>Precise Diagnosis</h4>
              <p>
                When your teeth are decayed or damaged, we use modern tools to
                detect the area and provide treatment.
              </p>
            </div>
          </div>
          <div
            className={`${styles.WhyGrid} ${
              serviceView4 && !isScrolling ? styles.animate : ""
            }`}
            ref={service4}
          >
            <FontAwesomeIcon icon={faTooth} className={styles.fal} />
            <div className={styles.WhyGridContnet}>
              <h4>Annual Check-ups</h4>
              <p>
                We provide annual check-up for dental health conditions and
                offer many promotions for members of our center.
              </p>
            </div>
          </div>
          <div
            className={`${styles.WhyGrid} ${
              serviceView5 && !isScrolling ? styles.animate : ""
            }`}
            ref={service5}
          >
            <FontAwesomeIcon icon={faCreditCard} className={styles.fal} />
            <div className={styles.WhyGridContnet}>
              <h4>Flexible Installment</h4>
              <p>
                We allow periodic installments of purchasing costs in case
                customers are unable to afford the price
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhyChoose;
