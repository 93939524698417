import React, { useState, useEffect } from "react";

import styles from "../Braces/Braces.module.css";
import SideMenuLivingBraces from "./SideMenuLivingBraces";

export default function BrushingAndFlossing({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            ORTHODONTIC BRUSHING AND FLOSSING
          </h1>
          <h4 className={styles.secondaryQuestion}>Brush after every meal.</h4>
          <p className={styles.mainDescription}>
            <ul
              className={styles.subDescription}
              style={{ fontSize: "1.4rem", paddingTop: "0rem" }}
            >
              <li>
                Use a toothbrush with a small amount of toothpaste.Brushing with
                Braces Use circular, vibrating motions around the gumline for
                about 10 seconds on each tooth.
              </li>
              <li>Brush every tooth slowly.</li>
              <li>
                Brush the lower teeth up and the upper teeth down.Also brush
                your tongue and the roof of your mouth.
              </li>
            </ul>
          </p>
          <h4
            className={styles.secondaryQuestion}
          >
            Floss nightly after brushing to remove the plaque missed by
            brushing.
          </h4>
          <p className={styles.secondaryDescription}>
            <ul
              className={styles.subDescription}
              style={{ fontSize: "1.4rem", paddingTop: "0rem" }}
            >
              <li>Carefully pull waxed floss between wire and braces.</li>
              <li>Floss carefully around the braces.</li>
              <li>Floss carefully around the gum area.</li>
              <li>Floss around each tooth.</li>
            </ul>
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuLivingBraces
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
