import React, { useState, useEffect } from "react";

import styles from "./Procedures.module.css";
import SideMenuProcedures from "./SideMenuProcedures";

export default function FacialTrauma({ menuItem, menuItemName, dentistry }) {
  const oralsurgery = {
    textColor: "#d099d9",
    spanColor: "#644a68",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = oralsurgery;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            FACIAL TRAUMA
          </h1>

          <p className={styles.mainDescription}>
            An oral and maxillofacial specialist is thoroughly qualified to
            repair <b>facial injuries. </b> These professionals are well versed
            in emergency care, acute treatment, and long-term reconstruction and
            rehabilitation – not just for physical reasons, but for emotional
            ones as well. <b>Injuries to the face, </b>by their very nature,
            impart a high degree of emotional as well as physical trauma to
            patients. The science and art of treating these injuries requires
            special training involving a “hands on” experience and an
            understanding of how the treatment provided will influence the
            patient’s long-term function and appearance.
          </p>
          <p
            className={styles.mainDescription}
            style={{ marginBottom: "0.5rem" }}
          >
            Oral and maxillofacial surgeon meet and exceed these modern
            standards. He is trained, skilled, and uniquely qualified to manage
            and treat facial trauma and deliver emergency room coverage for
            facial injuries, which include the following conditions:
          </p>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>Facial lacerations</li>
              <li>Intraoral lacerations</li>
              <li>Avulsed (knocked out) teeth</li>
              <li>Fractured facial bones (cheek, nose, or eye socket)</li>
              <li>Fractured jaws (upper and lower jaw)</li>
            </ul>
          </p>

          <h4 className={styles.secondaryQuestion}>
            The Nature of Maxillofacial Trauma
          </h4>
          <p className={styles.secondaryDescription}>
            There are a number of possible causes of facial trauma, such as
            motor vehicle accidents, accidental falls, sports injuries,
            interpersonal violence, and work-related injuries. Types of facial
            injuries can range from injuries to teeth to extremely severe
            injuries to the skin and bones of the face. Typically, facial
            injuries are classified as either soft tissue injuries (skin and
            gums), bone injuries (fractures), or injuries to special regions
            (such as the eyes, facial nerves, or the salivary glands).
          </p>
          <h4 className={styles.secondaryQuestion}>
            Soft Tissue Injuries of the Maxillofacial Region
          </h4>
          <p className={styles.secondaryDescription}>
            When soft tissue injuries, such as lacerations, occur on the face
            they are repaired by suturing. In addition to the obvious concern of
            providing a repair that yields the best cosmetic result possible,
            care is taken to inspect for and treat injuries to structures such
            as facial nerves, salivary glands, and salivary ducts (or outflow
            channels).
          </p>
          <h4 className={styles.secondaryQuestion}>
            Bone Injuries of the Maxillofacial Region
          </h4>
          <p className={styles.secondaryDescription}>
            Fractures to the bones in the face are treated in a similar manner
            to fractures in other parts of the body. The specific form of
            treatment is determined by various factors, which include the
            location of the fracture, the severity of the fracture, and the age
            and general health of the patient. When an arm or leg is fractured a
            cast is often applied to stabilize the bone to allow for proper
            healing. Since a cast cannot be placed on the face, other means have
            been developed to stabilize facial fractures.
          </p>
          <p className={styles.secondaryDescription}>
            One of these options involves wiring the jaws together for certain
            fractures of the upper and/or lower jaw. Certain other types of
            fractures of the jaw are best treated and stabilized by the surgical
            placement of small plates and screws at the involved site. This
            technique of treatment can often allow for healing and eliminates
            the necessity of having the jaws wired together. This technique is
            called “rigid fixation” of a fracture. The relatively recent
            development and use of rigid fixation has profoundly improved the
            recovery period for many patients, allowing them to return to normal
            function more quickly.
          </p>
          <p className={styles.secondaryDescription}>
            The treatment of facial fractures should be accomplished in a
            thorough and predictable manner. More importantly, the patient’s
            facial appearance should be minimally affected. An attempt at
            accessing the facial bones through the fewest incisions necessary is
            always made. At the same time, the incisions that become necessary
            are designed to be small and, whenever possible, are placed so that
            the resultant scar is hidden.
          </p>
          <h4 className={styles.secondaryQuestion}>
            Injuries to the Teeth & Surrounding Dental Structures
          </h4>
          <p className={styles.secondaryDescription}>
            Isolated injuries to teeth are quite common and may require the
            expertise of various dental specialists. Oral surgeons usually are
            involved in treating fractures in the supporting bone, or in
            replanting teeth that have been displaced or knocked out. These
            types of injuries are treated by one of a number of forms of
            splinting (stabilizing by wiring or bonding teeth together). If a
            tooth is knocked out it should be placed in salt water or milk. The
            sooner the tooth is re-inserted into the dental socket the better
            chance it will survive. Therefore, the patient should see a dentist
            or oral surgeon as soon as possible. Never attempt to wipe the tooth
            off, since remnants of the ligament that hold the tooth in the jaw
            are attached and are vital to the success of replanting the tooth.
            Other dental specialists may be called upon, such as endodontists,
            who may be asked to perform root canal therapy, and/or restorative
            dentists, who may need to repair or rebuild fractured teeth. In the
            event that injured teeth cannot be saved or repaired, dental
            implants are often now utilized as replacements for missing teeth.
          </p>
          <p className={styles.secondaryDescription}>
            The proper treatment of facial injuries is now the realm of
            specialists who are well versed in emergency care, acute treatment,
            long-term reconstruction, and rehabilitation of the patient.
          </p>
        </div>

        {windowWidth > 730 && (
          <SideMenuProcedures
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
