import React, { useState, useEffect } from "react";

import styles from "./Braces.module.css";
import SideMenuBraces from "./SideMenuBraces";

export default function InOvationSystemBraces({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const orthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#8ba7c3",
    backgroundColor: "#e7f1fca0",
  };
  const styleProperties = orthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            In-Ovation System Braces
          </h1>
          <h4 className={styles.secondaryQuestion}>
            Your choice for a beautiful smile.
          </h4>
          <h4 className={styles.secondaryQuestion}>
            We happily offer our patients In-Ovation System Braces.
          </h4>
          <p className={styles.mainDescription}>
            In-Ovation is a new and technically advanced system that can give
            you the radiant smile you have always wanted more quickly than
            traditional braces, and without the need for ugly, unsanitary and
            uncomfortable ties or elastics. Best of all, there’s never a need to
            have your braces tightened! Using light, gentle forces, In-Ovation
            makes the path to a beautiful smile faster and more comfortable than
            you ever thought
          </p>
          <h4 className={styles.secondaryQuestion}>Traditional Braces</h4>
          <p className={styles.secondaryDescription}>
            Elastics and ties used in older braces put pressure and friction on
            teeth which slows treatment and causes discomfort.
          </p>
          <h4 className={styles.secondaryQuestion}>In-Ovation</h4>
          <p className={styles.secondaryDescription}>
            With the In-Ovation R system, teeth are free to move more quickly,
            easily and comfortably.
          </p>
          <h4 className={styles.secondaryQuestion}>
            With In-Ovation You Can Have…
          </h4>
          <p className={styles.secondaryDescription}>
            <ul
              className={styles.subDescription}
              style={{ fontSize: "1.4rem", paddingTop: "0rem" }}
            >
              <li>Faster results</li>
              <li>Shorter appointments</li>
              <li>Fewer office visits</li>
              <li>Greater comfort</li>
              <li>Improved facial proportion and appearance</li>
            </ul>
          </p>
          <h4 className={styles.secondaryQuestion}>
            Attractive Advantages For You…
          </h4>
          <p className={styles.secondaryDescription}>
            In-Ovation comes in three styles that look great and are comfortable
            to wear. Ask your doctor which In-Ovation bracket is best for you!
          </p>
          <p className={styles.secondaryDescription}>
            <ul
              className={styles.subDescription}
              style={{ fontSize: "1.4rem", paddingTop: "0rem" }}
            >
              <li>
                <b>In-Ovation R</b> is smaller and has a lower profile than
                other self-ligating brackets. With no elastic or metal ties, you
                will have less irritation, less plaque build-up and less
                difficulty keeping your teeth clean.
              </li>
              <li>
                <b>In-Ovation C</b> is a translucent ceramic bracket that offers
                fast and comfortable treatment with the added benefit of high
                aesthetics.
              </li>
              <li>
                <b>In-Ovation L</b> MTM offers a superb alternative to tray
                aligners for patients requiring 6mm of movement or less.
                Completely invisible, L MTM self-ligating “quick clips” work
                within a few short months to give you the perfect smile you have
                always wanted.
              </li>
            </ul>
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuBraces
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
