import React, { useState, useEffect } from "react";
import SideMenuRestorativeDentistry from "../RestorativeDentistry/SideMenuRestorativeDentistry";

import styles from "../CosmeticDentistry/DentalBonding.module.css";

export default function BruxismTreatment({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            BRUXISM TREATMENT
          </h1>

          <p className={styles.mainDescription}>
            <b>Bruxism </b>
            is an oral parafunctional activity which commonly occurs with most
            people at some point in their lives. The two main characteristics of
            this condition are grinding the teeth and clenching of the jaw.
            These actions usually occur during a person’s sleeping hours, but
            occasionally they occur during the day.
          </p>

          <p className={styles.mainDescription}>
            Bruxism is one of the most common known sleep disorders. Chewing is
            a neuromuscular activity controlled by a subconscious process, but
            more highly controlled by the brain. During sleep, the subconscious
            process may become active while the higher control is inactive
            (asleep), resulting in bruxism. The most common symptoms are
            earaches and headaches, depression, eating disorders, anxiety, and
            chronic stress.
          </p>
          <p className={styles.mainDescription}>
            A BiteStrip® is a device available from our office to diagnose
            bruxism at home. The device itself is a small electromyography,
            which can sense and monitor activity in the jaw muscles during
            sleep. The frequency and severity of the condition can then be
            assessed and a plan of treatment can be determined.
          </p>

          <h4 className={styles.secondaryQuestion}>
            Why should I seek treatment for Bruxism?
          </h4>
          <p className={styles.mainDescription}>
            <b>Gum Recession –</b> Bruxism is a leading cause of gum recession
            and tooth loss. Grinding teeth can damage the soft tissue directly
            and lead to loose teeth and deep pockets where bacteria are able to
            colonize and decay the supporting bone.
          </p>
          <p className={styles.mainDescription}>
            <b>Facial Pain –</b> Grinding can eventually shorten and blunt the
            teeth. This can lead to muscle pain in the myofascial region and, in
            severe cases, incapacitating headaches.
          </p>
          <p className={styles.mainDescription}>
            <b>Occlusal Trauma –</b> The abnormal wear patterns on the occlusal
            (chewing) surfaces of the teeth can lead to fractures, which if left
            untreated, may require restorative treatment at a later time.
          </p>
          <p className={styles.mainDescription}>
            <b>Arthritis –</b> In the most severe cases, bruxism can eventually
            lead to painful arthritis in the temporomandibular (TMJ) joints that
            allow the jaw to open and close smoothly.
          </p>
          <h4 className={styles.secondaryQuestion}>
            Bruxism Treatment Options
          </h4>
          <p className={styles.mainDescription}>
            Though there is no one cure for bruxism, there are a variety of
            devices and services available from our office to help treat
            bruxism.
          </p>
          <p className={styles.mainDescription}>
            <b>Mouthguard –</b> An acrylic mouthguard can be designed from teeth
            impressions to minimize the abrasive grinding action during normal
            sleep. Mouthguards are expected to be worn on a long-term basis to
            help prevent tooth damage.
          </p>
          <p className={styles.mainDescription}>
            <b>NTI-tss Device –</b> This device only covers the front teeth and
            must be fitted at our office. The idea behind the NTI-tss is to
            prevent grinding the rear molars by limiting the contraction of the
            temporalis muscle in the jaw.
          </p>
          {/* <p className={styles.mainDescription}>
            <b>BOTOX® Cosmetic –</b> BOTOX® Cosmetic can be injected into the
            muscles responsible for bruxing by disabling them enough to prevent
            grinding, but not enough to disrupt normal functions like speaking
            and chewing.
          </p> */}

          <p className={styles.mainDescription}>
            Once bruxing is under control, we can perform a variety of dental
            procedures to restore the pleasant aesthetic appearance to your
            smile such as crowns, gum grafts and crown lengthening.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuRestorativeDentistry
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
