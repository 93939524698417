import React from "react";

import { useInView } from "react-intersection-observer";
import styles from "./OralSurgeryServices3.module.css";

import endodontic from "../../images/Procedures.jpg";
import generalHero from "../../images/Review.jpg";
import { IonIcon } from "@ionic/react";

import { arrowForward } from "ionicons/icons";
export default function OralSurgeryServices3({ serviceName }) {
  const [header, headerView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  const [service, serviceView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  return (
    <section className={styles.generalServiesSection}>
      <div
        className={`${styles.generalDescriptionCenter} ${
          headerView ? styles.animate : ""
        }`}
        ref={header}
      >
        <h1 className={styles.generalServiceHeader}>
          Our OralSurgery Services
        </h1>

        <p className={styles.generalServiceHeaderDescription}>
          Our OralSurgery dentistry offers all kinds of services and study new
          technology to add new custom services to the list
        </p>
      </div>

      <div
        className={`${styles.generalServices} ${
          serviceView ? styles.animate : ""
        }`}
        ref={service}
      >
        <div className={styles.generalserviceCard}>
          <img
            src={generalHero}
            className={styles.generalserviceCardImage}
          ></img>
          <div className={styles.generalserviceCardContent}>
            <h3 className={styles.generalserviceCardHeader}>
              Surgical Instructions
            </h3>
            <p className={styles.generalserviceCardDescription}>
              Detailed post-operative guidelines to ensure a smooth and safe
              recovery after your oral surgery.
            </p>
          </div>
          <div className={styles.generalBtnBorder}>
            <a
              className={styles.generalserviceCardNavigation}
              href="/oralsurgery/SurgicalInstructions"
            >
              <span>Learn more</span>
              <IonIcon icon={arrowForward} className={styles.sideArrow} />
            </a>
          </div>
        </div>
        <div className={styles.generalserviceCard}>
          <img
            src={endodontic}
            className={styles.generalserviceCardImage}
          ></img>
          <div className={styles.generalserviceCardContent}>
            <h3 className={styles.generalserviceCardHeader}>Procedures</h3>
            <p className={styles.generalserviceCardDescription}>
              {/* Our OralSurgery Dentistry department is focused on the restoration
              and replacement of missing or damaged teeth. */}
              Comprehensive oral surgery procedures such as tooth extractions,
              dental implants, and corrective jaw surgeries tailored to your
              dental needs.
            </p>
          </div>

          <div className={styles.generalBtnBorder}>
            <a
              className={styles.generalserviceCardNavigation}
              href="oralsurgery/Procedures"
            >
              <span>Learn more</span>
              <IonIcon icon={arrowForward} className={styles.sideArrow} />
            </a>
          </div>
        </div>
      </div>
      <div className={styles.regularCheckup}>
        <span>
          Don’t skip your regular check-up for teeth.
          <a href="#">Book an appointment!</a>
        </span>
      </div>
    </section>
  );
}
