import React, { useState, useEffect } from "react";

import styles from "../PediatricMenu/PediatricMenu.module.css";
import SideMenuPediatricMenu from "./SideMenuPediatricMenu";

export default function ToothEruption({ menuItem, menuItemName, dentistry }) {
  const pediatricProperty = {
    textColor: "#9086a5",
    spanColor: "#776c8c",
    backgroundColor: "#e6e4eb",
  };
  const styleProperties = pediatricProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            TOOTH ERUPTION
          </h1>

          <p className={styles.mainDescription}>
            The first baby teeth come into the mouth are the two bottom front
            teeth. You will notice this when your baby is about six to eight
            months old. Next to follow will be the four upper front teeth and
            the remainder of your baby’s teeth will appear periodically. They
            will usually appear in pairs along the sides of the jaw until the
            child is about 2-1/2 years old.
          </p>

          <p className={styles.secondaryDescription}>
            At around 2-1/2 years old, your child should have all 20 teeth.
            Between the ages of five and six, the first permanent teeth will
            begin to erupt. Some of the permanent teeth replace baby teeth and
            some don’t. Don’t worry if some teeth are a few months early or late
            as all children are different.
          </p>
          <p className={styles.secondaryDescription}>
            Baby teeth are important as they not only hold space for permanent
            teeth, but they are important to chewing, biting, speech, and
            appearance.
          </p>
          <p className={styles.secondaryDescription}>
            For this reason it is important to maintain a healthy diet and daily
            hygiene.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuPediatricMenu
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
