import React from "react";

import { useInView } from "react-intersection-observer";
import styles from "./AboutWelcome.module.css";

export default function AboutWelcome({ serviceName }) {
  const [header, headerView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  const [service, serviceView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  return (
    <section className={styles.generalServiesSection}>
      <div
        className={`${styles.generalDescriptionCenter} ${
          headerView ? styles.animate : ""
        }`}
        ref={header}
      >
        <h1 className={styles.generalServiceHeader}>
          We Treat You With Kindness
        </h1>

        <p className={styles.generalServiceHeaderDescription}>
          At Dental Planet, we believe in the power of healthy smiles to
          transform lives. Established with a passion for exceptional dental
          care, we are dedicated to providing our community with personalized,
          compassionate, and top-tier dental services.
        </p>
        <br></br>
        <p className={styles.generalServiceHeaderDescription}>
          Our mission is to create a positive impact on the oral health and
          overall well-being of our patients. We are committed to delivering
          comprehensive dental care with a focus on preventive measures, patient
          education, and advanced treatments.
        </p>
        <div className={styles.ctaBtn}>
          <a href="#" className={styles.btnPrimary}>
            Book Appointment
          </a>
        </div>
      </div>
    </section>
  );
}
