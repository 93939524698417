import React, { useState, useEffect } from "react";
import SideMenuCosmeticProsthodontic from "../RestorativeProsthodontic/SideMenuCosmeticProsthodontic";

import styles from "./DentalBonding.module.css";
import SideMenuCosmeticDentistry from "./SideMenuCosmeticDentistry";

export default function InlaysOutlays({ menuItem, menuItemName, dentistry }) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            Inlays & Onlays
          </h1>
          <p className={styles.marginBottom}>
            <span
              className={styles.secondaryBold}
              style={{ color: styleProperties.spanColor }}
            >
              When more than half of a tooth’s biting surface is damaged, we
              often use an inlay or onlay to restore the tooth’s anatomy.
            </span>
          </p>
          <h4 className={styles.secondaryQuestion}>
            What are dental inlays and onlays?
          </h4>
          <p className={styles.secondaryDescription}>
            Inlays and onlays are made of porcelain, gold, or composite resin
            and are bonded to the damaged area of the tooth. An inlay, which is
            similar to a dental filling, is placed inside the cusp tips of the
            tooth.
          </p>
          <p className={styles.secondaryDescription}>
            Traditionally, gold has been the material of choice for inlays and
            onlays. However, in recent years, porcelain has become increasingly
            popular due to its strength and color, which has the potential of
            matching the natural color of your teeth.
          </p>
          <h4 className={styles.secondaryQuestion}>
            How are inlays and onlays applied?
          </h4>
          <p className={styles.secondaryDescription}>
            Two appointments are required for the completion of inlays and
            onlays.
          </p>
          <p className={styles.marginBottom}>
            <span
              className={styles.secondaryBold}
              style={{ color: styleProperties.spanColor }}
            >
              First Appointment
            </span>
          </p>
          <p className={styles.secondaryDescription}>
            During the first visit, the damaged or decaying area of the tooth
            (or the filling that is being replaced) is removed, and the tooth is
            prepared for the inlay or onlay. To ensure proper fit and bite, we
            make an impression of the tooth and send it to a lab for
            fabrication. Our Dentist then applies a temporary filling on the
            tooth and schedules the next appointment.
          </p>
          <p className={styles.marginBottom}>
            <span
              className={styles.secondaryBold}
              style={{ color: styleProperties.spanColor }}
            >
              Second Appointment
            </span>
          </p>
          <p className={styles.secondaryDescription}>
            At the second appointment, the temporary filling is removed. Next,
            Dentist make sure that the inlay or onlay fits correctly.
            If the fit is satisfactory, the inlay or onlay is bonded to the
            tooth with a strong resin and polished to a smooth finish.
          </p>
          <h4 className={styles.secondaryQuestion}>
            Considerations for Inlays and Onlays
          </h4>
          <p className={styles.secondaryDescription}>
            Traditional fillings can reduce the strength of a natural tooth by
            up to 50 percent. Alternatively, inlays and onlays, which are bonded
            directly onto the tooth, using special high-strength resins,
            actually increase the strength of a tooth by up to 75 percent. As a
            result, they typically last from 10 to 30 years. In some cases,
            where the damage to the tooth is not extensive enough to merit an
            entire dental crown, onlays provide a very good alternative. For
            more information regarding our inlays and onlays, call our office
            today! Dental Planet Office Phone Number +91 9908873366
          </p>
        </div>
        {windowWidth > 730 &&
          (dentistry == "general" ? (
            <SideMenuCosmeticDentistry
              menuItem={handleMenuClick}
              menuItemName={menuItemName}
              dentistry={dentistry}
            />
          ) : (
            <SideMenuCosmeticProsthodontic
              menuItem={handleMenuClick}
              menuItemName={menuItemName}
              dentistry={dentistry}
            />
          ))}
      </div>
    </section>
  );
}
