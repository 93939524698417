import React, { useState, useEffect } from "react";

import styles from "../Braces/Braces.module.css";

import SideMenuInvisalign from "./SideMenuInvisalign";

export default function InvisalignTeen({ menuItem, menuItemName, dentistry }) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            INVISALIGN® TEEN COULD BE THE ANSWER FOR YOUR FAMILY
          </h1>
          <p className={styles.mainDescription}>
            Invisalign® Teen offers a completely new approach to orthodontics,
            giving you the smile you want without the embarrassment and hassles
            of traditional braces. No teen wants to have metal in their mouth.
            And no parent wants to argue about what their teen can and can’t do
            with braces. Invisalign® Teen straightens teeth using clear plastic
            aligners, doing the job of braces without metal brackets and wires –
            a win for both parents and teens alike.
          </p>
          <h4 className={styles.secondaryQuestion}>
            With Invisalign® Teen, you can:
          </h4>
          <p>
            <ul
              className={styles.subDescription}
              style={{ fontSize: "1.4rem", paddingTop: "0rem" }}
            >
              <li>
                Eat the foods you love without worrying about breaking brackets
                or loosening wires. Simply remove the aligners while you eat
                your favorite foods and then put them back in when you are done.
              </li>
              <li>
                Feel Confident. Since Invisalign® Teen aligners are clear, no
                one will see them in pictures or in person.
              </li>
              <li>
                Brush and floss as usual to maintain good oral health and fresh
                breath. Simply remove your aligners to clean your teeth in the
                morning and evening as you normally would.
              </li>
              <li>
                Participate in your favorite activities without worry.
                Invisalign® Teen allows you to play sports or a musical
                instrument without worrying about cutting your mouth. And you
                can temporarily replace your aligners with a mouth guard when
                required for contact sports.
              </li>
              <li>
                Be comfortable! Invisalign® Teen plastic aligners are custom fit
                for comfort and durability.
              </li>
            </ul>
          </p>
          <h4 className={styles.secondaryQuestion}>
            With Invisalign® Teen, parents can expect:
          </h4>
          <p>
            <ul
              className={styles.subDescription}
              style={{ fontSize: "1.4rem", paddingTop: "0rem" }}
            >
              <li>
                Compliance. Teen aligners have blue dots that fade when they are
                worn. If a teen isn’t wearing them, the dots won’t fade. This
                allows parents to make the most of their investment by ensuring
                that their teen is wearing the aligners as prescribed: 20-22
                hours per day.
              </li>
              {/* <li>
                Affordability. The cost is usually the same as metal braces, and
                up to half of the cost may be covered by dental insurance. In
                addition to that, Invisalign® Teen provides six replacement
                aligners in case they are lost.
              </li> */}
            </ul>
          </p>
          <p className={styles.mainDescription}>
            Now your teen can have a straighter smile with a state of the art
            system that fits his or her busy lifestyle. In fact, Invisalign®
            Teen clear braces are so convenient, they’re a great fit for the
            entire family.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuInvisalign
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
