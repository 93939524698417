import React, { useState, useEffect } from "react";

import styles from "./Procedures.module.css";

import SideMenuProcedures from "./SideMenuProcedures";

export default function Procedures({ menuItem, menuItemName, dentistry }) {
  const oralsurgery = {
    textColor: "#d099d9",
    spanColor: "#644a68",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = oralsurgery;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            ORAL SURGERY PROCEDURES
          </h1>
          <p className={styles.mainDescription}>
            Oral and maxillofacial surgeons manage a wide variety of problems
            relating to the mouth, teeth, and facial regions. Select any of the
            links below to find out more about the services
          </p>

          <ul className={styles.items}>
            <li className={styles.item}>
              <a href="/oralsurgery/Procedures/DentalImplants">
                Dental Implants
              </a>
            </li>
            <li className={styles.item}>
              <a href="/oralsurgery/Procedures/BoneGrafting">Bone Grafting</a>
            </li>
            <li className={styles.item}>
              <a href="/oralsurgery/Procedures/WisdomTeeth">Wisdom Teeth</a>
            </li>
            <li className={styles.item}>
              <a href="/oralsurgery/Procedures/JawSurgery">
                Orthognathic Surgery
              </a>
            </li>
            <li className={styles.item}>
              <a href="/oralsurgery/Procedures/FacialTrauma">Facial Trauma</a>
            </li>
            <li className={styles.item}>
              <a href="/oralsurgery/Procedures/ToothExtractions">
                Tooth Extractions
              </a>
            </li>
            <li className={styles.item}>
              <a href="/oralsurgery/Procedures/Preprosthetic">
                Pre-Prosthetic Surgery
              </a>
            </li>
            <li className={styles.item}>
              <a href="/oralsurgery/Procedures/DistractionOsteogenesis">
                Distraction Osteogenesis
              </a>
            </li>
            <li className={styles.item}>
              <a href="/oralsurgery/Procedures/OralPathology">Oral Cancer</a>
            </li>
            <li className={styles.item}>
              <a href="/oralsurgery/Procedures/TMJ">TMJ</a>
            </li>
            <li className={styles.item}>
              <a href="/oralsurgery/Procedures/SleepApnea">Sleep Apnea</a>
            </li>
            <li className={styles.item}>
              <a href="/oralsurgery/Procedures/CleftLipAndPalate">
                Cleft Lip & Palate
              </a>
            </li>
            {/* <li className={styles.item}>
              <a href="/oralsurgery/Procedures/Protein">
                Bone Morphogenetic Protein
              </a>
            </li> */}
            <li className={styles.item}>
              <a href="/oralsurgery/Procedures/StemCells">Stem Cells</a>
            </li>
            <li className={styles.item}>
              <a href="/oralsurgery/Procedures/RichPlasma">
                Platelet Rich Plasma and Platelet Rich Fibrin
              </a>
            </li>
            {/* <li className={styles.item}>
              <a href="/oralsurgery/Procedures/RichFibrin">
                Platelet Rich Fibrin
              </a>
            </li> */}
            <li className={styles.item}>
              <a href="/oralsurgery/Procedures/ImpactedTeeth">
                Exposure of Impacted Teeth
              </a>
            </li>
            <li className={styles.item}>
              <a href="/oralsurgery/Procedures/3DImaging">3D Imaging</a>
            </li>
            <li className={styles.item}>
              <a href="/oralsurgery/Procedures/ANESTHESIA">Anesthesia</a>
            </li>
          </ul>
        </div>
        {windowWidth > 730 && (
          <SideMenuProcedures
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
