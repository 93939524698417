import React, { useState, useRef, useEffect } from "react";

import { useInView } from "react-intersection-observer";
import PediatricDropDownHeader from "./PediatricDropDownHeader";
import styles from "./Pediatricheader3.module.css";
import { useAppContext } from "../../AppContext";
import DropDownHeader from "../Home/DropDownHeader";

export default function PediatricHeader3({ generalMenuItem }) {
  const { setDisableAnimations, setIsScrolling } = useAppContext();
  const [ref, inView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  const [displayList, setDisplay] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const handleMenuItem = (data) => {
    setDisplay("none");
    generalMenuItem(data);
  };

  const handleHover = () => {
    setDisplay("flex");
    setIsHovered(true);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleScrollToSchedule = () => {
    setDisableAnimations(true);
    setIsScrolling(true);
    const scheduleSection = document.getElementById("schedule-section");
    if (scheduleSection) {
      scheduleSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <section className={styles.header}>
      <div
        className={`${styles.headerContainer} ${inView ? styles.animate : ""}`}
        ref={ref}
      >
        <div className={styles.logoSection}>
          <a href="/">
            <h3>Dental Planet</h3>
          </a>
        </div>
        {windowWidth <= 730 ? (
          <DropDownHeader dentistry={"pediatric"} />
        ) : (
          <nav className={styles.navBar}>
            <ul className={styles.navList}>
              <li className={styles.navItem}>
                <div className={styles.navBlock}>Services</div>
                <ul className={styles.dropDown}>
                  <li className={styles.dropDownItem}>
                    <a href="/general" className={styles.dropDownLink}>
                      General
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/prosthodontic" className={styles.dropDownLink}>
                      Prosthodontic
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/orthodontic" className={styles.dropDownLink}>
                      Orthodontic
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/pediatric" className={styles.dropDownLink}>
                      Pediatric
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/endodontic" className={styles.dropDownLink}>
                      Endodontic
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/periodontal" className={styles.dropDownLink}>
                      Periodontal
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/oralsurgery" className={styles.dropDownLink}>
                      Oral Surgery
                    </a>
                  </li>
                </ul>
              </li>

              <li className={styles.navItem}>
                <div className={styles.navBlock}>
                  <a
                    className={styles.navBlock}
                    href="/pediatric/pediatricdentistry"
                  >
                    Pediatric
                  </a>
                </div>
                <ul className={styles.dropDown}>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/pediatric/pediatricdentistry/ToothBrushing"
                    >
                      Tooth Brushing
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/pediatric/pediatricdentistry/DigitSucking"
                    >
                      Digit Sucking
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/pediatric/pediatricdentistry/ToothEruption"
                    >
                      Tooth Eruption
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/pediatric/pediatricdentistry/ToothDecay"
                    >
                      Early Childhood Tooth Decay
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/pediatric/pediatricdentistry/CavityPrevention"
                    >
                      Cavity Prevention
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/pediatric/pediatricdentistry/GumDisease"
                    >
                      Gum Disease
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/pediatric/pediatricdentistry/OralCare"
                    >
                      Adolescence and Oral Care
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/pediatric/pediatricdentistry/ToothFillings"
                    >
                      Fillings / Restorations
                    </a>
                  </li>
                  {/* <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/pediatric/pediatricdentistry/AmalgamFillings"
                    >
                      Amalgam Fillings
                    </a>
                  </li> */}
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/pediatric/pediatricdentistry/DentalSealants"
                    >
                      Dental Sealants
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/pediatric/pediatricdentistry/Extractions"
                    >
                      Tooth Extractions
                    </a>
                  </li>

                  {/* <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/pediatric/pediatricdentistry/Bonding"
                    >
                      Bonding
                    </a>
                  </li> */}
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/pediatric/pediatricdentistry/InlaysOnlays"
                    >
                      Inlays & Onlays
                    </a>
                  </li>
                </ul>
              </li>

              <li className={styles.navItem}>
                <div className={styles.navBlock}>
                  <a className={styles.navBlock} href="/pediatric/Orthodontics">
                    {" "}
                    Orthodontics
                  </a>
                </div>
                <ul className={styles.dropDown}>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/pediatric/Orthodontics/RemovableAppliances"
                    >
                      Removable Appliances
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/pediatric/Orthodontics/Bites"
                    >
                      Types of Bites
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/pediatric/Orthodontics/ClassificationOfTeeth"
                    >
                      Classification of the Teeth
                    </a>
                  </li>
                </ul>
              </li>

              <li className={styles.navItem}>
                <a
                  href="#schedule-section"
                  onClick={handleScrollToSchedule}
                  className={styles.btnPrimary}
                >
                  Book An Appointment
                </a>
              </li>
            </ul>
          </nav>
        )}
      </div>
    </section>
  );
}
