import React, { useState, useEffect } from "react";
import SideMenuCosmeticProsthodontic from "../RestorativeProsthodontic/SideMenuCosmeticProsthodontic";

import styles from "./DentalBonding.module.css";
import SideMenuCosmeticDentistry from "./SideMenuCosmeticDentistry";

export default function CEREC({ menuItem, menuItemName, dentistry }) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            CEREC
          </h1>
          <h4 className={styles.secondaryQuestion}>The CEREC Procedure</h4>
          <p className={styles.mainDescription}>
            Let’s face it, very few of us have perfect teeth, free of decay and
            fillings. You can probably see a filling or two in your own mouth,
            which do just that — "fill" a cavity, or hole, in your tooth left
            from the excavation of decayed tooth structure. In many cases, those
            fillings are made of metal material and can go bad, weaken the
            tooth, or get additional decay under or around it. In fact, 1.2
            billion of these metal fillings will need to be replaced in the next
            10 years. <b> CEREC </b> is a method used by thousands of dentists
            worldwide since 1987 not only to replace these fillings, but also to
            restore any tooth that is decayed, weakened, broken, etc. to its
            natural strength and beauty. Better yet, it’s done with all-ceramic
            materials that are tooth-colored in a single appointment!
          </p>

          <h4 className={styles.secondaryQuestion}>
            Exam and Preparation for CEREC
          </h4>

          <p className={styles.secondaryDescription}>
            First, Our Dentist will examines the tooth and determines the
            appropriate treatment. It could be a simple filling, or a full
            crown, depending on how much healthy tooth structure is. Next, he or
            she administers an anesthetic and prepares your tooth for the
            restoration, removing decayed and weakened tooth tissue. This
            preparation is just like he or she would do for many other
            restorative techniques.
          </p>
          <h4 className={styles.secondaryQuestion}>Optical Impression</h4>

          <p className={styles.secondaryDescription}>
            Then, your dentist takes an Optical Impression of the prepared
            tooth. Instead of filling a tray with impression "goop" that you
            must bite into and hold in your mouth until it hardens, your dentist
            coats the tooth with a non-toxic, tasteless powder. A camera is then
            used to take a digital picture of your tooth. This whole Optical
            Impression process takes only a minute or two.
          </p>
          <h4 className={styles.secondaryQuestion}>No Temporaries</h4>

          <p className={styles.secondaryDescription}>
            Next, the CEREC machine helps our Dentist to create the restoration
            for your tooth. The CEREC 3D software takes the digital picture and
            converts it into a 3-dimensional virtual model on the computer
            screen. He or she then uses his or her dental expertise to design
            the restoration using the CEREC 3D computer program. Within a few
            minutes, Dentist clicks a button, and the restoration design data is
            sent to a separate milling machine in the office. A ceramic block
            that matches your tooth shade is placed in the milling machine.
            About 10 – 20 minutes later, your all-ceramic, tooth-colored
            restoration is finished and ready to bond in place. Finally, your
            dentist tries the restoration in your mouth to ensure proper fit and
            bite. The restoration is then polished and bonded to the prepared
            tooth. Your tooth is restored with no "temporary" or return trip
            necessary. All of this is done in a single appointment! Ask us about
            CEREC today.
          </p>
        </div>
        {windowWidth > 730 &&
          (dentistry == "general" ? (
            <SideMenuCosmeticDentistry
              menuItem={handleMenuClick}
              menuItemName={menuItemName}
              dentistry={dentistry}
            />
          ) : (
            <SideMenuCosmeticProsthodontic
              menuItem={handleMenuClick}
              menuItemName={menuItemName}
              dentistry={dentistry}
            />
          ))}
      </div>
    </section>
  );
}
