import React, { useState, useEffect } from "react";

import styles from "../CosmeticDentistry/DentalBonding.module.css";
import SideMenuGeneralProsthodonticDentistry from "./SideMenuGeneralProsthodonticDentistry";

export default function GeneralProsthodontic({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            General Dentistry
          </h1>
          <p className={styles.mainDescription}>
            {" "}
            As a Dentist, our interests include all aspects of your oral health
            and any aesthetic concerns you may have as well. We know that a
            beautiful smile starts with a healthy smile.
          </p>
          <p className={styles.mainDescription}>
            Even a subtle change in your smile helps you to project an image of
            self-confidence and high personal esteem. When you feel good about
            yourself, it shows in your appearance.
          </p>

          <h4
            className={styles.secondaryQuestion}
            style={{ color: styleProperties.textColor }}
          >
            Our team of Dentists can now give you the smile you want, including:
          </h4>
          <ul className={styles.items}>
            <li className={styles.item}>
              <a href="/general/General/OralHygiene">Oral Hygiene</a>
            </li>
            <li className={styles.item}>
              <a href="/general/General/TeethCleaning">Teeth Cleaning</a>
            </li>
            <li className={styles.item}>
              <a href="/general/General/PeriodontalDisease">
                Periodontal Disease
              </a>
            </li>
            <li className={styles.item}>
              <a href="/general/General/WomenAndPeriodontalHealth">
                Women and Periodontal Health
              </a>
            </li>
            <li className={styles.item}>
              <a href="/general/General/DentalSpecialties">
                Dental Specialties
              </a>
            </li>
          </ul>
        </div>
        {windowWidth > 730 && (
          <SideMenuGeneralProsthodonticDentistry
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
