import React from "react";

import styles from "./PeriodontalMenu.module.css";
import SideMenuPeriodontalMenu from "./SideMenuPeriodontalMenu";

export default function OsseousSurgery({ menuItem, menuItemName, dentistry }) {
  const periodontalProperty = {
    textColor: "#fc7444",
    spanColor: "#978ab1",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = periodontalProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  return (
    <section
      className={styles.mainSection}
      //  style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            OSSEOUS SURGERY
          </h1>
          <h4 className={styles.secondaryQuestion}>
            <b> Goals of Osseous Surgery</b>
          </h4>
          <p className={styles.secondaryDescription}>
            Osseous surgery is used to reshape deformities and remove pockets in
            the alveolar bone surrounding the teeth. It is a common necessity in
            effective treatment of more advanced periodontal diseases. The
            ultimate goal of osseous surgery is to reduce or eliminate the
            periodontal pockets that cause periodontal disease. Despite the word
            “surgery” the procedure is reported to feel more like a thorough
            cleaning.
          </p>

          <p
            className={styles.secondaryDescription}
            style={{ marginBottom: "0.5rem" }}
          >
            <b>The specific goals of osseous surgery include:</b>
          </p>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>
                Reducing Bacterial Spread: Bacteria from the mouth can spread
                throughout the body and cause other life-threatening conditions
                such as heart disease and respiratory disease. Removing deep
                tartar and thereby bacteria can help reduce the risk of bacteria
                spreading.
              </li>
              <li>
                Preventing Bone Loss: The immune system’s inflammatory response
                prompted by periodontal bacteria can lead to bone loss in the
                jaw region, and cause teeth to fall out. Osseous surgery seeks
                to stop periodontal disease before it progresses to this level.
              </li>
              <li>
                Enhancing the Smile: Mouths plagued with periodontal disease are
                often unsightly. Brown gums, rotting teeth, and ridge
                indentations can leave a person feeling depressed and too
                self-conscious to smile. Fortunately, osseous surgery can help
                reduce bacteria and disease and thereby restore your mouth to
                its former radiance, while restoring confidence at the same
                time.
              </li>
              <li>Replacement of old, worn out, or damaged fillings.</li>
              <li>Reconstruction of badly worn and damaged teeth.</li>
            </ul>
          </p>
        </div>
        <SideMenuPeriodontalMenu
          menuItem={handleMenuClick}
          menuItemName={menuItemName}
          dentistry={dentistry}
        />
      </div>
    </section>
  );
}
