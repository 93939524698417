import React, { useState, useEffect } from "react";

import styles from "./Braces.module.css";
import SideMenuBraces from "./SideMenuBraces";

export default function Retainers({ menuItem, menuItemName, dentistry }) {
  const orthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#8ba7c3",
    backgroundColor: "#e7f1fca0",
  };
  const styleProperties = orthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            RETAINERS
          </h1>
          <p className={styles.mainDescription}>
            After the active orthodontic treatment period is complete and your
            braces have been removed, you will be given a retainer. This marks
            the beginning of the retention phase, during which a retainer is
            used to hold teeth in their new positions while the jawbone, gums
            and muscles adapt. You must wear the retainer as instructed,
            otherwise your teeth may revert to their former positions, and the
            benefits of having had braces will be lost.
          </p>
          <h4 className={styles.secondaryQuestion}>
            When to Wear Your Retainers
          </h4>
          <p className={styles.secondaryDescription}>
            Wear your retainer at all times, even while you are sleeping, with
            the following exceptions: The retainer may be removed for eating,
            swimming, and vigorous sporting activities
          </p>
          <h4 className={styles.secondaryQuestion}>Pain and Soreness</h4>
          <p className={styles.secondaryDescription}>
            The retainer may make your teeth sore and you may even find that
            your speech is slightly altered for a few days. This is normal with
            a new retainer or after an adjustment and will resolve on its own.
            If you develop a sore spot on your gums, call our office so that we
            can adjust the retainer for better comfort.
          </p>
          <h4 className={styles.secondaryQuestion}>Cleaning Your Retainer</h4>
          <p className={styles.secondaryDescription}>
            Brush your retainer daily with toothpaste. If needed, denture
            cleaner can be used for a more thorough cleaning. If you have a
            bonded wire retainer behind your front teeth, be careful to clean it
            as well when brushing your teeth. Do not boil your retainer to
            sterilize it. If you feel that it needs sterilization, bring it to
            the office so that we can properly disinfect it for you.
          </p>
          <h4 className={styles.secondaryQuestion}>
            How to Keep Your Retainer Safe
          </h4>
          <p className={styles.secondaryDescription}>
            Because the retainer is removable, it can be taken out for
            convenience during meals and for cleaning. However, careful care
            should be taken not to lose your retainer. When eating meals, be
            sure to keep it in its case for safety. Never wrap the retainer in a
            napkin or store it in your pocket. Keep it away from dogs and cats,
            as they love to chew on these appliances. Please note: an additional
            charge will be incurred for lost or broken retainers.
          </p>
          <h4 className={styles.secondaryQuestion}>Treatment Time</h4>
          <p className={styles.secondaryDescription}>
            Retainer treatment time varies by patient. Some may even require
            permanent retention to keep teeth aligned properly. We will discuss
            this as part of your overall treatment plan.
          </p>
          <p className={styles.secondaryDescription}>
            {" "}
            <b>
              Remember, your retainer can only work when you are wearing it!
              Please give us a call if you have any questions about retainer
              use.
            </b>
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuBraces
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
