import React, { useState, useEffect } from "react";

import styles from "../PediatricMenu/PediatricMenu.module.css";

import SideMenuOrthodontic from "./SideMenuOrthodontic";

export default function ClassificationOfTeeth({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const pediatricProperty = {
    textColor: "#9086a5",
    spanColor: "#776c8c",
    backgroundColor: "#e6e4eb",
  };
  const styleProperties = pediatricProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            CLASSIFICATION OF TEETH
          </h1>

          <p className={styles.mainDescription}>
            The classification of bites is broken up into three main categories:
            Class I, II, and III. This classification refers to the position of
            the first molars and how they bite together.
          </p>

          <p className={styles.secondaryDescription}>
            <b>Class I:</b>
          </p>
          <p className={styles.secondaryDescription}>
            Class I is a normal relationship between the upper teeth, lower
            teeth and jaws or balanced bite.
          </p>

          <p className={styles.secondaryDescription}>
            <b>Class II:</b>
          </p>
          <p className={styles.secondaryDescription}>
            Class II is where the lower first molar is posterior (or more
            towards the back of the mouth) than the upper first molar. In this
            abnormal relationship, the upper front teeth and jaw project further
            forward than the lower teeth and jaw. There is a convex appearance
            in profile with a receding chin and lower lip. Class II problems can
            be due to insufficient growth of the lower jaw, an over growth of
            the upper jaw or a combination of the two. In many cases, Class II
            problems are genetically inherited and can be aggravated by
            environmental factors such as finger sucking. Class II problems are
            treated via growth redirection to bring the upper teeth, lower teeth
            and jaws into harmony.
          </p>
          <p className={styles.secondaryDescription}>
            <b>Class III:</b>
          </p>
          <p className={styles.secondaryDescription}>
            Class III is where the lower first molar is anterior (or more
            towards the front of the mouth) than the upper first molar. In this
            abnormal relationship, the lower teeth and jaw project further
            forward than the upper teeth and jaws. There is a concave appearance
            in profile with a prominent chin. Class III problems are usually due
            to an overgrowth in the lower jaw, undergrowth of the upper jaw or a
            combination of the two. Like Class II problems, they can be
            genetically inherited.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuOrthodontic
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
