import React, { useState, useEffect } from "react";

import styles from "./Procedures.module.css";
import SideMenuProcedures from "./SideMenuProcedures";

export default function Oralpathology({ menuItem, menuItemName, dentistry }) {
  const oralsurgery = {
    textColor: "#d099d9",
    spanColor: "#644a68",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = oralsurgery;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            ORAL CANCER
          </h1>

          <p className={styles.mainDescription}>
            The inside of the mouth is normally lined with a special type of
            skin (mucosa) that is smooth and coral pink in color. Any alteration
            in this appearance could be a warning sign for a pathological
            process. The most serious of these is <b>oral cancer. </b>
          </p>

          <p
            className={styles.secondaryDescription}
            style={{ marginBottom: "0.5rem" }}
          >
            The following can be signs at the beginning of a pathological
            process or cancerous growth:
          </p>

          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>
                Reddish patches (erythroplasia) or whitish patches (leukoplakia)
                in the mouth
              </li>
              <li>A sore that fails to heal and bleeds easily</li>
              <li>
                A lump or thickening on the skin lining the inside of the mouth
              </li>

              <li>
                Chronic sore throat or hoarseness and/or difficulty in chewing
                or swallowing
              </li>
            </ul>
          </p>
          <p className={styles.secondaryDescription}>
            These changes can be detected on the lips, cheeks, palate, and gum
            tissue around the teeth, tongue, face, and/or neck. Pain does not
            always occur with pathology, and curiously, is not often associated
            with oral cancer. However, any patient with facial and/or oral pain
            without an obvious cause or reason may also be at risk for oral
            cancer.
          </p>

          <p className={styles.secondaryDescription}>
            We would recommend performing an oral cancer self-examination
            monthly. Remember that your mouth is one of your body’s most
            important warning systems. Do not ignore suspicious lumps or sores.
            Please contact us so we can assist you with any questions or
            concerns.
          </p>
        </div>

        {windowWidth > 730 && (
          <SideMenuProcedures
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
