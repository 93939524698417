import React, { useState, useEffect } from "react";

import styles from "./Instructions.module.css";
import SideMenuInstructions from "./SideMenuInstructions";

export default function SurgicalInstructions({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const oralsurgery = {
    textColor: "#d099d9",
    spanColor: "#644a68",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = oralsurgery;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            SURGICAL INSTRUCTIONS
          </h1>
          <p className={styles.mainDescription}>
            With our advanced surgical techniques, we aim to make a significant
            impact. Our practice combines skill, experience, and a blend of
            science and artistry to redesign your smile. smile.
          </p>
          <p className={styles.mainDescription}>
            Even minor enhancements can boost your self-confidence and personal
            esteem, making you feel better about yourself and enhancing your
            overall appearance.
          </p>
          <h4
            className={styles.secondaryQuestion}
            style={{ color: styleProperties.textColor }}
          >
            Surgical Instructions Treatments
          </h4>
          <ul className={styles.items}>
            {/* <li className={styles.item}>
              <a href="/oralsurgery/SurgicalInstructions/BeforeAnesthesia">
                Before Anesthesia
              </a>
            </li> */}
            <li className={styles.item}>
              <a href="/oralsurgery/SurgicalInstructions/DentalImplantSurgery">
                After Dental Implant Surgery
              </a>
            </li>
            <li className={styles.item}>
              <a href="/oralsurgery/SurgicalInstructions/WisdomToothRemoval">
                After Wisdom Tooth Removal
              </a>
            </li>
            <li className={styles.item}>
              <a href="/oralsurgery/SurgicalInstructions/ToothExposure">
                After Impacted Tooth Exposure
              </a>
            </li>
            <li className={styles.item}>
              <a href="/oralsurgery/SurgicalInstructions/AfterExtractions">
                After Extractions
              </a>
            </li>
            <li className={styles.item}>
              <a href="/oralsurgery/SurgicalInstructions/MultipleExtractions">
                After Multiple Extractions
              </a>
            </li>
          </ul>
        </div>
        {windowWidth > 730 && (
          <SideMenuInstructions
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
