import React, { useState, useEffect } from "react";

import styles from "../CosmeticDentistry/DentalBonding.module.css";
import SideMenuRestorativeDentistry from "./SideMenuRestorativeDentistry";

export default function RootCanal({ menuItem, menuItemName, dentistry }) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            NON SURGICAL ROOT CANAL
          </h1>
          <h4 className={styles.secondaryQuestion}>What is a root canal?</h4>
          <p className={styles.mainDescription}>
            A{" "}
            <b
              className={styles.mainSpan}
              style={{ color: styleProperties.spanColor }}
            >
              root canal{" "}
            </b>
            is a simple treatment that saves a tooth when its pulp has become
            infected. The pulp is at the center of your tooth and is a
            collection of blood vessels that helps to build the surrounding
            tooth. Infection of the pulp can be caused by trauma to the tooth,
            deep decay, cracks and chips, or repeated dental procedures. Various
            symptoms of the infection may include visible injury or swelling of
            the tooth and sensitivity to temperature or pain in the tooth or
            gums.
          </p>
          <h4 className={styles.secondaryQuestion}>
            How is a root canal performed?
          </h4>
          <p className={styles.mainDescription}>
            If you experience any of these symptoms, we will most likely
            recommend non-surgical treatment to eliminate the diseased pulp. We
            begin by removing the injured pulp and thoroughly cleaning and
            sealing the root canal system. This therapy is usually completed in
            one or more visits, depending on the extent of the treatment
            required. Success for this type of treatment occurs in about 90% of
            cases.
          </p>

          <p className={styles.secondaryDescription}>
            We use local anesthesia to eliminate discomfort. In addition, we
            will provide nitrous oxide analgesia if indicated. Most our patients
            are able to drive home after treatment and comfortably return to
            normal activity right away. If your tooth is not amenable to
            endodontic treatment or the chance of success is unfavorable, you
            will be informed at the time of consultation or when a complication
            becomes evident during or after treatment.
          </p>

          <h4 className={styles.secondaryQuestion}>
            What happens after root canal treatment?
          </h4>
          <p className={styles.secondaryDescription}>
            When your root canal therapy has been completed, we keep a record of
            your treatment at our office and schedule a follow-up restoration to
            be placed within a few weeks. It is rare for endodontic patients to
            experience complications after routine endodontic treatment or
            microsurgery. However, if a problem does occur, we are available at
            all times to evaluate your tooth and proceed as necessary. To
            prevent further decay, continue to practice good dental hygiene.
          </p>
          <h4 className={styles.secondaryQuestion}>
            How much will root canal treatment cost?
          </h4>
          <p className={styles.secondaryDescription}>
            The cost associated with this procedure can vary depending on
            factors such as the severity of damage to the affected tooth and
            which tooth is affected. In general, endodontic treatment is much
            less expensive than tooth removal and its replacement – an
            artificial tooth.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuRestorativeDentistry
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
