// Home.js
import React, { useState } from "react";
import ClearBraces from "../CosmeticDentistry/ClearBraces";
import DentalBonding from "../CosmeticDentistry/DentalBonding";
import InlaysOutlays from "../CosmeticDentistry/InlaysOutlays";
import PorcelainVein from "../CosmeticDentistry/PorcelainVeins";
import TeethWhitening from "../CosmeticDentistry/TeethWhitening";

import GeneralHero from "./GeneralHero";
import GeneralHeroMenu from "./GeneralHeroMenu";
import GeneralLocation from "./GeneralLocation";
import DentalFillings from "../RestorativeDentistry/DentalFillings";
import DentalImplants from "../RestorativeDentistry/DentalImplants";
import DentalBridges from "../RestorativeDentistry/DentalBridges";
import DentalCrowns from "../RestorativeDentistry/DentalCrowns";
import RootCanal from "../RestorativeDentistry/RootCanal";
import Extractions from "../RestorativeDentistry/Extractions";
import GeneralServices from "./GeneralServices";
import E4DDentist from "../CosmeticDentistry/E4DDentist";
import CEREC from "../CosmeticDentistry/CEREC";

import Dentures from "../RestorativeDentistry/Dentures";
import ToothCleaning from "../GeneralPreventiveDentistry/ToothCleaning";
import GeneralHeader3 from "./Generalheader3";
import CosmeticDentistry from "../CosmeticDentistry/CosmeticDentistry";
import RestorativeDentistry from "../RestorativeDentistry/RestorativeDentistry";
import Preventative from "../PreventiveCareDentistry/PreventativeCare";
import TeethCleaningDentalCheckups from "../PreventiveCareDentistry/TeethCleaningDentalCheckups";
import OralHygiene from "../PreventiveCareDentistry/OralHygiene";
import ChildDentistry from "../PreventiveCareDentistry/ChildDentistry";
import DentalSealants from "../PreventiveCareDentistry/DentalSealants";
import Arestin from "../PreventiveCareDentistry/Arestin";
import GumDiseaseTreatment from "../PreventiveCareDentistry/GumDiseaseTreatment";
import ScheduleCTA from "../Home/ScheduleCta";
import Schedule from "../Home/Schedule";
import Footer from "../Home/Footer";

import ToothExtractions from "../RestorativeProsthodontic/ToothExtractions";
import BruxismTreatment from "../RestorativeProsthodontic/BruxismTreatment";
import GeneralProsthodontic from "../GeneralProsthodonticDentistry/GeneralProsthodontic";
import TeethCleaning from "../GeneralProsthodonticDentistry/TeethCleaning";
import PERIODONTALDISEASE from "../GeneralProsthodonticDentistry/PERIODONTALDISEASE";
import WomenAndPeriodontalHealth from "../GeneralProsthodonticDentistry/WomenAndPeriodontalHealth";
import DentalSpecialties from "../GeneralProsthodonticDentistry/DentalSpecialties";

const GeneralHome = (cosmeticData) => {
  const [cosmeticDataMenu, setCosmeticMenu] = useState(cosmeticData);

  const handleMenuItem = (data) => {
    setCosmeticMenu(data);
  };

  return (
    <>
      <GeneralHeader3 generalMenuItem={handleMenuItem} />
      {cosmeticDataMenu.cosmeticData === "GeneralHome" ? (
        <GeneralHero />
      ) : (
        <GeneralHeroMenu />
      )}
      {cosmeticDataMenu.cosmeticData === "GeneralHome" && <GeneralServices />}
      {/* Cosmetic Dentistry Services */}
      {cosmeticDataMenu.cosmeticData === "CosmeticDentistry" && (
        <CosmeticDentistry
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="general"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "DentalBonding" && (
        <DentalBonding
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="general"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "PorcelainVeins" && (
        <PorcelainVein
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="general"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "InlaysOnlays" && (
        <InlaysOutlays
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="general"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "TeethWhitening" && (
        <TeethWhitening
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="general"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "E4DDentist" && (
        <E4DDentist
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="general"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "ClearBraces" && (
        <ClearBraces
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="general"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "CEREC" && (
        <CEREC
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="general"
        />
      )}
      {/* Restorative Dentistry Services */}
      {cosmeticDataMenu.cosmeticData === "Restorative" && (
        <RestorativeDentistry
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="general"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "ToothExtractions" && (
        <ToothExtractions
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry={"general"}
        />
      )}
      {cosmeticDataMenu.cosmeticData === "BruxismTreatment" && (
        <BruxismTreatment
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry={"general"}
        />
      )}
      {cosmeticDataMenu.cosmeticData === "DentalFillings" && (
        <DentalFillings
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="general"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "DentalImplants" && (
        <DentalImplants
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="general"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "DentalBridges" && (
        <DentalBridges
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="general"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "DentalCrowns" && (
        <DentalCrowns
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="general"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "NonSurgicalRootCanal" && (
        <RootCanal
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="general"
        />
      )}
      {/* {cosmeticDataMenu.cosmeticData === "ToothExtractions" && (
        <Extractions
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="general"
        />
      )} */}
      {cosmeticDataMenu.cosmeticData === "Dentures" && (
        <Dentures
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="general"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "ToothCleaning" && (
        <ToothCleaning
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="general"
        />
      )}
      {/* Preventative Care Services */}
      {cosmeticDataMenu.cosmeticData === "PreventativeCare" && (
        <Preventative
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="general"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "TeethCleaningDentalCheckups" && (
        <TeethCleaningDentalCheckups
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="general"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "OralHygiene" && (
        <OralHygiene
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="general"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "ChildDentistry" && (
        <ChildDentistry
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="general"
        />
      )}

      {cosmeticDataMenu.cosmeticData === "DentalSealants" && (
        <DentalSealants
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="general"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "Arestin" && (
        <Arestin
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="general"
        />
      )}

      {cosmeticDataMenu.cosmeticData === "GumDiseaseTreatment" && (
        <GumDiseaseTreatment
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="general"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "General" && (
        <GeneralProsthodontic
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="general"
        />
      )}
      {/* {cosmeticDataMenu.cosmeticData === "OralHygiene" && (
        <OralHygiene
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="general"
        />
      )} */}
      {cosmeticDataMenu.cosmeticData === "TeethCleaning" && (
        <TeethCleaning
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="general"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "PeriodontalDisease" && (
        <PERIODONTALDISEASE
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="general"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "WomenAndPeriodontalHealth" && (
        <WomenAndPeriodontalHealth
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="general"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "DentalSpecialties" && (
        <DentalSpecialties
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="general"
        />
      )}
      <Schedule
        dentistry="general"
        subService={cosmeticDataMenu.cosmeticData}
      />
      {/* <GeneralSchedule /> */}
      <GeneralLocation />
      <ScheduleCTA
        dentistry="general"
        subService={cosmeticDataMenu.cosmeticData}
      />
      <Footer dentistry="general" />
    </>
  );
};

export default GeneralHome;
