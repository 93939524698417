import React, { useState, useEffect } from "react";

import styles from "../PediatricMenu/PediatricMenu.module.css";
import SideMenuPediatricMenu from "./SideMenuPediatricMenu";

export default function ToothDecay({ menuItem, menuItemName, dentistry }) {
  const pediatricProperty = {
    textColor: "#9086a5",
    spanColor: "#776c8c",
    backgroundColor: "#e6e4eb",
  };
  const styleProperties = pediatricProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            EARLY CHILDHOOD TOOTH DECAY
          </h1>
          <h4 className={styles.secondaryQuestion}>What Causes Tooth Decay?</h4>
          <p className={styles.mainDescription}>
            Several specific types of bacteria that live on the teeth cause
            decay. When sugar is consumed, the bacteria use the sugar and then
            manufacture acids that dissolve the teeth and cause an infection in
            the tooth. This infection is called decay.
          </p>
          <h4 className={styles.secondaryQuestion}>
            What Is Early Childhood Tooth Decay?
          </h4>
          <p className={styles.mainDescription}>
            Babies who go to bed with a bottle of milk, formula, or juice are
            more likely to get tooth decay. Because the sugar in formula, milk,
            or juice stays in contact with the teeth for a long time during the
            night, the teeth can decay quickly.
          </p>

          <h4
            className={styles.secondaryQuestion}
            style={{ marginBottom: "0.5rem" }}
          >
            Some Tips To Avoid Early Childhood Tooth Decay
          </h4>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>
                Put your child to bed with a bottle of plain water, not milk or
                juice.
              </li>
              <li>
                Try not to let your child walk around using a bottle of milk or
                juice as a pacifier.
              </li>
              <li>
                Start to teach your child to drink from a cup at about six
                months of age. Plan to stop using a bottle by 12 to 14 months at
                the latest.
              </li>
              <li>Don’t dip your child’s pacifier in honey or sugar.</li>
            </ul>
          </p>
          <h4 className={styles.secondaryQuestion}>What Is Fluoride?</h4>
          <p className={styles.mainDescription}>
            Fluoride helps make teeth strong and prevents tooth decay. If the
            water where you live does not have enough fluoride, your doctor may
            prescribe fluoride supplements (fluoride drops or pills). You would
            give these drops or pills every day, starting when your child is
            about six months old. Only give as much as the directions say to use
            because too much fluoride can cause spots on your child’s teeth.
            Also, be sure to call your local water authority and ask if your
            water is fluoridated. If it is, tell your dentist or pediatrician so
            that your child is not being over fluoridated. Children should take
            these drops or pills until they are 12 to 16 years old (or until you
            move to an area with fluoride in the water).
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuPediatricMenu
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
