import React, { useState, useEffect } from "react";

import styles from "../Braces/Braces.module.css";
import SideMenuAligners from "./SideMenuAligners";

export default function ClearAligners({ menuItem, menuItemName, dentistry }) {
  const orthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#8ba7c3",
    backgroundColor: "#e7f1fca0",
  };
  const styleProperties = orthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            CLEAR ALIGNERS
          </h1>
          <p className={styles.mainDescription}>
            Clear aligners are the most versatile and convenient choice among
            all orthodontic treatments. Made of a clear, flexible material,
            orthodontic aligners are comfortable, virtually invisible and
            removable, making them a popular choice for adults and teens who are
            seeking a more discreet approach to straightening their teeth. Clear
            aligners treat the same bite and alignment issues that traditional
            metal braces do, without the brackets and wires.
          </p>

          <h4 className={styles.secondaryQuestion}>
            How Do Clear Aligners Work?
          </h4>
          <p className={styles.mainDescription}>
            A series of custom-made aligner trays is used to gradually shift
            your teeth into place. Each tray is designed to move just one tooth
            or one section of teeth at a time. Once the tray’s work is complete
            (typically every two weeks), we move on to the next.
          </p>

          <h4 className={styles.secondaryQuestion}>Pain and Soreness</h4>
          <p className={styles.mainDescription}>
            Because there are no brackets and wires, clear aligners do not cause
            significant pain or soreness. In some cases, patients feel temporary
            discomfort (pressure) for the first few days after switching trays.
            That pressure may be uncomfortable but is actually a good sign that
            the trays are doing their job!
          </p>

          <h4 className={styles.secondaryQuestion}>
            Caring for Clear Aligners
          </h4>
          <p className={styles.mainDescription}>
            Aligners should be worn for 20-22 hours per day. They should only be
            removed for cleaning, eating and drinking. To clean your aligners,
            simply brush and rinse them each time you brush your teeth.
          </p>

          <h4 className={styles.secondaryQuestion}>Treatment Time</h4>
          <p className={styles.mainDescription}>
            Treatment time typically falls within the range of 9-16 months. Some
            patients may also benefit from wearing a retainer after treatment is
            complete to prevent regression. This will all be discussed as part
            of your overall treatment plan.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuAligners
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
