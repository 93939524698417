import React from "react";

import styles from "./SideMenuPreventativeCare.module.css";
import { IonIcon } from "@ionic/react";
import { caretForwardOutline } from "ionicons/icons";
export default function SideMenuPreventativeCare({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const generalProperty = {
    backgroundColor: "#ffa07d",
  };
  const prosthodonticProperty = {
    backgroundColor: "#b6d2d7",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;

  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  return (
    <div
      className={styles.menuSection}
      style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <h2 className={styles.secondaryHeading}>Preventative Care</h2>
      <ul className={styles.main_nav_list}>
        <li className={styles.service}>
          {menuItemName === "TeethCleaningDentalCheckups" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/general/PreventativeCare/TeethCleaningDentalCheckups"
          >
            <p className={styles.serviceHeader}>
              Teeth Cleaning & Dental Checkups
            </p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "OralHygiene" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/general/PreventativeCare/OralHygiene"
          >
            <p className={styles.serviceHeader}>Oral Hygiene</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "ChildDentistry" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/general/PreventativeCare/ChildDentistry"
          >
            <p className={styles.serviceHeader}>Child Dentistry</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "DentalSealants" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/general/PreventativeCare/DentalSealants"
          >
            <p className={styles.serviceHeader}>Dental Sealants</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "Arestin" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/general/PreventativeCare/Arestin"
          >
            <p className={styles.serviceHeader}>Arestin</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "GumDiseaseTreatment" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/general/PreventativeCare/GumDiseaseTreatment"
          >
            <p className={styles.serviceHeader}>Gum Disease Treatment </p>
          </a>
        </li>
      </ul>
    </div>
  );
}
