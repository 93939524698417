import React, { useState, useEffect } from "react";

import styles from "../Braces/Braces.module.css";

import SideMenuInvisalign from "./SideMenuInvisalign";

export default function InvisalignCost({ menuItem, menuItemName, dentistry }) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            PAYING FOR INVISALIGN® TREATMENT
          </h1>
          <h4 className={styles.secondaryQuestion}>
            The Cost of Invisalign® Is Similar To That of Traditional Braces
          </h4>
          <p className={styles.mainDescription}>
            Many people assume that clear aligners cost more than metal braces.
            So you may be surprised to hear that Invisalign® is generally no
            more expensive than traditional braces. The primary factors that
            determine the cost of treatment include: the severity of the issues
            that you want corrected and the length of your treatment.
          </p>
          {/* <h4 className={styles.secondaryQuestion}>
            Will My Insurance Cover Invisalign®?
          </h4>
          <p className={styles.mainDescription}>
            Many dental insurance policies do cover Invisalign® treatment. If
            your insurance covers orthodontic care, it may also cover
            Invisalign®. In fact, many plans cover as much as $3500 toward
            Invisalign® treatment. In addition to your traditional dental
            insurance policy, Flexible Spending Account (FSA) funds can also be
            used for Invisalign®, and we also offer flexible and affordable
            monthly payment plan options to address any remaining financial
            concerns.
          </p> */}
          <h4 className={styles.secondaryQuestion}>
            Are There Any Other Cost Considerations?
          </h4>
          <p className={styles.mainDescription}>
            Because Invisalign® uses a series of invisible, removable aligners,
            it has little impact on your appearance and lifestyle, allowing you
            to freely pursue professional and personal goals without hindrance.
            While traditional braces sometimes make adults feel uncomfortable in
            social and business settings, Invisalign® is removable, allowing you
            to act and eat normally with friends and colleagues alike. With
            clear braces, you can straighten your teeth in a way that is both
            comfortable and allows you to feel confident at home and at work.
          </p>
          <p className={styles.mainDescription}>
            Invisalign® is an affordable investment in your smile with long-term
            value! Call us for more information.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuInvisalign
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
