import React, { useState, useEffect } from "react";

import styles from "./Procedures.module.css";
import SideMenuProcedures from "./SideMenuProcedures";

export default function Procedures({ menuItem, menuItemName, dentistry }) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const endodonticProperty = {
    textColor: "#6fcfff",
    spanColor: "#64bae6",
    backgroundColor: "#e7f1fca0",
  };

  const styleProperties =
    dentistry === "general" ? generalProperty : endodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            ENDODONTIC PROCEDURES
          </h1>
          <h4 className={styles.secondaryQuestion}>What is a root canal?</h4>
          <p className={styles.mainDescription}>
            Today’s advanced endodontic treatments and materials can give you a
            healthier, more complete smile that can last you a lifetime. Your
            Endodontists, Dr.K.Pavani Reddy, can help revitalize and maintain
            your teeth and gums, giving you the smile you’ve always wanted.
          </p>
          <h4 className={styles.secondaryQuestion}>Procedures</h4>
          <ul className={styles.items}>
            <li className={styles.item}>
              <a href="/endodontic/Procedures/RootCanal">Root Canal</a>
            </li>
            <li className={styles.item}>
              <a href="/endodontic/Procedures/GentleWaveUltraCleaning">
                GentleWave UltraCleaning
              </a>
            </li>
            <li className={styles.item}>
              <a href="/endodontic/Procedures/EndodonticRetreatment">
                Endodontic Retreatment
              </a>
            </li>
            <li className={styles.item}>
              <a href="/endodontic/Procedures/Apicoectomy">Apicoectomy</a>
            </li>
            <li className={styles.item}>
              <a href="/endodontic/Procedures/CrackedTeeth">Cracked Teeth</a>
            </li>
            <li className={styles.item}>
              <a href="/endodontic/Procedures/TraumaticInjuries">
                Traumatic Injuries
              </a>
            </li>
            <li className={styles.item}>
              <a href="/endodontic/Procedures/Navigation">
                Dynamic 3D Navigation
              </a>
            </li>
          </ul>
        </div>
        {windowWidth > 730 && (
          <SideMenuProcedures
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
