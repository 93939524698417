import React from "react";

import { useInView } from "react-intersection-observer";
import styles from "./WhyPediatricDentistry.module.css";
import pediatricWhyImage from "../../images/Pediatric1.png";
export default function WhyPediatricDentistry({ serviceName }) {
  const [ref, inView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  return (
    <section className={styles.whyPediatricSection}>
      <div
        className={`${styles.pediatricWhyContainer} ${
          inView ? styles.animate : ""
        }`}
        ref={ref}
      >
        <div className={styles.pediatricWhyImageSection}>
          <img
            src={pediatricWhyImage}
            className={styles.pediatricWhyImage}
          ></img>
        </div>
        <div className={styles.pediatricWhyContent}>
          <h3 className={styles.pediatricWhyQuestion}>
            Why A Pediatric Dentistry?
          </h3>
          <p className={styles.pediatricWhyDescription}>
            Have you ever witnessed your child eagerly looking forward to a
            dental visit? Seems unimaginable, doesn't it? Well, not at our
            practice. Perhaps it's our gentle approach and unique way of
            connecting with kids. Our team of pediatric specialists and staff
            not only adore children but is also expertly trained to create a
            comforting environment. We go beyond routine dental care – we strive
            to make it an enjoyable experience. Your little ones not only learn
            the importance of proper oral hygiene but also discover that a trip
            to the dentist can be filled with laughter and fun.
          </p>
          <a href="#" className={styles.pediatricWhyBtn}>
            Book Online
          </a>
        </div>
      </div>
    </section>
  );
}
