import React, { useState } from "react";
import { useParams } from "react-router-dom";

import Header2 from "./Header2";
import RegistrationFormHero from "./RegistrationFormHero";
export default function RegistrationForm() {
  const { dentistry, subService } = useParams();

  // Decode parameters if needed
  const decodedDentistry = decodeURIComponent(dentistry);
  const decodedSubService = decodeURIComponent(subService);

  console.log("Dentistry:", decodedDentistry);
  console.log("SubService:", decodedSubService);

  return (
    <>
      <Header2 />
      <h1>{(decodedDentistry, decodedSubService)}</h1>
      <RegistrationFormHero />
    </>
  );
}
