import React, { useState, useEffect } from "react";

import styles from "../PediatricMenu/PediatricMenu.module.css";

import SideMenuOrthodontic from "./SideMenuOrthodontic";

export default function RemovableAppliances({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const pediatricProperty = {
    textColor: "#9086a5",
    spanColor: "#776c8c",
    backgroundColor: "#e6e4eb",
  };
  const styleProperties = pediatricProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            REMOVABLE APPLIANCES
          </h1>

          <p className={styles.mainDescription}>
            Removable appliances may be used to move selected teeth, to hold
            selected teeth in place, to influence growth of the jaws, and to
            influence tooth eruption. They are often used in conjunction with
            fixed appliances.
          </p>

          <p className={styles.secondaryDescription}>
            Removable appliances cannot be used to treat all orthodontic
            problems. It takes skill and experience to recognize conditions that
            can be successfully treated with removable appliances.
          </p>
          <p className={styles.secondaryDescription}>
            Because the patient can easily remove the appliance, good compliance
            is required to achieve the desired outcome.
          </p>
          <h4 className={styles.secondaryQuestion}>Retainers</h4>
          <p className={styles.secondaryDescription}>
            Once the teeth are moved into their correct position, the braces are
            removed. The next step is to use retainers to hold the teeth in the
            desired position. Retainers must be worn as instructed or the teeth
            WILL move back toward their original positions.
          </p>
          <p className={styles.secondaryDescription}>
            Remember; wear them in your mouth, not in your pocket. Retainers
            work when you follow our instructions.
          </p>
          <p className={styles.secondaryDescription}>
            Clean your retainers! After meals, clean all parts of the retainer
            with a tooth brush.
          </p>
          <p className={styles.secondaryDescription}>
            Handle your retainers with care. Retainers are easy to lose. If you
            take your retainers out, always place them in your retainer case for
            safety.
          </p>
          <h4 className={styles.secondaryQuestion}>Elastics</h4>
          <p
            className={styles.secondaryDescription}
            style={{ marginBottom: "0.5rem" }}
          >
            Attached to your braces, elastics (rubber bands) exert the proper
            force that creates the right amount of pressure to move teeth. In
            order for this force to remain constant, elastics must be worn all
            the time and changed every day. Any time missed in wearing your
            elastics will only make your treatment take longer, so remember
            these things:
          </p>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>
                You are responsible for placing the elastics on your braces
                between appointments. Make sure to wear them as instructed.
                Remove them only when brushing your teeth, gums, and braces
                after meals. Then put them back on immediately.
              </li>
              <li>
                Always carry a few elastics with you, so if one breaks you can
                replace it right away. If your supply is low, call the office
                and we will provide you an additional supply of elastics.
              </li>
              <li>
                If you forget to insert your elastics one day, don’t double up
                the next day — just follow your regular instructions.
              </li>
              <li>
                Elastics become worn out. When they lose their elasticity, they
                don’t provide the proper pressure on your teeth and jaws. It is
                very important to change them as directed, even when they are
                not broken.
              </li>
            </ul>
          </p>

          <p className={styles.mainDescription}>
            If your elastics break frequently, a wire or band loosens, or a hook
            breaks off — call our office immediately. Don’t wait until your next
            appointment. These problems need to be corrected as soon as
            possible!
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuOrthodontic
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
