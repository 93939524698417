import React, { useEffect, useState } from "react";

import styles from "./Instructions.module.css";
import SideMenuInstructions from "./SideMenuInstructions";

export default function BeforeAnesthesia({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const oralsurgery = {
    textColor: "#d099d9",
    spanColor: "#644a68",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = oralsurgery;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      //  style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            BEFORE INTRAVENOUS ANESTHESIA SEDATION
          </h1>
          <h4 className={styles.secondaryQuestion}>
            Home Instructions Before Oral Surgery
          </h4>
          <p
            className={styles.mainDescription}
            style={{ marginBottom: "1.0rem" }}
          >
            Please follow these instructions to prepare for oral surgery
          </p>

          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>
                You may not have anything to eat or drink (including water) for
                eight hours prior to the appointment.
              </li>
              <li>
                No smoking for at least 12 hours before surgery. Ideally, cut
                down or stop smoking as soon as possible prior to the day of
                surgery.
              </li>
              <li>
                A responsible adult must accompany the patient to the office,
                remain in the office during the procedure, and drive the patient
                home.
              </li>
              <li>
                The patient should not drive a vehicle or operate any machinery
                for 24 hours following anesthesia.
              </li>
              <li>
                Please wear loose fitting clothing with sleeves which can be
                rolled up past the elbow, and low-heeled shoes.
              </li>
              <li>
                Contact lenses, jewelry, and dentures must be removed at the
                time of surgery.
              </li>
              <li>
                Do not wear lipstick, excessive makeup, or nail polish on the
                day of surgery.
              </li>
              <li>
                If you have an illness such as a cold, sore throat, stomach or
                upset bowels, please notify the office.
              </li>
              <li>
                If you take routine oral medications, please check with Dentist
                prior to your surgical date for instructions.
              </li>
            </ul>
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuInstructions
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
