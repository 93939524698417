import React, { useState, useEffect } from "react";

import styles from "../CosmeticDentistry/DentalBonding.module.css";
import SideMenuPreventativeCare from "./SideMenuPreventativeCare";

export default function TeethCleaningDentalCheckups({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            TEETH CLEANING & DENTAL CHECKUPS
          </h1>
          <p className={styles.mainDescription}>
            There are many ways you can play an active role in maintaining your
            oral health. Having your teeth cleaned in our office every six
            months is an excellent place to start. Our hygienists and dentists
            identify plaque and problem areas before thoroughly cleaning your
            teeth, providing you with information to sustain a happy, healthy
            smile.
          </p>
          <h4 className={styles.secondaryQuestion}>
            Why is it important to have consistent teeth cleanings and checkups?
          </h4>
          <p className={styles.secondaryDescription}>
            Routine checkups are an essential part of proper oral hygiene. Some
            of the benefits of regular teeth cleanings include:
            <ul className={styles.subDescription}>
              <li>Healthier bones and gums.</li>
              <li>Longer lasting teeth.</li>
              <li>
                Early detection and treatment of cancer and other oral health
                conditions.
              </li>
              <li>Beautiful, healthy smiles.</li>
            </ul>
          </p>
          <h4 className={styles.secondaryQuestion}>
            What happens when we miss teeth cleanings?
          </h4>
          <p className={styles.secondaryDescription}>
            Failure to keep up on proper oral hygiene can lead to:
            <ul className={styles.subDescription}>
              <li>
                An impaired ability to eat and digest foods, leading to
                nutritional and systemic problems.
              </li>
              <li>
                Bad breath, resulting from advancing periodontal disease and/or
                rotting food particles.
              </li>
              <li>
                Negative effects on cardiovascular well-being and overall
                health.
              </li>
              <li>Gum infection.</li>
            </ul>
          </p>
          <h4 className={styles.secondaryQuestion}>
            What to Expect at Your First Checkup
          </h4>
          <p className={styles.secondaryDescription}>
            Our dental cleaning appointments are about an hour-long, and new
            patient visits typically last a bit longer. We take pride in getting
            to know each of our patients, tailoring treatments to the health
            needs and goals of every individual. Here’s what to expect the first
            time you come to our office:
            <ul className={styles.subDescription}>
              <li>
                Dental History— Our Doctor will gather dental and medical
                histories so that we have a clear picture of your current
                health, including any medical conditions that can impact dental
                treatment.
              </li>

              <li>
                Oral Screening and Teeth Exams—After we perform an oral
                cancer/lesion screening for symptoms of cancer and other
                illnesses, such as heart disease, cancer, and diabetes, we
                examine your teeth for decay.
              </li>
              <li>
                Gum Evaluation and Teeth Cleanings—Our dentist will evaluate
                your gums, measuring periodontal pockets with a probe, and clean
                your teeth by identifying and removing areas of tartar, plaque,
                and calculus.
              </li>
              <li>
                Oral Hygiene Habits—We end your visit by reviewing and
                reinforcing proper oral hygiene.
              </li>
            </ul>
          </p>
          <h4 className={styles.secondaryQuestion}>
            Proper Oral Hygiene: How to Prevent Tooth Decay
          </h4>

          <p className={styles.secondaryDescription}>
            There are several simple and affordable ways in which you can fight
            tooth decay—a common chronic health condition.
            <ol className={styles.suborderDescription}>
              <li>Brush twice and floss once daily.</li>
              <li>Have dental cleanings and checkups twice a year.</li>
              <li>Eat fewer sugary and acidic foods.</li>

              <li>Ask your dentist about additional cleaning supplements.</li>
            </ol>
          </p>

          <h4 className={styles.secondaryQuestion}>
            The Teeth Cleaning Procedure
          </h4>
          <p className={styles.secondaryDescription}>
            When you come in for a cleaning, your dentist identifies and removes
            plaque—a soft film that can be removed by brushing—and tartar—a
            hardened plaque that requires dental instruments for removal. We
            make every effort to clean all surfaces of your teeth, including the
            tiny spaces between your teeth and along gum-lines where bacteria
            gather.
          </p>
          <p className={styles.secondaryDescription}>
            We often complete the cleaning with prophy paste. Its coarse
            quality, which removes any remaining plaque as it dissolves into a
            finer texture, leaves the teeth shiny and smooth. We inform you of
            any areas where plaque and tartar build-up, so you can give those
            places extra attention by flossing and brushing each day. Our goal
            is to enable you to easily and effectively care for your teeth.
          </p>
          <h4 className={styles.secondaryQuestion}>
            Are there different kinds of teeth cleanings?
          </h4>
          <p className={styles.secondaryDescription}>
            {" "}
            No. Prophylaxis—a fancy word for teeth cleaning—is the only dental
            procedure of its kind. Prophylaxis involves a routine teeth cleaning
            every six months.
          </p>

          <p className={styles.secondaryDescription}>
            The procedures that are sometimes called “cleanings” are actually
            periodontal treatments:
            <ul className={styles.subDescription}>
              <li>
                Full Mouth Debridement—a pre-cleaning for those with severe
                calculus build up.
              </li>
              <li>
                Scaling and Root Planing—a deep cleaning for those with early
                stages of gum disease.
              </li>
              <li>
                Perio Maintenance—a periodontal cleaning for those with a
                history of gum disease.
              </li>
            </ul>
          </p>

          <h4 className={styles.secondaryQuestion}>
            The Cost of Teeth Cleaning
          </h4>
          <p className={styles.secondaryDescription}>
            Every patient’s budgetary situation is different, so we are happy to
            discuss yours with you. Our main objective is to help you have a
            successful oral health experience.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuPreventativeCare
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
