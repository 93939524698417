import React, { useEffect, useState } from "react";

import styles from "./PeriodontalMenu.module.css";
import SideMenuPeriodontalMenu from "./SideMenuPeriodontalMenu";

export default function LaserTherapy({ menuItem, menuItemName, dentistry }) {
  const periodontalProperty = {
    textColor: "#fc7444",
    spanColor: "#978ab1",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = periodontalProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            LANAP LASER PERIODONTAL PROCEDURES
          </h1>

          <p className={styles.mainDescription}>
            Another recent development in periodontal treatment is the use of
            laser to regenerate tissue. Utilizing the body’s own natural ability
            to “heal itself”,Periodontist utilizes the PerioLase™ for the{" "}
            <b>Laser Assisted New Attachment Procedure (LANAP™). </b>Performed
            without a scalpel and sutures, LANAP™ creates an environment for
            regeneration of the tissues around your teeth (gum, bone, cementum,
            ligament) that are lost due to periodontal disease.
          </p>

          <p className={styles.mainDescription}>
            The laser therapy works by targeting only diseased gum, which it
            discerns by the color. Diseased gums are darker than healthy gums.
            The laser is then used to agitate the healthy tissue, encouraging it
            to reattach to the bone. Because of the regrowth of this connective
            tissue, there is also a much lower chance of gum disease returning
            than with traditional surgery.
          </p>
          <p className={styles.mainDescription}>
            Due to the ability of the laser to target and remove only diseased
            tissue, Periodontist is able to help restore you to health with no
            post-operative pain and fewer side effects and risks than
            traditional surgery. This is in conjunction with a detailed homecare
            program that is described below.
          </p>
          <h4 className={styles.secondaryQuestion}>
            LANAP™ Post-Operative Instructions
          </h4>

          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>
                <p>
                  Anti-microbial rinses and antibiotic medications may be
                  prescribed for you. Take any prescribed medications according
                  to directions and continue taking until all meds are gone.
                </p>
              </li>
              <li>
                <p>
                  Reduce physical activity for several hours following surgery.
                </p>
              </li>
              <li>
                <p>
                  Periodontal laser procedures usually result in little or no
                  discomfort following surgery.
                </p>
              </li>
              <li>
                <p>
                  For the first 24 hour period immediately following surgery,
                  take two (2) (Ibuprofen) every 4-6 hours even if you do not
                  have any pain.
                </p>
              </li>
              <li>
                <p>
                  For the first 24 hour period immediately following surgery,
                  stay on a liquid diet only. For the following 3-7 days after
                  surgery, a soft diet is recommended. Try to eat soft but
                  nutritious foods such as eggs, yogurt, cottage cheese, malts,
                  ice cream, etc. Stay away from spicy and “chippy” foods. It is
                  also important to maintain good fluid intake during this
                  period.
                </p>
              </li>
              <li>
                <p>
                  Carefully avoid chewing food in the areas of the mouth where
                  the laser has been used for at least one week following
                  surgery. One of the most important results of laser surgery is
                  the blood clots that form following surgery. It is extremely
                  important not to dislodge the tiny clots that form in and
                  around the gums.
                </p>
              </li>
              <li>
                <p>
                  Do not be alarmed with any color changes or appearance of
                  tissues following laser therapy. Tissues can be gray, yellow,
                  red, blue, purple, “stringy” and reflect normal response to
                  laser treatments.
                </p>
              </li>
              <li>
                <p>
                  It is OK to spit, rinse, and wash your mouth today. Rinse as
                  directed with Chlorhexidine mouth wash morning and night. In
                  between, it is OK to rinse gently every three (3) hours with
                  warm salt water (1/2 teaspoon of salt dissolved in an 8oz.
                  glass of warm water). Do not brush or floss your teeth until
                  instructed to do so (see Treatment and Home Care sequence
                  sheet).
                </p>
              </li>
              <li>
                <p>
                  Try to keep your mouth as clean as possible in order to help
                  the healing process. Brush, floss, and follow other home care
                  measures in all the areas of your mouth except for the surgery
                  area.
                </p>
              </li>
              <li>
                <p>
                  Do not apply excessive tongue or cheek pressure to the surgery
                  area.
                </p>
              </li>
            </ul>
          </p>

          <b className={styles.secondaryDescription}>
            Do not be alarmed if one of the following occurs:
          </b>

          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>
                <p>Light bleeding</p>
              </li>
              <li>
                <p>Slight swelling</p>
              </li>
              <li>
                <p>Some soreness, tenderness, or tooth sensitivity</p>
              </li>
              <li>
                <p>Medicinal taste, from Chlorhexidine mouth wash</p>
              </li>
            </ul>
          </p>

          <b className={styles.secondaryDescription}>
            Please call the office at Dental Planet Phone Number 040-40 24 7676
            / (+91) 9908873366 if you are experiencing any of periodontal /
            periodontalServices / ReductionSurgeryhe following signs and
            symptoms:
          </b>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>
                <p>Prolonged or severe pain</p>
              </li>
              <li>
                <p>Prolonged or excessive bleeding</p>
              </li>
              <li>
                <p>Considerably elevated or persistent temperature</p>
              </li>
            </ul>
          </p>
          <p className={styles.secondaryDescription}>
            Do not be alarmed that beginning within two weeks after therapy and
            extending as long as one year or more, the teeth may become sore,
            tender, or sensitive as the bone and ligaments around the teeth
            regenerate and become more firm. This is a sign of healing, but may
            also indicate the presence of a bite imbalance that may need to be
            adjusted.
          </p>
          <p className={styles.secondaryDescription}>
            If you have any questions or concerns, please don’t hesitate to call
            us! City Office Phone Number 9908873366
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuPeriodontalMenu
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
