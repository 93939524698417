import React from "react";

import styles from "./SideMenuPreventiveDentistry.module.css";

import { IonIcon } from "@ionic/react";
import { caretForwardOutline } from "ionicons/icons";
export default function SideMenuPreventiveDentistry({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const generalProperty = {
    backgroundColor: "#ffa07d",
  };
  const prosthodonticProperty = {
    backgroundColor: "#b6d2d7",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;

  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  return (
    <div
      className={styles.menuSection}
      style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <h2 className={styles.secondaryHeading}>Preventive Dentistry</h2>
      <ul className={styles.main_nav_list}>
        <li className={styles.service}>
          {menuItemName === "ToothCleaning" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            onClick={() => {
              handleMenuClick("ToothCleaning");
            }}
          >
            <p className={styles.serviceHeader}>Tooth Cleaning</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "PorcelainVeins" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            onClick={() => {
              handleMenuClick("PorcelainVeins");
            }}
          >
            <p className={styles.serviceHeader}>Oral Hygiene</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "InlaysOutlays" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            onClick={() => {
              handleMenuClick("InlaysOutlays");
            }}
          >
            <p className={styles.serviceHeader}>Child Dentistry</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "TeethWhitening" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            onClick={() => {
              handleMenuClick("TeethWhitening");
            }}
          >
            <p className={styles.serviceHeader}>Dental Sealants</p>
          </a>
        </li>
        <li className={styles.service}>
          <a
            className={styles.main_nav_link}
            onClick={() => {
              handleMenuClick("DentalBonding");
            }}
          >
            <p className={styles.serviceHeader}>Arestin</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "ClearBraces" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            onClick={() => {
              handleMenuClick("ClearBraces");
            }}
          >
            <p className={styles.serviceHeader}>Gum Disease</p>
          </a>
        </li>
      </ul>
    </div>
  );
}
