import React from "react";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../AppContext";

import styles from "./Schedule.module.css";
function Schedule({ dentistry, subService }) {
  const { setDisableAnimations, setIsScrolling } = useAppContext();
  const [ref, inView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  const homeProperty = {
    textColor: "#545cd6",
    hoverColor: "#656cda",
  };

  const generalProperty = {
    textColor: "#ff7745",
    hoverColor: "#ff8558",
  };

  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    hoverColor: "#94b3b9",
  };

  const orthodonticProperty = {
    textColor: "#aed1f4",
    hoverColor: "#8ba7c3",
  };

  const pediatricProperty = {
    textColor: "#84789b",
    hoverColor: "#978ab1",
  };
  const endonticProperty = {
    textColor: "#6fcfff",
    hoverColor: "#64bae6",
  };

  const periodontalProperty = {
    textColor: "#ff7745",
    hoverColor: "#cc5f37",
  };

  const oralSurgeryProperty = {
    textColor: "#d099d9",
    hoverColor: "#644a68",
  };
  const styleProperties = {
    home: homeProperty,
    general: generalProperty,
    prosthodontic: prosthodonticProperty,
    orthodontic: orthodonticProperty,
    pediatric: pediatricProperty,
    endontic: endonticProperty,
    periodontal: periodontalProperty,
    oralSurgery: oralSurgeryProperty,
  }[dentistry];

  const navigate = useNavigate();

  const handleScrollToSchedule = () => {
    setDisableAnimations(true);
    setIsScrolling(true);
    const scheduleSection = document.getElementById("schedule-section");
    if (scheduleSection) {
      scheduleSection.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <section className={styles.scheduleSection}>
      <div
        className={`${styles.scheduleContainer} ${
          inView ? styles.animate : ""
        }`}
        ref={ref}
      >
        <h2 className={styles.boxHeader}>Schedule Your Visit</h2>
        <hr />
        <p className={styles.boxContent}>
          Are you in pain or have an urgent dental concern? Call 040-40 24 7676
          / (+91) 9908873366 to schedule an emergency appointment.
        </p>
        <a
          onClick={handleScrollToSchedule}
          href="#schedule-section"
          className={styles.btnPrimary}
          style={{ backgroundColor: styleProperties.textColor }}
        >
          Schedule Appointment
        </a>
      </div>
    </section>
  );
}

export default Schedule;
