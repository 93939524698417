import React, { useState, useEffect } from "react";

import styles from "./Procedures.module.css";
import SideMenuProcedures from "./SideMenuProcedures";

export default function DistractionOsteogenesis({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const oralsurgery = {
    textColor: "#d099d9",
    spanColor: "#644a68",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = oralsurgery;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            DISTRACTION OSTEOGENESIS
          </h1>
          <p className={styles.mainDescription}>
            <b>Distraction osteogenesis </b>(DO)is a relatively new method of
            treatment for selected deformities and defects of the jaw and skull.
            Although it was first used in 1903, in the 1950’s the Russian
            orthopedic surgeon, Our Team of Dentist's, slowly perfected the
            surgical and postoperative management of distraction osteogenesis
            treatment to correct deformities and repair defects in the arms and
            legs. His work went mostly unnoticed until he presented to the
            Western Medical Society in the mid-1960s.
          </p>
          <p className={styles.mainDescription}>
            Distraction osteogenesis was first used to treat defects of the oral
            and facial region in 1990. Since then, the surgical and
            technological advances made in the field of distraction osteogenesis
            have provided oral and maxillofacial surgeons with a safe and
            predictable method to treat selected deformities.
          </p>
          <p className={styles.mainDescription}>
            If you have questions about distraction osteogenesis, please call
            our office at and schedule an appointment.
          </p>

          <h4 className={styles.secondaryQuestion}>
            Frequently Asked Questions About Distraction Osteogenesis
          </h4>
          <p className={styles.secondaryDescription}>
            <b>What does the term distraction osteogenesis mean? </b>Simply
            stated, distraction osteogenesis means the slow movement apart
            (distraction) of two bony segments in a manner such that new bone is
            allowed to grow and fill in the gap created by the separating bony
            segments.
          </p>
          <p className={styles.secondaryDescription}>
            <b>
              Is the surgery for distraction osteogenesis more involved than
              “traditional surgery” for a similar procedure?{" "}
            </b>
            No. Distraction osteogenesis surgery is usually done on an
            outpatient basis with most of the patients going home the same day
            of surgery. The surgical procedure itself is less invasive so there
            is usually less pain and swelling.
          </p>
          {/* <p className={styles.secondaryDescription}>
            <b>
              Will my insurance company cover the cost of osteogenesis surgical
              procedure?{" "}
            </b>
            Most insurance companies will cover the cost of the osteogenesis
            surgical procedure provided that there is adequate and accurate
            documentation of the patient’s condition. Of course, individual
            benefits within the insurance company policy vary. After you are
            seen for your consultation at our office, we will assist you in
            determining whether or not your insurance company will cover a
            particular surgical procedure.
          </p> */}
          <p className={styles.secondaryDescription}>
            <b>Is distraction osteogenesis painful? </b>
            Since all distraction osteogenesis surgical procedures are done
            while the patient is under general anesthesia, pain during the
            surgical procedure is not an issue. Postoperatively, you will be
            supplied with appropriate analgesics (pain killers) to keep you
            comfortable, and antibiotics to fight off infection. Activation of
            the distraction device to slowly separate the bones may cause mild
            discomfort. In general, the slow movement of bony segments produces
            discomfort roughly similar to having braces tightened.
          </p>
          <p className={styles.secondaryDescription}>
            <b>
              What are the benefits of distraction osteogenesis vs. traditional
              surgery for a similar condition?{" "}
            </b>
            Distraction osteogenesis surgical procedures typically produce less
            pain and swelling than the traditional surgical procedure for a
            similar condition. Distraction osteogenesis eliminates the need for
            a second surgical site to harvest bone graft material. Lastly,
            distraction osteogenesis is associated with greater stability when
            used in major cases where significant movement of bony segments are
            involved.
          </p>
          <p className={styles.secondaryDescription}>
            <b>What are the disadvantages of distraction osteogenesis? </b>
            Distraction osteogenesis requires the patient to return to the
            surgeon’s office frequently during the initial two weeks after
            surgery. This is necessary because in this time frame the surgeon
            will need to closely monitor the patient for any infection and teach
            the patient how to activate the appliance. In some cases, a second
            minor office surgical procedure is necessary to remove the
            distraction appliance.
          </p>
          <p className={styles.secondaryDescription}>
            <b>
              Can distraction osteogenesis be used instead of bone grafts to add
              bone to my jaws?{" "}
            </b>
            Yes. Recent advances in technology have provided the oral and
            maxillofacial surgeon with a distraction device that can be used to
            slowly grow bone in selected areas of bone loss that has occurred in
            the upper and lower jaws. The newly formed bone can then serve as an
            excellent foundation for dental implants.
          </p>
          <p className={styles.secondaryDescription}>
            <b>Does distraction osteogenesis leave scars on the face? </b>
            No. The entire surgery is performed within the mouth and the
            distraction devices used by Dentist remain inside the mouth. There
            are no facial surgical incisions made, therefore, no facial scars
            result.
          </p>
          <p className={styles.secondaryDescription}>
            <b>
              Are there any age limitations for patients who can receive
              osteogenesis?{" "}
            </b>
            No. Distraction osteogenesis works well on patients of all ages. In
            general, the younger the patient the shorter the distraction time
            and the faster the consolidation phase. Adults require slightly
            longer period of distraction and consolidation because the bone
            regenerative capabilities are slightly slower than those of
            adolescence or infants.
          </p>
        </div>

        {windowWidth > 730 && (
          <SideMenuProcedures
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
