import React, { useState, useEffect } from "react";
import SideMenuCosmeticProsthodontic from "../RestorativeProsthodontic/SideMenuCosmeticProsthodontic";

import styles from "./DentalBonding.module.css";
import SideMenuCosmeticDentistry from "./SideMenuCosmeticDentistry";

export default function ClearBraces({ menuItem, menuItemName, dentistry }) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            Aligners
          </h1>
          <p className={styles.mainDescription}>
            Aligners offer a discreet, removable way to straighten your teeth
            without the hassle of metal brackets and wires.
          </p>

          <ul
            className={styles.subDescription}
            style={{ fontSize: "1.4rem", paddingTop: "0rem" }}
          >
            <li>
              Invisalign use a series of clear removable aligners to straighten
              your teeth without metal wires or brackets.
            </li>
            {/* <li>
              The aligners are made through a combination of Team expertise and 3-D computer imaging technology.
            </li> */}
            <li>
              You wear each set of aligners for about 2 weeks, removing them
              only to eat, drink, brush, and floss.
            </li>
            <li>
              As you replace each aligner with the next in the series, your
              teeth will move – little by little, week by week – until they have
              straightened to the final position the doctor has prescribed.
            </li>
            <li>
              You’ll visit the clinic about once every 6 weeks to ensure that
              your treatment is progressing as planned.
            </li>
            <li>
              Total treatment time averages 9 – 15 months and the average number
              of aligners worn during treatment is between 18 to 30, but both
              will vary from case to case.
            </li>
          </ul>
        </div>
        {windowWidth > 730 &&
          (dentistry == "general" ? (
            <SideMenuCosmeticDentistry
              menuItem={handleMenuClick}
              menuItemName={menuItemName}
              dentistry={dentistry}
            />
          ) : (
            <SideMenuCosmeticProsthodontic
              menuItem={handleMenuClick}
              menuItemName={menuItemName}
              dentistry={dentistry}
            />
          ))}
      </div>
    </section>
  );
}
