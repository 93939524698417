import React, { useState, useEffect } from "react";

import styles from "../CosmeticDentistry/DentalBonding.module.css";
import SideMenuGeneralProsthodonticDentistry from "./SideMenuGeneralProsthodonticDentistry";

export default function DentalSpecialties({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            DENTAL SPECIALTIES
          </h1>
          <h4 className={styles.secondaryQuestion}>What is a Periodontist?</h4>
          <p className={styles.mainDescription}>
            Periodontists are dentists who specialize in the diagnosis and
            treatment of periodontal (gum) disease. They have had extensive
            training with three additional years of study after dental school.
            As specialists they devote their time, energy and skill to helping
            patients care for their gums. A periodontist is one of the eight
            dental specialists recognized by the American Dental Association and
            Indian Dental Association.
          </p>
          <h4 className={styles.secondaryQuestion}>
            Why is your dentist referring you to a Periodontist?
          </h4>
          <p className={styles.mainDescription}>
            Your dentist has determined that your gums require special
            attention. The periodontist and dentist work together as a team to
            provide you with the highest level of care. They will combine their
            experience to recommend the best treatment available to you while
            keeping each other informed on your progress. By referring you to
            the specialist, your dentist is showing a strong commitment to your
            dental health.
          </p>

          <h4 className={styles.secondaryQuestion}>
            What is an Oral and Maxillofacial Surgeon (OMFS)?
          </h4>
          <p className={styles.secondaryDescription}>
            Oral and maxillofacial surgeons are dentists specializing in surgery
            of the mouth, face and jaws. They are well trained to do a wide
            range of procedures including all types of surgery of both the bones
            and soft tissues of the face, mouth and neck.
          </p>

          <h4 className={styles.secondaryQuestion}>What is an Endodontist?</h4>
          <p className={styles.secondaryDescription}>
            The Endodontist examines, diagnoses and treats diseases and
            destructive processes, including injuries and abnormalities of
            dental pulps and periapical tissues of the teeth by performing root
            canal treatment.
          </p>
          <p className={styles.secondaryDescription}>
            Endodontists examine patients and interpret radiographs and pulp
            tests to determine pulp vitality and periapical tissue condition.
            They evaluate their findings and prescribe a method of treatment to
            prevent loss of teeth.
          </p>

          <h4 className={styles.secondaryQuestion}>
            What is a Prosthodontist?
          </h4>
          <p className={styles.secondaryDescription}>
            The prosthodontist examines and diagnoses disabilities caused by
            loss of teeth and supporting structures. They formulate and execute
            treatment plans for the construction of corrective prostheses to
            restore proper function and esthetics of the mouth, face, and jaw.
          </p>
          <h4 className={styles.secondaryQuestion}>
            What is a Pediatric Dentist?
          </h4>
          <p className={styles.secondaryDescription}>
            A pediatric dentist has at least three additional years of training
            beyond dental school. The additional training focuses on management
            and treatment of a child’s developing teeth, child behavior,
            physical growth and development, and the special needs of children’s
            dentistry. Although either type of dentist is capable of addressing
            your child’s oral health care needs, a pediatric dentist, his or her
            staff, and even the office décor are all geared to care for children
            and to put them at ease. If your child has special needs, care from
            a pediatric dentist should be considered.
          </p>
          <h4 className={styles.secondaryQuestion}>What is an Orthodontist?</h4>
          <p className={styles.secondaryDescription}>
            An orthodontist prevents and treats mouth, teeth, and jaw problems.
            Using braces, retainers, and other devices, an orthodontist helps
            straighten a person’s teeth and correct the way the jaws line up
          </p>
          <p className={styles.secondaryDescription}>
            Orthodontists treat kids for many problems, including having crowded
            or overlapping teeth or having problems with jaw growth and tooth
            development. These tooth and jaw problems may be caused by tooth
            decay, losing baby teeth too soon, accidents, or habits like thumb
            sucking. These problems can also be genetic or inherited.
          </p>
          <h4 className={styles.secondaryQuestion}>
            So why would you go to the Orthodontist?
          </h4>
          <p className={styles.secondaryDescription}>
            Your dentist or one of your parents might recommend it because they
            see a problem with your teeth or jaws. Or a kid who doesn’t like the
            way his or her teeth look might ask to see an orthodontist.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuGeneralProsthodonticDentistry
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
