import React from "react";

import styles from "./SideMenuGeneralProsthodonticDentistry.module.css";

import { IonIcon } from "@ionic/react";
import { caretForwardOutline } from "ionicons/icons";
export default function SideMenuGeneralProsthodonticDentistry({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const generalProperty = {
    backgroundColor: "#ffa07d",
  };
  const prosthodonticProperty = {
    backgroundColor: "#b6d2d7",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;

  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  return (
    <div
      className={styles.menuSection}
      style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <h2 className={styles.secondaryHeading}>General Dentistry</h2>
      <ul className={styles.main_nav_list}>
        <li className={styles.service}>
          {menuItemName === "OralHygiene" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/general/General/OralHygiene"
          >
            <p className={styles.serviceHeader}>Oral Hygiene</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "TeethCleaning" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/general/General/TeethCleaning"
          >
            <p className={styles.serviceHeader}>Teeth Cleaning</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "PeriodontalDisease" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/general/General/PeriodontalDisease"
          >
            <p className={styles.serviceHeader}>Periodontal Disease</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "WomenAndPeriodontalHealth" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/general/General/WomenAndPeriodontalHealth"
          >
            <p className={styles.serviceHeader}>
              {" "}
              Women and Periodontal Health
            </p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "DentalSpecialties" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/general/General/DentalSpecialties"
          >
            <p className={styles.serviceHeader}> Dental Specialties</p>
          </a>
        </li>
      </ul>
    </div>
  );
}
