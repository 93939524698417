import React, { useState, useEffect } from "react";

import styles from "./RestorativeMenu.module.css";
import SideMenuRestorativeProsthodonticDentistry from "./SideMenuRestorativeProsthodonticDentistry";

export default function Dentures({ menuItem, menuItemName, dentistry }) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            Dentures
          </h1>

          <p className={styles.mainDescription}>
            <b>
              A denture or a complete denture as it is often called, is an
              appliance that is inserted in the mouth, replaces natural teeth
              and provides support for the cheeks and lips..
            </b>
          </p>

          <h4
            className={styles.secondaryQuestion}
            style={{ marginBottom: "1.2rem" }}
          >
            Most dentures are made of acrylic and can be fabricated two
            different ways.
          </h4>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>
                A <b>conventional denture</b> is made after all teeth have been
                extracted and the tissues (gums) have healed.
              </li>
              <li>
                An immediate denture is fabricated and inserted immediately
                after the teeth are extracted and the tissues are allowed to
                heal under the denture.
              </li>
              <li>
                An <b>upper denture</b> has acrylic, usually gum colored, that
                covers the palate (roof of the mouth).
              </li>
              <li>
                A <b>lower denture</b> is shaped like a horseshoe to leave room
                for the tongue.
              </li>
            </ul>
          </p>
          <p className={styles.mainDescription}>
            The teeth are made of plastic, porcelain or a combination of both.
            Dentures can be fabricated to fit over endodontically treated teeth
            and a complete denture can be attached to <b>dental implants</b> to
            allow for a more secure fit of the appliance.
          </p>
          <p className={styles.mainDescription}>
            Dentures over a normal course of time will wear and need to be
            replaced or <b>relined</b> in order to keep the jaw alignment
            normal. The alignment will slowly change as the bone and gum ridges
            recede or shrink due to the <b>extraction of the teeth.</b> Regular
            dental examinations are still important for the denture wearer so
            that the oral tissues can be checked for disease or change.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuRestorativeProsthodonticDentistry
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
