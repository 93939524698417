import React from "react";

import styles from "./SideMenuInstructions.module.css";

import { IonIcon } from "@ionic/react";
import { caretForwardOutline } from "ionicons/icons";
export default function SideMenuInstructions({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const generalProperty = {
    backgroundColor: "#ffa07d",
  };
  const prosthodonticProperty = {
    backgroundColor: "#b6d6f5",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;

  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  return (
    <div
      className={styles.menuSection}
      style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <h2 className={styles.secondaryHeading}>Instructions</h2>
      <ul className={styles.main_nav_list}>
        <li className={styles.service}>
          {menuItemName === "BeforeEndotonticTreatment" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/endodontic/Instructions/BeforeEndotonticTreatment"
          >
            <p className={styles.serviceHeader}>Before Endodontic Treatment</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "GeneralInstructions" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/endodontic/Instructions/GeneralInstructions"
          >
            <p className={styles.serviceHeader}>General Instructions</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "AfterTreatmentFAQ" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/endodontic/Instructions/AfterTreatmentFAQ"
          >
            <p className={styles.serviceHeader}>After Treatment FAQ</p>
          </a>
        </li>
      </ul>
    </div>
  );
}
