import React from "react";

import styles from "./SideMenuRestorativeDentistry.module.css";
import { IonIcon } from "@ionic/react";
import { caretForwardOutline } from "ionicons/icons";
export default function SideMenuRestorativeDentistry({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const generalProperty = {
    backgroundColor: "#ffa07d",
  };
  const prosthodonticProperty = {
    backgroundColor: "#b6d2d7",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;

  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  return (
    <div
      className={styles.menuSection}
      style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <h2 className={styles.secondaryHeading}>Restorative Dentistry</h2>
      <ul className={styles.main_nav_list}>
        <li className={styles.service}>
          {menuItemName === "DentalFillings" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/general/Restorative/DentalFillings"
          >
            <p className={styles.serviceHeader}>Dental Fillings</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "DentalImplants" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/general/Restorative/DentalImplants"
          >
            <p className={styles.serviceHeader}>Dental Implants</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "DentalBridges" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/general/Restorative/DentalBridges"
          >
            <p className={styles.serviceHeader}>Dental Bridges</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "DentalCrowns" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/general/Restorative/DentalCrowns"
          >
            <p className={styles.serviceHeader}>Dental Crowns</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "NonSurgicalRootCanal" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/general/Restorative/NonSurgicalRootCanal"
          >
            <p className={styles.serviceHeader}>Non Surgical Root Canal</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "ToothExtractions" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/general/Restorative/ToothExtractions"
          >
            <p className={styles.serviceHeader}>ToothExtractions</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "BruxismTreatment" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/general/Restorative/BruxismTreatment"
          >
            <p className={styles.serviceHeader}>Bruxism Treatment</p>
          </a>
        </li>
        {/* <li className={styles.service}>
          {menuItemName === "ToothExtractions" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/general/Restorative/ToothExtractions"
          >
            <p className={styles.serviceHeader}>Tooth Extractions</p>
          </a>
        </li> */}
        <li className={styles.service}>
          {menuItemName === "Dentures" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/general/Restorative/Dentures"
          >
            <p className={styles.serviceHeader}>Dentures</p>
          </a>
        </li>
      </ul>
    </div>
  );
}
