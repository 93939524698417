import React, { useState, useEffect } from "react";
import SideMenuImplantsMenu from "../../Periodontal/DentalImplants/SideMenuImplantsMenu";

import styles from "./Procedures.module.css";
import SideMenuProcedures from "./SideMenuProcedures";

export default function BoneGrafting({ menuItem, menuItemName, dentistry }) {
  const oralsurgery = {
    textColor: "#d099d9",
    spanColor: "#644a68",
    backgroundColor: "#f3f2f5",
  };
  const periodontalProperty = {
    textColor: "#fc7444",
    spanColor: "#978ab1",
    backgroundColor: "#f3f2f5",
  };

  const styleProperties =
    dentistry == "periodontal" ? periodontalProperty : oralsurgery;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            BONE GRAFTING
          </h1>
          <h4 className={styles.secondaryQuestion}>
            Major & Minor Bone Grafting
          </h4>
          <p className={styles.mainDescription}>
            Missing teeth over a period of time can cause your jaw bone to
            atrophy, or resorb. This often results in poor quality and quantity
            of bone suitable for the placement of dental implants as well as
            long term shifting of remaining teeth and changes to facial
            structure. Most patients, in these situations, are not a good case
            for dental implants.
          </p>
          <p className={styles.mainDescription}>
            Fortunately, today we have the ability to grow bone where it is
            needed. This not only gives us the opportunity to place implants of
            proper length and width, but it also gives us a chance to restore
            functionality and aesthetic appearance.
          </p>
          <h4 className={styles.secondaryQuestion}>Major Bone Grafting</h4>
          <p className={styles.secondaryDescription}>
            <b> Bone grafting </b>can repair implant sites with inadequate bone
            structure due to previous extractions, gum disease, or injuries. The
            bone is either obtained from a tissue bank or your own bone is taken
            from the jaw, hip or tibia (below the knee). Sinus bone grafts are
            also performed to replace bone in the posterior upper jaw. In
            addition, special membranes may be utilized that dissolve under the
            gum to protect the bone graft, as well as encourage bone
            regeneration. This is called guided bone regeneration, or guided
            tissue regeneration.
          </p>

          <p className={styles.secondaryDescription}>
            Major bone grafts are typically performed to repair defects of the
            jaws. These defects may arise as a result of traumatic injuries,
            tumor surgery, or congenital defects. Large defects are repaired
            using the patient’s own bone. This bone is harvested from a number
            of different areas depending on the size needed. The skull
            (cranium), hip (iliac crest), and lateral knee (tibia), are common
            donor sites. These procedures are routinely performed in an
            operating room and require a hospital stay.
          </p>
        </div>
        {dentistry == "periodontal"
          ? windowWidth > 730 && (
              <SideMenuImplantsMenu
                menuItem={handleMenuClick}
                menuItemName={menuItemName}
                dentistry={dentistry}
              />
            )
          : windowWidth > 730 && (
              <SideMenuProcedures
                menuItem={handleMenuClick}
                menuItemName={menuItemName}
                dentistry={dentistry}
              />
            )}
      </div>
    </section>
  );
}
