import React from "react";
import { useInView } from "react-intersection-observer";

import styles from "./EndodonticLocation.module.css";
import { IonIcon } from "@ionic/react";
import { location } from "ionicons/icons";
import { call } from "ionicons/icons";

function EndodonticLocation() {
  const [detailsRef, detailsRefView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  const [hoursRef, hoursRefView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  return (
    <section className={styles.locationSection}>
      <div
        className={`${styles.details} ${detailsRefView ? styles.animate : ""}`}
        ref={detailsRef}
      >
        <h3>Dental Planet</h3>
        <p>
          We’re conveniently located in Vinayak Nagar, Gachibowli, Hyderabad.
          Find us on the ground floor of the building. Plenty of free parking is
          available. Our clinic is easily accessible by public transport, with
          bus stops and metro stations nearby. We look forward to welcoming you
          to our state-of-the-art facility.
        </p>
        <div className={styles.location}>
          <IonIcon icon={location} className={styles.locationIcon} />
          <p>
            1-37/1/125, Old Mumbai Highway, Vinayak Nagar, Gachibowli,
            Hyderabad, Telangana, 500032
          </p>
        </div>
        <div className={styles.callContent}>
          <IonIcon icon={call} className={styles.timeIcon} />
          <p>Call (+91) 9908873366</p>
        </div>
      </div>
      <div
        className={`${styles.hours} ${hoursRefView ? styles.animate : ""}`}
        ref={hoursRef}
      >
        <h3>Hours</h3>
        <div className={styles.timings}>
          <ul className={styles.time}>
            <li>
              <h4>Monday</h4>
            </li>
            <li>
              <h4>Tuesday</h4>
            </li>
            <li>
              <h4>Wednesday</h4>
            </li>
            <li>
              <h4>Thursday</h4>
            </li>
            <li>
              <h4>Friday</h4>
            </li>
            <li>
              <h4>Saturday</h4>
            </li>
            <li>
              <h4>Sunday</h4>
            </li>
          </ul>
          <ul className={styles.time}>
            <li>
              <h4>9AM - 9PM</h4>
            </li>
            <li>
              <h4>9AM - 9PM</h4>
            </li>
            <li>
              <h4>9AM - 9PM</h4>
            </li>
            <li>
              <h4>9AM - 9PM</h4>
            </li>
            <li>
              <h4>9AM - 9PM</h4>
            </li>
            <li>
              <h4>9AM - 9PM</h4>
            </li>
            <li>
              <h4>9AM - 9PM</h4>
            </li>
          </ul>
          {/* <div className={styles.time}>
            <h4>Monday</h4>
            <div className={styles.empty}></div>
            <h4>9AM - 9PM</h4>
          </div>
          <div className={styles.time}>
            <h4>Tuesday</h4>
            <div className={styles.empty}></div>
            <h4>9AM - 9PM</h4>
          </div>
          <div className={styles.time}>
            <h4>Wednesday</h4>
            <div className={styles.emptyWed}></div>
            <h4>9AM - 9PM</h4>
          </div>
          <div className={styles.time}>
            <h4>Thursday</h4>
            <div className={styles.emptyThur}></div>
            <h4>9AM - 9PM</h4>
          </div>
          <div className={styles.time}>
            <h4>Friday</h4>
            <div className={styles.emptyFri}></div>
            <h4>9AM - 9PM</h4>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default EndodonticLocation;
