import React from "react";

import { useInView } from "react-intersection-observer";
import styles from "./PeriodontalFooter.module.css";

function PeriodontalFooter() {
  const [navRef, inView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  return (
    <section className={styles.footerSection} ref={navRef}>
      <div className={`${styles.navigation} ${inView ? styles.animate : ""}`}>
        <h4 className={styles.footerheader}>Navigation</h4>
        <div className={styles.navElements}>
          <a href="/">Home</a>
          <a href="#">About</a>
        </div>
      </div>
      <div className={styles.services}>
        <h4
          className={`${styles.footerheader} ${inView ? styles.animate : ""}`}
        >
          Services
        </h4>
        <div
          className={`${styles.serviceElement} ${inView ? styles.animate : ""}`}
        >
          <a href="/general">General Dentistry</a>
          <a href="/prosthodontic">Prosthodontic Dentistry</a>
          <a href="#">Restorative Dentistry</a>
          <a href="/pediatric">Pediatric Dentistry</a>
          <a href="#">Orthodontic Dentistry</a>
          <a href="#">Endodontic Dentistry</a>
          <a href="#">Periodontal Dentistry</a>
        </div>
      </div>
      <div className={`${styles.patientInfo} ${inView ? styles.animate : ""}`}>
        <h4 className={styles.footerheader}>Patient Info</h4>
        <div className={styles.patientElement}>
          <a href="#">New Patient</a>
          <a href="#">Patient Registration</a>
          <a href="/patient/#whyChoose">Patient Reviews</a>
        </div>
      </div>
      <div className={`${styles.location} ${inView ? styles.animate : ""}`}>
        <h4 className={styles.footerheader}>Location</h4>
        <div className={styles.locationElement}>
          <a href="#"> 1-37/1/125, Old Mumbai Highway,</a>
          <a href="#">Vinayak Nagar, Gachibowli,</a>
          <a href="#">Hyderabad, Telangana, 500032</a>
        </div>
      </div>
    </section>
  );
}

export default PeriodontalFooter;
