import React, { useEffect, useState } from "react";

import styles from "./PeriodontalMenu.module.css";
import SideMenuPeriodontalMenu from "./SideMenuPeriodontalMenu";

export default function Perioscopic({ menuItem, menuItemName, dentistry }) {
  const periodontalProperty = {
    textColor: "#fc7444",
    spanColor: "#978ab1",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = periodontalProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            PERIOSCOPE TREATMENT
          </h1>
          <h4 className={styles.secondaryQuestion}>The Perioscope</h4>
          <p className={styles.mainDescription}>
            The perioscope is a new technology developed for the{" "}
            <b>treatment of periodontal disease.</b> The perioscope is an
            endoscope that has been specifically designed to explore and
            visualize the pocket (space) under the gum of teeth involved with
            periodontitis. An image on a computer monitor shows the diseased
            tooth’s root surface. The perioscope enables the clinician to see
            the contents of the periodontal pocket and to analyze the root
            surface of the tooth for disease-causing bacterial accumulations
            (plaque and calculus).
          </p>

          <p className={styles.secondaryDescription}>
            The perioscope guides the clinician during the process of cleaning
            the root surface free of plaque and calculus – the treatment for
            periodontitis. It also allows the clinician to identify other
            problems (cracks, perforations and other disease-causing flaws of
            the tooth root’s surface) that are located under the gum, and which
            previously required surgery to detect.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuPeriodontalMenu
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
