import React, { useState, useEffect } from "react";

import styles from "./PeriodontalMenu.module.css";
import SideMenuPeriodontalMenu from "./SideMenuPeriodontalMenu";

export default function PeriodontalMaintenance({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const periodontalProperty = {
    textColor: "#fc7444",
    spanColor: "#978ab1",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = periodontalProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            // style={{ color: styleProperties.textColor }}
          >
            PERIODONTAL MAINTENANCE
          </h1>
          <h4 className={styles.secondaryQuestion}>
            Supportive Periodontal Care
          </h4>

          <p className={styles.secondaryDescription}>
            After Dentist has completed the active phase of periodontal
            treatment, your periodontal disease will be under control. He will
            provide you with a personalized maintenance program of care to keep
            your gums healthy.
          </p>
          <p className={styles.secondaryDescription}>
            Maintenance therapy is an ongoing program designed to prevent
            disease in the gum tissues and bone supporting your teeth. Adherence
            to a program of conscientious home oral care and{" "}
            <b>regularly scheduled maintenance therapy visits</b> with your
            dentist will give you an excellent chance of keeping your teeth for
            your lifetime.
          </p>
          <h4 className={styles.secondaryQuestion}>
            Why is supportive periodontal care important?
          </h4>
          <p className={styles.secondaryDescription}>
            As you have learned, you are susceptible to gum disease. And, you
            have probably learned, too, that the main cause of gum disease is
            bacterial plaque, a sticky, tooth color biofilm that constantly
            forms on your teeth. The bacteria in this plaque produce toxins,
            which constantly attack your gums and teeth. Unless plaque is
            removed, it hardens into a rough, porous deposit called calculus, or
            tartar. Daily brushing and flossing will help to minimize the
            formation of calculus, but it won’t completely prevent it. No matter
            how careful you are in cleaning your teeth and gums, bacterial
            plaque can cause a recurrence of gum disease from two to four months
            after your last professional cleaning. Therefore, a dental
            professional must check for hidden problems and remove the hardened
            plaque at time intervals appropriate for you so that your teeth and
            gums stay healthy.
          </p>
          <h4 className={styles.secondaryQuestion}>
            Who should perform supportive periodontal therapy?
          </h4>
          <p className={styles.secondaryDescription}>
            The answer depends on you and the severity of your gum disease
            before treatment. Generally, the more severe your periodontal
            disease is initially, the more often we need to oversee your care.
            Together, you, your general dentist and periodontist will work out
            the most effective schedule for your supportive periodontal care.
          </p>
          <h4
            className={styles.secondaryQuestion}
            style={{ marginBottom: "0.5rem" }}
          >
            <b>
              Your maintenance/supportive periodontal care visit may include:
            </b>
          </h4>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>discussion of any changes in your health history</li>
              <li>examination of your mouth tissues for abnormal changes</li>
              <li>measurement of the depth of pockets around your teeth</li>
              <li>
                assessment of your oral hygiene habits and provision of
                instruction
              </li>
              <li>removal of bacterial plaque and tartar</li>
              <li>
                x-ray film studies to evaluate your teeth and the bone
                supporting your teeth
              </li>
              <li>
                examination of your teeth for decay and other dental problems
              </li>
              <li>checkup on the way your teeth fit together when you bite</li>
              <li>
                application or prescription of medications to reduce tooth
                sensitivity or other problems you may have
              </li>
            </ul>
          </p>
          <h4 className={styles.secondaryQuestion}>
            How often should you have supportive periodontal care visits?
          </h4>
          <p
            className={styles.secondaryDescription}
            style={{ marginBottom: "0.5rem" }}
          >
            Your periodontal condition is the deciding factor. The interval
            between your supportive periodontal care (SPC) visits might be as
            often as every few weeks or as frequent as every six months.
            Everyone’s situation is different.{" "}
            <b>
              The frequency of your supportive care visits will be influenced
              by:
            </b>
          </p>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>the type of periodontal disease you have</li>
              <li>the type of periodontal treatment you have</li>
              <li>your response to treatment</li>
              <li>your rate of plaque growth</li>
              <li>your personal commitment to good oral care at home</li>
            </ul>
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuPeriodontalMenu
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
