import React, { useState, useEffect } from "react";
import SideMenuCosmeticProsthodontic from "../RestorativeProsthodontic/SideMenuCosmeticProsthodontic";

import styles from "./DentalBonding.module.css";
import SideMenuCosmeticDentistry from "./SideMenuCosmeticDentistry";

export default function DentalBonding({ menuItem, menuItemName, dentistry }) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            Dental Bonding
          </h1>
          <p className={styles.mainDescription}>
            <b>
              Dental bonding is an alternative to{" "}
              <b
                className={styles.mainSpan}
                style={{ color: styleProperties.spanColor }}
              >
                teeth veneers{" "}
              </b>
              and can be used as a restorative procedure for teeth that are
              chipped, cracked, discolored or misarranged.
            </b>
          </p>
          <h4 className={styles.secondaryQuestion}>
            How does dental bonding work?
          </h4>
          <p className={styles.secondaryDescription}>
            The tooth is prepared for dental bonding by lightly etching the
            surface and applying a bonding liquid. Once the liquid sets,
            composite is applied and sculpted into the desired shape by the
            dentist. Once set, the resin is trimmed, smoothed and polished to a
            natural appearance.
          </p>
          <h4 className={styles.secondaryQuestion}>
            Considerations for Dental Bonding
          </h4>
          <p className={styles.secondaryDescription}>
            The bonding procedure can often be completed in a single office
            visit, and can improve the appearance of a tooth significantly.
            However, since the composite used is not as strong as your natural
            tooth enamel, it is more likely to stain, chip or break than natural
            teeth. Bonding typically lasts three to five years before need of
            repair.
          </p>
        </div>
        {windowWidth > 730 &&
          (dentistry == "general" ? (
            <SideMenuCosmeticDentistry
              menuItem={handleMenuClick}
              menuItemName={menuItemName}
              dentistry={dentistry}
            />
          ) : (
            <SideMenuCosmeticProsthodontic
              menuItem={handleMenuClick}
              menuItemName={menuItemName}
              dentistry={dentistry}
            />
          ))}
      </div>
    </section>
  );
}
