import React, { useEffect, useState } from "react";

import styles from "./PeriodontalMenu.module.css";
import SideMenuPeriodontalMenu from "./SideMenuPeriodontalMenu";

export default function Scaling({ menuItem, menuItemName, dentistry }) {
  const periodontalProperty = {
    textColor: "#fc7444",
    spanColor: "#978ab1",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = periodontalProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            SCALING & ROOT PLANING
          </h1>

          <p className={styles.mainDescription}>
            <b>
              The initial stage of treatment for periodontal disease is usually
              a thorough cleaning that may include scaling or root planing.
            </b>{" "}
            The objective of these non-surgical procedures is to remove
            etiologic agents such as dental plaque and tartar, or calculus,
            which cause gingival inflammation and disease. Scaling and root
            planing can be used as a stand-alone treatment, or a preventative
            measure. They are commonly performed on cases of gingivitis and
            moderate-to-severe periodontal disease.
          </p>
          <h4 className={styles.secondaryQuestion}>
            What does scaling and root planing entail?
          </h4>
          <p
            className={styles.secondaryDescription}
            style={{ marginBottom: "0.5rem" }}
          >
            Dentist will only perform scaling and root planing after a thorough
            examination of the mouth, which may include taking x-rays and
            visually examining the mouth. Depending on the condition of the
            gums, the amount of tartar present, the depth of the pockets, and
            the progression of periodontitis, Dentist may recommend scaling and
            root planing. In some cases, a local anesthesia may be used during
            the procedure.
          </p>

          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>
                <b>Scaling:</b>
                <p>
                  {" "}
                  When scaling is performed, calculus and plaque that attaches
                  to the tooth surfaces is removed. The process especially
                  targets the area below the gum line, along the root. Scaling
                  is performed with a special dental tool called an ultrasonic
                  scaling tool. The scaling tool usually includes an irrigation
                  process that can be used to deliver an antimicrobial agent
                  below the gums to help reduce oral bacteria.
                </p>
              </li>
              <li>
                <b>Root Planing:</b>
                <p>
                  {" "}
                  Root planing is performed in order to remove cementum and
                  surface dentin that is embedded with unwanted microorganisms,
                  toxins and tartar. The root of the tooth is literally
                  smoothed, which promotes healing, and also helps prevent
                  bacteria from easily colonizing in the future.
                </p>
              </li>
            </ul>
          </p>

          <p className={styles.secondaryDescription}>
            Antibiotics or irrigation with anti-microbials (chemical agents or
            mouth rinses) may be recommended to help control the growth of
            bacteria that create toxins and cause periodontitis. In some cases,
            Dentist may place antibiotic fibers in the periodontal pockets after
            scaling and planing. This may be done to control infection and to
            encourage normal healing.
          </p>
          <p className={styles.secondaryDescription}>
            When deep pockets between teeth and gums are present, it is
            difficult for Dentist to thoroughly remove plaque and tartar.
            Patients can seldom, if ever, keep these pockets clean and free of
            plaque. Consequently, surgery may be needed to restore periodontal
            health.
          </p>
          <h4 className={styles.secondaryQuestion}>
            Benefits of Scaling & Root Planing Treatment
          </h4>
          <p className={styles.secondaryDescription}>
            If treatment is successful, scaling and planing may have many
            periodontal benefits. One is that it can help prevent disease.
            Research has proven that bacteria from periodontal infections can
            travel through the blood stream and affect other areas of the body,
            sometimes causing heart and respiratory diseases. Scaling and root
            planing remove bacteria that cause these conditions.
          </p>
          <p className={styles.secondaryDescription}>
            Another benefit of treatment is protecting teeth against tooth loss.
            When gum pockets exceed 3mm in depth, the risk for periodontal
            disease increases. As pockets deepen, more bacteria are able to
            colonize, eventually causing a chronic inflammatory response by the
            body to destroy gingival and bone tissue. This leads to tooth loss.
          </p>
          <p className={styles.secondaryDescription}>
            Finally, scaling and root planing may make the mouth more
            aesthetically pleasing, and should reduce bad breath caused from
            food particles and bacteria in the oral cavity. Superficial stains
            on the teeth will be removed during scaling and planing, adding an
            extra bonus to the procedures.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuPeriodontalMenu
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
