import React, { useState, useEffect } from "react";

import styles from "./Procedures.module.css";
import SideMenuProcedures from "./SideMenuProcedures";

export default function EndodonticRetreatment({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const endodonticProperty = {
    textColor: "#6fcfff",
    spanColor: "#64bae6",
    backgroundColor: "#e7f1fca0",
  };
  const styleProperties = endodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            ENDODONTIC RETREATMENT
          </h1>
          <p className={styles.mainDescription}>
            With the appropriate care, your teeth that have had endodontic
            treatment will last as long as other natural teeth. Yet, a tooth
            that has received treatment may fail to heal or pain may continue to
            exist. Sometimes, the pain may occur months or years after
            treatment. If so, Dentist may need to perform Endodontic
            Retreatment.
          </p>
          <h4 className={styles.secondaryQuestion}>
            Improper healing may be caused by:
          </h4>
          <p className={styles.secondaryDescription}>
            <li>
              Curved or narrow canals that were not treated during the initial
              treatment.
            </li>
            <li>
              Complicated canals that went undetected during the initial
              treatment.
            </li>
            <li>
              The crown or restoration was not placed within the appropriate
              amount of time following the procedure.
            </li>
            <li>
              The crown or restoration that did not prevent saliva from
              contaminating the inside of the tooth.
            </li>
          </p>
          <h4 className={styles.secondaryQuestion}>
            In some cases, new problems can influence a tooth that was
            successfully treated:
          </h4>
          <p className={styles.secondaryDescription}>
            <li>
              New decay can expose a root canal filling material, causing
              infection.
            </li>
            <li>
              A cracked or loose filling or crown can expose the tooth to new
              infection.
            </li>
          </p>
          <p className={styles.mainDescription}>
            Once retreatment has been selected as a solution to your problem,
            the doctors will reopen your tooth to gain access to the root canal
            filling material. This restorative material will be removed to
            enable access to the root canal. The doctors will now clean your
            canals and carefully examine the inside of the problematic tooth.
            Once cleaned, the doctors will fill and seal the canals and place a
            temporary filling in the tooth.
          </p>
          <b>
            <p className={styles.mainDescription}>
              At this point, you will need to return to your dentist as soon as
              possible in order to have a new crown or restoration placed on the
              tooth to restore full functionality.
            </p>
          </b>
        </div>
        {windowWidth > 730 && (
          <SideMenuProcedures
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
