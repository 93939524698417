import React from "react";

import styles from "./SideMenuInstructions.module.css";
import { IonIcon } from "@ionic/react";
import { caretForwardOutline } from "ionicons/icons";
export default function SideMenuInstructions({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const periodontalProperty = {
    backgroundColor: "#d8ceda",
  };
  const styleProperties = periodontalProperty;

  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  return (
    <div
      className={styles.menuSection}
      style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <h2 className={styles.secondaryHeading}>Surgical Instructions</h2>
      <ul className={styles.main_nav_list}>
        {/* <li className={styles.service}>
          {menuItemName === "BeforeAnesthesia" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/oralsurgery/SurgicalInstructions/BeforeAnesthesia"
          >
            <p className={styles.serviceHeader}>Before Anesthesia</p>
          </a>
        </li> */}
        <li className={styles.service}>
          {menuItemName === "DentalImplantSurgery" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/oralsurgery/SurgicalInstructions/DentalImplantSurgery"
          >
            <p className={styles.serviceHeader}>After Dental Implant Surgery</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "WisdomToothRemoval" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/oralsurgery/SurgicalInstructions/WisdomToothRemoval"
          >
            <p className={styles.serviceHeader}> After Wisdom Tooth Removal</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "ToothExposure" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/oralsurgery/SurgicalInstructions/ToothExposure"
          >
            <p className={styles.serviceHeader}>
              After Impacted Tooth Exposure
            </p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "AfterExtractions" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/oralsurgery/SurgicalInstructions/AfterExtractions"
          >
            <p className={styles.serviceHeader}>After Extractions</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "MultipleExtractions" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/oralsurgery/SurgicalInstructions/MultipleExtractions"
          >
            <p className={styles.serviceHeader}>After Multiple Extractions</p>
          </a>
        </li>
      </ul>
    </div>
  );
}
