// Home.js
import React, { useState } from "react";
import ProsthodonticFooter from "./ProsthodonticFooter";
import ProsthodonticHeroComponent from "./ProsthodonticHero";
import ProsthodonticHeroMenu from "./ProsthodonticHeroMenu";
import ProsthodonticLocation from "./ProsthodonticLocation";
import ProsthodonticSchedule from "./ProsthodonticSchedule";
import ProsthodonticScheduleCTA from "./ProsthodonticScheduleCta";
import ProsthodonticServices from "./ProsthodonticServices";
import ToothColoredFillings from "../RestorativeProsthodontic/ToothColoredFillings";
import OralHygiene from "../GeneralProsthodonticDentistry/OralHygiene";
import ProsthodonticHeader3 from "./Prosthodonticheader3";
import RestorativeProsthodontic from "../RestorativeProsthodontic/RestorativeProsthodontic";
import DentalCrowns from "../RestorativeProsthodontic/DentalCrowns";
import FullMouthReconstruction from "../RestorativeProsthodontic/FullMouthReconstruction";
import DentalBridges from "../RestorativeProsthodontic/DentalBridges";
import DentalSealants from "../RestorativeProsthodontic/DentalSealants";
import Dentures from "../RestorativeProsthodontic/Dentures";
import PreProsthodontic from "../RestorativeProsthodontic/PreProsthodontic";

import CleftLipAndPalate from "../RestorativeProsthodontic/CleftLipAndPalate";
import PorcelainVein from "../CosmeticDentistry/PorcelainVeins";
import DentalBonding from "../CosmeticDentistry/DentalBonding";
import TeethWhitening from "../CosmeticDentistry/TeethWhitening";
import ClearBraces from "../CosmeticDentistry/ClearBraces";
import CEREC from "../CosmeticDentistry/CEREC";
import InlaysOutlays from "../CosmeticDentistry/InlaysOutlays";
import DentalImplants from "../RestorativeProsthodontic/DentalImplants";
import CosmeticProsthodontic from "../RestorativeProsthodontic/CosmeticProsthodontic";
import GeneralProsthodontic from "../GeneralProsthodonticDentistry/GeneralProsthodontic";
import TeethCleaning from "../GeneralProsthodonticDentistry/TeethCleaning";
import PERIODONTALDISEASE from "../GeneralProsthodonticDentistry/PERIODONTALDISEASE";
import WomenAndPeriodontalHealth from "../GeneralProsthodonticDentistry/WomenAndPeriodontalHealth";
import DentalSpecialties from "../GeneralProsthodonticDentistry/DentalSpecialties";
import ScheduleCTA from "../Home/ScheduleCta";
import Scaling from "../PeriodontalMenu/RootPlaning";
import Schedule from "../Home/Schedule";
import Footer from "../Home/Footer";
import E4DDentist from "../CosmeticDentistry/E4DDentist";

const ProsthodonticHome = (cosmeticData) => {
  const [cosmeticDataMenu, setCosmeticMenu] = useState(cosmeticData);

  const handleMenuItem = (data) => {
    setCosmeticMenu(data);
    console.log(cosmeticDataMenu);
  };
  return (
    <>
      <ProsthodonticHeader3 generalMenuItem={handleMenuItem} />
      {cosmeticDataMenu.cosmeticData === "ProsthodonticHome" ? (
        <ProsthodonticHeroComponent />
      ) : (
        <ProsthodonticHeroMenu />
      )}

      {cosmeticDataMenu.cosmeticData === "ProsthodonticHome" && (
        <ProsthodonticServices />
      )}

      {cosmeticDataMenu.cosmeticData === "Restorative" && (
        <RestorativeProsthodontic
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="prosthodontic"
        />
      )}

      {cosmeticDataMenu.cosmeticData === "DentalImplants" && (
        <DentalImplants
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry={"prosthodontic"}
        />
      )}

      {cosmeticDataMenu.cosmeticData === "DentalCrowns" && (
        <DentalCrowns
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry={"prosthodontic"}
        />
      )}

      {cosmeticDataMenu.cosmeticData === "ToothColoredFillings" && (
        <ToothColoredFillings
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry={"prosthodontic"}
        />
      )}
      {cosmeticDataMenu.cosmeticData === "FullMouthReconstruction" && (
        <FullMouthReconstruction
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry={"prosthodontic"}
        />
      )}
      {cosmeticDataMenu.cosmeticData === "DentalBrigdes" && (
        <DentalBridges
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry={"prosthodontic"}
        />
      )}
      {/* {cosmeticDataMenu.cosmeticData === "DentalSealants" && (
        <DentalSealants
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry={"prosthodontic"}
        />
      )} */}
      {cosmeticDataMenu.cosmeticData === "Dentures" && (
        <Dentures
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry={"prosthodontic"}
        />
      )}
      {cosmeticDataMenu.cosmeticData === "PreProstheticSurgery" && (
        <PreProsthodontic
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry={"prosthodontic"}
        />
      )}

      {cosmeticDataMenu.cosmeticData === "CleftLipAndPalate" && (
        <CleftLipAndPalate
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry={"prosthodontic"}
        />
      )}

      {/* Cosmetic Menu */}
      {cosmeticDataMenu.cosmeticData === "Cosmetic" && (
        <CosmeticProsthodontic
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry={"prosthodontic"}
        />
      )}
      {cosmeticDataMenu.cosmeticData === "PorcelainVeneers" && (
        <PorcelainVein
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry={"prosthodontic"}
        />
      )}
      {/* {cosmeticDataMenu.cosmeticData === "DentalBonding" && (
        <DentalBonding
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry={"prosthodontic"}
        />
      )}
      {cosmeticDataMenu.cosmeticData === "TeethWhitening" && (
        <TeethWhitening
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry={"prosthodontic"}
        />
      )} */}
      {cosmeticDataMenu.cosmeticData === "E4DDentist" && (
        <E4DDentist
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry={"prosthodontic"}
        />
      )}
      {cosmeticDataMenu.cosmeticData === "InlaysAndOnlays" && (
        <InlaysOutlays
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry={"prosthodontic"}
        />
      )}

      {/* {cosmeticDataMenu.cosmeticData === "ClearBraces" && (
        <ClearBraces
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry={"prosthodontic"}
        />
      )} */}
      {cosmeticDataMenu.cosmeticData === "CEREC" && (
        <CEREC
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry={"prosthodontic"}
        />
      )}

      {/*
      {cosmeticDataMenu.cosmeticData === "Porcelain" && (
        <Porcelain
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry={"prosthodontic"}
        />
      )}
      {cosmeticDataMenu.cosmeticData === "OralHygiene" && (
        <OralHygiene
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry={"prosthodontic"}
        />
      )} */}
      <Schedule dentistry="prosthodontic" />
      <ProsthodonticLocation />
      <ScheduleCTA
        dentistry={"prosthodontic"}
        subService={cosmeticDataMenu.cosmeticData}
      />

      <Footer dentistry={"prosthodontic"} />
    </>
  );
};

export default ProsthodonticHome;
