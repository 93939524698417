import React, { useState, useEffect } from "react";

import styles from "../CosmeticDentistry/DentalBonding.module.css";
import SideMenuRestorativeDentistry from "./SideMenuRestorativeDentistry";

export default function DentalImplants({ menuItem, menuItemName, dentistry }) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            Dental Implants
          </h1>
          <p className={styles.mainDescription}>
            <b
              className={styles.mainSpan}
              style={{ color: styleProperties.spanColor }}
            >
              Dental implants{" "}
            </b>
            are designed to provide a foundation for replacement teeth that
            look, feel and function like natural teeth. The person who has lost
            teeth regains the ability to eat virtually anything and can smile
            with confidence, knowing that teeth appear natural and that facial
            contours will be preserved. The implants are tiny titanium posts
            which are placed into the jaw bone where teeth are missing. The bone
            bonds with the titanium, creating a strong foundation for artificial
            teeth. In addition, dental implants can help preserve facial
            structure, preventing the bone deterioration which occurs when teeth
            are missing.
          </p>
          <p className={styles.secondaryDescription}>
            <b>
              Dental implants are changing the way people live! With them,
              people are rediscovering the comfort and confidence to eat, speak,
              laugh and enjoy life.
            </b>
          </p>
          <h4 className={styles.secondaryQuestion}>
            Evaluation for Dental Implants
          </h4>
          <p className={styles.secondaryDescription}>
            If, like many others, you feel implant dentistry is the choice for
            you, we ask that you undergo a dental/radiographic examination and
            health history. During these consultation visits, your specific
            needs and considerations will be addressed by an Oral and
            Maxillofacial Surgeon referred by our practice. Your questions and
            concerns are important to us and our team will work with you very
            closely to help make your procedure a success.
          </p>
          <h4 className={styles.secondaryQuestion}>
            Surgical Advances in Dental Implants
          </h4>
          <p className={styles.secondaryDescription}>
            Using the most recent advances in dental implant technology, Dentist
            are able to place single stage implants. These implants do not
            require a second procedure to uncover them, but do require a minimum
            of six weeks of healing time before artificial teeth are placed.
            There are even situations where the implant can be placed at the
            same time as the tooth extraction – further minimizing your number
            of surgical procedures.
          </p>
          <p className={styles.secondaryDescription}>
            Dental implant placement is a team effort between a periodontist and
            a restorative dentist. Dentist will perform the actual implant
            surgery, initial tooth extractions, and bone grafting if necessary.
            The restorative dentist (your dentist) fits and makes the permanent
            prosthesis. Your dentist will also make any temporary prosthesis
            needed during the implant process.
          </p>
          <h4 className={styles.secondaryQuestion}>Dental Implant Procedure</h4>
          <p className={styles.secondaryDescription}>
            Dental implants are metal anchors, which act as tooth root
            substitutes. They are surgically placed into the jaw bone. Small
            posts are then attached to the implant, which protrude through the
            gums. These posts provide stable anchors for artificial replacement
            teeth.
          </p>
          <p className={styles.secondaryDescription}>
            <b>
              For most patients, the placement of dental implants involves two
              surgical procedures.
            </b>{" "}
            First, dental implants are placed within your jaw bone. For the
            first three to six months following surgery, the implants are
            beneath the surface of the gums, gradually bonding with the jaw
            bone. You should be able to wear temporary dentures and eat a soft
            diet during this time. At the same time, your restorative dentist
            designs the final bridgework or denture, which will ultimately
            improve both function and aesthetics.
          </p>
          <p className={styles.secondaryDescription}>
            After the dental implant has bonded to the jaw bone, the second
            phase begins. The surgeon will uncover the implants and attach a
            small healing collar. Your Dentist can then start making your new
            teeth. An impression must be taken. Then posts or attachments can be
            connected to the implants. The replacement teeth are then made over
            the posts or attachments.{" "}
            <b>
              The entire procedure usually takes six to eight months. Most
              patients do not experience any disruption in their daily life.
            </b>
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuRestorativeDentistry
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
