import React, { useEffect, useState } from "react";

import styles from "../../PeriodontalMenu/PeriodontalMenu.module.css";
import SideMenuImplantsMenu from "./SideMenuImplantsMenu";

export default function CeramicDentalImplants({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const periodontalProperty = {
    textColor: "#fc7444",
    spanColor: "#978ab1",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = periodontalProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            CERAMIC DENTAL IMPLANTS
          </h1>
          <h4 className={styles.secondaryQuestion}>
            A HOLISTIC DENTISTRY APPROACH TO DENTAL IMPLANTS
          </h4>
          <h4 className={styles.secondaryQuestion}>
            What are ceramic implants?
          </h4>
          <p className={styles.mainDescription}>
            Dental implants are made of posts that are screwed into the jawbone,
            anchoring the final crown restoration and serving as excellent
            replacements for missing teeth. Since the 1960s, these artificial
            teeth have been made of titanium.{" "}
            <b>
              In 1987, Zirconia dental implants were introduced into dental
              implantology as a metal free, ceramic alternative.{" "}
            </b>
          </p>
          <h4 className={styles.secondaryQuestion}>
            Benefits of Ceramic Implants
          </h4>
          <p
            className={`${styles.secondaryDescription} ${styles.marginBottom}`}
          >
            <b>Health</b>
          </p>
          <p
            className={`${styles.secondaryDescription} ${styles.marginBottom}`}
          >
            Metal has the potential of causing harm to the body, blood supply,
            tissues, or cranial structures, so one advantage to having ceramic
            implants is that they are metal free and completely safe. Put
            simply, ceramic implants are a premium solution that pose no risk to
            your health.
          </p>
          <p
            className={`${styles.secondaryDescription} ${styles.marginBottom}`}
          >
            <b>Aesthetics</b>
          </p>
          <p
            className={`${styles.secondaryDescription} ${styles.marginBottom}`}
          >
            Our ceramic implants are made of tooth-colored material, so there is
            no chance that they will look like artificial teeth, unlike metal
            implants that have the potential to show through thin, translucent
            gums or gum recession.
          </p>
          <p
            className={`${styles.secondaryDescription} ${styles.marginBottom}`}
          >
            <b>Biocompatibility</b>
          </p>
          <p
            className={`${styles.secondaryDescription} ${styles.marginBottom}`}
          >
            Osseointegration is the amazing process of an implant (made of
            foreign material) fusing with bone. Zirconia—the component used in
            ceramic implants—has proven to be a truly biocompatible substance
            that can fully integrate with your jaw, while not affecting the
            health of the nearby tissue.
          </p>
          <p
            className={`${styles.secondaryDescription} ${styles.marginBottom}`}
          >
            <b>Durability</b>
          </p>
          <p
            className={`${styles.secondaryDescription} ${styles.marginBottom}`}
          >
            Ceramic implants are built to last, and because of the excellent
            biocompatible material of Zirconia, they prove durable and secure
            under the most extreme masticatory pressure.
          </p>
          <p
            className={`${styles.secondaryDescription} ${styles.marginBottom}`}
          >
            <b>Allergies</b>
          </p>
          <p
            className={`${styles.secondaryDescription} ${styles.marginBottom}`}
          >
            A very popular advantage of ceramic implants is the fact that they
            are hypoallergenic. The metal free factor provides peace of mind for
            patients with allergies or even mild sensitivity to metals.
          </p>
          <h4 className={styles.secondaryQuestion}>
            Would I benefit from ceramic implants?
          </h4>
          <p
            className={styles.secondaryDescription}
            style={{ marginBottom: "0.5rem" }}
          >
            Ceramic implants are ideal for those who have missing teeth and 
            metal sensitivity. If one or more of the following factors describe
            you, you may be a good case for this revolutionary, teeth
            replacement option:
          </p>

          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>Missing one or more teeth</li>
              <li>Good overall health</li>
              <li>Adequate jawbone mass and support</li>
              <li>Healthy gums</li>
              <li>Thin or translucent gums or gum recession</li>
              <li>Sensitivity/allergy to metals</li>
              <li>Looking for a holistic, metal free approach</li>
              <li>Committed to oral health</li>
            </ul>
          </p>
          <h4 className={styles.secondaryQuestion}>
            Metal-Free Implant Options
          </h4>
          <h4 className={styles.secondaryQuestion}>
            Are ceramic dental implants safe?
          </h4>
          <p className={styles.mainDescription}>
            The possible risks posed by ceramic dental implants are essentially
            the same as metal implants and any surgery in which a foreign body
            is placed in the body: infection at the implant site, injury or
            damage to surrounding structures, nerve damage, and sinus problems.
            However, the success rate of ceramic implants, which means no
            implant failure, is over 97%, and we have found that the unsurpassed
            level of our doctor’s expertise and training ensure the most optimal
            results possible.
          </p>
          <h4 className={styles.secondaryQuestion}>
            The Cost of Ceramic Implants
          </h4>
          <p className={styles.mainDescription}>
            Because of the extensive number of advantages that ceramic implants
            offer, we believe that you can’t afford NOT to use metal free
            implants. However, we understand that every person and situation is
            unique, so when you come in for a consultation, we will decide if
            you’re a case for ceramic implants and provide you with a
            detailed treatment plan that fits exactly with your oral needs and
            goals. The cost of ceramic implants depends on the particulars of
            each person’s case. That’s why we invite you to come in for an
            evaluation where we can give you an accurate estimate of what your
            cost will be.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuImplantsMenu
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
