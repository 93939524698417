import React, { useState, useRef, useEffect } from "react";

import { useInView } from "react-intersection-observer";
import PeriodontalDropDownHeader1 from "./PeriodontalDropDownHeader1";
import styles from "./PeriodontalHeader3.module.css";
import { useAppContext } from "../../AppContext";
import DropDownHeader from "../Home/DropDownHeader";
export default function PeriodontalHeader3({ generalMenuItem }) {
  const { setDisableAnimations, setIsScrolling } = useAppContext();
  const [ref, inView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  const [displayList, setDisplay] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const handleMenuItem = (data) => {
    setDisplay("none");
    generalMenuItem(data);
  };

  const handleHover = () => {
    setDisplay("flex");
    setIsHovered(true);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleScrollToSchedule = () => {
    setDisableAnimations(true);
    setIsScrolling(true);
    const scheduleSection = document.getElementById("schedule-section");
    if (scheduleSection) {
      scheduleSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <section className={styles.header}>
      <div
        className={`${styles.headerContainer} ${inView ? styles.animate : ""}`}
        ref={ref}
      >
        <div className={styles.logoSection}>
          <a href="/">
            <h3>Dental Planet</h3>
          </a>
        </div>
        {windowWidth <= 730 ? (
          <DropDownHeader dentistry={"periodontal"} />
        ) : (
          <nav className={styles.navBar}>
            <ul className={styles.navList}>
              <li className={styles.navItem}>
                <div className={styles.navBlock}>Services</div>
                <ul className={styles.dropDown}>
                  <li className={styles.dropDownItem}>
                    <a href="/general" className={styles.dropDownLink}>
                      General
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/prosthodontic" className={styles.dropDownLink}>
                      Prosthodontic
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/orthodontic" className={styles.dropDownLink}>
                      Orthodontic
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/pediatric" className={styles.dropDownLink}>
                      Pediatric
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/endodontic" className={styles.dropDownLink}>
                      Endodontic
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/periodontal" className={styles.dropDownLink}>
                      Periodontal
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/oralsurgery" className={styles.dropDownLink}>
                      Oral Surgery
                    </a>
                  </li>
                </ul>
              </li>

              <li className={styles.navItem}>
                <div className={styles.navBlock}>
                  <a
                    className={styles.navBlock}
                    href="/periodontal/periodontalServices"
                  >
                    Periodontal
                  </a>
                </div>

                <ul className={styles.dropDown}>
                  {/* <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/periodontal/TreatmentMethods"
                    >
                      Treatment Methods
                    </a>
                  </li> */}
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/periodontal/PeriodontalMaintenance"
                    >
                      Periodontal Maintenance
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/periodontal/TeethCleaning"
                    >
                      Professional Teeth Cleaning
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/periodontal/periodontalServices/Perioscopic"
                    >
                      Perioscopic Treatment
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/periodontal/periodontalServices/Bruxism"
                    >
                      Bruxism Treatment
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/periodontal/periodontalServices/Planing"
                    >
                      Scaling and Root Planing
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/periodontal/periodontalServices/Crown"
                    >
                      Crown Lengthening
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      href="/periodontal/periodontalServices/LaserTherapy"
                      className={styles.dropDownLink}
                    >
                      Laser Therapy
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/periodontal/periodontalServices/BiteAdjustment"
                    >
                      Bite Adjustment
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      href="/periodontal/periodontalServices/ReductionSurgery"
                      className={styles.dropDownLink}
                    >
                      Osseous Surgery
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/periodontal/periodontalServices/Grafting"
                    >
                      Gum Grafting
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/periodontal/periodontalServices/TissueRegeneration"
                    >
                      Guided Bone & Tissue Regeneration
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/periodontal/periodontalServices/Protein"
                    >
                      Bone Morphogenetic Protein
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/periodontal/periodontalServices/PeriodontalSurgery"
                    >
                      Cosmetic Periodontal Surgery
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/periodontal/periodontalServices/OralCancer"
                    >
                      Oral Cancer Exam
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/periodontal/periodontalServices/Stemcell"
                    >
                      Stem Cell recovery
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/periodontal/periodontalServices/ANESTHESIA"
                    >
                      Anesthesia
                    </a>
                  </li>
                </ul>
              </li>

              <li className={styles.navItem}>
                <div className={styles.navBlock}>
                  <a
                    className={styles.navBlock}
                    href="/periodontal/DentalImplants"
                  >
                    Dental Implants
                  </a>
                </div>
                <ul className={styles.dropDown}>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/periodontal/DentalImplants/MissingTeeth"
                    >
                      Replacing Missing Teeth
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/periodontal/DentalImplants/ImplantPlacement"
                    >
                      Overview of Implant Placement
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/periodontal/DentalImplants/LowerUpperTeeth"
                    >
                      Missing All Upper or Lower Teeth
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/periodontal/DentalImplants/BoneGrafting"
                    >
                      Bone Grafting for Implants
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/periodontal/DentalImplants/OverDenture"
                    >
                      Implant Supported Overdenture
                    </a>
                  </li>
                  {/* <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/periodontal/DentalImplants/TeethInAnHour"
                    >
                      Teeth-in-an-Hour
                    </a>
                  </li> */}
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/periodontal/DentalImplants/CeramicDentalImplants"
                    >
                      Ceramic Dental Implants
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/periodontal/DentalImplants/AfterImplantPlacement"
                    >
                      After Implant Placement
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/periodontal/DentalImplants/ImplantsCost"
                    >
                      Cost of Dental Implants
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/periodontal/DentalImplants/PerioBoneGrafting"
                    >
                      Bone Grafting
                    </a>
                  </li>
                </ul>
              </li>

              <li className={styles.navItem}>
                <a
                  href="#schedule-section"
                  onClick={handleScrollToSchedule}
                  className={styles.btnPrimary}
                >
                  Book An Appointment
                </a>
              </li>
            </ul>
          </nav>
        )}
      </div>
    </section>
  );
}
