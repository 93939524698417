import React, { useState, useEffect } from "react";
import styles from "./Procedures.module.css";
import SideMenuProcedures from "./SideMenuProcedures";
export default function APICOECTOMY({ menuItem, menuItemName, dentistry }) {
  const endodonticProperty = {
    textColor: "#6fcfff",
    spanColor: "#64bae6",
    backgroundColor: "#e7f1fca0",
  };
  const styleProperties = endodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            APICOECTOMY
          </h1>
          <h4 className={styles.secondaryQuestion}>
            Why would I need endodontic surgery?
          </h4>
          <p className={styles.secondaryDescription}>
            Generally, a root canal is all that is needed to save teeth with
            injured pulp from extraction. Occasionally, this non-surgical
            procedure will not be sufficient to heal the tooth and Dentist will
            recommend surgery. <b>Endodontic surgery </b>can be used to locate
            fractures or hidden canals that do not appear on x-rays but still
            manifest pain in the tooth. Damaged root surfaces or the surrounding
            bone may also be treated with this procedure.
            <b>
              {" "}
              The most common surgery used to save damaged teeth is an
              apicoectomy or root-end resection.
            </b>
          </p>
          <h4 className={styles.secondaryQuestion}>What is an apicoectomy?</h4>
          <p className={styles.secondaryDescription}>
            An incision is made in the gum tissue to expose the bone and
            surrounding inflamed tissue. The damaged tissue is removed along
            with the end of the root tip. A root-end filling is placed to
            prevent reinfection of the root and the gum is sutured. The bone
            naturally heals around the root over a period of months restoring
            full function.
          </p>
          <h4 className={styles.secondaryQuestion}>
            What should I expect after an apicoectomy?
          </h4>
          <p className={styles.secondaryDescription}>
            Following the procedure, there may be some discomfort or slight
            swelling while the incision heals. This is normal for any surgical
            procedure. To alleviate any discomfort, an appropriate pain
            medication will be recommended. If you have pain that does not
            respond to medication, please call our office.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuProcedures
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
