import React, { useState, useEffect } from "react";

import styles from "./Procedures.module.css";
import SideMenuProcedures from "./SideMenuProcedures";

export default function RichPlasma({ menuItem, menuItemName, dentistry }) {
  const oralsurgery = {
    textColor: "#d099d9",
    spanColor: "#644a68",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = oralsurgery;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            PLATELET RICH PLASMA and PLATELET RICH FIBRIN
          </h1>
          <p className={styles.mainDescription}>
            <b> Platelet rich plasma </b>(PRP) and <b>Platelet rich fibrin </b>
            (PRF)are by-product of blood (plasma) that is rich in platelets.
            Until now, its use has been confined to the hospital setting. This
            was due mainly to the cost of separating the platelets from the
            blood and the large amount of blood needed to produce a suitable
            quantity of platelets. New technology permits doctors to harvest and
            produce a sufficient quantity of platelets from only 55 cc of blood,
            which is drawn from the patient while they are having outpatient
            surgery.
          </p>
          <h4 className={styles.secondaryQuestion}>
            Why all the excitement about PRP and PRF?
          </h4>
          <p className={styles.secondaryDescription}>
            <b>PRP and PRF</b> permits the body to take advantage of the normal
            healing pathways at a greatly accelerated rate. During the healing
            process, the body rushes many cells and cell-types to the wound in
            order to initiate the healing process. One of those cell types is
            platelets. Platelets perform many functions, including formation of
            a blood clot and release of growth factors (GF) into the wound.
            These growth factors; platelet derived growth factors (PDGF),
            transforming growth factor beta (TGF), and insulin-like growth
            factor (ILGF), function to assist the body in repairing itself by
            stimulating stem cells to regenerate new tissue. The more growth
            factors released and sequestered into the wound, the more stem cells
            are stimulated to produce new tissue. Thus, PRP and PRF permits the
            body to heal faster and more efficiently.
          </p>
          <p className={styles.secondaryDescription}>
            A subfamily of TGF, is bone morphogenic protein (BMP). BMP has been
            shown to induce the formation of new bone in research studies in
            both animals and humans. This is of great significance to the
            surgeon who places dental implants. By adding PRP and PRF, and BMP,
            to the implant site with bone substitute particles, the implant
            surgeon can now grow bone more predictably and faster than ever
            before.
          </p>
          <h4 className={styles.secondaryQuestion}>
            PRP and PRF Have Many Clinical Applications
          </h4>
          <p className={styles.secondaryDescription}>
            PRP and PRF can be used to aid bone grafting for dental implants.
            This includes onlay and inlay grafts, sinus lift procedures, ridge
            augmentation procedures, closure of cleft and/or lip, and palate
            defects. It can also assist in repair of bone defects created by
            removal of teeth, or small cysts and repair of fistulas between the
            sinus cavity and mouth.
          </p>
          <h4
            className={styles.secondaryQuestion}
            style={{ marginBottom: "0.5rem" }}
          >
            PRP and PRF Also Has Many Advantages
          </h4>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>
                <b>Safety: </b>PRP and PRF are by-product of the patient’s own
                blood, therefore, disease transmission is not an issue.
              </li>
              <li>
                <b>Convenience: </b>PRP and PRF can be generated in the doctor’s
                office while the patient is undergoing an outpatient surgical
                procedure such as the placement of dental implants.
              </li>
              <li>
                <b>Faster healing: </b>The supersaturation of the wound with PRP
                and PRF, and thus growth factors, produces an increase of tissue
                synthesis and faster tissue regeneration.
              </li>
              {/* <li>
                <b>Cost effectiveness: </b>Since PRP harvesting is done with
                only 55 cc of blood in the doctor’s office, the patient need not
                incur the expense of the harvesting procedure in hospital or at
                a blood bank.
              </li> */}
              <li>
                <b>Ease of use: </b>PRP and PRF are easy to handle and actually
                improves the ease of application of bone substitute materials
                and bone grafting products by making them more gel-like.
              </li>
            </ul>
          </p>
          <h4 className={styles.secondaryQuestion}>
            Frequently Asked Questions About PRP
          </h4>
          <p className={styles.secondaryDescription}>
            <b>Is PRP and PRF safe? </b>
            Yes. During the outpatient surgical procedure a small amount of your
            own blood is drawn out via the IV. This blood is then placed in the
            PRP and PRF centrifuge machine and spun down. In less than 15
            minutes, the PRP and PRF is formed and ready to use.
          </p>
          <p className={styles.secondaryDescription}>
            <b>Should PRP and PRF be used in all bone-grafting cases? </b>
            Not always. In some cases, there is no need for PRP and PRF.
            However, in the majority of cases, application of PRP and PRF to the
            bone graft will increase the final amount of bone present, in
            addition to making the wound heal faster and more efficiently.
          </p>
          {/* <p className={styles.secondaryDescription}>
            <b>Will my insurance cover the costs? </b>
            Unfortunately not. The cost of the PRP application (approximately
            $400) is paid by the patient.
          </p> */}
          <p className={styles.secondaryDescription}>
            <b>Can PRP and PRF be used alone to stimulate bone formation? </b>
            No. PRP and PRF must be mixed with either the patient’s own bone, a
            bone substitute material such as demineralized freeze-dried bone, or
            a synthetic bone product, such as BIO-OSS.
          </p>
          <p className={styles.secondaryDescription}>
            <b>Are there any contraindications to PRP and PRF? </b>
            Very few. Obviously, patients with bleeding disorders or hematologic
            diseases do not qualify for this in-office procedure. Check with
            your surgeon and/or primary care physician to determine if PRP and
            PRF is right for you.
          </p>
          <h4
            className={styles.secondaryQuestion}
            style={{ marginBottom: "0.5rem" }}
          >
            What are the differences between PRP and PRF?
          </h4>
          <p className={styles.secondaryDescription}>
            Although there are some basic similarities between platelet-rich
            plasma and platelet-rich fibrin, it is the differences between these
            two products that makes platelet-rich fibrin stand out as a superior
            treatment option. Both of these products require blood to be taken
            from the patient. PRP requires more blood to be taken than PRF. Both
            of the products require the whole blood sample to be placed into a
            centrifuge, where the blood is then processed to help separate the
            blood into distinct layers. Platelet-rich plasma is spun at a higher
            speed, which effectively causes all of the heavier cells in the
            blood, such as white blood cells and stem cells, to collect in the
            bottom part of the test tube and allows the lighter platelets and
            plasma to collect in the top part of the test tube. The platelets
            and plasma are then collected from the upper part of the test tube
            and injected into the region with tissue damage. However, newer
            research is showing that a platelet product that has an even higher
            concentration of platelets, as well as a concentration of some white
            blood cells and stem cells, is even more effective than traditional
            platelet-rich plasma. With this new information, platelet-rich
            fibrin was created. The PRF product is spun at a lower speed so that
            the layers of the blood do not separate out as distinctly. This
            allows some of the white blood cells and stem cells to remain within
            the platelet layer that is collected for treatment. Thus, there are
            more healing factors within PRF than is typically seen in PRP. In
            addition, the lower spin speed causes less trauma to the individual
            cells of the blood, allowing more stem cells to remain in the final
            PRF product.
          </p>
          <p className={styles.secondaryDescription}>
            Another distinct difference between platelet-rich plasma and
            platelet-rich fibrin is the concentration of platelets within the
            final product. Literature states that an ideal concentration of
            platelets for a platelet rich plasma sample is between 2-5 times the
            level of platelets typically found within the body. Newer literature
            now points out that an even higher concentration of platelets may be
            even more effective in the treatment of tissue damage. Platelet-rich
            fibrin products contain approximately 10 times the platelet
            concentration that is found within the body.
          </p>
          <p className={styles.secondaryDescription}>
            A final difference worth mentioning between platelet-rich plasma and
            platelet-rich fibrin is the fact that with PRF, no anticoagulant is
            used during the processing of the blood. With platelet-rich plasma,
            the blood that is collected is placed in test tubes that have an
            anticoagulant called acid citrate dextrose (ACD), which keeps the
            blood product from clotting too quickly. In an effort to keep the
            platelet product as natural as possible, the platelet-rich fibrin
            test tubes do not have any anticoagulant within them. Without any
            anticoagulant in the test tubes, the natural fibrinogen within our
            blood is converted to fibrin by thrombin in the early stages of clot
            formation. This creates a spongy fibrin matrix that activates the
            platelets and allows for a slow release of growth factors, which
            starts the tissue healing process.
          </p>
        </div>

        {windowWidth > 730 && (
          <SideMenuProcedures
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
