import React, { useState, useEffect } from "react";

import styles from "../Braces/Braces.module.css";
import SideMenuLivingBraces from "./SideMenuLivingBraces";

export default function EatingWithBraces({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            EATING WITH BRACES
          </h1>
          <p className={styles.mainDescription}>
            During orthodontic treatment, you will be asked to avoid certain
            types of food that may damage your braces. In general, hard, sticky,
            crunchy and chewy foods should not be eaten while you have braces as
            they have a tendency to damage them, which may lead to a longer
            treatment time.
          </p>
          <h4
            className={styles.secondaryQuestion}
          >
            Foods to Avoid:
          </h4>
          <p className={styles.secondaryDescription}>
            <ul
              className={styles.subDescription}
              style={{ fontSize: "1.4rem", paddingTop: "0rem" }}
            >
              <li>Hard foods such as candy and nuts.</li>
              <li>Sticky foods such as caramel and gum.</li>
              <li>Chewy foods like hard rolls, bagels, and licorice.</li>
              <li>Crunchy foods such as chips, popcorn and ice.</li>
              <li>
                Large foods that you must bite into such as whole apples and
                carrots.
              </li>
            </ul>
          </p>
          <h4
            className={styles.secondaryQuestion}
          >
            Foods to Enjoy:
          </h4>
          <p className={styles.secondaryDescription}>
            <ul
              className={styles.subDescription}
              style={{ fontSize: "1.4rem", paddingTop: "0rem" }}
            >
              <li>Soft breads, tortillas, pancakes and muffins.</li>
              <li>Dairy products such as milk, pudding and cheese.</li>
              <li>Soft grains including pasta and rice.</li>
              <li>Meat such as ground beef and shredded chicken.</li>
              <li>Fish and seafood.</li>
              <li>
                Vegetables that are soft such as mashed potatoes, beans and
                spinach.
              </li>
              <li>Soft Fruits such as applesauce and bananas.</li>
              <li>Sweets including ice cream, milkshakes, gelatin and cake.</li>
            </ul>
          </p>
          <p className={styles.mainDescription}>
            We want to make your orthodontic treatment as comfortable and quick
            as possible! Please don’t hesitate to call us with any questions
            that you may have about living with your braces.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuLivingBraces
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
