import React, { useEffect, useState } from "react";

import styles from "./Instructions.module.css";
import SideMenuInstructions from "./SideMenuInstructions";

export default function WisdomToothRemoval({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const oralsurgery = {
    textColor: "#d099d9",
    spanColor: "#644a68",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = oralsurgery;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      //  style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            AFTER WISDOM TOOTH REMOVAL
          </h1>
          <h4 className={styles.secondaryQuestion}>
            Home Instructions After Wisdom Teeth Extraction
          </h4>
          <p className={styles.mainDescription}>
            The removal of
            <b
              className={styles.mainSpan}
              style={{ color: styleProperties.spanColor }}
            >
              impacted wisdom teeth
            </b>
            is a surgical procedure. Post-operative care is very important.
            Unnecessary pain and complications such as infection and swelling
            can be minimized if these instructions are followed carefully.
          </p>
          <h4
            className={styles.secondaryQuestion}
            style={{ marginBottom: "0.5rem" }}
          >
            Immediately Following Surgery
          </h4>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>
                The gauze pad placed over the surgical area should be kept in
                place for a half hour. After this time, the gauze pad should be
                removed and discarded.
              </li>
              <li>
                Vigorous mouth rinsing and/or touching the wound area following
                surgery should be avoided. This may initiate bleeding by causing
                the blood clot that has formed to become dislodged.
              </li>
              <li>
                Take the prescribed pain medications as soon as you begin to
                feel discomfort. This will usually coincide with the local
                anesthetic becoming diminished.
              </li>
              <li>
                Restrict your activities the day of surgery and resume normal
                activity when you feel comfortable.
              </li>
              <li>
                Place ice packs to the sides of your face where surgery was
                performed. Refer to the section on swelling for a more thorough
                explanation.
              </li>
            </ul>
          </p>
          <h4 className={styles.secondaryQuestion}>Bleeding</h4>
          <p className={styles.secondaryDescription}>
            A certain amount of bleeding is to be expected following surgery.
            Slight bleeding, oozing, or redness in the saliva is not uncommon.
            Excessive bleeding may be controlled by first rinsing or wiping any
            old clots from your mouth, then placing a gauze pad over the area
            and biting firmly for thirty minutes. Repeat if necessary. If
            bleeding continues, bite on a moistened black tea bag for thirty
            minutes. The tannic acid in the black tea helps to form a clot by
            contracting bleeding vessels. To minimize further bleeding, do not
            become excited, sit upright, and avoid exercise. If bleeding does
            not subside, call our office for further instructions.
          </p>
          <h4 className={styles.secondaryQuestion}>Swelling</h4>
          <p className={styles.secondaryDescription}>
            The swelling that is normally expected is usually proportional to
            the surgery involved. Swelling around the mouth, cheeks, eyes, and
            sides of the face is not uncommon. This is the body’s normal
            reaction to surgery and eventual repair. The swelling will not
            become apparent until the day following surgery and will not reach
            its maximum until 2-3 days post-operatively. However, the swelling
            may be minimized by the immediate use of ice packs. Two baggies
            filled with ice, or ice packs, should be applied to the sides of the
            face where surgery was performed. The ice packs should be left on
            continuously while you are awake. After 36 hours, ice has no
            beneficial effect. If swelling or jaw stiffness has persisted for
            several days, there is no cause for alarm. This is a normal reaction
            to surgery. Thirty-six hours following surgery, the application of
            moist heat to the sides of the face is beneficial in reducing the
            size of the swelling.
          </p>
          <h4 className={styles.secondaryQuestion}>Pain</h4>
          <p className={styles.secondaryDescription}>
            For moderate pain, one or two tablets of Acetaminophen may be taken
            every 3-4 hours. Ibuprofen may be taken instead of Acetaminophen.
            Ibuprofen bought over the counter comes in 200 mg tablets: 2-3
            tablets may be taken four times daily, not to exceed 3200mg daily
            for an adult. Consult our practice for individuals under 18. Do not
            take the two medications at the same time.
          </p>
          <p className={styles.secondaryDescription}>
            For severe pain, the prescribed medication should be taken as
            directed. Do not take any of the above medication if you are
            allergic to them, or have been instructed by your doctor not to take
            it. Do not drive an automobile or work around machinery. Avoid
            alcoholic beverages. Pain or discomfort following surgery should
            subside more and more every day. If pain persists, it may require
            attention and you should call the office.
          </p>
          <h4 className={styles.secondaryQuestion}>Diet</h4>
          <p className={styles.secondaryDescription}>
            Drink from a glass and do not use straws. The sucking motion can
            cause more bleeding by dislodging the blood clot. You may eat
            anything soft by chewing away from the surgical sites. A high
            calorie, high protein intake is very important. Our staff can
            provide suggested diet instructions. Nourishment should be taken
            regularly. You should prevent dehydration by taking fluids
            regularly. Your food intake will be limited for the first few days.
            You should compensate for this by increasing your fluid intake. At
            least 5-6 glasses of liquid should be taken daily. Try not to miss
            any meals. You will feel better, have more strength, less discomfort
            and heal faster if you continue to eat.
          </p>
          <p className={styles.secondaryDescription}>
            <b>CAUTION: </b>If you suddenly sit up or stand from a lying
            position you may become dizzy. If you are lying down following
            surgery, make sure you sit up for one minute before standing.
          </p>
          <h4 className={styles.secondaryQuestion}>Keep the mouth clean</h4>
          <p className={styles.secondaryDescription}>
            No rinsing of any kind should be performed until the day following
            surgery. The day after surgery you should begin rinsing at least 5-6
            times a day, especially after eating, with a teaspoon of salt mixed
            into one cup of warm water.
          </p>
          <h4 className={styles.secondaryQuestion}>Discoloration</h4>
          <p className={styles.secondaryDescription}>
            In some cases, discoloration of the skin follows swelling. The
            development of black, blue, green, or yellow discoloration is due to
            blood spreading beneath the tissues. This is a normal post-operative
            occurrence, which may occur 2-3 days post-operatively. Moist heat
            applied to the area may speed up the removal of the discoloration.
          </p>
          <h4 className={styles.secondaryQuestion}>Antibiotics</h4>
          <p className={styles.secondaryDescription}>
            If you have been placed on antibiotics, take the tablets or liquid
            as directed. Antibiotics will be given to help prevent infection.
            Discontinue antibiotic use in the event of a rash or any other
            unfavorable reaction and contact our office immediately. Call the
            office if you have any questions.
          </p>

          <h4
            className={styles.secondaryQuestion}
            style={{ marginBottom: "0.5rem" }}
          >
            Other Complications
          </h4>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>
                If numbness of the lip, chin, or tongue occurs there is no cause
                for alarm. As reviewed in your consultation, this is usually
                temporary in nature. You should be aware that if your lip or
                tongue is numb, you could bite it and not feel the sensation.
                Call our clinic if you have any questions.
              </li>
              <li>
                Slight elevation of temperature immediately following surgery is
                not uncommon. If the temperature persists, notify the office.
                Acetaminophen or ibuprofen should be taken to reduce the fever.
              </li>
              <li>
                You should be careful going from the lying down position to
                standing. You could get light headed from low blood sugar or
                medications. Before standing up, you should sit for one minute
                before getting up.
              </li>
              <li>
                Occasionally, patients may feel hard projections in the mouth
                with their tongue. They are not roots; they are the bony walls
                which supported the tooth. These projections usually smooth out
                spontaneously. If not, they can be removed by Dentist.
              </li>
              <li>
                If the corners of your mouth are stretched, they may dry out and
                crack. Your lips should be kept moist with an ointment such as
                vaseline.
              </li>
              <li>
                Sore throats and pain when swallowing are not uncommon. The
                muscles get swollen. The normal act of swallowing can then
                become painful. This will subside in 2-3 days.
              </li>
              <li>
                Stiffness (Trismus) of the jaw muscles may cause difficulty in
                opening your mouth for a few days following surgery. This is a
                normal post-operative event which will resolve in time.
              </li>
            </ul>
          </p>
          <h4 className={styles.secondaryQuestion}>Finally</h4>
          <p className={styles.secondaryDescription}>
            Sutures are placed in the area of surgery to minimize post-operative
            bleeding and to help healing. Sometimes they become dislodged. This
            is no cause for alarm. Just remove the suture from your mouth and
            discard it. The sutures will be removed approximately one week after
            surgery. The removal of sutures requires no anesthesia or needles.
            It takes only a minute or so, and there is usually no discomfort
            associated with this procedure.
          </p>
          <p className={styles.secondaryDescription}>
            The pain and swelling should subside more and more each day
            following surgery. If your post-operative pain or swelling worsens
            or unusual symptoms occur, call our office for instructions.
          </p>
          <p className={styles.secondaryDescription}>
            There will be a void where the tooth was removed. The void will fill
            in with new tissue gradually over the next month. In the meantime,
            the area should be kept clean, especially after meals, with salt
            water rinses or a toothbrush
          </p>
          <p className={styles.secondaryDescription}>
            Your case is unique, no two mouths are alike. Discuss any problems
            with the trained experts best able to effectively help you.
          </p>
          <p className={styles.secondaryDescription}>
            Brushing your teeth is okay – just be gentle at the surgical sites.
          </p>
          <p className={styles.secondaryDescription}>
            A dry socket is when the blood clot gets dislodged prematurely from
            the tooth socket. Symptoms of pain at the surgical site and even
            pain near the ear may occur 2-3 days following surgery. Call the
            office if this occurs.
          </p>
          <p className={styles.secondaryDescription}>
            If you are involved in regular exercise, be aware that your normal
            nourishment intake is reduced. Exercise may weaken you. If you get
            light headed, stop exercising.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuInstructions
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
