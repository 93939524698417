import React, { useState, useRef, useEffect } from "react";

import { useInView } from "react-intersection-observer";
import OralSurgeryDropDownHeader1 from "./OralSurgeryDropDownHeader1";
import styles from "./OralSurgeryHeader3.module.css";
import { useAppContext } from "../../AppContext";
import DropDownHeader from "../Home/DropDownHeader";
export default function OralSurgeryHeader3({ generalMenuItem }) {
  const { setDisableAnimations, setIsScrolling } = useAppContext();
  const [ref, inView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  const [displayList, setDisplay] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const handleMenuItem = (data) => {
    setDisplay("none");
    generalMenuItem(data);
  };

  const handleHover = () => {
    setDisplay("flex");
    setIsHovered(true);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleScrollToSchedule = () => {
    setDisableAnimations(true);
    setIsScrolling(true);
    const scheduleSection = document.getElementById("schedule-section");
    if (scheduleSection) {
      scheduleSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <section className={styles.header}>
      <div
        className={`${styles.headerContainer} ${inView ? styles.animate : ""}`}
        ref={ref}
      >
        <div className={styles.logoSection}>
          <a href="/">
            <h3>Dental Planet</h3>
          </a>
        </div>
        {windowWidth <= 730 ? (
          <DropDownHeader dentistry={"oralSurgery"} />
        ) : (
          <nav className={styles.navBar}>
            <ul className={styles.navList}>
              <li className={styles.navItem}>
                <div className={styles.navBlock}>Services</div>
                <ul className={styles.dropDown}>
                  <li className={styles.dropDownItem}>
                    <a href="/general" className={styles.dropDownLink}>
                      General
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/prosthodontic" className={styles.dropDownLink}>
                      Prosthodontic
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/orthodontic" className={styles.dropDownLink}>
                      Orthodontic
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/pediatric" className={styles.dropDownLink}>
                      Pediatric
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/endodontic" className={styles.dropDownLink}>
                      Endodontic
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/periodontal" className={styles.dropDownLink}>
                      Periodontal
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/oralsurgery" className={styles.dropDownLink}>
                      Oral Surgery
                    </a>
                  </li>
                </ul>
              </li>

              <li className={styles.navItem}>
                <div className={styles.navBlock}>
                  <a
                    className={styles.navBlock}
                    href="/oralsurgery/SurgicalInstructions"
                  >
                    Surgical Instructions
                  </a>
                </div>
                <ul className={styles.dropDown}>
                  {/* <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/oralsurgery/SurgicalInstructions/BeforeAnesthesia"
                    >
                      Before Anesthesia
                    </a>
                  </li> */}
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/oralsurgery/SurgicalInstructions/DentalImplantSurgery"
                    >
                      After Dental Implant Surgery
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/oralsurgery/SurgicalInstructions/WisdomToothRemoval"
                    >
                      After Wisdom Tooth Removal
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      href="/oralsurgery/SurgicalInstructions/ToothExposure"
                      className={styles.dropDownLink}
                    >
                      After Impacted Tooth Exposure
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      href="/oralsurgery/SurgicalInstructions/AfterExtractions"
                      className={styles.dropDownLink}
                    >
                      After Extractions
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      href="/oralsurgery/SurgicalInstructions/MultipleExtractions"
                      className={styles.dropDownLink}
                    >
                      After Multiple Extractions
                    </a>
                  </li>
                </ul>
              </li>

              <li className={styles.navItem}>
                <div className={styles.navBlock}>
                  <a className={styles.navBlock} href="/oralsurgery/Procedures">
                    Procedures
                  </a>
                </div>
                <ul className={styles.dropDown}>
                  <li className={styles.dropDownItem}>
                    <a
                      href="/oralsurgery/Procedures/DentalImplants"
                      className={styles.dropDownLink}
                    >
                      Dental Implants
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/oralsurgery/Procedures/BoneGrafting"
                    >
                      Bone Grafting
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/oralsurgery/Procedures/WisdomTeeth"
                    >
                      Wisdom Teeth
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/oralsurgery/Procedures/JawSurgery"
                    >
                      Orthognathic Surgery
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/oralsurgery/Procedures/FacialTrauma"
                    >
                      Facial Trauma
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/oralsurgery/Procedures/ToothExtractions"
                    >
                      Tooth Extractions
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/oralsurgery/Procedures/Preprosthetic"
                    >
                      Pre-Prosthetic Surgery
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/oralsurgery/Procedures/DistractionOsteogenesis"
                    >
                      Distraction Osteogenesis
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      href="/oralsurgery/Procedures/OralPathology"
                      className={styles.dropDownLink}
                    >
                      Oral Cancer
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      href="/oralsurgery/Procedures/TMJ"
                      className={styles.dropDownLink}
                    >
                      TMJ
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      href="/oralsurgery/Procedures/SleepApnea"
                      className={styles.dropDownLink}
                    >
                      Sleep Apnea
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      href="/oralsurgery/Procedures/CleftLipAndPalate"
                      className={styles.dropDownLink}
                    >
                      Cleft Lip & Palate
                    </a>
                  </li>
                  {/* <li className={styles.dropDownItem}>
                    <a
                      href="/oralsurgery/Procedures/Protein"
                      className={styles.dropDownLink}
                    >
                      Bone Morphogenetic Protein
                    </a>
                  </li> */}
                  <li className={styles.dropDownItem}>
                    <a
                      href="/oralsurgery/Procedures/StemCells"
                      className={styles.dropDownLink}
                    >
                      Stem Cells
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      href="/oralsurgery/Procedures/RichPlasma"
                      className={styles.dropDownLink}
                    >
                      Platelet Rich Plasma and Platelet Rich Fibrin
                    </a>
                  </li>
                  {/* <li className={styles.dropDownItem}>
                    <a
                      href="/oralsurgery/Procedures/RichFibrin"
                      className={styles.dropDownLink}
                    >
                      Platelet Rich Fibrin
                    </a>
                  </li> */}
                  <li className={styles.dropDownItem}>
                    <a
                      href="/oralsurgery/Procedures/ImpactedTeeth"
                      className={styles.dropDownLink}
                    >
                      Exposure of Impacted Teeth
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      href="/oralsurgery/Procedures/3DImaging"
                      className={styles.dropDownLink}
                    >
                      3D Imaging
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      href="/oralsurgery/Procedures/ANESTHESIA"
                      className={styles.dropDownLink}
                    >
                      Anesthesia
                    </a>
                  </li>
                </ul>
              </li>

              <li className={styles.navItem}>
                <a
                  href="#schedule-section"
                  onClick={handleScrollToSchedule}
                  className={styles.btnPrimary}
                >
                  Book An Appointment
                </a>
              </li>
            </ul>
          </nav>
        )}
      </div>
    </section>
  );
}
