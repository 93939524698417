import React from "react";

import styles from "./SideMenuAligners.module.css";
import { IonIcon } from "@ionic/react";
import { caretForwardOutline } from "ionicons/icons";
export default function SideMenuAligners({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const generalProperty = {
    backgroundColor: "#ffa07d",
  };
  const prosthodonticProperty = {
    backgroundColor: "#b6d6f5",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;

  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  return (
    <div
      className={styles.menuSection}
      style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <h2 className={styles.secondaryHeading}>Clear Braces</h2>
      <ul className={styles.main_nav_list}>
        <li className={styles.service}>
          {menuItemName === "ClearAligners" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/orthodontic/ClearBraces/ClearAligners"
          >
            <p className={styles.serviceHeader}>Clear Aligners</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "ClearCeramicBraces" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/orthodontic/ClearBraces/ClearCeramicBraces"
          >
            <p className={styles.serviceHeader}>Clear Ceramic Braces</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "ClearAlignersForTeens" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/orthodontic/ClearBraces/ClearAlignersForTeens"
          >
            <p className={styles.serviceHeader}>Clear Aligners For Teens</p>
          </a>
        </li>
      </ul>
    </div>
  );
}
