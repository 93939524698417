import React from "react";

import styles from "../../PeriodontalMenu/SideMenuPeriodontalMenu.module.css";
import { IonIcon } from "@ionic/react";
import { caretForwardOutline } from "ionicons/icons";
export default function SideMenuImplantsMenu({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const periodontalProperty = {
    backgroundColor: "#ffa07d",
  };
  const styleProperties = periodontalProperty;

  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  return (
    <div
      className={styles.menuSection}
      style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <h2 className={styles.secondaryHeading}>Periodontal Services</h2>
      <ul className={styles.main_nav_list}>
        <li className={styles.service}>
          {menuItemName === "MissingTeeth" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/periodontal/DentalImplants/MissingTeeth"
          >
            <p className={styles.serviceHeader}>Replacing Missing Teeth</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "ImplantPlacement" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/periodontal/DentalImplants/ImplantPlacement"
          >
            <p className={styles.serviceHeader}>
              {" "}
              Overview of Implant Placement
            </p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "LowerUpperTeeth" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/periodontal/DentalImplants/LowerUpperTeeth"
          >
            <p className={styles.serviceHeader}>
              {" "}
              Missing All Upper or Lower Teeth
            </p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "BoneGrafting" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/periodontal/DentalImplants/BoneGrafting"
          >
            <p className={styles.serviceHeader}>Bone Grafting for Implants</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "OverDenture" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/periodontal/DentalImplants/OverDenture"
          >
            <p className={styles.serviceHeader}>
              Implant Supported Overdenture
            </p>
          </a>
        </li>
        {/* <li className={styles.service}>
          {menuItemName === "TeethInAnHour" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/periodontal/DentalImplants/TeethInAnHour"
          >
            <p className={styles.serviceHeader}>Teeth-in-an-Hour</p>
          </a>
        </li> */}
        <li className={styles.service}>
          {menuItemName === "CeramicDentalImplants" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/periodontal/DentalImplants/CeramicDentalImplants"
          >
            <p className={styles.serviceHeader}> Ceramic Dental Implants</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "AfterImplantPlacement" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/periodontal/DentalImplants/AfterImplantPlacement"
          >
            <p className={styles.serviceHeader}> After Implant Placement </p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "ImplantsCost" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/periodontal/DentalImplants/ImplantsCost"
          >
            <p className={styles.serviceHeader}>Cost of Dental Implants</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "PerioBoneGrafting" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/periodontal/DentalImplants/PerioBoneGrafting"
          >
            <p className={styles.serviceHeader}>Bone Grafting</p>
          </a>
        </li>
      </ul>
    </div>
  );
}
