import React, { useState, useEffect } from "react";

import styles from "./Procedures.module.css";
import SideMenuProcedures from "./SideMenuProcedures";

export default function TraumaticInjuries({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const endodonticProperty = {
    textColor: "#6fcfff",
    spanColor: "#64bae6",
    backgroundColor: "#e7f1fca0",
  };
  const styleProperties = endodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            TRAUMATIC INJURIES TO THE MOUTH
          </h1>
          <h4 className={styles.secondaryQuestion}>Dislodged Teeth</h4>
          <p className={styles.mainDescription}>
            Injuries to the mouth can cause teeth to be pushed back into their
            sockets. Your general dentist may reposition and stabilize your
            tooth. Root canal treatment is usually started within a few weeks of
            the injury and a medication, such as calcium hydroxide, will be
            placed inside the tooth. Eventually, a permanent root canal filling
            will be implanted.
          </p>

          <p className={styles.secondaryDescription}>
            Sometimes a tooth may be pushed partially out of the socket. Again,
            your endodontist or general dentist may reposition and stabilize
            your tooth. If the pulp remains healthy, then no other treatment is
            necessary. Yet, if the pulp becomes damaged or infected, root canal
            treatment will be required. If left untreated, many different
            complications can arise. These complications can include but are not
            limited to: Infection of the tooth, root system or surrounding
            tissue, inadequate hygiene due to an inability to properly clean the
            dislodged tooth and the gums, and loss of the tooth.
          </p>
          <h4 className={styles.secondaryQuestion}>Avulsed Teeth</h4>
          <p className={styles.secondaryDescription}>
            If an injury causes a tooth to be completely knocked out of your
            mouth, it is important that you are treated immediately! If this
            happens to you, keep the tooth moist. If possible, put it back into
            the socket. A tooth can be saved if it remains moist. You can even
            put the tooth in milk or a glass of water (add a pinch of salt.)
            Dentist may start root canal treatment based upon the stage of root
            development. The length of time the tooth was out of your mouth and
            the way the tooth was stored, may influence the type of treatment
            you receive.
          </p>
          <h4 className={styles.secondaryQuestion}>
            Tooth Injuries in Children
          </h4>
          <p className={styles.secondaryDescription}>
            An injured immature tooth may need one of the following procedures
            to improve the chances of saving the tooth:
          </p>
          <h4 className={styles.secondaryQuestion}>
            <b>Apexogenesis</b>
          </h4>
          <p className={styles.secondaryDescription}>
            This procedure encourages the root to continue development as the
            pulp is healed. Soft tissue is covered with medication to encourage
            growth. The tip of the root (apex) will continue to close as the
            child gets older. In turn, the walls of the root canal will thicken.
            If the pulp heals, no additional treatment will be necessary. The
            more mature the root becomes, the better the chance to save the
            tooth.
          </p>
          <h4 className={styles.secondaryQuestion}>
            <b>Apexification</b>
          </h4>
          <p className={styles.secondaryDescription}>
            In this case, the unhealthy pulp is removed. The doctors place
            medication into the root to help a hard tissue form near the root
            tip. This hardened tissue provides a barrier for the root canal
            filling. At this point, the root canal walls will not continue to
            develop, making the tooth susceptible to fractures. So it is
            important to have the tooth properly restored by your dentist.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuProcedures
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
