import React from "react";
import { useAppContext } from "../../AppContext";

import styles from "./PeriodontalHero.module.css";
export default function PeriodontalHero() {
  const { setDisableAnimations, setIsScrolling } = useAppContext();
  const handleScrollToSchedule = () => {
    setDisableAnimations(true);
    setIsScrolling(true);
    const scheduleSection = document.getElementById("schedule-section");
    if (scheduleSection) {
      scheduleSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <section className={styles.heroSection}>
      <div className={styles.heroContainer}>
        <div className={styles.innerHeader}>
          <h3 className={styles.heroHeader}>
            <span>Your path to better dental wellness starts here</span>
          </h3>
          <p className={styles.heroDescription}>
            Count on our expert dentist to help you feel better and look better
          </p>
          <div className={styles.ctaBtn}>
            <a
              href="schedule-section"
              onClick={handleScrollToSchedule}
              className={styles.btnPrimary}
            >
              Book An Appointment
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
