import React from "react";

import styles from "./SideMenuPatient.module.css";
import { IonIcon } from "@ionic/react";
import { caretForwardOutline } from "ionicons/icons";
export default function SideMenuPatient({ menuItem, menuItemName, dentistry }) {
  const generalProperty = {
    textColor: "#545cd6",
    spanColor: "#656cda",
    backgroundColor: "#b3b3b3cf",
  };

  const styleProperties = generalProperty;

  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  return (
    <div
      className={styles.menuSection}
      style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <h2 className={styles.secondaryHeading}>Patient Informantion</h2>
      <ul className={styles.main_nav_list}>
        <li className={styles.service}>
          {menuItemName === "FirstVisit" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a className={styles.main_nav_link} href="/patient/FirstVisit">
            <p className={styles.serviceHeader}>First Visit</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "NewPatients" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a className={styles.main_nav_link} href="/patient/NewPatients">
            <p className={styles.serviceHeader}>New Patients</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "Scheduling" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a className={styles.main_nav_link} href="/patient/Scheduling">
            <p className={styles.serviceHeader}>Scheduling</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "PatientRegistration" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/patient/PatientRegistration"
          >
            <p className={styles.serviceHeader}>Patient Registration</p>
          </a>
        </li>
      </ul>
    </div>
  );
}
