import React, { useState, useEffect } from "react";
import axios from "axios";

import { useInView } from "react-intersection-observer";
import styles from "./PatientContent.module.css";
import SideMenuPatient from "./SideMenuPatient";
import Modal from "../Home/Modal";

export default function Scheduling({ menuItem, menuItemName, dentistry }) {
  const generalProperty = {
    textColor: "#545cd6",
    spanColor: "#656cda",
    backgroundColor: "#b3b3b3a9",
  };

  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [service, setService] = useState("");
  const [status, setStatus] = useState("");
  const [submit, setSubmit] = useState(false);
  const [complete, setComplete] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [patientMessage, setPatientMessage] = useState("");
  const success = "Submission Successfull";
  const successMessage =
    "Thank you for contacting us! Your message has been successfully sent. We will get back to you shortly.";
  const failMessage =
    "We are sorry, but there was an error sending your message. Please try again later.";
  const fail = "Submission Failed";
  const [ref, inView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });

  const handleCloseModal = () => {
    // Clear the error state when the dialog box is closed
    setModalIsOpen(false); // Close the modal dialog box
  };

  const sendWhatsAppMessage = async (e) => {
    let subservice = "Patient Scheduling Page";
    setSubmit(true);
    e.preventDefault();
    setService(subservice);
    console.log(service, subservice);
    try {
      const res = await axios.post(
        "https://dentalplanet-o6pa.onrender.com/send-whatsapp",
        {
          name: name,
          email: email,
          phoneNumber: phone,
          message: patientMessage,
          service: subservice,
        }
      );
      console.log(res);

      //  alert("WhatsApp message sent!");
      setStatus(success);
      setMessage(successMessage);
      setModalIsOpen(true);
      setComplete(true);
    } catch (error) {
      setStatus(fail);
      setMessage(failMessage);
      setModalIsOpen(true);
      alert("Error sending WhatsApp message. Check console for details.");
    }
  };
  const styleProperties = generalProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            Scheduling
          </h1>
          <p className={styles.mainDescription}>
            We will schedule your appointment as promptly as possible. If you
            have pain or an emergency situation, every attempt will be made to
            see you that day.
          </p>
          <p className={styles.secondaryDescription}>
            We try our best to stay on schedule to minimize your waiting. Due to
            the fact your Dentist provide many types of dental services, various
            circumstances may lengthen the time allocated for a procedure.
            Emergency cases can also arise and cause delays. We appreciate your
            understanding and patience.
          </p>

          <p
            className={styles.secondaryDescription}
            style={{ color: styleProperties.textColor }}
          >
            <b>
              Please call us at City Office Phone Number +91 9908873366 with any
              questions or to schedule an appointment.
            </b>
          </p>
          <h4
            className={styles.secondaryQuestion}
            style={{ color: styleProperties.textColor }}
          >
            Contact Us Today
          </h4>
          <p className={styles.secondaryDescription}>
            The first step towards a beautiful, healthy smile is to schedule an
            appointment. Please contact our office by phone or complete the
            appointment request form below. Our scheduling coordinator will
            contact you to confirm your appointment. If you are an existing
            patient, this contact form should not be utilized for communicating
            private health information.
          </p>
          <h4
            className={styles.secondaryQuestion}
            style={{ color: styleProperties.textColor }}
          >
            Fill the Form
          </h4>
          <form className={styles.ctaForm} action="#">
            <div>
              <input
                id="patientName"
                type="text"
                placeholder="Name"
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>

            <div>
              <input
                id="email"
                type="email"
                placeholder="me@example.com"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            <div>
              <input
                type="text"
                placeholder="Phone Number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>

            <textarea
              id="message"
              type="te"
              placeholder="Your message holds the key to unlocking our services"
              // onChange={handleMessageChange}
              onChange={(e) => setPatientMessage(e.target.value)}
              required
            ></textarea>

            <a
              href="#"
              className={styles.btnForm}
              onClick={sendWhatsAppMessage}
            >
              {complete
                ? "Submitted"
                : submit
                ? "Submitting..."
                : "Get In Touch"}
              {/* {load ? "Schedule a Call" : " Call Scheduled"} */}
            </a>
          </form>
        </div>
        {windowWidth > 730 && (
          <SideMenuPatient
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
        {modalIsOpen && (
          <Modal
            modalOpen={modalIsOpen}
            message={message}
            status={status}
            handleSetModal={setModalIsOpen}
          />
        )}
      </div>
    </section>
  );
}
