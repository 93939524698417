import React, { useState, useEffect } from "react";

import styles from "../../PeriodontalMenu/PeriodontalMenu.module.css";

import SideMenuImplantsMenu from "./SideMenuImplantsMenu";

export default function ImplantsMenu({ menuItem, menuItemName, dentistry }) {
  const periodontalProperty = {
    textColor: "#fc7444",
    spanColor: "#978ab1",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = periodontalProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      //  style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            Dental Implants
          </h1>
          <h4
            className={styles.secondaryQuestion}
            style={{ color: styleProperties.textColor }}
          >
            What are dental implants?
          </h4>
          <p className={styles.mainDescription}>
            A natural tooth consists of a root and a crown. If you compare
            natural teeth to <b>implant-supported replacement teeth,</b> you’ll
            see they have the same basic parts. Both have a crown (the visible
            part used to chew food). Both have a root that holds the tooth
            securely under the gum and is anchored into the jaw. The difference
            is that the implant is made of titanium – the same time-tested
            material used by surgeons for artificial joints. When you lose a
            tooth, you lose both the root and the crown. Before replacing the
            crown, the root must first be replaced with a small{" "}
            <b>dental implant.</b>
          </p>

          <p className={styles.secondaryDescription}>
            Time is allowed for bone to heal and grow around the dental implant.
            The bone bonds with the titanium, creating a strong foundation for
            artificial teeth. A support post (abutment) is then placed on the
            implant and a new replacement tooth (crown) is placed on top of the
            abutment. In many cases a temporary replacement tooth can be
            attached to the dental implant immediately after it is placed. If
            all of your teeth are missing, a variety of treatment options are
            available to support the replacement teeth.
          </p>
          <h4
            className={styles.secondaryQuestion}
            style={{ color: styleProperties.textColor }}
          >
            Surgical Advances
          </h4>
          <p className={styles.secondaryDescription}>
            Recent advances in dental implant technology have opened the door
            for single stage implants. These implants do not require a second
            procedure to uncover them, but do require a minimum of six weeks of
            healing time before artificial teeth are placed. There are even
            situations where the implant can be placed at the same time as the
            tooth extraction – further minimizing your number of surgical
            procedures. As a Periodontist, we are experts in making sure that
            your prosthetic teeth, including dental implants, look and feel just
            like your natural ones. By carefully fitting and measuring with the
            help of the newest in imaging technology, we are able to ensure
            optimal function and aesthetics.
          </p>
          <ul className={styles.items}>
            <li className={styles.item}>
              <a href="/periodontal/DentalImplants/MissingTeeth">
                Replacing Missing Teeth
              </a>
            </li>
            <li className={styles.item}>
              <a href="/periodontal/DentalImplants/ImplantPlacement">
                Overview of Implant Placement
              </a>
            </li>
            <li className={styles.item}>
              <a href="/periodontal/DentalImplants/LowerUpperTeeth">
                Missing All Upper or Lower Teeth
              </a>
            </li>
            <li className={styles.item}>
              <a href="/periodontal/DentalImplants/BoneGrafting">
                Bone Grafting for Implants
              </a>
            </li>
            <li className={styles.item}>
              <a href="/periodontal/DentalImplants/OverDenture">
                Implant Supported Overdenture
              </a>
            </li>
            {/* <li className={styles.item}>
              <a href="/periodontal/DentalImplants/TeethInAnHour">
                Teeth-in-an-Hour
              </a>
            </li> */}
            <li className={styles.item}>
              <a href="/periodontal/DentalImplants/CeramicDentalImplants">
                Ceramic Dental Implants
              </a>
            </li>
            <li className={styles.item}>
              <a href="/periodontal/DentalImplants/AfterImplantPlacement">
                After Implant Placement
              </a>
            </li>
            <li className={styles.item}>
              <a href="/periodontal/DentalImplants/ImplantsCost">
                Cost of Dental Implants
              </a>
            </li>
            <li className={styles.item}>
              <a href="/periodontal/DentalImplants/PerioBoneGrafting">
                Bone Grafting
              </a>
            </li>
          </ul>
        </div>
        {windowWidth > 730 && (
          <SideMenuImplantsMenu
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
