import React, { useState, useEffect } from "react";

import styles from "./DentalBonding.module.css";
import SideMenuCosmeticDentistry from "./SideMenuCosmeticDentistry";

export default function CosmeticDentistry({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            COSMETIC DENTISTRY
          </h1>
          <p className={styles.mainDescription}>
            Using advanced cosmetic dental treatments and materials, we can make
            a real difference. The skill, experience, and commitment of our
            practice — using a unique combination of science and artistry — can
            literally redesign your smile.
          </p>
          <p className={styles.mainDescription}>
            Even a subtle change in your smile helps you to project an image of
            self-confidence and high personal esteem. When you feel good about
            yourself, it shows in your appearance.
          </p>
          <h4
            className={styles.secondaryQuestion}
            style={{ color: styleProperties.textColor }}
          >
            Cosmetic Dental Treatments
          </h4>
          <ul className={styles.items}>
            <li className={styles.item}>
              <a href="/general/CosmeticDentistry/DentalBonding">
                Dental Bonding
              </a>
            </li>
            <li className={styles.item}>
              <a href="/general/CosmeticDentistry/PorcelainVeins">
                Porcelain Veneers
              </a>
            </li>
            <li className={styles.item}>
              <a href="/general/CosmeticDentistry/InlaysOnlays">
                Inlays & Onlays
              </a>
            </li>
            <li className={styles.item}>
              <a href="/general/CosmeticDentistry/TeethWhitening">
                Teeth Whitening
              </a>
            </li>
            <li className={styles.item}>
              <a href="/general/CosmeticDentistry/E4DDentist">E4D Dentist</a>
            </li>
            <li className={styles.item}>
              <a href="/general/CosmeticDentistry/ClearBraces">Aligners</a>
            </li>
            <li className={styles.item}>
              <a href="/general/CosmeticDentistry/CEREC">CEREC</a>
            </li>
          </ul>
        </div>
        {windowWidth > 730 && (
          <SideMenuCosmeticDentistry
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
