import styles from "./Instructions.module.css";
import SideMenuInstructions from "./SideMenuInstructions";
import { useEffect, useState } from "react";

export default function AfterExtractions({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const oralsurgery = {
    textColor: "#d099d9",
    spanColor: "#644a68",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = oralsurgery;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
    //  style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            AFTER TOOTH EXTRACTION
          </h1>
          <h4 className={styles.secondaryQuestion}>
            Home Instructions After the Removal of a Single Tooth
          </h4>
          <p className={styles.mainDescription}>
            After{" "}
            <b
              className={styles.mainSpan}
              style={{ color: styleProperties.spanColor }}
            >
              tooth extraction,{" "}
            </b>
            it’s important for a blood clot to form to stop the bleeding and
            begin the healing process. Bite on a gauze pad for 30-45 minutes
            immediately after the appointment. If the bleeding or oozing still
            persists, place another gauze pad and bite firmly for another 30
            minutes. You may have to do this several times to staunch the flow
            of blood.
          </p>

          <p className={styles.secondaryDescription}>
            After the blood clot forms it is important to not disturb or
            dislodge the clot. Do not rinse vigorously, suck on straws, smoke,
            drink alcohol or brush teeth next to the extraction site for 72
            hours. These activities may dislodge or dissolve the clot and hinder
            the healing process. Limit vigorous exercise for the next 24 hours,
            as this increases blood pressure and may cause more bleeding from
            the extraction site.
          </p>

          <p className={styles.secondaryDescription}>
            After the tooth is extracted you may feel some pain and experience
            some swelling. An ice pack or an unopened bag of frozen peas or corn
            applied to the area will keep swelling to a minimum. Take pain
            medications as prescribed. The swelling usually subsides after 48
            hours.
          </p>

          <p className={styles.secondaryDescription}>
            Use pain medication as directed. Call our office if the medication
            doesn’t seem to be working. If antibiotics are prescribed, continue
            to take them for the indicated length of time even if signs and
            symptoms of infection are gone. Drink lots of fluids and eat
            nutritious, soft food on the day of the extraction. You can eat
            normally as soon as you are comfortable.
          </p>

          <p className={styles.secondaryDescription}>
            It is important to resume your normal dental routine after 24 hours.
            This should include brushing and flossing your teeth at least once a
            day. This will speed healing and help keep your mouth fresh and
            clean.
          </p>
          <p className={styles.secondaryDescription}>
            For severe pain, the prescribed medication should be taken as
            directed. Do not take any of the above medication if you are
            allergic to them, or have been instructed by your doctor not to take
            it. Do not drive an automobile or work around machinery. Avoid
            alcoholic beverages. Pain or discomfort following surgery should
            subside more and more every day. If pain persists, it may require
            attention and you should call the office.
          </p>

          <p className={styles.secondaryDescription}>
            After a few days you should feel fine and can resume your normal
            activities. If you have heavy bleeding, severe pain, continued
            swelling for 2-3 days, or a reaction to the medication, call our
            office immediately.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuInstructions
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
