import React, { useState, useEffect } from "react";

import styles from "../PediatricMenu/PediatricMenu.module.css";
import SideMenuPediatricMenu from "./SideMenuPediatricMenu";

export default function GumDisease({ menuItem, menuItemName, dentistry }) {
  const pediatricProperty = {
    textColor: "#9086a5",
    spanColor: "#776c8c",
    backgroundColor: "#e6e4eb",
  };
  const styleProperties = pediatricProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            GUM DISEASE
          </h1>

          <p className={styles.mainDescription}>
            While many people believe periodontal disease is an adult problem,
            studies indicate that gingivitis (the first stage of periodontal
            disease) is nearly a universal problem among children and
            adolescents. Advanced forms of periodontal disease are more rare in
            children than adults, but can occur.
          </p>

          <p className={styles.mainDescription}>
            Chronic gingivitis is common in children. It can cause gum tissue to
            swell, turn red, and bleed easily. Gingivitis is preventable and
            treatable with a regular routine of brushing, flossing, and
            professional dental care. If left untreated, it can eventually
            advance to more serious forms of periodontal disease.
          </p>
          <p className={styles.mainDescription}>
            Localized aggressive periodontitis can affect young healthy
            children. It is found in teenagers and young adults and mainly
            affects the first molars and incisors. It is characterized by the
            severe loss of alveolar bone, and ironically, patients generally
            form very little dental plaque or calculus.
          </p>
          <p className={styles.mainDescription}>
            Generalized aggressive periodontitis may begin around puberty and
            involve the entire mouth. It is marked by inflammation of the gums
            and heavy accumulations of plaque and calculus. Eventually it can
            cause the teeth to become loose.
          </p>

          <h4
            className={styles.secondaryQuestion}
            style={{ marginBottom: "0.5rem" }}
          >
            Conditions that make children more susceptible to periodontal
            disease include:
          </h4>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>Type I diabetes</li>
              <li>Down syndrome</li>
              <li>Papillon-Lefevre syndrome</li>
            </ul>
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuPediatricMenu
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
