import React, { useState } from "react";
import { IonIcon } from "@ionic/react";
import { checkmarkCircle, closeCircle, close } from "ionicons/icons";
import styles from "./Modal.module.css";
export default function Modal({
  messageStatus,
  modalOpen,
  message,
  status,
  handleSetModal,
}) {
  const handleClose = () => {
    handleSetModal(!modalOpen);
  };

  return (
    <div className={`${styles.modal} ${modalOpen ? styles.active : ""}`}>
      {console.log(messageStatus)}
      <div className={styles.overlay} onClick={handleClose}></div>
      <div className={styles.container}>
        <IonIcon icon={close} className={styles.close} onClick={handleClose} />
        <IonIcon
          icon={messageStatus === "fail" ? closeCircle : checkmarkCircle}
          className={styles.checkmark}
        />
        <h3>{status}</h3>
        <p>{message}</p>
      </div>
    </div>
  );
}
