import React, { useEffect, useState } from "react";

import styles from "../../PeriodontalMenu/PeriodontalMenu.module.css";
import SideMenuImplantsMenu from "./SideMenuImplantsMenu";

export default function AfterImplantPlacement({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const periodontalProperty = {
    textColor: "#fc7444",
    spanColor: "#978ab1",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = periodontalProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            AFTER DENTAL IMPLANT PLACEMENT FAQ
          </h1>
          <h4 className={styles.secondaryQuestion}>
            What can I use for teeth while the implants heal?
          </h4>
          <p className={styles.mainDescription}>
            Many options are available, and they are tailored to your specific
            requirements. If you need a replacement tooth while the implants are
            healing, temporary removable teeth or a temporary bridge can be
            made. If all of your teeth are missing, we can usually modify your
            present complete denture or make you a new temporary denture. If you
            would prefer non-removable teeth during the healing phase, depending
            on your particular situation, some implants can be placed and
            “loaded” immediately. This means a temporary or permanent
            replacement tooth can be placed on, or shortly after, the day the
            implant is placed.
          </p>
          <h4 className={styles.secondaryQuestion}>
            What are the potential problems after dental implant surgery?What
            are the potential problems after dental implant surgery?
          </h4>
          <p className={styles.secondaryDescription}>
            Although it is natural to be concerned about the pain that may be
            caused by these procedures, most patients do not experience severe
            or significant post-operative pain. Pain medication and antibiotics
            will be prescribed for you to make your recovery as easy as
            possible. Occasionally, some people develop post-operative
            infections that require additional antibiotic treatment. Even though
            great care is taken to place the implant precisely, occasionally
            adjacent teeth are injured in the placement process. In addition,
            there is a chance that the nerve in the lower jaw, which provides
            sensation to your lower lip and chin, may be affected. If you are
            missing quite a lot of bone, it might be difficult to place an
            implant without infringing on the nerve space. Although we take
            great care to avoid this nerve, occasionally it is irritated during
            the procedure, resulting in tingling, numbness or a complete lack of
            sensation in your lip, chin or tongue. Usually these altered
            sensations will resolve within time, but they can be permanent
            and/or painful. If you notify us of post-operative numbness as soon
            as possible, it will allow us to manage your care in the most
            appropriate way.
          </p>
          <h4 className={styles.secondaryQuestion}>
            How long will the implants last?
          </h4>
          <p className={styles.secondaryDescription}>
            Implants usually last a long time. When patients are missing all of
            their teeth, long-term studies (more than 30 years) show an 80 to 90
            percent success rate. For patients missing one or several teeth,
            recent studies show a success rate of greater than 95 percent, which
            compares favorably with other areas in the body that receive implant
            replacement (such as hips or knees). However, if one of your dental
            implants either doesn’t heal properly or loosens after a period of
            time, you may need to have it removed. After the site heals (or on
            occasion at the time of removal), another implant usually can be
            placed.
          </p>
          <h4 className={styles.secondaryQuestion}>
            When are the replacement teeth attached to the implant?
          </h4>
          <p className={styles.secondaryDescription}>
            The replacement teeth are usually attached to the implant when
            adequate healing has occurred and your jaw bone is firmly fused to
            the implant. Depending on a variety of factors, it may be possible
            to begin this phase of your treatment immediately or shortly after
            implant placement. We will review the most appropriate treatment
            sequence and timing for your particular situation.
          </p>
          <p className={styles.secondaryDescription}>
            The dental work required to complete your treatment is complex. Most
            of the work involves actually making the new teeth before they are
            placed. Your appointments are considered more comfortable and more
            pleasant than previous methods of tooth replacement. Frequently,
            this process can be performed without local anesthesia.
          </p>
          <p className={styles.secondaryDescription}>
            Your restorative treatment begins with specialized impressions that
            allow us to produce a replica of your mouth and implants. We will
            also make “bite” records so that we see the relationship of your
            upper and lower jaws. With this information, we will make the
            abutments (support posts) that attach your replacement teeth to your
            implants. Various types of abutments exist. Frequently, we can use
            “off the shelf” abutments. Other times, custom abutments must be
            made of gold or a tooth-colored ceramic material. As you can
            imagine, these custom made abutments add to the cost and treatment
            time involved. Which abutment to use is a decision that often cannot
            be made until after healing is complete and impressions have been
            made.
          </p>
          <p className={styles.secondaryDescription}>
            The number of appointments and the amount of time required for each
            appointment is different for each patient. No two cases are exactly
            the same and regardless of the number of teeth replaced, the work
            must be completed with great precision and attention to detail. If
            you are having only a few teeth replaced, as few as three short
            appointments may be required. Between appointments, we will need
            time to complete the necessary lab work to make your replacement
            teeth. It is most beneficial that you keep all of your scheduled
            appointments.
          </p>
          <p className={styles.secondaryDescription}>
            If your final restoration is a removable denture, you will need to
            come to as many as five office appointments (although it may be
            fewer) over the following several months. During these appointments,
            we will perform a series of impressions, bites and adjustments in
            order to make your new teeth, as well as the custom support bars,
            snaps, magnets, or clips that will secure your teeth to the dental
            implants. During this period, every effort will be made to ensure
            you have comfortable, temporary replacement teeth.
          </p>
          <p className={styles.secondaryDescription}>
            In general, once your implants are placed, you can expect your tooth
            replacement treatment to be completed anywhere from 1 to 4 months
            but may extend upto 12 months depending upon local and systemic
            conditions. For these reasons, it is difficult for us to tell you
            exactly how much the restorative phase of your treatment will cost,
            although you should receive a reasonable estimate from our office.
            It also is difficult to give you a specific timeframe for completion
            of your treatment until after the implants are ready for
            restoration.
          </p>
          <h4 className={styles.secondaryQuestion}>
            How do I clean my new teeth?
          </h4>
          <p className={styles.secondaryDescription}>
            As with natural teeth, it is important that you clean
            implant-supported restorations regularly with toothbrushes, floss
            and any other recommended aids. You should also visit your dentist
            several times each year for hygiene and maintenance. As with regular
            dentures and other tooth replacements, your dental implants and
            their associated components are subject to wear and tear and
            eventually will need repair, including clip replacement, relines,
            screw tightening, and other adjustments.
          </p>
          <h4 className={styles.secondaryQuestion}>
            Will one doctor do everything?
          </h4>
          <p className={styles.secondaryDescription}>
            Usually, a dental surgeon places the dental implant(s) and performs
            other necessary surgical procedures – your general dentist provides
            the temporary and permanent replacement teeth. Both doctors are
            involved in planning your dental treatment. Also, depending upon a
            variety of factors, different dental specialists may help with your
            dental care.
          </p>
          {/* <h4 className={styles.secondaryQuestion}>
            How much does dental implant treatment cost?
          </h4>
          <p className={styles.secondaryDescription}>
            Before treatment begins, every effort will be made to give you an
            accurate estimate of all the expenses involved in placing the
            implants and making your replacement teeth. In many cases, there is
            an initial charge for the diagnostic work-up, including study
            models, x-rays, and the fabrication of a surgical template to ensure
            the best possible result. In addition you will be charged for the
            abutment or support post(s), plus the crown, dentures, or anything
            else that will be placed over the implants, including temporary
            restorations. Periodic maintenance such as hygiene visits, tissue
            conditioners, denture relines and other repairs will also incur
            additional charges.
          </p> */}
          {/* <p className={styles.secondaryDescription}>
            When different doctors are involved in your treatment, you will be
            charged separately for their services. We will try to assist you in
            estimating what your actual payments will be after we evaluate your
            insurance coverage or other third party payments. Also, you should
            consider your personal financial investment in each treatment option
            as some insurance companies provide limited or no coverage.
          </p> */}
          {/* <p className={styles.secondaryDescription}>
            Each patient is unique, and it is not possible for us to discuss
            every option and every contingency for treatment outcome. This
            website is intended to help you understand the general treatment
            options available to you. If your specific treatment options are not
            clear, please contact us. We will be happy to answer any questions
            you have about your dental care.
          </p> */}
        </div>
        {windowWidth > 730 && (
          <SideMenuImplantsMenu
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
