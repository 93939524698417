import React, { useState, useEffect } from "react";

import styles from "./Procedures.module.css";
import SideMenuProcedures from "./SideMenuProcedures";

export default function RichFibrin({ menuItem, menuItemName, dentistry }) {
  const oralsurgery = {
    textColor: "#d099d9",
    spanColor: "#644a68",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = oralsurgery;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            PLATELET RICH FIBRIN  
          </h1>

          <p className={styles.mainDescription}>
            Platelet rich fibrin (PRF) is a by-product of blood (plasma) that is
            rich in platelets. Until now, its use has been confined to the
            hospital setting. This was due mainly to the cost of separating the
            platelets from the blood and the large amount of blood needed to
            produce a suitable quantity of platelets. New technology permits
            doctors to harvest and produce a sufficient quantity of platelets
            from only 20-50 cc of blood, which is drawn from the patient while
            they are having outpatient surgery.
          </p>
          <h4 className={styles.secondaryQuestion}>
            Why all the excitement about PRF?
          </h4>
          <p className={styles.secondaryDescription}>
            PRF permits the body to take advantage of the normal healing
            pathways at a greatly accelerated rate. During the healing process,
            the body rushes many cell types to the wound in order to initiate
            the healing process. Platelets are one of those cell types.
            Platelets perform many functions, including formation of a blood
            clot and release of growth factors (GF) into the wound. These growth
            factors; platelet derived growth factors (PDGF), transforming growth
            factor beta (TGF), and insulin-like growth factor (ILGF), function
            to assist the body in repairing itself by stimulating stem cells to
            regenerate new tissue. The more growth factors released and
            sequestered into the wound, the more stem cells are stimulated to
            produce new tissue. Thus, PRF permits the body to heal faster and
            more efficiently.
          </p>
          <p className={styles.secondaryDescription}>
            A particularly important component  is bone morphogenic protein
            (BMP). It has been shown to induce the formation of new bone in
            research studies. This is of great significance to the surgeon who
            places dental implants. By adding PRF to the implant site with bone
            substitute particles, the implant surgeon can now grow bone more
            predictably and faster than ever before.
          </p>
          <h4 className={styles.secondaryQuestion}>
            PRF Has Many Clinical Applications
          </h4>
          <p className={styles.secondaryDescription}>
            PRF can be used to aid bone grafting for dental implants. This
            includes sinus lift procedures, ridge augmentation procedures, and
            palate defects. It can also assist in repair of bone defects created
            by removal of teeth, or small cysts and repair of fistulas between
            the sinus cavity and mouth.
          </p>
          <h4
            className={styles.secondaryQuestion}
            style={{ marginBottom: "0.5rem" }}
          >
            PRF Also Has Many Advantages
          </h4>

          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>
                <b>Safety: </b>PRF is a by-product of the patient’s own blood,
                therefore, disease transmission is not an issue.
              </li>
              <li>
                <b>Convenience: </b>PRF can be generated in the doctor’s office
                while the patient is undergoing an outpatient surgical procedure
                such as the placement of dental implants.
              </li>
              <li>
                <b>Faster healing: </b>The supersaturation of the wound with
                PRF, and thus growth factors, produces an increase of tissue
                synthesis and faster tissue regeneration.
              </li>
              <li>
                <b>Cost effectiveness: </b>Since PRF harvesting is done with
                only 20-50 cc of blood in the doctor’s office, the patient need
                not incur the expense of the harvesting procedure in hospital or
                at a blood bank.
              </li>
              <li>
                <b>Ease of use: </b>PRF is easy to handle and actually improves
                the ease of application of bone substitute materials and bone
                grafting products by making them more gel-like.
              </li>
            </ul>
          </p>
          <h4 className={styles.secondaryQuestion}>
            Frequently Asked Questions About PRF
          </h4>
          <p className={styles.secondaryDescription}>
            <b>Is PRF safe? </b>
            Yes. During the outpatient surgical procedure a small amount of your
            own blood is drawn out via the IV. This blood is then placed in the
            PRF centrifuge machine and spun down. In less than 15 minutes, the
            PRF is formed and ready to use.
          </p>
          <p className={styles.secondaryDescription}>
            <b>Should PRF be used in all bone-grafting cases? </b>
            Not always. In some cases, there is no need for PRF. However, in the
            majority of cases, application of PRF to the bone graft will
            increase the final amount of bone present, in addition to making the
            wound heal faster and more efficiently.
          </p>
          {/* <p className={styles.secondaryDescription}>
            <b>Will my insurance cover the costs? </b>
            Unfortunately not. The cost of the PRF application (approximately
            $450) is paid by the patient.
          </p> */}
          <p className={styles.secondaryDescription}>
            <b>Can PRF be used alone to stimulate bone formation? </b>
            No. PRF must be mixed with either the patient’s own bone, a bone
            substitute material such as demineralized freeze-dried bone, or a
            synthetic bone product.
          </p>
          <p className={styles.secondaryDescription}>
            <b>Are there any contraindications to PRF? </b>
            Very few. Obviously, patients with bleeding disorders or hematologic
            diseases do not qualify for this in-office procedure. Check with
            your surgeon to determine if PRF is right for you.
          </p>
        </div>

        {windowWidth > 730 && (
          <SideMenuProcedures
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
