import React, { useState, useEffect } from "react";

import styles from "./Procedures.module.css";
import SideMenuProcedures from "./SideMenuProcedures";
import SideMenuPeriodontalMenu from "../../PeriodontalMenu/SideMenuPeriodontalMenu";

export default function Stemcells({ menuItem, menuItemName, dentistry }) {
  const oralsurgery = {
    textColor: "#d099d9",
    spanColor: "#644a68",
    backgroundColor: "#f3f2f5",
  };
  const periodontalProperty = {
    textColor: "#fc7444",
    spanColor: "#978ab1",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties =
    dentistry == "periodontal" ? periodontalProperty : oralsurgery;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            STEM CELLS
          </h1>
          <h4 className={styles.secondaryQuestion}>
            The Restorative Properties of Stem Cells
          </h4>

          <p className={styles.mainDescription}>
            Stem cells are unique because they drive the natural healing process
            throughout your life. Stem cells are different from other cells in
            the body because they regenerate and produce specialized cell types.
            They heal and restore skin, bones, cartilage, muscles, nerves and
            other tissues when injured.
          </p>

          <p
            className={styles.secondaryDescription}
            style={{ marginBottom: "0.5rem" }}
          >
            As a result, amazing new medical treatments are being developed to
            treat a range of diseases contemporary medicine currently deems
            difficult or impossible to treat. Among them are:
          </p>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>Parkinson’s Disease</li>
              <li>Brain Injuries</li>
              <li>Heart Disease</li>
              <li>Diabetes</li>
              <li>Arthritis</li>
              <li>Muscular dystrophy</li>
              <li>Leukemia</li>
              <li>Crohn’s disease</li>
              <li>Multiple Sclerosis</li>
              <li>Periodontal Disease</li>
              <li>Sports Injuries</li>
              <li>Cosmetic and Anti-aging Applications</li>
            </ul>
          </p>

          <p className={styles.secondaryDescription}>
            While stem cells can be found in most tissues of the body, they are
            usually buried deep, are few in number and are similar in appearance
            to surrounding cells. With the discovery of stem cells in teeth, an
            accessible and available source of stem cells has been identified.
          </p>

          <p className={styles.secondaryDescription}>
            The tooth is nature’s “safe” for these valuable stem cells, and
            there is an abundance of these cells in baby teeth, wisdom teeth and
            permanent teeth. The stem cells contained within teeth are capable
            of replicating themselves and can be readily recovered at the time
            of a planned dental procedure. Living stem cells found within
            extracted teeth were routinely discarded every day, but now, with
            the knowledge from recent medical research, your Doctor provides you
            the opportunity to save these cells for future use in developing
            medical treatments for your family.
          </p>
          <p className={styles.secondaryDescription}>
            Aside from being the most convenient stem cells to access, dental
            stem cells have significant medical benefits in the development of
            new medical therapies. Using one’s own stem cells for medical
            treatment means a much lower risk of rejection by the body and
            decreases the need for powerful drugs that weaken the immune system,
            both of which are negative but typical realities that come into play
            when tissues or cells from a donor are used to treat patients.
          </p>

          <p className={styles.secondaryDescription}>
            Further, the stem cells from teeth have been observed in research
            studies to be among the most powerful stem cells in the human body.
            Stem cells from teeth replicate at a faster rate and for a longer
            period of time than do stem cells harvested from other tissues of
            the body.
          </p>
          <p className={styles.secondaryDescription}>
            Stem cells in the human body age over time and their regenerative
            abilities slow down later in life. The earlier in life that your
            family’s stem cells are secured, the more valuable they will be when
            they are needed most.
          </p>
        </div>

        {windowWidth > 730 &&
          (dentistry == "periodontal" ? (
            <SideMenuPeriodontalMenu
              menuItem={handleMenuClick}
              menuItemName={menuItemName}
              dentistry={dentistry}
            />
          ) : (
            <SideMenuProcedures
              menuItem={handleMenuClick}
              menuItemName={menuItemName}
              dentistry={dentistry}
            />
          ))}
      </div>
    </section>
  );
}
