import React, { useState, useEffect } from "react";

import styles from "./RestorativeMenu.module.css";
import SideMenuRestorativeProsthodonticDentistry from "./SideMenuRestorativeProsthodonticDentistry";

export default function DentalBridges({ menuItem, menuItemName, dentistry }) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            DENTAL BRIDGES
          </h1>
          <p className={styles.mainDescription}>
            Many people will experience tooth loss at some point in their life.
            As a Dentist, we will thoughtfully assess all aspects of your case
            to help you decide which tooth replacement option is best for you.
            Dental bridges, popular because of their affordability and
            convincing appearance, are just one of the options available to our
            patients.
          </p>
          <h4 className={styles.secondaryQuestion}>Dental Bridge Options</h4>
          <p className={styles.mainDescription}>
            <b>A dental bridge</b> — a prosthesis used to replace missing teeth
            — attaches artificial teeth to adjacent natural teeth, called
            abutment teeth. Bridges are either permanently attached (fixed
            bridges) or they can be removable (removable bridge).
          </p>
          <p className={styles.mainDescription}>
            <b>
              Fixed bridges are applied by either placing crowns on the abutment
              teeth or by bonding the artificial teeth directly to the abutment
              teeth (meryland bridges). Removable prosthesis are attached to the
              teeth with metal clasps or by precision attachments.
            </b>
          </p>
          <p className={styles.secondaryDescription}>
            If you are missing one or more teeth, you may be aware of their
            importance to your appearance and dental health. Your teeth work
            together for many daily functions from eating to speaking. With
            missing teeth, it is difficult to do these things. Missing teeth can
            and should be replaced. Fixed bridges are a great way to restore
            your dental health and appearance.
          </p>
          <h4 className={styles.secondaryQuestion}>
            What exactly is a bridge or fixed partial denture?
          </h4>
          <p className={styles.secondaryDescription}>
            A bridge (fixed partial denture) is a device that fills the gap
            where teeth are absent. Fixed bridges are bonded into place and can
            only be removed by a dental professional. Removable prosthesis, as the
            name implies, can be taken out and cleaned. Fixed bridges offer more
            stability than their removable counterparts.
          </p>
          <h4 className={styles.secondaryQuestion}>
            Why do I need a dental bridge?
          </h4>
          <p className={styles.secondaryDescription}>
            Oral functionality and appearance are important reasons for wearing
            a bridge. A bridge helps support your lips and cheeks. The loss of a
            back tooth may cause your mouth to sink and your face to look older.
          </p>
          <p className={styles.secondaryDescription}>
            Dental health is the most important reason for a bridge. Teeth were
            designed to complement each other. Unusual stresses are placed on
            the gums and other oral tissues when teeth are missing, causing a
            number of potentially harmful disorders.
          </p>
          <p className={styles.secondaryDescription}>
            Increased risk of <b>gum disease</b> has proven to be one of the
            worst side effects of missing teeth and can be minimized with a
            bridge.
          </p>
          <p className={styles.secondaryDescription}>
            Missing teeth can cause speech disorders because they are used to
            make many of the sounds we use to speak clearly.
          </p>
          <h4 className={styles.secondaryQuestion}>
            How is a bridge attached?
          </h4>
          <p className={styles.secondaryDescription}>
            The attachment procedure usually takes two or three appointments to
            complete. At the first appointment, your Dentist will prepare the
            teeth on either side of the gap by removing a portion of the enamel
            and dentin.
          </p>
          <p className={styles.secondaryDescription}>
            Since the bridge must be fabricated very precisely to ensure correct
            bite and to match the opposing tooth, impressions of the teeth are
            taken and sent to a lab where the bridge will be constructed.
          </p>
          <p className={styles.secondaryDescription}>
            Fixed bridges are typically cemented to the natural teeth next to
            the space left by the missing tooth. A pontic (false tooth) replaces
            the lost tooth.<b>Dental crowns,</b> which are cemented onto the
            natural teeth, provide support for the bridge.
          </p>
          <h4 className={styles.secondaryQuestion}>What materials are used?</h4>
          <p className={styles.secondaryDescription}>
            Bridges can be constructed from gold alloys, non-precious alloys,
            porcelain, or a combination of these materials. Porcelain is often
            bonded to either a precious or non-precious metal.
          </p>
          <h4 className={styles.secondaryQuestion}>
            How do I take care of my bridge?
          </h4>
          <p className={styles.secondaryDescription}>
            A strict regimen of <b>brushing and flossing</b> will keep the
            bridge and surrounding teeth clean. This is of critical importance
            as the bridge relies on the neighboring teeth for support.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuRestorativeProsthodonticDentistry
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
