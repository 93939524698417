import React, { useState, useEffect } from "react";

import styles from "../PediatricMenu/PediatricMenu.module.css";

import SideMenuOrthodontic from "./SideMenuOrthodontic";

export default function Bites({ menuItem, menuItemName, dentistry }) {
  const pediatricProperty = {
    textColor: "#9086a5",
    spanColor: "#776c8c",
    backgroundColor: "#e6e4eb",
  };
  const styleProperties = pediatricProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            ORTHODONTIC DISORDERS
          </h1>
          <h4 className={styles.secondaryQuestion}>Crossbite</h4>
          <p className={styles.mainDescription}>
            Crossbite can occur in the front and/or the sides of the mouth: One
            or more upper teeth bite on the inside of the lower teeth. This can
            occur with a single tooth or multiple teeth. Early correction of
            crossbite is recommended.
          </p>
          <h4
            className={styles.secondaryQuestion}
            style={{ marginBottom: "0.5rem" }}
          >
            {" "}
            <b>Crossbite should be corrected because it can: </b>
          </h4>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>cause premature wear of the teeth</li>
              <li>cause gum disease including bone loss</li>
              <li>cause asymmetrical development of the jaws</li>
              <li>cause dysfunctional chewing patterns</li>
              <li>make your smile less attractive</li>
            </ul>
          </p>
          <h4 className={styles.secondaryQuestion}>
            <b>How can a crossbite be orthodontically corrected?</b>
          </h4>
          <p className={styles.secondaryDescription}>
            If there is a single tooth crossbite, the tooth can be moved with
            braces into the correct position. In some cases, a retainer can be
            utilized. With multiple teeth in crossbite, the arch needs to be
            expanded with braces or other intra-oral appliances
          </p>
          <h4 className={styles.secondaryQuestion}>Openbite</h4>
          <p className={styles.secondaryDescription}>
            Openbite is an insufficient vertical overlap of the teeth. It is
            caused by oral habits such as tongue thrust, digit sucking or when
            the jaws don’t grow evenly. Timing of treatment is critical to the
            overall success of the therapy.
          </p>
          <h4 className={styles.secondaryQuestion}>
            <b>How can an openbite be orthodontically corrected?</b>
          </h4>
          <p className={styles.secondaryDescription}>
            Openbite can be corrected through growth modification of the jaws
            using braces, extrusion of the anterior teeth and in some cases
            surgical correction of the jaws. Also breaking oral habits, such as
            digit sucking, will facilitate the correction of an openbite.
          </p>
          <h4 className={styles.secondaryQuestion}>Overbite</h4>
          <p className={styles.secondaryDescription}>
            Overbite occurs when the upper front teeth protrude over the lower
            front teeth. Generally there is no contact between the upper and
            lower front teeth. Often you cannot see the lower incisors. Overbite
            is due to a disproportionate amount of eruption of front teeth or
            over development of the bone that supports the teeth and a front to
            back discrepancy in the growth of the upper or lower jaw (Class II
            Relationship). Overbite is also known as a deep bite.
          </p>
          <h4
            className={styles.secondaryQuestion}
            style={{ marginBottom: "0.5rem" }}
          >
            <b>Overbite should be corrected because it can:</b>
          </h4>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>cause improper functioning of your front teeth</li>
              <li>
                result in the lower front teeth biting into the gum tissue of
                the upper palate leading to tissue problems
              </li>
              <li>unusual wear of the lower front teeth</li>
              <li>cause jaw or joint problems</li>
              <li>make your smile less attractive</li>
            </ul>
          </p>
          <h4 className={styles.secondaryQuestion}>
            <b>How can an overbite be orthodontically corrected?</b>
          </h4>
          <p className={styles.secondaryDescription}>
            Overbite can be corrected through moving the front teeth up and/or
            bringing the back teeth together, which will “open” the bite so the
            teeth are properly aligned and the deep bite is eliminated.
          </p>
          <h4 className={styles.secondaryQuestion}>Overjet</h4>
          <p className={styles.secondaryDescription}>
            Overjet is also known as protrusion. In this case, the lower teeth
            are too far behind the upper front teeth. This can be caused by an
            improper alignment of the molars (Class II Relationship), a skeletal
            imbalance of the upper and lower jaw; flared upper incisors, missing
            lower teeth or a combination of all the above. In addition, oral
            habits such as thumb sucking, finger sucking or tongue thrusting can
            exacerbate the condition.
          </p>
          <h4
            className={styles.secondaryQuestion}
            style={{ marginBottom: "0.5rem" }}
          >
            <b>Overjet should be corrected because it can:</b>
          </h4>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>prevent proper functioning of the front teeth</li>
              <li>lead to premature wear</li>
              <li>make your smile less attractive</li>
            </ul>
          </p>
          <h4 className={styles.secondaryQuestion}>
            <b>How can overjet be orthodontically corrected?</b>
          </h4>
          <p className={styles.secondaryDescription}>
            Overjet can be corrected through growth modification using a
            functional appliance and/or elastics to reduce the skeletal
            imbalance or extraction of teeth.
          </p>
          <h4 className={styles.secondaryQuestion}>Underbite</h4>
          <p className={styles.secondaryDescription}>
            The lower teeth protrude past the front teeth. An underbite is
            usually caused by undergrowth of the upper jaw, overgrowth of the
            lower jaw, or a combination of the two (Class III Relationship).
            Underbite can also be caused by flared upper incisors, missing lower
            teeth or a combination of all the above. Early correction of
            underbite is recommended.
          </p>
          <h4
            className={styles.secondaryQuestion}
            style={{ marginBottom: "0.5rem" }}
          >
            <b>Underbite should be corrected because it can:</b>
          </h4>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>
                prevent proper functioning of the front teeth or molars which
                can lead to premature wear of the teeth
              </li>
              <li>cause chewing or eating problems</li>
              <li>cause jaw or joint problems</li>
              <li>make your smile less attractive</li>
            </ul>
          </p>
          <h4 className={styles.secondaryQuestion}>
            <b>How can an underbite be orthodontically corrected?</b>
          </h4>
          <p className={styles.secondaryDescription}>
            Underbite can be corrected through growth modification of the jaws,
            extraction of teeth and in some cases, surgical correction of the
            jaws.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuOrthodontic
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
