import React, { useState, useEffect } from "react";

import axios from "axios";

import { useInView } from "react-intersection-observer";
import styles from "./PatientContent.module.css";

import SideMenuPatient from "./SideMenuPatient";
import Modal from "../Home/Modal";

export default function NewPatient({ menuItem, menuItemName, dentistry }) {
  const generalProperty = {
    textColor: "#545cd6",
    spanColor: "#656cda",
    backgroundColor: "#b3b3b3a9",
  };

  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [service, setService] = useState("");
  const [status, setStatus] = useState("");
  const [submit, setSubmit] = useState(false);
  const [complete, setComplete] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [patientMessage, setPatientMessage] = useState("");
  const success = "Submission Successfull";
  const successMessage =
    "Thank you for contacting us! Your message has been successfully sent. We will get back to you shortly.";
  const failMessage =
    "We are sorry, but there was an error sending your message. Please try again later.";
  const fail = "Submission Failed";
  const [ref, inView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });

  const handleCloseModal = () => {
    // Clear the error state when the dialog box is closed
    setModalIsOpen(false); // Close the modal dialog box
  };

  const sendWhatsAppMessage = async (e) => {
    let subservice = "New Patient Page";
    setSubmit(true);
    e.preventDefault();
    setService(subservice);
    console.log(service, subservice);
    try {
      const res = await axios.post(
        "https://dentalplanet-o6pa.onrender.com/send-whatsapp",
        {
          name: name,
          email: email,
          phoneNumber: phone,
          message: patientMessage,
          service: subservice,
        }
      );
      console.log(res);

      //  alert("WhatsApp message sent!");
      setStatus(success);
      setMessage(successMessage);
      setModalIsOpen(true);
      setComplete(true);
    } catch (error) {
      setStatus(fail);
      setMessage(failMessage);
      setModalIsOpen(true);
      alert("Error sending WhatsApp message. Check console for details.");
    }
  };

  const styleProperties = generalProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            Contact Us Today
          </h1>

          <p className={styles.secondaryDescription}>
            The first step towards a beautiful, healthy smile is to schedule an
            appointment. Please contact our office by phone or complete the
            appointment request form below. Our scheduling coordinator will
            contact you to confirm your appointment.
          </p>

          <h4
            className={styles.secondaryQuestion}
            style={{ color: styleProperties.textColor }}
          >
            New Patients
          </h4>

          <p className={styles.mainDescription}>
            Learn More About the exceptional Services which we offer to our
            Patients!
          </p>
          <p
            className={styles.secondaryDescription}
            style={{ marginBottom: "1.2rem" }}
          >
            We want to thank you for visiting Dental Planet website! Our goal is
            for your first visit to be one of comfort and acknowledgement of
            your personal dental needs.
          </p>
          <p className={styles.secondaryDescription}>
            We will take the required time to properly diagnose your immediate
            dental concerns, review your past medical and dental history, and
            create a treatment plan that provides for your optimal dental
            health. With your cooperation, we will complete a thorough
            comprehensive dental evaluation.
          </p>
          <p className={styles.secondaryDescription}>
            Why? Because the foundation of a long term relationship is
            established at your first visit to our practice.
          </p>
          <p>
            {" "}
            If you are an existing patient, this contact form should not be
            utilized for communicating private health information.
          </p>

          <h4
            className={styles.secondaryQuestion}
            style={{ color: styleProperties.textColor }}
          >
            Fill the Form
          </h4>
          <form className={styles.ctaForm} action="#">
            <div>
              <input
                id="patientName"
                type="text"
                placeholder="Name"
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>

            <div>
              <input
                id="email"
                type="email"
                placeholder="me@example.com"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            <div>
              <input
                type="text"
                placeholder="Phone Number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>

            <textarea
              id="message"
              type="te"
              placeholder="Your message holds the key to unlocking our services"
              // onChange={handleMessageChange}
              onChange={(e) => setPatientMessage(e.target.value)}
              required
            ></textarea>

            <a
              href="#"
              className={styles.btnForm}
              onClick={sendWhatsAppMessage}
            >
              {complete
                ? "Submitted"
                : submit
                ? "Submitting..."
                : "Get In Touch"}
              {/* {load ? "Schedule a Call" : " Call Scheduled"} */}
            </a>
          </form>
        </div>
        {windowWidth > 730 && (
          <SideMenuPatient
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
        {modalIsOpen && (
          <Modal
            modalOpen={modalIsOpen}
            message={message}
            status={status}
            handleSetModal={setModalIsOpen}
          />
        )}
      </div>
    </section>
  );
}
