import React, { useState, useEffect } from "react";

import styles from "../Braces/Braces.module.css";

import SideMenuInvisalign from "./SideMenuInvisalign";

export default function Invisalign({ menuItem, menuItemName, dentistry }) {
  const orthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#8ba7c3",
    backgroundColor: "#e7f1fca0",
  };
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            INVISALIGN
          </h1>
          <p className={styles.mainDescription}>
            Invisalign® straightens your teeth just as effectively as
            traditional metal braces, but using a modern approach that is best
            suited for an active and social lifestyle. The clear plastic
            aligners are virtually invisible, so no one has to know they are
            there. And unlike traditional braces, they are removable! This makes
            it possible to brush and floss your teeth without the interference
            of brackets that can contribute to tooth decay. You can pop these
            clear braces out to eat and drink whatever you like with no food
            restrictions. Traditional braces use wires and metal that can poke
            and irritate your gums. Invisalign® is made of smooth plastic that
            is comfortable to wear. The convenience of Invisalign® appeals to
            people in all stages of life. Enjoy the significant benefits of
            improving your smile with minimal impact on how you look and feel.
          </p>
          <ul className={styles.items}>
            <li className={styles.item}>
              <a href="/orthodontic/Invisalign/InvisalignInfo">
                Invisalign Info
              </a>
            </li>
            <li className={styles.item}>
              <a href="/orthodontic/Invisalign/AdvantagesOfInvisalign">
                Advantages Of Invisalign
              </a>
            </li>
            <li className={styles.item}>
              <a href="/orthodontic/Invisalign/InvisalignCost">
                Invisalign Cost
              </a>
            </li>
            <li className={styles.item}>
              <a href="/orthodontic/Invisalign/InvisalignTeen">
                Invisalign Teen
              </a>
            </li>
            {/* <li className={styles.item}>
              <a href="/orthodontic/Invisalign/InvisalignVideos">
                Invisalign Videos
              </a>
            </li> */}
          </ul>
        </div>
        {windowWidth > 730 && (
          <SideMenuInvisalign
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
