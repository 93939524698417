import React from "react";

import { useInView } from "react-intersection-observer";
import styles from "./PeriodontalServices3.module.css";

import endodontic from "../../images/DentalImplants.png";
import generalHero from "../../images/periodontalServices.jpg";
import { IonIcon } from "@ionic/react";

import { arrowForward } from "ionicons/icons";
export default function PeriodontalServices3({ serviceName }) {
  const [header, headerView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  const [service, serviceView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  return (
    <section className={styles.generalServiesSection}>
      <div
        className={`${styles.generalDescriptionCenter} ${
          headerView ? styles.animate : ""
        }`}
        ref={header}
      >
        <h1 className={styles.generalServiceHeader}>
          Our Periodontal Services
        </h1>

        <p className={styles.generalServiceHeaderDescription}>
          Our periodontal dentistry offers all kinds of services and study new
          technology to add new custom services to the list
        </p>
      </div>

      <div
        className={`${styles.generalServices} ${
          serviceView ? styles.animate : ""
        }`}
        ref={service}
      >
        <div className={styles.generalserviceCard}>
          <img
            src={generalHero}
            className={styles.endodonticserviceCardImage}
          ></img>
          <div className={styles.generalserviceCardContent}>
            <h3 className={styles.generalserviceCardHeader}>
              Periodontal Services
            </h3>
            <p className={styles.generalserviceCardDescription}>
              Comprehensive care for gum health, including treatments for gum
              disease, gum recession, and other conditions affecting the tissues
              around your teeth.
            </p>
          </div>
          <div className={styles.generalBtnBorder}>
            <a
              className={styles.generalserviceCardNavigation}
              href="/periodontal/periodontalServices"
            >
              <span>Learn more</span>
              <IonIcon icon={arrowForward} className={styles.sideArrow} />
            </a>
          </div>
        </div>
        <div className={styles.generalserviceCard}>
          <img
            src={endodontic}
            className={styles.generalserviceCardImage}
          ></img>
          <div className={styles.generalserviceCardContent}>
            <h3 className={styles.generalserviceCardHeader}>Dental Implants</h3>
            <p className={styles.generalserviceCardDescription}>
              Expert placement of dental implants to replace missing teeth,
              restoring both function and appearance with precision and care.
            </p>
          </div>

          <div className={styles.generalBtnBorder}>
            <a
              className={styles.generalserviceCardNavigation}
              href="/periodontal/DentalImplants"
            >
              <span>Learn more</span>
              <IonIcon icon={arrowForward} className={styles.sideArrow} />
            </a>
          </div>
        </div>
      </div>
      <div className={styles.regularCheckup}>
        <span>
          Don’t skip your regular check-up for teeth.
          <a href="#">Book an appointment!</a>
        </span>
      </div>
    </section>
  );
}
