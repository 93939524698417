// Home.js

import React, { useState } from "react";
import Footer from "../Home/Footer";
import Header2 from "../Home/Header2";
import Location from "../Home/Location";
import ScheduleCTA from "../Home/ScheduleCta";
import FAQSection from "./FAQSection";
import FirstVisit from "./FirstVisit";
import NewPatient from "./NewPatient";
import PatientHero1 from "./PatientHero1";
import PatientHeroMenu from "./PatientHeroMenu";
import PatientRegistration from "./PatientRegistration";
import PatientTestimonials from "./PatientTestimonials";
import Scheduling from "./Scheduling";

const PatientHome = (cosmeticData) => {
  const [cosmeticDataMenu, setCosmeticMenu] = useState(cosmeticData);

  const handleMenuItem = (data) => {
    setCosmeticMenu(data);
  };

  return (
    <>
      <Header2 />
      {cosmeticDataMenu.cosmeticData === "PatientHome" ? (
        <PatientHero1 />
      ) : (
        <PatientHeroMenu />
      )}
      {cosmeticDataMenu.cosmeticData === "FirstVisit" && (
        <FirstVisit
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="general"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "NewPatients" && (
        <NewPatient
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="general"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "Scheduling" && (
        <Scheduling
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="general"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "PatientRegistration" && (
        <PatientRegistration
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="general"
        />
      )}

      {cosmeticDataMenu.cosmeticData === "PatientHome" && (
        <PatientTestimonials />
      )}

      <FAQSection />
      {/* <Welcome /> */}
      <Location />
      {/* <Schedule /> */}
      <ScheduleCTA
        dentistry={"home"}
        subService={cosmeticDataMenu.cosmeticData}
      />
      <Footer dentistry={"home"} />
    </>
  );
};

export default PatientHome;
