import React, { useEffect, useState } from "react";

import styles from "./PeriodontalMenu.module.css";
import SideMenuPeriodontalMenu from "./SideMenuPeriodontalMenu";

export default function TreatmentMethods({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const periodontalProperty = {
    textColor: "#fc7444",
    spanColor: "#978ab1",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = periodontalProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            PERIODONTAL TREATMENT METHODS
          </h1>
          <p className={styles.mainDescription}>
            Dentist's practice provides a variety of surgical services for
            the treatment of periodontal issues. We pride ourselves on the fact
            that we are very conservative in our treatment recommendations and
            limit surgery to the areas where it is absolutely necessary.
          </p>

          <p className={styles.secondaryDescription}>
            Many times, the early stages of periodontal disease are best treated
            with non-surgical periodontal therapy. Even in severe cases,
            non-surgical periodontal therapy often precedes surgical therapy.
            This is done to improve the overall tissue quality prior to surgery
            and also to help limit the areas requiring surgery.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuPeriodontalMenu
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
