import React, { useState, useEffect } from "react";

import styles from "../CosmeticDentistry/DentalBonding.module.css";
import SideMenuRestorativeDentistry from "./SideMenuRestorativeDentistry";

export default function DentalCrowns({ menuItem, menuItemName, dentistry }) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      //  style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            Dental Crowns
          </h1>
          <p className={styles.mainDescription}>
            Most dentistry looks like dentistry. Our goal is to provide
            dentistry that is undetectable. We replace existing{" "}
            <b
              className={styles.mainSpan}
              style={{ color: styleProperties.spanColor }}
            >
              dental crowns{" "}
            </b>
            and fillings with restorations that look and feel like your natural
            teeth.
          </p>
          <p className={styles.mainDescription}>
            Where damage to a person’s teeth is extreme, and apparently beyond
            repair, we can use porcelain or porcelain “pasted on gold” crowns to
            make the smile appear “as new”. This is an extremely reliable
            technique for repairing the most severe of dental problems, even
            permanently replacing missing teeth to offer a complete smile and a
            functional bite. We are renowned for the quality of our work and the
            fantastic changes we make for people using this technology. These
            treatments are used for a long-lasting correction of major dental
            problems. It is usual for these treatments to last for 20 to 30
            years, which is as close to permanent as dental treatment can get.
          </p>
          <h4 className={styles.secondaryQuestion}>
            How long does it take to fit a dental crown?
          </h4>
          <p className={styles.secondaryDescription}>
            Fitting a crown requires at least two visits to our office.
            Initially, we will remove decay, shape the tooth, and fit it with a
            temporary crown of either plastic or metal.
          </p>
          <p className={styles.secondaryDescription}>
            On the subsequent visit, we will remove the temporary crown and then
            fit and adjust the final crown. Finally, we will cement the crown
            into place and you have a new beautiful looking tooth.
          </p>
          <h4 className={styles.secondaryQuestion}>
            Key Benefits of Dental Crowns
          </h4>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>Replaces missing teeth</li>
              <li>Offers support to misshapen teeth or badly broken teeth</li>
              <li>Looks completely natural</li>
              <li>Fixes “smile” and functional chewing problems</li>
            </ul>
          </p>
          <h4 className={styles.secondaryQuestion}>
            What are the capabilities of crowns?
          </h4>
          <p className={styles.secondaryDescription}>
            Crown and bridgework is a very reliable solution for major dental
            problems caused through accidents, diseases or wear and tear. Major
            problems can usually be corrected using these techniques. Material
            used in these repairs is either high-grade porcelain or porcelain
            bonded to gold. A higher strength of the porcelain and gold
            materials is recommended to treat the most serious of dental
            problems. Where accidental damage has occurred, resulting in lost
            teeth, or where teeth have broken away through excessive wear, or as
            the result of old fillings breaking, dental crowns and/or dental
            bridges can be used as a long-term solution.
          </p>

          <p className={styles.secondaryDescription}>
            Many people have unexplained pain from filled back teeth, which is
            usually due to hairline cracks in the chewing part of the tooth.
            Placing crowns on these teeth relieves the pain and allows a return
            of full dental function for these teeth. In front teeth, older
            fillings can both weaken the teeth and cause “appearance” problems
            due to staining or chipping. Porcelain crowns and bridges are
            suitable in cases where porcelain veneers are not. In teeth with
            root canal fillings, crowns can prevent breakage.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuRestorativeDentistry
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
