import React, { useState, useEffect } from "react";

import styles from "./RestorativeMenu.module.css";
import SideMenuRestorativeProsthodonticDentistry from "./SideMenuRestorativeProsthodonticDentistry";

export default function CleftLipAndPalate({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            CLEFT LIP AND PALATE
          </h1>
          <p className={styles.mainDescription}>
            Cleft lip and cleft palate are the most common birth defects in the
            India. It is estimated that one of every 600 newborns is affected by
            cleft lip and/or cleft palate.
          </p>

          <p className={styles.mainDescription}>
            <b>A cleft palate </b> is an opening in the roof of the mouth in
            which the two sides of the palate did not fuse as the baby was
            developing in the uterus. Cleft palate can occur on one side
            (unilateral cleft palate) or on both sides (bilateral cleft palate).
            Because the lip and the palate develop separately, it is possible
            for the child to have a cleft palate with or without an accompanying
            cleft lip.
          </p>
          <p className={styles.mainDescription}>
            Cleft palate is a congenital defect, or birth defect, which occurs
            early in pregnancy. The causes of a majority of cleft palates appear
            to be a combination of genetics and environmental factors.
          </p>
          <p className={styles.mainDescription}>
            The surgery to close a cleft palate is usually completed within the
            first year of life so that the child’s speech normally develops. Our
            office will coordinate treatment with the oral or plastic surgeon
            and with the speech pathologist for treatment.
          </p>
          <p className={styles.secondaryDescription}>
            Our practice creates the prosthetic device used to temporarily close
            the palate so the baby can feed and grow until surgery. We may also
            make a dental bridge to replace missing teeth or create special
            appliances called “speech bulbs” or “palatal lifts” to help close
            the nose from the mouth enabling speech to sound more normal.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuRestorativeProsthodonticDentistry
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
