import React, { useState, useEffect } from "react";

import styles from "./PeriodontalMenu.module.css";
import SideMenuPeriodontalMenu from "./SideMenuPeriodontalMenu";
import SideMenuProcedures from "../OralSurgeryDentistry/Procedures/SideMenuProcedures";

export default function Protein({ menuItem, menuItemName, dentistry }) {
  const periodontalProperty = {
    textColor: "#fc7444",
    spanColor: "#978ab1",
    backgroundColor: "#f3f2f5",
  };
  const oralsurgery = {
    textColor: "#d099d9",
    spanColor: "#644a68",
    backgroundColor: "#f3f2f5",
  };

  const styleProperties =
    dentistry === "oralsurgery" ? oralsurgery : periodontalProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      //  style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            BONE MORPHOGENETIC PROTEIN
          </h1>

          <p className={styles.mainDescription}>
            <b>Bone morphogenetic protein </b> is an isolated protein that
            induces specific cells in our body to form new cartilage and bone.
            During surgery, the BMP is soaked onto and binds with a collagen
            sponge. The sponge is then designed to resorb, or disappear, over
            time. As the sponge dissolves, the bone morphogenetic protein
            stimulates the cells to produce new bone. The BMP also goes away
            once it has completed its task of jump-starting the normal bone
            healing process.
          </p>

          <p className={styles.secondaryDescription}>
            Since there is no need to harvest bone from the patients’ hip for
            BMP, recipients were spared donor site pain. Complications from the
            graft harvest site are also eliminated with the use of bone
            morphogenetic protein.
          </p>
          <p className={styles.secondaryDescription}>
            Your Dentist would be happy to discuss all grafting options with you
            at the time of consultation.
          </p>
        </div>
        {windowWidth > 730 &&
          (dentistry == "oralsurgery" ? (
            <SideMenuProcedures
              menuItem={handleMenuClick}
              menuItemName={menuItemName}
              dentistry={dentistry}
            />
          ) : (
            <SideMenuPeriodontalMenu
              menuItem={handleMenuClick}
              menuItemName={menuItemName}
              dentistry={dentistry}
            />
          ))}
      </div>
    </section>
  );
}
