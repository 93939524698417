import React, { useState, useEffect } from "react";

import styles from "./RestorativeMenu.module.css";
import SideMenuRestorativeProsthodonticDentistry from "./SideMenuRestorativeProsthodonticDentistry";

export default function RestorativeProsthodontic({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            Restorative Dentistry
          </h1>
          <p className={styles.mainDescription}>
            Today’s advanced dental treatments and materials can make a real
            difference, and the skill, experience, and commitment of our
            practice — using a unique combination of science and artistry — can
            literally redesign your smile.
          </p>
          <p className={styles.mainDescription}>
            Even a subtle change in your smile helps you to project an image of
            self-confidence and high personal esteem. When you feel good about
            yourself, it shows in your appearance.
          </p>

          <h4
            className={styles.secondaryQuestion}
            style={{ color: styleProperties.textColor }}
          >
            Our team of Dentists, can now give you the smile you want,
            including:
          </h4>
          <ul className={styles.items}>
            <li className={styles.item}>
              <a href="/prosthodontic/Restorative/DentalImplants">
                Dental Implants
              </a>
            </li>
            <li className={styles.item}>
              <a href="/prosthodontic/Restorative/DentalCrowns">
                Dental Crowns
              </a>
            </li>
            <li className={styles.item}>
              <a href="/prosthodontic/Restorative/ToothColoredFillings">
                Tooth Colored Fillings
              </a>
            </li>
            <li className={styles.item}>
              <a href="/prosthodontic/Restorative/FullMouthReconstruction">
                Full Mouth Rehabilitation
              </a>
            </li>
            <li className={styles.item}>
              <a href="/prosthodontic/Restorative/DentalBrigdes">
                Dental Bridges
              </a>
            </li>
            {/* <li className={styles.item}>
              <a href="/prosthodontic/Restorative/DentalSealants">
                Dental Sealants
              </a>
            </li> */}
            <li className={styles.item}>
              <a href="/prosthodontic/Restorative/Dentures">Dentures</a>
            </li>
            <li className={styles.item}>
              <a href="/prosthodontic/Restorative/PreProstheticSurgery">
                Pre-Prosthetic Surgery
              </a>
            </li>

            <li className={styles.item}>
              <a href="/prosthodontic/Restorative/CleftLipAndPalate">
                Cleft Lip and Palate
              </a>
            </li>
          </ul>
        </div>
        {windowWidth > 730 && (
          <SideMenuRestorativeProsthodonticDentistry
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
