import React, { useState, useEffect, useRef } from "react";

import styles from "../CosmeticDentistry/DentalBonding.module.css";
import SideMenuGeneralProsthodonticDentistry from "../GeneralProsthodonticDentistry/SideMenuGeneralProsthodonticDentistry";
import SideMenuPreventativeCare from "./SideMenuPreventativeCare";
import video from "../../images/Brush.mp4";

export default function OralHygiene({ menuItem, menuItemName, dentistry }) {
  const videoRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          }
        });
      },
      { threshold: 0.5 }
    );
    if (videoRef.current) {
      observer.observe(videoRef.current);
    }
    return () => observer.disconnect();
  }, []);
  return (
    <section
      className={styles.mainSection}
      //  style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      {console.log(styleProperties)}
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            ORAL HYGIENE
          </h1>

          <h4 className={styles.secondaryQuestion}>
            Why is oral hygiene so important?
          </h4>
          <p className={styles.secondaryDescription}>
            <b>
              Adults over 35 lose more teeth to gum diseases (periodontal
              disease) than from cavities.{" "}
            </b>{" "}
            Three out of four adults are affected at some time in their life.
            The best way to prevent cavities and periodontal disease is by good
            <b> tooth brushing and flossing </b>techniques, performed daily.
          </p>
          <p className={styles.secondaryDescription}>
            Periodontal disease and decay are both caused by bacterial plaque.
            Plaque is a tooth color biofilm, which sticks to your teeth at the
            gum line. Plaque constantly forms on your teeth. By thorough daily
            brushing and flossing you can remove these germs and help prevent
            periodontal disease.
          </p>
          <h4 className={styles.secondaryQuestion}>How to Brush Teeth</h4>

          <p className={styles.secondaryDescription}>
            Our Doctors recommend using a soft tooth brush. Position the brush
            at a 45 degree angle where your gums and teeth meet. Gently move the
            brush in a circular motion several times using small, gentle strokes
            brushing the outside surfaces of your teeth. Use light pressure
            while putting the bristles between the teeth, but not so much
            pressure that you feel any discomfort.
          </p>

          <p className={styles.secondaryDescription}>
            When you are done cleaning the outside surfaces of all your teeth,
            follow the same directions while cleaning the inside of the back
            teeth.
          </p>
          <p className={styles.secondaryDescription}>
            To clean the inside surfaces of the upper and lower front teeth,
            hold the brush vertically. Make several gentle back-and-forth
            strokes over each tooth. Don’t forget to gently brush the
            surrounding gum tissue.
          </p>

          <p className={styles.secondaryDescription}>
            Next you will clean the biting surfaces of your teeth by using
            short, gentle strokes. Change the position of the brush as often as
            necessary to reach and clean all surfaces. Try to watch yourself in
            the mirror to make sure you clean each surface. After you are done,
            rinse vigorously to remove any plaque you might have loosened while
            brushing.
          </p>
          <div ref={videoRef}>
            {isVisible && (
              <video width="600" controls>
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </div>

          <h4
            className={styles.secondaryQuestion}
            style={{ marginTop: "2.2rem" }}
          >
            How to Floss
          </h4>

          <p className={styles.secondaryDescription}>
            <b>
              Periodontal disease usually appears between the teeth where your
              toothbrush cannot reach.
            </b>
            Flossing is a very effective way to remove plaque from those
            surfaces. However, it is important to develop the proper technique.
            The following instructions will help you, but remember it takes time
            and practice.
          </p>

          <p className={styles.secondaryDescription}>
            Start with a piece of floss (waxed is easier) about 18” long.
            Lightly wrap most of the floss around the middle finger of one hand.
            Wrap the rest of the floss around the middle finger of the other
            hand.
          </p>
          <p className={styles.secondaryDescription}>
            To clean the upper teeth, hold the floss tightly between the thumb
            and forefinger of each hand. Gently insert the floss tightly between
            the teeth using a back-and-forth motion. Do not force the floss or
            try to snap it in to place. Bring the floss to the gum line then
            curve it into a C-shape against one tooth. Slide it into the space
            between the gum and the tooth until you feel light resistance. Move
            the floss up and down on the side of one tooth. Remember there are
            two tooth surfaces that need to be cleaned in each space. Continue
            to floss each side of all the upper teeth. Be careful not to cut the
            gum tissue between the teeth. As the floss becomes soiled, turn from
            one finger to the other to get a fresh section.
          </p>

          <p className={styles.secondaryDescription}>
            To clean between the bottom teeth, guide the floss using the
            forefingers of both hands. Do not forget the back side of the last
            tooth on both sides, upper and lower.
          </p>
          <p className={styles.secondaryDescription}>
            When you are done, rinse vigorously with water to remove plaque and
            food particles. Do not be alarmed if during the first week of
            flossing your gums bleed or are a little sore. If your gums hurt
            while flossing you could be doing it too hard or pinching the gum.
            As you floss daily and remove the plaque your gums will heal and the
            bleeding should stop.
          </p>
          <h4 className={styles.secondaryQuestion}>
            Caring for Sensitive Teeth
          </h4>

          <p className={styles.secondaryDescription}>
            Sometimes after dental treatment, teeth are sensitive to hot and
            cold. This should not last long, but only if the mouth is kept
            clean. If the mouth is not kept clean the sensitivity will remain
            and could become more severe. If your teeth are especially sensitive
            consult with your doctor. They may recommend a medicated toothpaste
            or mouth rinse made especially for sensitive teeth.
          </p>
          <h4 className={styles.secondaryQuestion}>
            Choosing Oral Hygiene Products
          </h4>

          <p className={styles.secondaryDescription}>
            <b>
              There are so many products on the market it can become confusing
              and choosing between all the products can be difficult. Here are
              some suggestions for choosing dental care products that will work
              for most patients.
            </b>
          </p>

          <p className={styles.secondaryDescription}>
            Automatic and “high-tech” electronic toothbrushes are safe and
            effective for the majority of the patients. Oral irrigators (water
            spraying devices) will rinse your mouth thoroughly, but will not
            remove plaque. You need to brush and floss in conjunction with the
            irrigator. We see excellent results with electric toothbrushes
            called Rotadent and Interplak.
          </p>

          <p className={styles.secondaryDescription}>
            Some toothbrushes have a rubber tip on the handle, this is used to
            massage the gums after brushing. There are also tiny brushes
            (interproximal toothbrushes) that clean between your teeth. If these
            are used improperly you could injure the gums, so discuss proper use
            with your doctor.
          </p>
          <p className={styles.secondaryDescription}>
            Fluoride toothpastes and mouth rinses, if used in conjunction with
            brushing and flossing, can reduce tooth decay as much as 40%.
            Remember, these rinses are not recommended for children under six
            years of age. Tartar control toothpastes will reduce tartar above
            the gum line, but gum disease starts below the gum line so these
            products have not been proven to reduce the early stage of gum
            disease.
          </p>
          <p className={styles.secondaryDescription}>
            Anti-plaque rinses, approved by the American Dental Association and
            Indian Dental Association, contain agents that may help bring early
            gum disease under control. Use these in conjunction with brushing
            and flossing.
          </p>
          <h4 className={styles.secondaryQuestion}>
            Professional Dental Cleaning
          </h4>
          <p className={styles.secondaryDescription}>
            Daily brushing and flossing will keep dental calculus to a minimum,
            but a professional dental cleaning will remove calculus in places
            your toothbrush and floss have missed. Your visit to our office is
            an important part of your program to prevent gum disease. Keep your
            teeth for your lifetime.
          </p>
        </div>
        {windowWidth > 730 &&
          (dentistry == "general" ? (
            <SideMenuGeneralProsthodonticDentistry
              menuItem={handleMenuClick}
              menuItemName={menuItemName}
              dentistry={dentistry}
            />
          ) : (
            <SideMenuPreventativeCare
              menuItem={handleMenuClick}
              menuItemName={menuItemName}
              dentistry={dentistry}
            />
          ))}
      </div>
    </section>
  );
}
