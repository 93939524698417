import React, { useState, useRef } from "react";

import { IonIcon } from "@ionic/react";
import {
  menuOutline,
  closeOutline,
  addOutline,
  removeOutline,
} from "ionicons/icons";

import styles from "./DropDownHeader.module.css";
import { useAppContext } from "../../AppContext";

export default function DropDownHeader({ dentistry }) {
  const { setDisableAnimations, setIsScrolling } = useAppContext();
  const [menuOpen, setMenuOpen] = useState(false);
  const [openService, setOpenServices] = useState(false);
  const [openPatient, setOpenPatient] = useState(false);
  // General
  const [openGeneralService, setOpenGeneralServices] = useState(false);
  const [openGeneralCosmeticService, setOpenGeneralCosmeticService] =
    useState(false);
  const [openGeneralRestorativeService, setOpenGeneralRestorativeService] =
    useState(false);
  const [
    openGeneralPreventativeCareService,
    setOpenGeneralPreventativeCareService,
  ] = useState(false);

  /* Prosthodontic */
  const [openProsthodonticService, setOpenProsthodonticService] =
    useState(false);

  const [openProsthodonticRestorative, setOpenProsthodonticRestorative] =
    useState(false);

  const [openProsthodonticCosmetic, setOpenProsthodonticCosmetic] =
    useState(false);

  const [openProsthodonticGeneral, setOpenProsthodonticGeneral] =
    useState(false);

  const [openOrthodonticService, setOpenOrthodonticService] = useState(false);

  const [openOrthodonticBracesService, setOpenOrthodonticBracesService] =
    useState(false);

  const [
    openOrthodonticClearBracesService,
    setOpenOrthodonticClearBracesService,
  ] = useState(false);

  const [
    openOrthodonticLivingWithBracesService,
    setOpenOrthodonticLivingWithBracesService,
  ] = useState(false);

  const [
    openOrthodonticInvisalignService,
    setOpenOrthodonticInvisalignService,
  ] = useState(false);

  const [openPediatricService, setOpenPediatricService] = useState(false);

  const [openPediatricPediatricService, setOpenPediatricPediatricService] =
    useState(false);

  const [openPediatricOrthodonticService, setOpenPediatricOrthodonticService] =
    useState(false);

  const [openEndodonticService, setOpenEndodonticService] = useState(false);

  const [
    openEndodonticInstructionsService,
    setOpenEndodonticInstructionsService,
  ] = useState(false);

  const [openEndodonticProceduresService, setOpenEndodonticProceduresService] =
    useState(false);

  const [openPeriodontalService, setOpenPeriodontalService] = useState(false);

  const [
    openPeriodontalPeriodontalService,
    setOpenPeriodontalPeriodontalService,
  ] = useState(false);

  const [
    openPeriodontalDentalImplantsService,
    setOpenPeriodontalDentalImplantsService,
  ] = useState(false);

  const [openOralSurgeryService, setOpenOralSurgeryService] = useState(false);

  const [
    openOralSurgerySurgicalInstructionsService,
    setOpenOralSurgerySurgicalInstructionsService,
  ] = useState(false);

  const [
    openOralSurgeryProceduresService,
    setOpenOralSurgeryProceduresService,
  ] = useState(false);

  const menuRef = useRef(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    setOpenServices(false);
    setOpenPatient(false);
  };

  const handleOpenService = (event) => {
    event.stopPropagation();
    setOpenServices(!openService);
    setOpenGeneralServices(false);
    setOpenProsthodonticService(false);
    setMenuOpen(true);
    setOpenPatient(false);
  };
  const handleScrollToSchedule = () => {
    setDisableAnimations(true);
    setIsScrolling(true);
    const scheduleSection = document.getElementById("schedule-section");
    if (scheduleSection) {
      scheduleSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleOpenGeneralService = (event) => {
    event.stopPropagation();

    setOpenServices(true);
    setMenuOpen(true);
    setOpenPatient(false);

    setOpenGeneralServices(!openGeneralService);

    setOpenProsthodonticService(false);
    setOpenOrthodonticService(false);
    setOpenPediatricService(false);
    setOpenEndodonticService(false);
    setOpenPeriodontalService(false);
    setOpenOralSurgeryService(false);
  };

  const handleOpenGeneralCosmeticService = (event) => {
    event.stopPropagation();

    setOpenServices(true);
    setMenuOpen(true);
    setOpenPatient(false);

    setOpenGeneralServices(true);

    setOpenProsthodonticService(false);
    setOpenOrthodonticService(false);
    setOpenPediatricService(false);
    setOpenEndodonticService(false);
    setOpenPeriodontalService(false);
    setOpenOralSurgeryService(false);

    setOpenGeneralCosmeticService(!openGeneralCosmeticService);
    setOpenGeneralRestorativeService(false);
    setOpenGeneralPreventativeCareService(false);
  };

  const handleOpenGeneralRestorativeService = (event) => {
    event.stopPropagation();

    setOpenServices(true);
    setMenuOpen(true);
    setOpenPatient(false);

    setOpenGeneralServices(true);

    setOpenProsthodonticService(false);
    setOpenOrthodonticService(false);
    setOpenPediatricService(false);
    setOpenEndodonticService(false);
    setOpenPeriodontalService(false);
    setOpenOralSurgeryService(false);

    setOpenGeneralCosmeticService(false);
    setOpenGeneralRestorativeService(!openGeneralRestorativeService);
    setOpenGeneralPreventativeCareService(false);
  };

  const handleOpenGeneralPreventativeCareService = (event) => {
    event.stopPropagation();

    setOpenServices(true);
    setMenuOpen(true);
    setOpenPatient(false);

    setOpenGeneralServices(true);

    setOpenProsthodonticService(false);
    setOpenOrthodonticService(false);
    setOpenPediatricService(false);
    setOpenEndodonticService(false);
    setOpenPeriodontalService(false);
    setOpenOralSurgeryService(false);

    setOpenGeneralCosmeticService(false);
    setOpenGeneralRestorativeService(!openGeneralRestorativeService);
    setOpenGeneralPreventativeCareService(false);
  };

  const handleOpenProsthodonticService = (event) => {
    event.stopPropagation();

    setOpenServices(true);
    setMenuOpen(true);
    setOpenPatient(false);

    setOpenProsthodonticService(!openProsthodonticService);

    setOpenGeneralServices(false);
    setOpenOrthodonticService(false);
    setOpenPediatricService(false);
    setOpenEndodonticService(false);
    setOpenPeriodontalService(false);
    setOpenOralSurgeryService(false);
  };

  const handleOpenProsthodonticRestorative = (event) => {
    event.stopPropagation();

    setOpenServices(true);
    setMenuOpen(true);
    setOpenPatient(false);

    setOpenProsthodonticService(true);

    setOpenGeneralServices(false);
    setOpenOrthodonticService(false);
    setOpenPediatricService(false);
    setOpenEndodonticService(false);
    setOpenPeriodontalService(false);
    setOpenOralSurgeryService(false);

    setOpenProsthodonticRestorative(!openProsthodonticRestorative);
    setOpenProsthodonticCosmetic(false);
    setOpenProsthodonticGeneral(false);
  };

  const handleOpenProsthodonticCosmetic = (event) => {
    event.stopPropagation();

    setOpenServices(true);
    setMenuOpen(true);
    setOpenPatient(false);

    setOpenProsthodonticService(true);

    setOpenGeneralServices(false);
    setOpenOrthodonticService(false);
    setOpenPediatricService(false);
    setOpenEndodonticService(false);
    setOpenPeriodontalService(false);
    setOpenOralSurgeryService(false);

    setOpenProsthodonticRestorative(false);
    setOpenProsthodonticCosmetic(!openProsthodonticCosmetic);
    setOpenProsthodonticGeneral(false);
  };

  const handleOpenProsthodonticGeneral = (event) => {
    event.stopPropagation();

    setOpenServices(true);
    setMenuOpen(true);
    setOpenPatient(false);

    setOpenProsthodonticService(true);

    setOpenGeneralServices(false);
    setOpenOrthodonticService(false);
    setOpenPediatricService(false);
    setOpenEndodonticService(false);
    setOpenPeriodontalService(false);
    setOpenOralSurgeryService(false);

    setOpenProsthodonticRestorative(false);
    setOpenProsthodonticCosmetic(false);
    setOpenProsthodonticGeneral(!openProsthodonticGeneral);
  };

  const handleOpenOrthodonticService = (event) => {
    event.stopPropagation();

    setOpenServices(true);
    setMenuOpen(true);
    setOpenPatient(false);

    setOpenOrthodonticService(!openOrthodonticService);

    setOpenGeneralServices(false);
    setOpenProsthodonticService(false);
    setOpenPediatricService(false);
    setOpenEndodonticService(false);
    setOpenPeriodontalService(false);
    setOpenOralSurgeryService(false);
  };

  const handleOpenOrthodonticBracesService = (event) => {
    event.stopPropagation();

    setOpenServices(true);
    setMenuOpen(true);
    setOpenPatient(false);

    setOpenOrthodonticService(true);

    setOpenGeneralServices(false);
    setOpenProsthodonticService(false);
    setOpenPediatricService(false);
    setOpenEndodonticService(false);
    setOpenPeriodontalService(false);
    setOpenOralSurgeryService(false);

    setOpenOrthodonticBracesService(!openOrthodonticBracesService);
    setOpenOrthodonticClearBracesService(false);
    setOpenOrthodonticLivingWithBracesService(false);
    setOpenOrthodonticInvisalignService(false);
  };

  const handleOpenOrthodonticClearBracesService = (event) => {
    event.stopPropagation();

    setOpenServices(true);
    setMenuOpen(true);
    setOpenPatient(false);

    setOpenOrthodonticService(true);

    setOpenGeneralServices(false);
    setOpenProsthodonticService(false);
    setOpenPediatricService(false);
    setOpenEndodonticService(false);
    setOpenPeriodontalService(false);
    setOpenOralSurgeryService(false);

    setOpenOrthodonticBracesService(false);
    setOpenOrthodonticClearBracesService(!openOrthodonticClearBracesService);
    setOpenOrthodonticLivingWithBracesService(false);
    setOpenOrthodonticInvisalignService(false);
  };

  const handleOpenOrthodonticLivingWithBracesService = (event) => {
    event.stopPropagation();

    setOpenServices(true);
    setMenuOpen(true);
    setOpenPatient(false);

    setOpenOrthodonticService(true);

    setOpenGeneralServices(false);
    setOpenProsthodonticService(false);
    setOpenPediatricService(false);
    setOpenEndodonticService(false);
    setOpenPeriodontalService(false);
    setOpenOralSurgeryService(false);

    setOpenOrthodonticBracesService(false);
    setOpenOrthodonticClearBracesService(false);
    setOpenOrthodonticLivingWithBracesService(
      !openOrthodonticLivingWithBracesService
    );
    setOpenOrthodonticInvisalignService(false);
  };

  const handleOpenOrthodonticInvisalignService = (event) => {
    event.stopPropagation();

    setOpenServices(true);
    setMenuOpen(true);
    setOpenPatient(false);

    setOpenOrthodonticService(true);

    setOpenGeneralServices(false);
    setOpenProsthodonticService(false);
    setOpenPediatricService(false);
    setOpenEndodonticService(false);
    setOpenPeriodontalService(false);
    setOpenOralSurgeryService(false);

    setOpenOrthodonticBracesService(!openOrthodonticBracesService);
    setOpenOrthodonticClearBracesService(false);
    setOpenOrthodonticLivingWithBracesService(false);
    setOpenOrthodonticInvisalignService(!openOrthodonticInvisalignService);
  };

  const handleOpenPediatricService = (event) => {
    event.stopPropagation();

    setOpenServices(true);
    setMenuOpen(true);
    setOpenPatient(false);

    setOpenPediatricService(!openPediatricService);

    setOpenGeneralServices(false);
    setOpenProsthodonticService(false);
    setOpenOrthodonticService(false);
    setOpenEndodonticService(false);
    setOpenPeriodontalService(false);
    setOpenOralSurgeryService(false);
  };

  const handleOpenPediatricPediatricService = (event) => {
    event.stopPropagation();

    setOpenServices(true);
    setMenuOpen(true);
    setOpenPatient(false);

    setOpenPediatricService(true);

    setOpenGeneralServices(false);
    setOpenProsthodonticService(false);
    setOpenOrthodonticService(false);
    setOpenEndodonticService(false);
    setOpenPeriodontalService(false);
    setOpenOralSurgeryService(false);

    setOpenPediatricPediatricService(!openPediatricPediatricService);
    setOpenPediatricOrthodonticService(false);
  };
  const handleOpenPediatricOrthodonticService = (event) => {
    event.stopPropagation();

    setOpenServices(true);
    setMenuOpen(true);
    setOpenPatient(false);

    setOpenPediatricService(true);

    setOpenGeneralServices(false);
    setOpenProsthodonticService(false);
    setOpenOrthodonticService(false);
    setOpenEndodonticService(false);
    setOpenPeriodontalService(false);
    setOpenOralSurgeryService(false);

    setOpenPediatricPediatricService(false);
    setOpenPediatricOrthodonticService(!openPediatricOrthodonticService);
  };

  const handleOpenEndodonticService = (event) => {
    event.stopPropagation();

    setOpenServices(true);
    setMenuOpen(true);
    setOpenPatient(false);

    setOpenEndodonticService(!openEndodonticService);

    setOpenGeneralServices(false);
    setOpenProsthodonticService(false);
    setOpenOrthodonticService(false);
    setOpenPediatricService(false);
    setOpenPeriodontalService(false);
    setOpenOralSurgeryService(false);
  };

  const handleOpenEndodonticInstructionsService = (event) => {
    event.stopPropagation();

    setOpenServices(true);
    setMenuOpen(true);
    setOpenPatient(false);

    setOpenEndodonticService(true);

    setOpenGeneralServices(false);
    setOpenProsthodonticService(false);
    setOpenOrthodonticService(false);
    setOpenPediatricService(false);
    setOpenPeriodontalService(false);
    setOpenOralSurgeryService(false);

    setOpenEndodonticInstructionsService(!openEndodonticInstructionsService);
  };

  const handleOpenEndodonticProceduresService = (event) => {
    event.stopPropagation();

    setOpenServices(true);
    setMenuOpen(true);
    setOpenPatient(false);

    setOpenEndodonticService(true);

    setOpenGeneralServices(false);
    setOpenProsthodonticService(false);
    setOpenOrthodonticService(false);
    setOpenPediatricService(false);
    setOpenPeriodontalService(false);
    setOpenOralSurgeryService(false);

    setOpenEndodonticInstructionsService(false);
    setOpenEndodonticProceduresService(!openEndodonticProceduresService);
  };

  const handleOpenPeriodontalService = (event) => {
    event.stopPropagation();

    setOpenServices(true);
    setMenuOpen(true);
    setOpenPatient(false);

    setOpenPeriodontalService(!openPeriodontalService);

    setOpenGeneralServices(false);
    setOpenProsthodonticService(false);
    setOpenOrthodonticService(false);
    setOpenPediatricService(false);
    setOpenEndodonticService(false);
    setOpenOralSurgeryService(false);
  };

  const handleOpenPeriodontalPeriodontalService = (event) => {
    event.stopPropagation();

    setOpenServices(true);
    setMenuOpen(true);
    setOpenPatient(false);

    setOpenPeriodontalService(true);

    setOpenGeneralServices(false);
    setOpenProsthodonticService(false);
    setOpenOrthodonticService(false);
    setOpenPediatricService(false);
    setOpenEndodonticService(false);
    setOpenOralSurgeryService(false);

    setOpenPeriodontalPeriodontalService(!openPeriodontalPeriodontalService);
  };

  const handleOpenPeriodontalDentalImplantsService = (event) => {
    event.stopPropagation();

    setOpenServices(true);
    setMenuOpen(true);
    setOpenPatient(false);

    setOpenPeriodontalService(true);

    setOpenGeneralServices(false);
    setOpenProsthodonticService(false);
    setOpenOrthodonticService(false);
    setOpenPediatricService(false);
    setOpenEndodonticService(false);
    setOpenOralSurgeryService(false);

    setOpenPeriodontalPeriodontalService(false);
    setOpenPeriodontalDentalImplantsService(
      !openPeriodontalDentalImplantsService
    );
  };

  const handleOpenOralSurgeryService = (event) => {
    event.stopPropagation();

    setOpenServices(true);
    setMenuOpen(true);
    setOpenPatient(false);

    setOpenOralSurgeryService(!openOralSurgeryService);

    setOpenGeneralServices(false);
    setOpenProsthodonticService(false);
    setOpenOrthodonticService(false);
    setOpenPediatricService(false);
    setOpenEndodonticService(false);
    setOpenPeriodontalService(false);
  };

  const handleOpenOralSurgerySurgicalInstructionsService = (event) => {
    event.stopPropagation();

    setOpenServices(true);
    setMenuOpen(true);
    setOpenPatient(false);

    setOpenOralSurgeryService(true);

    setOpenGeneralServices(false);
    setOpenProsthodonticService(false);
    setOpenOrthodonticService(false);
    setOpenPediatricService(false);
    setOpenEndodonticService(false);
    setOpenPeriodontalService(false);

    setOpenOralSurgerySurgicalInstructionsService(
      !openOralSurgerySurgicalInstructionsService
    );
  };

  const handleOpenOralSurgeryProceduresService = (event) => {
    event.stopPropagation();

    setOpenServices(true);
    setMenuOpen(true);
    setOpenPatient(false);

    setOpenOralSurgeryService(true);

    setOpenGeneralServices(false);
    setOpenProsthodonticService(false);
    setOpenOrthodonticService(false);
    setOpenPediatricService(false);
    setOpenEndodonticService(false);
    setOpenPeriodontalService(false);

    setOpenOralSurgerySurgicalInstructionsService(false);
    setOpenOralSurgeryProceduresService(!openOralSurgeryProceduresService);
  };

  const handleOpenPatient = (event) => {
    event.stopPropagation();
    setOpenServices(false);
    setMenuOpen(true);
    setOpenGeneralServices(false);
    setOpenGeneralCosmeticService(false);
    setOpenGeneralRestorativeService(false);
    setOpenGeneralPreventativeCareService(false);
    setOpenProsthodonticService(false);
    setOpenPatient(!openPatient);
  };
  const homeProperty = {
    textColor: "#545cd6",
    hoverColor: "#656cda",
  };

  const generalProperty = {
    textColor: "#ff7745",
    hoverColor: "#ff8558",
  };

  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    hoverColor: "#94b3b9",
  };

  const orthodonticProperty = {
    textColor: "#aed1f4",
    hoverColor: "#8ba7c3",
  };

  const pediatricProperty = {
    textColor: "#84789b",
    hoverColor: "#978ab1",
  };
  const endonticProperty = {
    textColor: "#6fcfff",
    hoverColor: "#64bae6",
  };

  const periodontalProperty = {
    textColor: "#ff7745",
    hoverColor: "#cc5f37",
  };

  const oralSurgeryProperty = {
    textColor: "#d099d9",
    hoverColor: "#644a68",
  };

  const styleProperties = {
    home: homeProperty,
    general: generalProperty,
    prosthodontic: prosthodonticProperty,
    orthodontic: orthodonticProperty,
    pediatric: pediatricProperty,
    endontic: endonticProperty,
    periodontal: periodontalProperty,
    oralSurgery: oralSurgeryProperty,
  }[dentistry];
  return (
    <nav className={styles.navBar} onClick={toggleMenu}>
      <div className={`${styles.menuIcon} ${menuOpen ? styles.open : ""}`}>
        {menuOpen ? (
          <IonIcon icon={closeOutline} className={styles.sideArrow} />
        ) : (
          <IonIcon icon={menuOutline} className={styles.sideArrow} />
        )}
      </div>
      <nav
        ref={menuRef}
        className={`${styles.sideMenu} ${menuOpen ? styles.open : ""}`}
      >
        <ul className={styles.navList}>
          <li className={styles.navItem}>
            <a href="/about" className={styles.navLink}>
              About
            </a>
          </li>
          <li className={styles.navItem}>
            <div
              className={`${styles.navBlock} ${styles.bold}`}
              onClick={handleOpenPatient}
            >
              <span>
                {" "}
                <a href="/patient"> Patient</a>
              </span>
              {openPatient ? (
                <IonIcon icon={removeOutline} className={styles.add} />
              ) : (
                <IonIcon icon={addOutline} className={styles.add} />
              )}
            </div>
            <ul
              className={`${styles.dropDown} ${
                openPatient ? styles.active : ""
              }`}
            >
              <li className={styles.dropDownItem}>
                <a href="/patient/FirstVisit" className={styles.dropDownLink}>
                  First Visit
                </a>
              </li>
              <li className={styles.dropDownItem}>
                <a href="/patient/NewPatients" className={styles.dropDownLink}>
                  New Patients
                </a>
              </li>
              <li className={styles.dropDownItem}>
                <a href="/patient/Scheduling" className={styles.dropDownLink}>
                  Scheduling
                </a>
              </li>
              <li className={styles.dropDownItem}>
                <a
                  href="/patient/PatientRegistration"
                  className={styles.dropDownLink}
                >
                  Patient Registration
                </a>
              </li>
            </ul>
          </li>
          <li className={styles.navItem}>
            <div
              className={`${styles.navBlock} ${styles.bold}`}
              onClick={handleOpenService}
            >
              <span>Services</span>
              {openService ? (
                <IonIcon icon={removeOutline} className={styles.add} />
              ) : (
                <IonIcon icon={addOutline} className={styles.add} />
              )}
            </div>
            <ul
              className={`${styles.dropDown} ${
                openService ? styles.active : ""
              }`}
            >
              <li className={styles.dropDownItem}>
                <div
                  className={`${styles.navBlock} ${
                    openGeneralService ? styles.bold : ""
                  }`}
                  onClick={handleOpenGeneralService}
                >
                  <span>
                    <a href="/general">General</a>
                  </span>
                  {openGeneralService ? (
                    <IonIcon icon={removeOutline} className={styles.add} />
                  ) : (
                    <IonIcon icon={addOutline} className={styles.add} />
                  )}
                </div>
                <ul
                  className={`${styles.dropDown} ${
                    openGeneralService ? styles.active : ""
                  }`}
                >
                  <li className={styles.dropDownItem}>
                    <div
                      className={`${styles.navBlock} ${
                        openGeneralCosmeticService ? styles.bold : ""
                      }`}
                      onClick={handleOpenGeneralCosmeticService}
                    >
                      <span>
                        <a href="/general/CosmeticDentistry/">Cosmetic</a>
                      </span>
                      {openGeneralCosmeticService ? (
                        <IonIcon icon={removeOutline} className={styles.add} />
                      ) : (
                        <IonIcon icon={addOutline} className={styles.add} />
                      )}
                    </div>
                    <ul
                      className={`${styles.dropDown} ${
                        openGeneralCosmeticService ? styles.active : ""
                      }`}
                    >
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/general/CosmeticDentistry/DentalBonding"
                        >
                          Dental Bonding
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/general/CosmeticDentistry/PorcelainVeins"
                        >
                          Porcelain Veins
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/general/CosmeticDentistry/InlaysOnlays"
                        >
                          Inlays & Onlays
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/general/CosmeticDentistry/TeethWhitening"
                        >
                          Teeth Whitening
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/general/CosmeticDentistry/E4DDentist"
                        >
                          E4D Dentist
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/general/CosmeticDentistry/ClearBraces"
                        >
                          Aligners
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/general/CosmeticDentistry/CEREC"
                        >
                          CEREC
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className={styles.dropDownItem}>
                    <div
                      className={`${styles.navBlock} ${
                        openGeneralRestorativeService ? styles.bold : ""
                      }`}
                      onClick={handleOpenGeneralRestorativeService}
                    >
                      <span>
                        <a href="/general/Restorative">Restorative</a>
                      </span>
                      {openGeneralRestorativeService ? (
                        <IonIcon icon={removeOutline} className={styles.add} />
                      ) : (
                        <IonIcon icon={addOutline} className={styles.add} />
                      )}
                    </div>
                    <ul
                      className={`${styles.dropDown} ${
                        openGeneralRestorativeService ? styles.active : ""
                      }`}
                    >
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/general/Restorative/DentalFillings"
                        >
                          Dental Fillings
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/general/Restorative/DentalImplants"
                        >
                          Dental Implants
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/general/Restorative/DentalBridges"
                        >
                          Dental bridges
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/general/Restorative/DentalCrowns"
                        >
                          Dental Crowns
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/general/Restorative/NonSurgicalRootCanal"
                        >
                          Non-surgical Root Canal
                        </a>
                      </li>
                      {/* <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/general/Restorative/ToothExtractions"
                        >
                          Tooth Extractions
                        </a>
                      </li> */}
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/general/Restorative/Dentures"
                        >
                          Dentures
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className={styles.dropDownItem}>
                    <div
                      className={`${styles.navBlock} ${
                        openGeneralPreventativeCareService ? styles.bold : ""
                      }`}
                      onClick={handleOpenGeneralPreventativeCareService}
                    >
                      <span>
                        <a href="/general/PreventativeCare">
                          Preventative Care
                        </a>
                      </span>
                      {openGeneralPreventativeCareService ? (
                        <IonIcon icon={removeOutline} className={styles.add} />
                      ) : (
                        <IonIcon icon={addOutline} className={styles.add} />
                      )}
                    </div>
                    <ul
                      className={`${styles.dropDown} ${
                        openGeneralPreventativeCareService ? styles.active : ""
                      }`}
                    >
                      {" "}
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/general/PreventativeCare/TeethCleaningDentalCheckups"
                        >
                          Teeth Cleaning & Dental Checkups
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          href="/general/PreventativeCare/OralHygiene"
                          className={styles.dropDownLink}
                        >
                          Oral Hygiene
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          href="/general/PreventativeCare/ChildDentistry"
                          className={styles.dropDownLink}
                        >
                          Child Dentistry
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          href="/general/PreventativeCare/DentalSealants"
                          className={styles.dropDownLink}
                        >
                          Dental Sealants
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          href="/general/PreventativeCare/Arestin"
                          className={styles.dropDownLink}
                        >
                          Arestin
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          href="/general/PreventativeCare/GumDiseaseTreatment"
                          className={styles.dropDownLink}
                        >
                          Gum Disease Treatment
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>

              <li className={styles.dropDownItem}>
                <div
                  className={`${styles.navBlock} ${
                    openProsthodonticService ? styles.bold : ""
                  }`}
                  onClick={handleOpenProsthodonticService}
                >
                  <span>
                    <a href="/prosthodontic">Prosthodontic</a>
                  </span>
                  {openProsthodonticService ? (
                    <IonIcon icon={removeOutline} className={styles.add} />
                  ) : (
                    <IonIcon icon={addOutline} className={styles.add} />
                  )}
                </div>
                <ul
                  className={`${styles.dropDown} ${
                    openProsthodonticService ? styles.active : ""
                  }`}
                >
                  <li className={styles.dropDownItem}>
                    <div
                      className={`${styles.navBlock} ${
                        openProsthodonticRestorative ? styles.bold : ""
                      }`}
                      onClick={handleOpenProsthodonticRestorative}
                    >
                      <span>
                        <a href="/prosthodontic/Restorative">Restorative</a>
                      </span>
                      {openProsthodonticRestorative ? (
                        <IonIcon icon={removeOutline} className={styles.add} />
                      ) : (
                        <IonIcon icon={addOutline} className={styles.add} />
                      )}
                    </div>
                    <ul
                      className={`${styles.dropDown} ${
                        openProsthodonticRestorative ? styles.active : ""
                      }`}
                    >
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/prosthodontic/Restorative/DentalImplants"
                        >
                          Dental Implants
                        </a>
                      </li>

                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/prosthodontic/Restorative/DentalCrowns"
                        >
                          Dental Crowns
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/prosthodontic/Restorative/ToothColoredFillings"
                        >
                          Tooth Colored Fillings
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          href="/prosthodontic/Restorative/FullMouthReconstruction"
                          className={styles.dropDownLink}
                        >
                          Full Mouth Rehabilitation
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          href="/prosthodontic/Restorative/DentalBrigdes"
                          className={styles.dropDownLink}
                        >
                          Dental Bridges
                        </a>
                      </li>
                      {/* <li className={styles.dropDownItem}>
                        <a
                          href="/prosthodontic/Restorative/DentalSealants"
                          className={styles.dropDownLink}
                        >
                          Dental Sealants
                        </a>
                      </li> */}
                      <li className={styles.dropDownItem}>
                        <a
                          href="/prosthodontic/Restorative/Dentures"
                          className={styles.dropDownLink}
                        >
                          Dentures
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          href="/prosthodontic/Restorative/PreProstheticSurgery"
                          className={styles.dropDownLink}
                        >
                          Pre-Prosthetic Surgery
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          href="/prosthodontic/Restorative/ToothExtractions"
                          className={styles.dropDownLink}
                        >
                          Tooth Extraction
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          href="/prosthodontic/Restorative/BruxismTreatment"
                          className={styles.dropDownLink}
                        >
                          Bruxism Treatment
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          href="/prosthodontic/Restorative/CleftLipAndPalate"
                          className={styles.dropDownLink}
                        >
                          Cleft Lip and Palate
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul
                  className={`${styles.dropDown} ${
                    openProsthodonticService ? styles.active : ""
                  }`}
                >
                  <li className={styles.dropDownItem}>
                    <div
                      className={`${styles.navBlock} ${
                        openProsthodonticCosmetic ? styles.bold : ""
                      }`}
                      onClick={handleOpenProsthodonticCosmetic}
                    >
                      <span>
                        <a href="/prosthodontic/Cosmetic">Cosmetic</a>
                      </span>
                      {openProsthodonticCosmetic ? (
                        <IonIcon icon={removeOutline} className={styles.add} />
                      ) : (
                        <IonIcon icon={addOutline} className={styles.add} />
                      )}
                    </div>
                    <ul
                      className={`${styles.dropDown} ${
                        openProsthodonticCosmetic ? styles.active : ""
                      }`}
                    >
                      <li className={styles.dropDownItem}>
                        <a
                          href="/prosthodontic/Cosmetic/PorcelainVeneers"
                          className={styles.dropDownLink}
                        >
                          Porcelain Veneers
                        </a>
                      </li>

                      {/* <li className={styles.dropDownItem}>
                        <a
                          href="/prosthodontic/Cosmetic/DentalBonding"
                          className={styles.dropDownLink}
                        >
                          Dental Bonding
                        </a>
                      </li> */}
                      <li className={styles.dropDownItem}>
                        <a
                          href="/prosthodontic/Cosmetic/InlaysAndOnlays"
                          className={styles.dropDownLink}
                        >
                          Inlays and Onlays
                        </a>
                      </li>
                      {/* <li className={styles.dropDownItem}>
                        <a
                          href="/prosthodontic/Cosmetic/TeethWhitening"
                          className={styles.dropDownLink}
                        >
                          Teeth Whitening
                        </a>
                      </li> */}
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/prosthodontic/Cosmetic/E4DDentist"
                        >
                          E4D Dentist
                        </a>
                      </li>
                      {/* <li className={styles.dropDownItem}>
                        <a
                          href="/prosthodontic/Cosmetic/ClearBraces"
                          className={styles.dropDownLink}
                        >
                          Clear Braces
                        </a>
                      </li> */}
                      <li className={styles.dropDownItem}>
                        <a
                          href="/prosthodontic/Cosmetic/CEREC"
                          className={styles.dropDownLink}
                        >
                          CEREC
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul
                  className={`${styles.dropDown} ${
                    openProsthodonticService ? styles.active : ""
                  }`}
                >
                  <li className={styles.dropDownItem}>
                    <div
                      className={`${styles.navBlock} ${
                        openProsthodonticGeneral ? styles.bold : ""
                      }`}
                      onClick={handleOpenProsthodonticGeneral}
                    >
                      <span>
                        <a href="/prosthodontic/General">General</a>
                      </span>
                      {openProsthodonticGeneral ? (
                        <IonIcon icon={removeOutline} className={styles.add} />
                      ) : (
                        <IonIcon icon={addOutline} className={styles.add} />
                      )}
                    </div>
                    <ul
                      className={`${styles.dropDown} ${
                        openProsthodonticGeneral ? styles.active : ""
                      }`}
                    >
                      <li className={styles.dropDownItem}>
                        <a
                          href="/prosthodontic/General/OralHygiene"
                          className={styles.dropDownLink}
                        >
                          Oral Hygiene
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          href="/prosthodontic/General/TeethCleaning"
                          className={styles.dropDownLink}
                        >
                          Teeth Cleaning
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          href="/prosthodontic/General/PeriodontalDisease"
                          className={styles.dropDownLink}
                        >
                          Periondontal Disease
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          href="/prosthodontic/General/WomenAndPeriodontalHealth"
                          className={styles.dropDownLink}
                        >
                          Women and Periondontal Health
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          href="/prosthodontic/General/DentalSpecialties"
                          className={styles.dropDownLink}
                        >
                          Dental Specialties
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className={styles.dropDownItem}>
                <div
                  className={`${styles.navBlock} ${
                    openOrthodonticService ? styles.bold : ""
                  }`}
                  onClick={handleOpenOrthodonticService}
                >
                  <span>
                    <a href="/orthodontic">Orthodontic</a>
                  </span>
                  {openOrthodonticService ? (
                    <IonIcon icon={removeOutline} className={styles.add} />
                  ) : (
                    <IonIcon icon={addOutline} className={styles.add} />
                  )}
                </div>
                <ul
                  className={`${styles.dropDown} ${
                    openOrthodonticService ? styles.active : ""
                  }`}
                >
                  <li className={styles.dropDownItem}>
                    <div
                      className={`${styles.navBlock} ${
                        openOrthodonticBracesService ? styles.bold : ""
                      }`}
                      onClick={handleOpenOrthodonticBracesService}
                    >
                      <span>
                        <a href="/orthodontic/Braces">Braces</a>
                      </span>
                      {openOrthodonticBracesService ? (
                        <IonIcon icon={removeOutline} className={styles.add} />
                      ) : (
                        <IonIcon icon={addOutline} className={styles.add} />
                      )}
                    </div>
                    <ul
                      className={`${styles.dropDown} ${
                        openOrthodonticBracesService ? styles.active : ""
                      }`}
                    >
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/orthodontic/Braces/OrthoBraces"
                        >
                          Braces
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/orthodontic/Braces/Retainers"
                        >
                          Retainers
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/orthodontic/Braces/Appliances"
                        >
                          Appliances
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/orthodontic/Braces/InOvationSystemBraces"
                        >
                          In-Ovation System Braces
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className={styles.dropDownItem}>
                    <div
                      className={`${styles.navBlock} ${
                        openOrthodonticClearBracesService ? styles.bold : ""
                      }`}
                      onClick={handleOpenOrthodonticClearBracesService}
                    >
                      <span>
                        <a href="/orthodontic/ClearBraces">Clear Braces</a>
                      </span>
                      {openOrthodonticClearBracesService ? (
                        <IonIcon icon={removeOutline} className={styles.add} />
                      ) : (
                        <IonIcon icon={addOutline} className={styles.add} />
                      )}
                    </div>
                    <ul
                      className={`${styles.dropDown} ${
                        openOrthodonticClearBracesService ? styles.active : ""
                      }`}
                    >
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/orthodontic/ClearBraces/ClearAligners"
                        >
                          Clear Aligners
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/orthodontic/ClearBraces/ClearCeramicBraces"
                        >
                          Clear Ceramic Braces
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/orthodontic/ClearBraces/ClearAlignersForTeens"
                        >
                          Clear Aligners For Teens
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className={styles.dropDownItem}>
                    <div
                      className={`${styles.navBlock} ${
                        openOrthodonticLivingWithBracesService
                          ? styles.bold
                          : ""
                      }`}
                      onClick={handleOpenOrthodonticLivingWithBracesService}
                    >
                      <span>
                        <a href="/orthodontic/LivingWithBraces">
                          Living With Braces
                        </a>
                      </span>
                      {openOrthodonticLivingWithBracesService ? (
                        <IonIcon icon={removeOutline} className={styles.add} />
                      ) : (
                        <IonIcon icon={addOutline} className={styles.add} />
                      )}
                    </div>
                    <ul
                      className={`${styles.dropDown} ${
                        openOrthodonticLivingWithBracesService
                          ? styles.active
                          : ""
                      }`}
                    >
                      {" "}
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/orthodontic/LivingWithBraces/Elastics"
                        >
                          Elastics
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/orthodontic/LivingWithBraces/EatingWithBraces"
                        >
                          Eating With Braces
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/orthodontic/LivingWithBraces/PainAndSoreness"
                        >
                          Pain And Soreness
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/orthodontic/LivingWithBraces/BrushingAndFlossing"
                        >
                          Brushing And Flossing
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/orthodontic/LivingWithBraces/RetainersAndAppliances"
                        >
                          Retainers And Appliances
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className={styles.dropDownItem}>
                    <div
                      className={`${styles.navBlock} ${
                        openOrthodonticInvisalignService ? styles.bold : ""
                      }`}
                      onClick={handleOpenOrthodonticInvisalignService}
                    >
                      <span>
                        <a href="/orthodontic/Invisalign">Invisalign</a>
                      </span>
                      {openOrthodonticInvisalignService ? (
                        <IonIcon icon={removeOutline} className={styles.add} />
                      ) : (
                        <IonIcon icon={addOutline} className={styles.add} />
                      )}
                    </div>
                    <ul
                      className={`${styles.dropDown} ${
                        openOrthodonticInvisalignService ? styles.active : ""
                      }`}
                    >
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/orthodontic/Invisalign/InvisalignInfo"
                        >
                          Invisalign Information
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/orthodontic/Invisalign/AdvantagesOfInvisalign"
                        >
                          Advantages of Invisalign
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/orthodontic/Invisalign/InvisalignCost"
                        >
                          Invisalign Cost
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/orthodontic/Invisalign/InvisalignTeen"
                        >
                          Invisalign Teen
                        </a>
                      </li>
                      {/* <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/orthodontic/Invisalign/InvisalignVideos"
                        >
                          Invisalign Videos
                        </a>
                      </li> */}
                    </ul>
                  </li>
                </ul>
              </li>
              <li className={styles.dropDownItem}>
                <div
                  className={`${styles.navBlock} ${
                    openPediatricService ? styles.bold : ""
                  }`}
                  onClick={handleOpenPediatricService}
                >
                  <span>
                    <a href="/pediatric">Pediatric</a>
                  </span>
                  {openPediatricService ? (
                    <IonIcon icon={removeOutline} className={styles.add} />
                  ) : (
                    <IonIcon icon={addOutline} className={styles.add} />
                  )}
                </div>
                <ul
                  className={`${styles.dropDown} ${
                    openPediatricService ? styles.active : ""
                  }`}
                >
                  <li className={styles.dropDownItem}>
                    <div
                      className={`${styles.navBlock} ${
                        openPediatricPediatricService ? styles.bold : ""
                      }`}
                      onClick={handleOpenPediatricPediatricService}
                    >
                      <span>
                        <a href="/pediatric/pediatricdentistry">Pediatric</a>
                      </span>
                      {openPediatricPediatricService ? (
                        <IonIcon icon={removeOutline} className={styles.add} />
                      ) : (
                        <IonIcon icon={addOutline} className={styles.add} />
                      )}
                    </div>
                    <ul
                      className={`${styles.dropDown} ${
                        openPediatricPediatricService ? styles.active : ""
                      }`}
                    >
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/pediatric/pediatricdentistry/ToothBrushing"
                        >
                          Tooth Brushing
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/pediatric/pediatricdentistry/DigitSucking"
                        >
                          Digit Sucking
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/pediatric/pediatricdentistry/ToothEruption"
                        >
                          Tooth Eruption
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/pediatric/pediatricdentistry/CavityPrevention"
                        >
                          Cavity Prevention
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/pediatric/pediatricdentistry/GumDisease"
                        >
                          Gum Disease
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/pediatric/pediatricdentistry/Extractions"
                        >
                          Tooth Extractions
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/pediatric/pediatricdentistry/ToothDecay"
                        >
                          Early Childhood Tooth Decay
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/pediatric/pediatricdentistry/DentalSealants"
                        >
                          Dental Sealants
                        </a>
                      </li>
                      {/* <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/pediatric/pediatricdentistry/AmalgamFillings"
                        >
                          Amalgam Fillings
                        </a>
                      </li> */}
                      {/* <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          // onClick={() => {
                          //   handleMenuItem("Bonding");
                          // }}
                        >
                          Bonding
                        </a>
                      </li> */}
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          // onClick={() => {
                          //   handleMenuItem("InlaysOutlays");
                          // }}
                        >
                          Inlays & Onlays
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/pediatric/pediatricdentistry/OralCare"
                        >
                          Adolescence and Oral Care
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/pediatric/pediatricdentistry/ToothFillings"
                        >
                          Fillings / Restorations
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className={styles.dropDownItem}>
                    <div
                      className={`${styles.navBlock} ${
                        openPediatricOrthodonticService ? styles.bold : ""
                      }`}
                      onClick={handleOpenPediatricOrthodonticService}
                    >
                      <span>
                        <a href="/pediatric/Orthodontics">Orthodontics</a>
                      </span>
                      {openPediatricOrthodonticService ? (
                        <IonIcon icon={removeOutline} className={styles.add} />
                      ) : (
                        <IonIcon icon={addOutline} className={styles.add} />
                      )}
                    </div>
                    <ul
                      className={`${styles.dropDown} ${
                        openPediatricOrthodonticService ? styles.active : ""
                      }`}
                    >
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          // onClick={() => {
                          //   handleMenuItem("RemovableAppliances");
                          // }}
                        >
                          Removable Appliances
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          // onClick={() => {
                          //   handleMenuItem("TypesOfBites");
                          // }}
                        >
                          Types of Bites
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          // onClick={() => {
                          //   handleMenuItem("ClassificationOfTheTeeth");
                          // }}
                        >
                          Classification of the Teeth
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className={styles.dropDownItem}>
                <div
                  className={`${styles.navBlock} ${
                    openEndodonticService ? styles.bold : ""
                  }`}
                  onClick={handleOpenEndodonticService}
                >
                  <span>
                    <a href="/endodontic">Endodontic</a>
                  </span>
                  {openEndodonticService ? (
                    <IonIcon icon={removeOutline} className={styles.add} />
                  ) : (
                    <IonIcon icon={addOutline} className={styles.add} />
                  )}
                </div>
                <ul
                  className={`${styles.dropDown} ${
                    openEndodonticService ? styles.active : ""
                  }`}
                >
                  <li className={styles.dropDownItem}>
                    <div
                      className={`${styles.navBlock} ${
                        openEndodonticInstructionsService ? styles.bold : ""
                      }`}
                      onClick={handleOpenEndodonticInstructionsService}
                    >
                      <span>
                        <a href="/endodontic/Instructions">Instructions</a>
                      </span>
                      {openEndodonticInstructionsService ? (
                        <IonIcon icon={removeOutline} className={styles.add} />
                      ) : (
                        <IonIcon icon={addOutline} className={styles.add} />
                      )}
                    </div>
                    <ul
                      className={`${styles.dropDown} ${
                        openEndodonticInstructionsService ? styles.active : ""
                      }`}
                    >
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/endodontic/Instructions/BeforeEndotonticTreatment"
                        >
                          Before Endodontic Treatment
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/endodontic/Instructions/GeneralInstructions"
                        >
                          General Instructions
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/endodontic/Instructions/AfterTreatmentFAQ"
                        >
                          After Treatment FAQ
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className={styles.dropDownItem}>
                    <div
                      className={`${styles.navBlock} ${
                        openPediatricOrthodonticService ? styles.bold : ""
                      }`}
                      onClick={handleOpenEndodonticProceduresService}
                    >
                      <span>
                        <a href="/endodontic/Procedures">Procedures</a>
                      </span>
                      {openEndodonticProceduresService ? (
                        <IonIcon icon={removeOutline} className={styles.add} />
                      ) : (
                        <IonIcon icon={addOutline} className={styles.add} />
                      )}
                    </div>
                    <ul
                      className={`${styles.dropDown} ${
                        openEndodonticProceduresService ? styles.active : ""
                      }`}
                    >
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/endodontic/Procedures/RootCanal"
                        >
                          Root Canal
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/endodontic/Procedures/GentleWaveUltraCleaning"
                        >
                          Gentle Wave UltraCleaning
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          // onClick={() => {
                          //   handleMenuItem("Retreatment");
                          // }}
                        >
                          Endodontic Retreatment
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          // onClick={() => {
                          //   handleMenuItem("Apicoectomy");
                          // }}
                        >
                          Apicoectomy
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          // onClick={() => {
                          //   handleMenuItem("CrackedTeeth");
                          // }}
                        >
                          Cracked teeth
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          // onClick={() => {
                          //   handleMenuItem("TraumaticInjuries");
                          // }}
                        >
                          Traumatic Injuries
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          // onClick={() => {
                          //   handleMenuItem("Navigation");
                          // }}
                        >
                          Dynamic 3D Navigation
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className={styles.dropDownItem}>
                <div
                  className={`${styles.navBlock} ${
                    openPeriodontalService ? styles.bold : ""
                  }`}
                  onClick={handleOpenPeriodontalService}
                >
                  <span>
                    <a href="/periodontal">Periodontal</a>
                  </span>
                  {openPeriodontalService ? (
                    <IonIcon icon={removeOutline} className={styles.add} />
                  ) : (
                    <IonIcon icon={addOutline} className={styles.add} />
                  )}
                </div>
                <ul
                  className={`${styles.dropDown} ${
                    openPeriodontalService ? styles.active : ""
                  }`}
                >
                  <li className={styles.dropDownItem}>
                    <div
                      className={`${styles.navBlock} ${
                        openPeriodontalPeriodontalService ? styles.bold : ""
                      }`}
                      onClick={handleOpenPeriodontalPeriodontalService}
                    >
                      <span>
                        <a href="/periodontal/periodontalServices">
                          Periodontal
                        </a>
                      </span>
                      {openPeriodontalPeriodontalService ? (
                        <IonIcon icon={removeOutline} className={styles.add} />
                      ) : (
                        <IonIcon icon={addOutline} className={styles.add} />
                      )}
                    </div>
                    <ul
                      className={`${styles.dropDown} ${
                        openPeriodontalPeriodontalService ? styles.active : ""
                      }`}
                    >
                      {/* <a
                        className={styles.dropDownLink}
                        href="/periodontal/TreatmentMethods"
                      >
                        Treatment Methods
                      </a> */}

                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/periodontal/PeriodontalMaintenance"
                        >
                          Periodontal Maintenance
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/periodontal/TeethCleaning"
                        >
                          Professional Teeth Cleaning
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/periodontal/periodontalServices/Perioscopic"
                        >
                          Perioscopic Treatment
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/periodontal/periodontalServices/Bruxism"
                        >
                          Bruxism Treatment
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/periodontal/periodontalServices/Planing"
                        >
                          Scaling and Root Planing
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/periodontal/periodontalServices/Crown"
                        >
                          Crown Lengthening
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          href="/periodontal/periodontalServices/LaserTherapy"
                          className={styles.dropDownLink}
                        >
                          Laser Therapy
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/periodontal/periodontalServices/BiteAdjustment"
                        >
                          Bite Adjustment
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          href="periodontal/periodontalServices/ReductionSurgery"
                          className={styles.dropDownLink}
                        >
                          Osseous Surgery
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/periodontal/periodontalServices/Grafting"
                        >
                          Gum Grafting
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/periodontal/periodontalServices/TissueRegeneration"
                        >
                          Guided Bone & Tissue Regeneration
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/periodontal/periodontalServices/Protein"
                        >
                          Bone Morphogenetic Protein
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/periodontal/periodontalServices/PeriodontalSurgery"
                        >
                          Cosmetic Periodontal Surgery
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/periodontal/periodontalServices/OralCancer"
                        >
                          Oral Cancer Exam
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/periodontal/periodontalServices/Stemcell"
                        >
                          Stem Cell recovery
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/periodontal/periodontalServices/ANESTHESIA"
                        >
                          Anesthesia
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className={styles.dropDownItem}>
                    <div
                      className={`${styles.navBlock} ${
                        openPeriodontalDentalImplantsService ? styles.bold : ""
                      }`}
                      onClick={handleOpenPeriodontalDentalImplantsService}
                    >
                      <span>
                        <a href="/periodontal/DentalImplants">
                          Dental Implants
                        </a>
                      </span>
                      {openPeriodontalDentalImplantsService ? (
                        <IonIcon icon={removeOutline} className={styles.add} />
                      ) : (
                        <IonIcon icon={addOutline} className={styles.add} />
                      )}
                    </div>
                    <ul
                      className={`${styles.dropDown} ${
                        openPeriodontalDentalImplantsService
                          ? styles.active
                          : ""
                      }`}
                    >
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/periodontal/DentalImplants/MissingTeeth"
                        >
                          Replacing Missing Teeth
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/periodontal/DentalImplants/ImplantPlacement"
                        >
                          Overview of Implant Placement
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/periodontal/DentalImplants/LowerUpperTeeth"
                        >
                          Missing All Upper or Lower Teeth
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/periodontal/DentalImplants/BoneGrafting"
                        >
                          Bone Grafting for Implants
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/periodontal/DentalImplants/OverDenture"
                        >
                          Implant Supported Overdenture
                        </a>
                      </li>
                      {/* <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/periodontal/DentalImplants/TeethInAnHour"
                        >
                          Teeth-in-an-Hour
                        </a>
                      </li> */}
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/periodontal/DentalImplants/CeramicDentalImplants"
                        >
                          Ceramic Dental Implants
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/periodontal/DentalImplants/AfterImplantPlacement"
                        >
                          After Implant Placement
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/periodontal/DentalImplants/ImplantsCost"
                        >
                          Cost of Dental Implants
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/periodontal/DentalImplants/PerioBoneGrafting"
                        >
                          Bone Grafting
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className={styles.dropDownItem}>
                <div
                  className={`${styles.navBlock} ${
                    openOralSurgeryService ? styles.bold : ""
                  }`}
                  onClick={handleOpenOralSurgeryService}
                >
                  <span>
                    <a href="/oralsurgery">Oral Surgery</a>
                  </span>
                  {openOralSurgeryService ? (
                    <IonIcon icon={removeOutline} className={styles.add} />
                  ) : (
                    <IonIcon icon={addOutline} className={styles.add} />
                  )}
                </div>
                <ul
                  className={`${styles.dropDown} ${
                    openOralSurgeryService ? styles.active : ""
                  }`}
                >
                  <li className={styles.dropDownItem}>
                    <div
                      className={`${styles.navBlock} ${
                        openOralSurgerySurgicalInstructionsService
                          ? styles.bold
                          : ""
                      }`}
                      onClick={handleOpenOralSurgerySurgicalInstructionsService}
                    >
                      <span>
                        <a href="/oralsurgery/SurgicalInstructions">
                          Surgical Instructions
                        </a>
                      </span>
                      {openOralSurgerySurgicalInstructionsService ? (
                        <IonIcon icon={removeOutline} className={styles.add} />
                      ) : (
                        <IonIcon icon={addOutline} className={styles.add} />
                      )}
                    </div>
                    <ul
                      className={`${styles.dropDown} ${
                        openOralSurgerySurgicalInstructionsService
                          ? styles.active
                          : ""
                      }`}
                    >
                      {/* <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/oralsurgery/SurgicalInstructions/BeforeAnesthesia"
                        >
                          Before Anesthesia
                        </a>
                      </li> */}
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/oralsurgery/SurgicalInstructions/DentalImplantSurgery"
                        >
                          After Dental Implant Surgery
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/oralsurgery/SurgicalInstructions/WisdomToothRemoval"
                        >
                          After Wisdom Tooth Removal
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          href=" 
                  /oralsurgery/SurgicalInstructions/WisdomToothRemoval"
                          className={styles.dropDownLink}
                        >
                          After Impacted Tooth Exposure
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          href="/oralsurgery/SurgicalInstructions/AfterExtractions"
                          className={styles.dropDownLink}
                        >
                          After Extractions
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          href="/oralsurgery/SurgicalInstructions/MultipleExtractions"
                          className={styles.dropDownLink}
                        >
                          After Multiple Extractions
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className={styles.dropDownItem}>
                    <div
                      className={`${styles.navBlock} ${
                        openOralSurgeryProceduresService ? styles.bold : ""
                      }`}
                      onClick={handleOpenOralSurgeryProceduresService}
                    >
                      <span>
                        <a href="/oralsurgery/Procedures">Procedures</a>
                      </span>
                      {openOralSurgeryProceduresService ? (
                        <IonIcon icon={removeOutline} className={styles.add} />
                      ) : (
                        <IonIcon icon={addOutline} className={styles.add} />
                      )}
                    </div>
                    <ul
                      className={`${styles.dropDown} ${
                        openOralSurgeryProceduresService ? styles.active : ""
                      }`}
                    >
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/oralsurgery/Procedures/DentalImplants"
                        >
                          Dental Implants
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/oralsurgery/Procedures/BoneGrafting"
                        >
                          Bone Grafting
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/oralsurgery/Procedures/WisdomTeeth"
                        >
                          Wisdom Teeth
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/oralsurgery/Procedures/JawSurgery"
                        >
                          Orthognathic Surgery
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/oralsurgery/Procedures/FacialTrauma"
                        >
                          Facial Trauma
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/oralsurgery/Procedures/ToothExtractions"
                        >
                          Tooth Extractions
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/oralsurgery/Procedures/Preprosthetic"
                        >
                          Pre-Prosthetic Surgery
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/oralsurgery/Procedures/DistractionOsteogenesis"
                        >
                          Distraction Osteogenesis
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/oralsurgery/Procedures/OralPathology"
                        >
                          Oral Cancer
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/oralsurgery/Procedures/TMJ"
                        >
                          TMJ
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/oralsurgery/Procedures/SleepApnea"
                        >
                          Sleep Apnea
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/oralsurgery/Procedures/CleftLipAndPalate"
                        >
                          Cleft Lip & Palate
                        </a>
                      </li>
                      {/* <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/oralsurgery/Procedures/Protein"
                        >
                          Bone Morphogenetic Protein
                        </a>
                      </li> */}
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/oralsurgery/Procedures/StemCells"
                        >
                          Stem Cells
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/oralsurgery/Procedures/RichPlasma"
                        >
                          Platelet Rich Plasma and Platelet Rich Fibrin
                        </a>
                      </li>
                      {/* <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/oralsurgery/Procedures/RichFibrin"
                        >
                          Platelet Rich Fibrin
                        </a>
                      </li> */}
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/oralsurgery/Procedures/ImpactedTeeth"
                        >
                          Exposure of Impacted Teeth
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/oralsurgery/Procedures/3DImaging"
                        >
                          3D Imaging
                        </a>
                      </li>
                      <li className={styles.dropDownItem}>
                        <a
                          className={styles.dropDownLink}
                          href="/oralsurgery/Procedures/ANESTHESIA"
                        >
                          Anesthesia
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li className={styles.navItem}>
            <a href="/contact" className={styles.navLink}>
              Contact
            </a>
          </li>
          <li className={styles.navItem}>
            <a
              href="#schedule-section"
              onClick={handleScrollToSchedule}
              className={styles.btnPrimary}
              style={{ backgroundColor: styleProperties.textColor }}
            >
              Book Appointment
            </a>
          </li>
        </ul>
      </nav>
    </nav>
  );
}
