import React, { useState, useEffect } from "react";

import styles from "../PediatricMenu/PediatricMenu.module.css";
import SideMenuPediatricMenu from "./SideMenuPediatricMenu";

export default function Extractions({ menuItem, menuItemName, dentistry }) {
  const pediatricProperty = {
    textColor: "#9086a5",
    spanColor: "#776c8c",
    backgroundColor: "#e6e4eb",
  };
  const styleProperties = pediatricProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            EXTRACTIONS
          </h1>

          <p className={styles.mainDescription}>
            You and your Dentist may determine that you need a tooth
            extraction for any number of reasons. Some teeth are extracted
            because they are severely decayed; others may have advanced
            periodontal disease, or have broken in a way that cannot be
            repaired. Other teeth may need removal because they are poorly
            positioned in the mouth (such as impacted teeth), or in preparation
            for orthodontic treatment.
          </p>

          <p className={styles.secondaryDescription}>
            The removal of a single tooth can lead to problems related to your
            chewing ability, problems with your jaw joint, and shifting teeth,
            which can have a major impact on your dental health.
          </p>
          <p className={styles.secondaryDescription}>
            To avoid these complications, in most cases, your Dentist will
            discuss alternatives to extractions as well as replacement of the
            extracted tooth.
          </p>
          <h4 className={styles.secondaryQuestion}>The Extraction Process</h4>
          <p className={styles.secondaryDescription}>
            At the time of extraction the doctor will need to numb your tooth,
            jaw bone and gums that surround the area with a local anesthetic.
          </p>
          <p className={styles.secondaryDescription}>
            During the extraction process you will feel a lot of pressure. This
            is from the process of firmly rocking the tooth in order to widen
            the socket for removal.
          </p>
          <p className={styles.secondaryDescription}>
            You feel the pressure without pain as the anesthetic has numbed the
            nerves stopping the transference of pain, yet the nerves that
            transmit pressure are not profoundly affected.
          </p>
          <p className={styles.secondaryDescription}>
            If you do feel pain at any time during the extraction please let us
            know right away.
          </p>
          <h4 className={styles.secondaryQuestion}>Sectioning a tooth</h4>
          <p className={styles.secondaryDescription}>
            Some teeth require sectioning. This is a very common procedure done
            when a tooth is so firmly anchored in its socket or the root is
            curved and the socket can’t expand enough to remove it. The doctor
            simply cuts the tooth into sections then removes each section one at
            a time.
          </p>

          <h4 className={styles.secondaryQuestion}>After Tooth Extraction</h4>
          <p className={styles.secondaryDescription}>
            After tooth extraction, it’s important for a blood clot to form to
            stop the bleeding and begin the healing process. Bite on a gauze pad
            for 30-45 minutes immediately after the appointment. If the bleeding
            or oozing still persists, place another gauze pad and bite firmly
            for another 30 minutes. You may have to do this several times to
            staunch the flow of blood.
          </p>
          <p className={styles.secondaryDescription}>
            After the blood clot forms it is important to not disturb or
            dislodge the clot. Do not rinse vigorously, suck on straws, smoke,
            drink alcohol or brush teeth next to the extraction site for 72
            hours. These activities may dislodge or dissolve the clot and hinder
            the healing process. Limit vigorous exercise for the next 24 hours,
            as this increases blood pressure and may cause more bleeding from
            the extraction site
          </p>
          <p className={styles.secondaryDescription}>
            After the tooth is extracted you may feel some pain and experience
            some swelling. An ice pack or an unopened bag of frozen peas or corn
            applied to the area will keep swelling to a minimum. Take pain
            medications as prescribed. The swelling usually subsides after 48
            hours.
          </p>
          <p className={styles.secondaryDescription}>
            Use pain medication as directed. Call our office if the medication
            doesn’t seem to be working. If antibiotics are prescribed, continue
            to take them for the indicated length of time even if signs and
            symptoms of infection are gone. Drink lots of fluids and eat
            nutritious, soft food on the day of the extraction. You can eat
            normally as soon as you are comfortable.
          </p>
          <p className={styles.secondaryDescription}>
            It is important to resume your normal dental routine after 24 hours.
            This should include brushing and flossing your teeth at least once a
            day. This will speed healing and help keep your mouth fresh and
            clean.
          </p>
          <p className={styles.secondaryDescription}>
            After a few days you should feel fine and can resume your normal
            activities. If you have heavy bleeding, severe pain, continued
            swelling for 2-3 days, or a reaction to the medication, call our
            office immediately.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuPediatricMenu
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
