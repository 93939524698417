import React, { useEffect, useState } from "react";

import styles from "./Instructions.module.css";
import SideMenuInstructions from "./SideMenuInstructions";

export default function AfterDentalImplants({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const oralsurgery = {
    textColor: "#d099d9",
    spanColor: "#644a68",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = oralsurgery;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      //  style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            AFTER PLACEMENT OF DENTAL IMPLANTS
          </h1>
          <h4 className={styles.secondaryQuestion}>
            Home Instructions After Dental Implant Surgery
          </h4>
          <p className={styles.mainDescription}>
            After{" "}
            <b
              className={styles.mainSpan}
              style={{ color: styleProperties.spanColor }}
            >
              dental implant surgery,
            </b>
            do not disturb the wound. Avoid rinsing, spitting, or touching the
            wound on the day of surgery. There will be a metal healing abutment
            protruding through the gingival (gum) tissue.
          </p>
          <h4 className={styles.secondaryQuestion}>Bleeding</h4>
          <p className={styles.secondaryDescription}>
            Some bleeding or redness in the saliva is normal for 24 hours.
            Excessive bleeding (your mouth fills rapidly with blood) can be
            controlled by biting on a gauze pad placed directly on the bleeding
            wound for 30 minutes. If bleeding continues profusely, please call
            for further instructions.
          </p>
          <h4 className={styles.secondaryQuestion}>Swelling</h4>
          <p className={styles.secondaryDescription}>
            Swelling is a normal occurrence after surgery. To minimize swelling
            apply an ice bag, or a plastic bag or towel filled with ice, on the
            cheek in the area of surgery. Apply the ice continuously, as much as
            possible, for the first 36 hours.
          </p>
          <h4 className={styles.secondaryQuestion}>Diet</h4>
          <p className={styles.secondaryDescription}>
            Drink plenty of fluids. Avoid hot liquids or food. Soft food and
            liquids should be eaten on the day of surgery. Return to a normal
            diet as soon as possible unless otherwise directed.
          </p>
          <h4 className={styles.secondaryQuestion}>Pain</h4>
          <p className={styles.secondaryDescription}>
            You should begin taking pain medication as soon as you feel the
            local anesthetic wearing off. For moderate pain, one or two tablets
            of Acetaminophen may be taken every 3-4 hours. Ibuprofen may be
            taken instead of Acetaminophen. Ibuprofen bought over the counter
            comes in 200 mg tablets: 2-3 tablets may be taken four times daily,
            not to exceed 3200mg daily for an adult. Consult our practice for
            individuals under 18. Do not take the two medications at the same
            time.
          </p>
          <p className={styles.secondaryDescription}>
            For severe pain, the prescribed medication should be taken as
            directed. Do not take any of the above medication if you are
            allergic to them, or have been instructed by your doctor not to take
            it. Do not drive an automobile or work around machinery. Avoid
            alcoholic beverages. Pain or discomfort following surgery should
            subside more and more every day. If pain persists, it may require
            attention and you should call the office.
          </p>
          <h4 className={styles.secondaryQuestion}>Antibiotics</h4>
          <p className={styles.secondaryDescription}>
            Be sure to take the prescribed antibiotics as directed to help
            prevent infection.
          </p>
          <h4 className={styles.secondaryQuestion}>Oral Hygiene</h4>
          <p className={styles.secondaryDescription}>
            Good oral hygiene is essential to good healing. The night of
            surgery, use the prescribed Peridex Oral Rinse before bed. The day
            after surgery, the Peridex should be used twice daily; after
            breakfast and before bed. Be sure to rinse for at least 30 seconds
            then spit it out. Warm salt water rinses (one teaspoon of salt in a
            cup of warm water) should be used at least 4-5 times a day as well,
            especially after meals. Brush your teeth and the healing abutments.
            Be gentle initially while brushing the surgical areas.
          </p>
          <h4 className={styles.secondaryQuestion}>Activity</h4>
          <p className={styles.secondaryDescription}>
            Keep physical activities to a minimum immediately following surgery.
            If you exercise, throbbing or bleeding may occur. If this occurs,
            you should discontinue exercising. Keep in mind that you are
            probably not taking in normal nourishment. This may weaken you and
            further limit your ability to exercise.
          </p>
          <h4 className={styles.secondaryQuestion}>Wearing your Prosthesis</h4>
          <p className={styles.secondaryDescription}>
            Partial dentures, flippers, or full dentures, should not be used
            immediately after surgery for at least 10 days, as discussed in the
            pre-operative consultation.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuInstructions
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
