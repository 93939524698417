// Home.js

import React, { useState } from "react";

import PeriodontalHeader2 from "./PeriodontalHeader";
import PeriodontalHero from "./PeriodontalHero";
import PeriodontalSchedule from "./PeriondontalSchedule";
import PeriodontalLocation from "./PeriodontalLocation";
import PeriodontalScheduleCTA from "./PeriodontalScheduleCTA";
import PeriodontalFooter from "./PeriodontalFooter";
import PeriodontalServices from "./PeriodontalServices";
import PeriodontalHeroMenu from "./PeriodontalHeroMenu";
import TreatmentMethods from "../PeriodontalMenu/TreatmentMethods";
import PeriodontalMaintenance from "../PeriodontalMenu/PeriodontalMaintenance";
import TeethCleaning from "../PeriodontalMenu/TeethCleaning";
import Perioscopic from "../PeriodontalMenu/PerioscopicTreatment";
import Bruxism from "../PeriodontalMenu/Bruxism";
import Scaling from "../PeriodontalMenu/RootPlaning";
import Crown from "../PeriodontalMenu/Crown";
import BiteAdjustment from "../PeriodontalMenu/BiteAdjustment";
import Grafting from "../PeriodontalMenu/GumGrafting";
import TissueRegeneration from "../PeriodontalMenu/TissueRegeneration";
import Protein from "../PeriodontalMenu/BONEMORPHOGENETICPROTEIN";
import PeriodontalSurgery from "../PeriodontalMenu/PeriodontalSurgery";
import OralCancer from "../PeriodontalMenu/OralCancer";
import PeriodontalHeader3 from "./Periodontalheader3";
import PeriodontalServices3 from "./PeriodontalServices3";
import PeriodontalMenu from "../PeriodontalMenu/PeriodontalMenu";
import Stemcells from "../OralSurgeryDentistry/Procedures/Stemcells";
import ImplantsMenu from "./DentalImplants/ImplantsMenu";
import ScheduleCTA from "../Home/ScheduleCta";
import MissingTeeth from "./DentalImplants/MissingTeeth";
import ImplantPlacement from "./DentalImplants/ImplantPlacement";
import LowerUpperTeeth from "./DentalImplants/LowerUpperTeeth";
import OverDenture from "./DentalImplants/OverDenture";
import TeethInAnHour from "./DentalImplants/TeethInAnHour";
import CeramicDentalImplants from "./DentalImplants/CeramicDentalImplants";
import AfterImplantPlacement from "./DentalImplants/AfterImplantplacement";
import ImplantsCost from "./DentalImplants/ImplantsCost";
import BoneGrafting from "../OralSurgeryDentistry/Procedures/BoneGrafting";
import BoneGraftingImplants from "./DentalImplants/BoneGrafting";
import Footer from "../Home/Footer";
import LaserTherapy from "../PeriodontalMenu/LaserTherapy";
import OsseousSurgery from "../PeriodontalMenu/OsseousSurgery";
import ANESTHESIA from "../OralSurgeryDentistry/Procedures/ANESTHESIA";

const PeriodontalHome = (cosmeticData) => {
  const [dataMenu, setDataMenu] = useState(cosmeticData);

  const handleMenuItem = (data) => {
    setDataMenu(data);
  };

  return (
    <>
      <PeriodontalHeader3 generalMenuItem={handleMenuItem} />

      {dataMenu.cosmeticData === "periodontal" ? (
        <PeriodontalHero />
      ) : (
        <PeriodontalHeroMenu />
      )}
      {/* {dataMenu.cosmeticData === null && <PeriodontalServices />} */}
      {dataMenu.cosmeticData === "periodontal" && <PeriodontalServices3 />}

      {dataMenu.cosmeticData === "periodontalServices" && (
        <PeriodontalMenu
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="periodontal"
        />
      )}
      {dataMenu.cosmeticData === "TreatmentMethods" && (
        <TreatmentMethods
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="periodontal"
        />
      )}
      {dataMenu.cosmeticData === "PeriodontalMaintenance" && (
        <PeriodontalMaintenance
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="periodontal"
        />
      )}
      {dataMenu.cosmeticData === "TeethCleaning" && (
        <TeethCleaning
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="periodontal"
        />
      )}
      {dataMenu.cosmeticData === "Perioscopic" && (
        <Perioscopic
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="periodontal"
        />
      )}
      {dataMenu.cosmeticData === "Bruxism" && (
        <Bruxism
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="periodontal"
        />
      )}
      {dataMenu.cosmeticData === "Planing" && (
        <Scaling
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="periodontal"
        />
      )}
      {dataMenu.cosmeticData === "Crown" && (
        <Crown
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="periodontal"
        />
      )}
      {dataMenu.cosmeticData === "BiteAdjustment" && (
        <BiteAdjustment
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="periodontal"
        />
      )}

      {dataMenu.cosmeticData === "ReductionSurgery" && (
        <OsseousSurgery
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="periodontal"
        />
      )}
      {dataMenu.cosmeticData === "Grafting" && (
        <Grafting
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="periodontal"
        />
      )}
      {dataMenu.cosmeticData === "TissueRegeneration" && (
        <TissueRegeneration
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="periodontal"
        />
      )}

      {dataMenu.cosmeticData === "Protein" && (
        <Protein
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="periodontal"
        />
      )}
      {dataMenu.cosmeticData === "PeriodontalSurgery" && (
        <PeriodontalSurgery
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="periodontal"
        />
      )}
      {dataMenu.cosmeticData === "OralCancer" && (
        <OralCancer
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="periodontal"
        />
      )}
      {dataMenu.cosmeticData === "Stemcell" && (
        <Stemcells
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="periodontal"
        />
      )}
      {dataMenu.cosmeticData === "ANESTHESIA" && (
        <ANESTHESIA
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="periodontal"
        />
      )}
      {dataMenu.cosmeticData === "DentalImplants" && (
        <ImplantsMenu
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="periodontal"
        />
      )}
      {dataMenu.cosmeticData === "MissingTeeth" && (
        <MissingTeeth
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="periodontal"
        />
      )}
      {dataMenu.cosmeticData === "ImplantPlacement" && (
        <ImplantPlacement
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="periodontal"
        />
      )}
      {dataMenu.cosmeticData === "LowerUpperTeeth" && (
        <LowerUpperTeeth
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="periodontal"
        />
      )}
      {dataMenu.cosmeticData === "BoneGrafting" && (
        <BoneGraftingImplants
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="periodontal"
        />
      )}
      {dataMenu.cosmeticData === "OverDenture" && (
        <OverDenture
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="periodontal"
        />
      )}
      {dataMenu.cosmeticData === "TeethInAnHour" && (
        <TeethInAnHour
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="periodontal"
        />
      )}
      {dataMenu.cosmeticData === "CeramicDentalImplants" && (
        <CeramicDentalImplants
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="periodontal"
        />
      )}
      {dataMenu.cosmeticData === "AfterImplantPlacement" && (
        <AfterImplantPlacement
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="periodontal"
        />
      )}
      {dataMenu.cosmeticData === "ImplantsCost" && (
        <ImplantsCost
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="periodontal"
        />
      )}
      {dataMenu.cosmeticData === "PerioBoneGrafting" && (
        <BoneGrafting
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="periodontal"
        />
      )}

      {dataMenu.cosmeticData === "LaserTherapy" && (
        <LaserTherapy
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="periodontal"
        />
      )}

      <PeriodontalSchedule />
      <PeriodontalLocation />
      <ScheduleCTA
        dentistry={"periodontal"}
        subService={dataMenu.cosmeticData}
      />
      <Footer dentistry={"periodontal"} />
    </>
  );
};

export default PeriodontalHome;
