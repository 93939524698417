import React, { useState, useEffect } from "react";

import styles from "./Procedures.module.css";
import SideMenuProcedures from "./SideMenuProcedures";

export default function WisdomTeeth({ menuItem, menuItemName, dentistry }) {
  const oralsurgery = {
    textColor: "#d099d9",
    spanColor: "#644a68",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = oralsurgery;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            WISDOM TEETH
          </h1>
          <h4 className={styles.secondaryQuestion}>What are wisdom teeth?</h4>
          <p className={styles.mainDescription}>
            Third molars are commonly referred to as <b>wisdom teeth. </b>They
            are usually the last teeth to develop and are located in the back of
            your mouth, behind your second molars. Their development is usually
            completed between the middle teenage years and early twenties, a
            time traditionally associated with the onset of maturity and the
            attainment of wisdom.
          </p>
          <h4 className={styles.secondaryQuestion}>Impacted Wisdom Teeth</h4>
          <p className={styles.mainDescription}>
            Although most people develop and grow 32 permanent adult teeth, many
            times their jaws are too small to accommodate the four wisdom teeth.
            When inadequate space prevents the teeth from erupting they are
            called impacted wisdom teeth. This indicates their inability to
            erupt into the proper position for chewing and cleaning.
          </p>
          <h2
            style={{ color: styleProperties.textColor, marginBottom: "1.5rem" }}
          >
            WISDOM TEETH REMOVAL
          </h2>
          <h4 className={styles.secondaryQuestion}>
            Oral Examination for Extraction of Wisdom Teeth
          </h4>
          <p className={styles.mainDescription}>
            With an oral examination and x-rays of the mouth, your Dentist
            can evaluate the position of the wisdom teeth and predict if there
            are present or future potential problems. Studies have shown that
            early evaluation and treatment result in a superior outcome for the
            patient. Patients are generally first evaluated in the mid-teenage
            years by their dentist, orthodontist, or by an oral and
            maxillofacial surgeon.
          </p>
          <p className={styles.mainDescription}>
            All outpatient surgery is performed under appropriate anesthesia to
            maximize patient comfort. Our doctors are trained, licensed, and
            highly experienced in providing various types of anesthesia for
            patients.
          </p>
          <h2
            style={{ color: styleProperties.textColor, marginBottom: "1.5rem" }}
          >
            AFTER EXTRACTION OF WISDOM TEETH
          </h2>
          <p className={styles.mainDescription}>
            In most cases, the removal of wisdom teeth is performed under local
            anesthesia, laughing gas (nitrous oxide/oxygen analgesia), or
            general anesthesia. These options, as well as the surgical risks
            (i.e., sensory nerve damage, sinus complications), will be discussed
            with you before the procedure is performed. Once the teeth are
            removed, the gum is sutured. To help control bleeding bite down on
            the gauze placed in your mouth. You will rest under our supervision
            in the office until you are ready to be taken home. Upon discharge
            your postoperative kit will include postoperative instructions, a
            prescription for pain medication, antibiotics, and a follow-up
            appointment in one week for suture removal.{" "}
          </p>
        </div>

        {windowWidth > 730 && (
          <SideMenuProcedures
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
