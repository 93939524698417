import React, { useState, useEffect } from "react";

import styles from "./RestorativeMenu.module.css";
import SideMenuRestorativeProsthodonticDentistry from "./SideMenuRestorativeProsthodonticDentistry";

export default function DentalCrowns({ menuItem, menuItemName, dentistry }) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            Dental Crowns
          </h1>
          <p className={styles.mainDescription}>
            A dental crown should look, feel and function exactly like a natural
            tooth. At Dental Planet, we use cutting edge materials and
            techniques to produce crowns with maximum longevity and maximum
            aesthetic appeal.
          </p>
          <h4 className={styles.secondaryQuestion}>What is a crown?</h4>
          <p className={styles.mainDescription}>
            The “crown” of your tooth is the part that sits above the gum line.
            When a tooth is damaged by cracks, breakage or excessive decay, or
            has undergone root canal treatment, we restore that tooth with an
            artificial crown, made of porcelain, ceramic or porcelain bonded to
            gold. As a Dentist, we have extensive training in the production of
            the most authentic crowns that you will ever see – ours often
            outlast the typical dental crown by years and sometimes even
            decades.
          </p>
          <h4 className={styles.secondaryQuestion}>
            Crowns by Your Prosthodontist
          </h4>
          <p className={styles.secondaryDescription}>
            Prosthodontists are dentists with several years of additional
            training that cover several important areas of dentistry, including
            crowns. When designing and producing crowns, we take extra care to
            ensure that your tooth isn’t just the right shape, but it is the
            exact color and even translucency – this is the only way to achieve
            a truly natural-looking restoration.
          </p>
          <h4 className={styles.secondaryQuestion}>
            How long does a dental crown take?
          </h4>
          <p className={styles.secondaryDescription}>
            The procedure usually takes just two visits – the first to place the
            temporary crown and the second to place the permanent one.
            <ul className={styles.subDescription}>
              <li>
                <b>Temporary Crown:</b> During the first visit, we will remove
                decay and shape your tooth to prepare it for the new crown. A
                dental impression will be taken for the lab and a temporary
                crown will be placed on your tooth.
              </li>
              <li>
                <b>Permanent Crown:</b> Once we receive your new crown from the
                lab (typically within 2 weeks), you will return to us for a
                quick appointment to permanently attach the crown and fit it to
                perfection!
              </li>
            </ul>
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuRestorativeProsthodonticDentistry
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
