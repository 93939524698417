import React, { useState, useEffect } from "react";

import styles from "../PediatricMenu/PediatricMenu.module.css";
import SideMenuPediatricMenu from "./SideMenuPediatricMenu";

export default function PediatricMenu({ menuItem, menuItemName, dentistry }) {
  const pediatricProperty = {
    textColor: "#9086a5",
    spanColor: "#776c8c",
    backgroundColor: "#e6e4eb",
  };
  const styleProperties = pediatricProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            PEDIATRIC DENTISTRY
          </h1>
          <h4
            className={styles.secondaryQuestion}
            style={{ color: styleProperties.textColor }}
          >
            Why A Pediatric Dentist?
          </h4>
          <p className={styles.mainDescription}>
            Has your child ever begged you not to leave the dentist? Impossible
            you say? Not at our office. It could be our gentle touch and special
            way with kids. Our pediatric specialists and staff love children and
            are specially trained to put them at ease. We teach your children
            the proper way to take care of their teeth and just as important,
            they learn that going to the dentist can be fun.
          </p>
          <h4
            className={styles.secondaryQuestion}
            style={{ color: styleProperties.textColor }}
          >
            What Is A Pediatric Dentist (Pedodontist)?
          </h4>
          <p className={styles.secondaryDescription}>
            In the same way that pediatricians are trained to meet a child’s
            medical needs, our pediatric dental specialists are uniquely
            qualified to protect your child’s oral health using the most
            advanced techniques, and all in our delightfully friendly open
            treatment area. Pediatric dentists have an additional three years of
            training at university pediatric facilities in addition to five
            years of dental school. Some pediatric dentists (including all of
            ours) practice general dentistry before specializing, giving them a
            unique perspective. They learn how to deal with the behavioral
            aspects of children, how to make them feel comfortable, and to make
            the experience pleasant. They also are trained and qualified to
            treat special needs patients.
          </p>
          <h4
            className={styles.secondaryQuestion}
            style={{ color: styleProperties.textColor }}
          >
            What Dental Problems Could My Child Have?
          </h4>
          <p className={styles.secondaryDescription}>
            Some dental problems begin very early in life. One concern is early
            childhood tooth decay, a serious condition caused by a child staying
            on the bottle (or breast) too long. Another problem is gum disease.
            About 40 percent of children two to three years old have at least
            mild inflammation of gum tissues. Oral habits (such as digit
            sucking) should also be checked. The earlier the dental visit, the
            better the chances of preventing problems. Strong, healthy teeth
            help your child chew food easily, speak clearly, and feel good about
            his or her appearance.
          </p>
          <h4
            className={styles.secondaryQuestion}
            style={{ color: styleProperties.textColor }}
          >
            Why Are Baby Teeth So Important?
          </h4>
          <p className={styles.secondaryDescription}>
            Primary teeth are important because they help with proper chewing
            and eating, help in speech development, and add to an attractive
            appearance. A child who can chew easily, speak clearly, and smile
            confidently is a happier child. Healthy primary teeth allow normal
            development of the jaw bones and muscles, save space for the
            permanent teeth, and guide them into place. If a baby tooth is lost
            too soon, permanent teeth may come in crooked. Decayed baby teeth
            can cause pain, abscesses, infections, and can spread to the
            permanent teeth. Also, your child’s general health can be affected
            if diseased baby teeth aren’t treated. Remember, some primary molars
            are not replaced until age 10-14, so they must last for years.
          </p>
          <h4
            className={styles.secondaryQuestion}
            style={{ color: styleProperties.textColor }}
          >
            What Should I Tell My Child About Their First Dental Visit?
          </h4>
          <p className={styles.secondaryDescription}>
            We are asked this question many times. We suggest you prepare your
            child the same way that you would before their first hair-cut or
            trip to the shoe store. This will not be the frightening experience
            you may remember from your youth. If you are nervous about the trip,
            then the less you say the better. You cannot hide your anxiety from
            a child (they have radar for these things). We will send you an
            activity/coloring book before the appointment that will explain
            everything. Have fun going through it with your child. Your child’s
            reaction to his first visit to the dentist may surprise you.
          </p>
          <h4
            className={styles.secondaryQuestion}
            style={{ color: styleProperties.textColor }}
          >
            What About Preventative Care?
          </h4>
          <p className={styles.secondaryDescription}>
            Tooth decay and children no longer have to go hand-in-hand. At our
            office, we are most concerned with all aspects of preventive care.
            We use the latest in sealant technology to protect your child’s
            teeth. Sealants are space-age plastics that are bonded to the
            chewing surfaces of decay-prone back teeth. This is just one of the
            ways we will set the foundation for your child’s lifetime of good
            oral health.
          </p>
          <h4
            className={styles.secondaryQuestion}
            style={{ color: styleProperties.textColor }}
          >
            What About Sterilization?
          </h4>
          <p className={styles.secondaryDescription}>
            Your health and peace of mind are always our primary concern;
            therefore, we use state-of-the-art sterilization procedures. After
            each patient’s visit, the treatment area is thoroughly disinfected.
            We ultrasonically clean and heat-sterilize all non-disposable
            instruments. Our staff wears gloves and masks during procedures.
            Please feel free to ask us for information on the measures we take
            to ensure the safety of you and your children or a tour of our
            sterilization area.
          </p>
          <ul className={styles.items}>
            <li className={styles.item}>
              <a href="/pediatric/pediatricdentistry/ToothBrushing">
                Tooth Brushing
              </a>
            </li>
            <li className={styles.item}>
              <a href="/pediatric/pediatricdentistry/DigitSucking">
                Digit Sucking
              </a>
            </li>
            <li className={styles.item}>
              <a href="/pediatric/pediatricdentistry/ToothEruption">
                Tooth Eruption
              </a>
            </li>
            <li className={styles.item}>
              <a href="/pediatric/pediatricdentistry/ToothDecay">
                Early Childhood Tooth Decay
              </a>
            </li>
            <li className={styles.item}>
              <a href="/pediatric/pediatricdentistry/CavityPrevention">
                Cavity Prevention
              </a>
            </li>
            <li className={styles.item}>
              <a href="/pediatric/pediatricdentistry/GumDisease">Gum Disease</a>
            </li>
            <li className={styles.item}>
              <a href="/pediatric/pediatricdentistry/OralCare">
                Adolescence and Oral Care
              </a>
            </li>
            <li className={styles.item}>
              <a href="/pediatric/pediatricdentistry/ToothFillings">
                Fillings / Restorations
              </a>
            </li>
            {/* <li className={styles.item}>
              <a href="/pediatric/pediatricdentistry/AmalgamFillings">
                Amalgam Fillings
              </a>
            </li> */}
            <li className={styles.item}>
              <a href="/pediatric/pediatricdentistry/DentalSealants">
                Dental Sealants
              </a>
            </li>
            <li className={styles.item}>
              <a href="/pediatric/pediatricdentistry/Extractions">
                Tooth Extractions
              </a>
            </li>
            {/* <li className={styles.item}>
              <a href="/pediatric/pediatricdentistry/Bonding">Bonding</a>
            </li> */}
            <li className={styles.item}>
              <a href="/pediatric/pediatricdentistry/InlaysOnlays">
                Inlays & Onlays
              </a>
            </li>
          </ul>
        </div>
        {windowWidth > 730 && (
          <SideMenuPediatricMenu
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
