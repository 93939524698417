import React, { useState, useEffect } from "react";

import styles from "../Braces/Braces.module.css";
import SideMenuAligners from "./SideMenuAligners";

export default function ClearCeramicBraces({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const orthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#8ba7c3",
    backgroundColor: "#e7f1fca0",
  };
  const styleProperties = orthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            CLEAR CERAMIC BRACES
          </h1>
          <p className={styles.mainDescription}>
            Clear ceramic braces are a practical alternative to traditional
            braces, offering the same smile-enhancing benefits without the
            noticeable look of metal.
          </p>

          <p className={styles.secondaryDescription}>
            Just like traditional braces, clear ceramic braces feature two
            important components: First, a clear or tooth-colored bracket is
            attached to each tooth. Next, we thread a tooth-colored wire through
            the brackets. The brackets may be made of a variety of ceramic and
            composite materials, but you will usually hear us refer to them as
            “ceramic”. <b>Please Note: </b> because these materials are more
            brittle and sensitive to breakage than stainless steel, a lighter
            force is used on your teeth during treatment, which may result in a
            slightly longer treatment time.
          </p>

          <h4 className={styles.secondaryQuestion}>
            Care for ceramic braces is the same as with traditional braces:
          </h4>
          <p className={styles.secondaryDescription}>
            <ul
              className={styles.subDescription}
              style={{ fontSize: "1.4rem", paddingTop: "0rem" }}
            >
              <li>
                Elastics must be worn at all times and changed daily in order to
                maintain a consistent force on the teeth.
              </li>
              <li>
                Hard, sticky, chewy or crunchy foods must be avoided during
                treatment time.
              </li>
              <li>
                Soreness is normal for several days following the placement of
                new braces and adjustment appointments. Gargling salt water and
                over-the-counter pain medications can help to relieve soreness.
              </li>
              <li>
                Braces and wires should be brushed and flossed after each meal.
              </li>
              <li>Special mouth guards should be worn during sports play.</li>
              <li>
                If you feel any sharp pain or a sore develops on the soft
                tissues of your mouth, call us so that we can remedy the
                situation as soon as possible.
              </li>
            </ul>
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuAligners
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
