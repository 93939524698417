import React from "react";

import styles from "./ProsthodonticFooter.module.css";
function ProsthodonticFooter() {
  return (
    <section className={styles.footerSection}>
      <div className={styles.navigation}>
        <h4 className={styles.footerheader}>Navigation</h4>
        <div className={styles.navElements}>
          <a href="/">Home</a>
          <a href="#">About</a>
        </div>
      </div>
      <div className={styles.services}>
        <h4 className={styles.footerheader}>Services</h4>
        <div className={styles.serviceElement}>
          <a href="/general">General Dentistry</a>
          <a href="/prosthodontic">Prosthodontic Dentistry</a>
          <a href="#">Cosmetic Dentistry</a>
          <a href="#">Restorative Dentistry</a>
          <a href="#">Orthodontic Dentistry</a>
          <a href="#">Endodontic Dentistry</a>
          <a href="#">Periodontal Dentistry</a>
        </div>
      </div>
      <div className={styles.patientInfo}>
        <h4 className={styles.footerheader}>Patient Info</h4>
        <div className={styles.patientElement}>
          <a href="#">New Patient</a>
          <a href="#">Patient Registration</a>
          <a href="#">Patient Reviews</a>
        </div>
      </div>
      <div className={styles.location}>
        <h4 className={styles.footerheader}>Location</h4>
        <div className={styles.locationElement}>
          <a href="#"> 1-37/1/125, Old Mumbai Highway,</a>
          <a href="#">Vinayak Nagar, Gachibowli,</a>
          <a href="#">Hyderabad, Telangana, 500032</a>
        </div>
      </div>
    </section>
  );
}

export default ProsthodonticFooter;
