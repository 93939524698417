import React from "react";

import { useInView } from "react-intersection-observer";
import styles from "./OrthodonticServices3.module.css";

import Braces from "../../images/Braces1.jpg";
import LivingWithBraces from "../../images/LivingWithBraces.png";
import ClearBraces from "../../images/ClearBraces.png";
import ClearBraces1 from "../../images/ClearBraces1.jpg";
import Aligners from "../../images/Aligners.png";

import { IonIcon } from "@ionic/react";

import { arrowForward } from "ionicons/icons";
export default function OrthodonticServices3({ serviceName }) {
  const [header, headerView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  const [service, serviceView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  const [service1, service1View] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  return (
    <section className={styles.generalServiesSection}>
      <div
        className={`${styles.generalDescriptionCenter} ${
          headerView ? styles.animate : ""
        }`}
        ref={header}
      >
        <h1 className={styles.generalServiceHeader}>
          Our Orthodontic Services
        </h1>

        <p className={styles.generalServiceHeaderDescription}>
          Our orthodontic dentistry offers all kinds of services and study new
          technology to add new custom services to the list
        </p>
      </div>

      <div
        className={`${styles.generalServices} ${
          serviceView ? styles.animate : ""
        }`}
        ref={service}
      >
        <div className={styles.generalserviceCard}>
          <img src={Braces} className={styles.generalserviceCardImage}></img>
          <div className={styles.generalserviceCardContent}>
            <h3 className={styles.generalserviceCardHeader}>Braces</h3>
            <p className={styles.generalserviceCardDescription}>
              Unlock a straighter, healthier smile with our comprehensive Braces
              services, meticulously tailored to align your teeth and elevate
              your confidence.
            </p>
          </div>

          <div className={styles.generalBtnBorder}>
            <a
              className={styles.generalserviceCardNavigation}
              href="/orthodontic/Braces"
            >
              <span>Learn more</span>
              <IonIcon icon={arrowForward} className={styles.sideArrow} />
            </a>
          </div>
        </div>
        <div className={styles.generalserviceCard}>
          <img
            src={ClearBraces1}
            className={styles.generalserviceCardImage}
          ></img>
          <div className={styles.generalserviceCardContent}>
            <h3 className={styles.generalserviceCardHeader}>Clear Braces</h3>
            <p className={styles.generalserviceCardDescription}>
              Clear braces can give you the smile without anyone knowing! We
              offer several options to get you started on the road to a
              healthier smile!
            </p>
          </div>

          <div className={styles.generalBtnBorder}>
            <a
              className={styles.generalserviceCardNavigation}
              href="/orthodontic/ClearBraces"
            >
              <span>Learn more</span>
              <IonIcon icon={arrowForward} className={styles.sideArrow} />
            </a>
          </div>
        </div>
      </div>
      <br></br>
      <div
        className={`${styles.generalServices1} ${
          service1View ? styles.animate : ""
        }`}
        ref={service1}
      >
        <div className={styles.generalserviceCard}>
          <img
            src={LivingWithBraces}
            className={styles.generalserviceCardImage}
          ></img>
          <div className={styles.generalserviceCardContent}>
            <h3 className={styles.generalserviceCardHeader}>
              Living With Braces
            </h3>
            <p className={styles.generalserviceCardDescription}>
              Ease into life with braces with our supportive guidance. We offer
              tips and assistance to make your journey to a straighter smile
              smooth and comfortable.
            </p>
          </div>

          <div className={styles.generalBtnBorder}>
            <a
              className={styles.generalserviceCardNavigation}
              href="/orthodontic/LivingWithBraces"
            >
              <span>Learn more</span>
              <IonIcon icon={arrowForward} className={styles.sideArrow} />
            </a>
          </div>
        </div>
        <div className={styles.generalserviceCard}>
          <img src={Aligners} className={styles.generalserviceCardImage}></img>
          <div className={styles.generalserviceCardContent}>
            <h3 className={styles.generalserviceCardHeader}>Invisalign</h3>
            <p className={styles.generalserviceCardDescription}>
              Experience the discreet and effective transformation of your smile
              with our Invisalign services, where clear aligners redefine
              orthodontic care.
            </p>
          </div>

          <div className={styles.generalBtnBorder}>
            <a
              className={styles.generalserviceCardNavigation}
              href="/orthodontic/Invisalign"
            >
              <span>Learn more</span>
              <IonIcon icon={arrowForward} className={styles.sideArrow} />
            </a>
          </div>
        </div>
      </div>
      <div className={styles.regularCheckup}>
        <span>
          Don’t skip your regular check-up for teeth.
          <a href="#">Book an appointment!</a>
        </span>
      </div>
    </section>
  );
}
