import React, { useState } from "react";
import axios from "axios";

import { useInView } from "react-intersection-observer";
import styles from "./ScheduleCTA.module.css";
import Modal from "./Modal";

export default function ScheduleCTA({ dentistry, subService }) {
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [service, setService] = useState("");
  const [status, setStatus] = useState("");
  const [messageStatus, setMessageStatus] = useState("");
  const [submit, setSubmit] = useState(false);
  const [complete, setComplete] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [patientMessage, setPatientMessage] = useState("");
  const success = "Submission Successfull";
  const s = "success";
  const f = "fail";
  const successMessage =
    "Thank you for contacting us! Your message has been successfully sent. We will get back to you shortly.";
  const failMessage =
    "We are sorry, but there was an error sending your message. Please try again later.";
  const fail = "Submission Failed";
  const homeProperty = {
    textColor: "#545cd6",
    hoverColor: "#656cda",
  };

  const generalProperty = {
    textColor: "#ff7745",
    hoverColor: "#ff8558",
  };

  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    hoverColor: "#94b3b9",
  };

  const orthodonticProperty = {
    textColor: "#aed1f4",
    hoverColor: "#8ba7c3",
  };

  const pediatricProperty = {
    textColor: "#84789b",
    hoverColor: "#978ab1",
  };
  const endonticProperty = {
    textColor: "#6fcfff",
    hoverColor: "#64bae6",
  };

  const periodontalProperty = {
    textColor: "#ff7745",
    hoverColor: "#cc5f37",
  };

  const oralSurgeryProperty = {
    textColor: "#d099d9",
    hoverColor: "#644a68",
  };
  const styleProperties = {
    home: homeProperty,
    general: generalProperty,
    prosthodontic: prosthodonticProperty,
    orthodontic: orthodonticProperty,
    pediatric: pediatricProperty,
    endontic: endonticProperty,
    periodontal: periodontalProperty,
    oralSurgery: oralSurgeryProperty,
  }[dentistry];
  const [ref, inView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });

  const handleCloseModal = () => {
    // Clear the error state when the dialog box is closed
    setModalIsOpen(false); // Close the modal dialog box
  };

  const sendWhatsAppMessage = async (e) => {
    setSubmit(true);
    e.preventDefault();
    console.log(phone, patientMessage, email);
    setService(subService);
    try {
      const res = await axios.post(
        "https://dentalplanet-o6pa.onrender.com/send-whatsapp",
        {
          name: name,
          email: email,
          phoneNumber: phone,
          message: patientMessage,
          service: subService,
        }
      );
      console.log(res);
      //  alert("WhatsApp message sent!");
      setStatus(success);
      setMessage(successMessage);
      setMessageStatus(s);
      setModalIsOpen(true);
      setComplete(true);
    } catch (error) {
      //  console.error("Error sending WhatsApp message:", error);
      setStatus(fail);
      setMessage(failMessage);
      setMessageStatus(f);
      setModalIsOpen(true);
      alert("Error sending WhatsApp message. Check console for details.");
    }
  };
  return (
    <section className={styles.schedueCta} id="schedule-section">
      <div className={styles.scheduleCtaContainer}>
        <div
          className={`${styles.scheduleCtaForm} ${
            inView ? styles.animate : ""
          }`}
          ref={ref}
        >
          <div className={styles.ctaTextBox}>
            <h2 className={styles.ctaSecondary}>Request An Appointment</h2>
            <form
              className={styles.ctaForm}
              action="#"
              onSubmit={sendWhatsAppMessage}
            >
              <div>
                <input
                  id="patientName"
                  type="text"
                  placeholder="Name"
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>

              <div>
                <input
                  id="email"
                  type="email"
                  placeholder="me@example.com"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>

              <div>
                <input
                  type="text"
                  placeholder="Phone Number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>

              <textarea
                id="message"
                type="te"
                placeholder="Your message holds the key to unlocking our services"
                // onChange={handleMessageChange}
                onChange={(e) => setPatientMessage(e.target.value)}
                required
              ></textarea>

              <button
                type="submit"
                className={styles.btnForm}
                style={{ backgroundColor: styleProperties.textColor }}
              >
                {complete
                  ? "Submitted"
                  : submit
                  ? "Submitting..."
                  : "Get In Touch"}
                {/* {load ? "Schedule a Call" : " Call Scheduled"} */}
              </button>
            </form>
          </div>
        </div>
        {/* <div className={styles.scheduleCtaContent}>
          <img className={styles.scheduleCtaProfile} src={} />
          <h3 className={styles.schduleCtaContentHeader}>
            Book An Appointment
          </h3>
          <p className={styles.scheduleContentDescription}>
            If you're in severe pain, don't hesitate to book an appointment with
            us. Our dedicated team is here to address your urgent dental needs
            promptly and provide the relief you deserve. Your comfort and
            well-being are our top priorities, and we are committed to ensuring
            that you receive the care you need, when you need it the most.
          </p>
        </div> */}

        {modalIsOpen && (
          <Modal
            messageStatus={messageStatus}
            modalOpen={modalIsOpen}
            message={message}
            status={status}
            handleSetModal={setModalIsOpen}
          />
        )}
      </div>
    </section>
  );
}
