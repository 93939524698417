import React, { useState, useEffect } from "react";

import styles from "./Braces.module.css";
import SideMenuBraces from "./SideMenuBraces";

export default function OrthoBraces({ menuItem, menuItemName, dentistry }) {
  const orthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#8ba7c3",
    backgroundColor: "#e7f1fca0",
  };
  const styleProperties = orthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            Braces
          </h1>
          <p className={styles.mainDescription}>
            Braces use constant, gentle pressure, applied to your teeth over
            time, to move them into proper position. Your braces are at work
            every moment of your orthodontic treatment. While braces are
            available in a variety of styles, including metal, plastic and
            ceramic, they all have the same goal: To correctly align your teeth
            and jaws for enhanced aesthetic appeal and for better oral health.
          </p>
          <h4 className={styles.secondaryQuestion}>Metal Braces</h4>
          <p className={styles.secondaryDescription}>
            Metal braces are popular with children and teens, and are smaller
            and more comfortable than ever before. Traditional metal braces
            feature two main components: the brackets that are placed on each
            tooth and the arch wire that is threaded through them. As the wire
            applies pressure to one side of the tooth, the jawbone on the other
            side gives way. This causes the tooth to move and new bone to grow
            behind it. Elastics (rubber bands) are used in conjunction with
            braces, exerting a constant force to move teeth. Elastics must be
            worn at all times and changed every day in order to maintain the
            proper force.
          </p>
          <h4 className={styles.secondaryQuestion}>Lingual Braces</h4>
          <p className={styles.secondaryDescription}>
            Lingual braces work just like traditional metal braces, but are
            placed behind teeth instead of on the front. This allows them to be
            completely hidden, so no one knows that you are wearing them.
            Typically, anyone who could have traditional metal braces can also
            get lingual braces done.
          </p>
          <h4 className={styles.secondaryQuestion}>Clear Braces</h4>
          <p className={styles.secondaryDescription}>
            Clear braces are a popular choice for adults and teens that would
            like a discreet alternative to traditional metal braces. Options
            include clear aligners such as Invisalign® and clear ceramic braces,
            which use tooth-colored ceramic brackets and wires instead for a
            nearly invisible look.
          </p>

          <p className={styles.secondaryDescription}>
            In-Ovation® System Braces – Also called “self-ligating”, these
            braces do not require elastics or ties, but instead use free-sliding
            technology to reduce pressure, friction, and treatment time for the
            patient.
          </p>
          <p className={styles.secondaryDescription}>
            {" "}
            <b>
              If you are interested in enhancing your smile, call us to find out
              about the many orthodontic options that we offer!
            </b>
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuBraces
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
