import React, { useState, useEffect } from "react";

import styles from "../CosmeticDentistry/DentalBonding.module.css";
import SideMenuPreventativeCare from "./SideMenuPreventativeCare";

export default function Preventative({ menuItem, menuItemName, dentistry }) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            Preventative Care
          </h1>
          <p className={styles.mainDescription}>
            Today’s advanced dental treatments and materials can give you a
            healthier, more complete smile that can last you a lifetime. Your
            Dentist, can help you with dental care routine
          </p>

          <h4
            className={styles.secondaryQuestion}
            style={{ color: styleProperties.textColor }}
          >
            Preventative Care
          </h4>
          <ul className={styles.items}>
            <li className={styles.item}>
              <a href="/general/PreventativeCare/TeethCleaningDentalCheckups">
                Teeth Cleaning & Dental Checkups
              </a>
            </li>
            <li className={styles.item}>
              <a href="/general/PreventativeCare/OralHygiene">Oral Hygiene</a>
            </li>
            <li className={styles.item}>
              <a href="/general/PreventativeCare/ChildDentistry">
                Child Dentistry
              </a>
            </li>
            <li className={styles.item}>
              <a href="/general/PreventativeCare/DentalSealants">
                {" "}
                Dental Sealants
              </a>
            </li>
            <li className={styles.item}>
              <a href="/general/PreventativeCare/Arestin">Arestin</a>
            </li>
            <li className={styles.item}>
              <a href="/general/PreventativeCare/GumDiseaseTreatment">
                Gum Disease Treatment
              </a>
            </li>
          </ul>
        </div>
        {windowWidth > 730 && (
          <SideMenuPreventativeCare
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
