// AppContext.js
import React, { createContext, useState, useContext } from "react";

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [isScrolling, setIsScrolling] = useState(false);
  const [disableAnimations, setDisableAnimations] = useState(false);

  return (
    <AppContext.Provider
      value={{
        isScrolling,
        setIsScrolling,
        disableAnimations,
        setDisableAnimations,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
