import React, { useState, useEffect } from "react";

import styles from "./Procedures.module.css";
import SideMenuProcedures from "./SideMenuProcedures";

export default function CrackedTeeth({ menuItem, menuItemName, dentistry }) {
  const endodonticProperty = {
    textColor: "#6fcfff",
    spanColor: "#64bae6",
    backgroundColor: "#e7f1fca0",
  };
  const styleProperties = endodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            CRACKED TEETH
          </h1>

          <p className={styles.mainDescription}>
            <b>Cracked teeth </b>demonstrate many types of symptoms, including
            pain when chewing, temperature sensitivities, or even the release of
            biting pressure. It is also common for tooth pain to come and go,
            making it difficult to diagnose the cause of discomfort.
          </p>

          <p className={styles.secondaryDescription}>
            Chewing can cause movement of the cracked pieces of your tooth, and
            the pulp within the tooth becomes irritated. At the same time, when
            biting pressure is released, the crack can close quickly, resulting
            in sharp pain. Eventually, the pulp will become damaged and tooth
            will consistently hurt, even when you are not chewing. It is
            possible that cracks can lead to infection of the pulp tissue, which
            can spread to the bone and gum surrounding the problematic tooth.
          </p>
          <h4 className={styles.secondaryQuestion}>Types of Cracks</h4>
          <p className={styles.secondaryDescription}>
            <b>Craze lines:</b>
            These are tiny cracks that only affect the outer enamel of the
            tooth. These cracks are more common in adults. These types of cracks
            are superficial and are usually of no concern.
          </p>
          <p className={styles.secondaryDescription}>
            <b>Fractured Cusp:</b>
            When a cusp becomes weakened, a fracture may result. The cusp may
            break off or be removed by a dentist. A fractured cusp rarely
            damages the pulp, so root canal is not necessary. Your dentist will
            usually restore the tooth with a full crown.
          </p>
          <p className={styles.secondaryDescriptiont}>
            <b>Treatable Cracked Tooth:</b> This type of crack extends from the
            chewing surface of the tooth and vertically migrates towards the
            root. In some cases, the crack may extend below the gum line. It is
            possible for the crack to extend further into the root. Damage to
            the pulp is commonplace. In this case, root canal treatment is
            usually necessary. A cracked tooth that is not treated will worsen,
            resulting in the loss of the tooth. Therefore, early detection is
            essential.
          </p>
          <p className={styles.secondaryDescriptiont}>
            <b>Split Tooth:</b> A split tooth is usually the result of an
            untreated cracked tooth. It can be identified by a crack with
            distinct segments. This type of tooth can never be saved intact.
            Yet, the position and extent of the problem will dictate whether any
            portion of the tooth can be saved. Sometimes, endodontic retreatment
            by the doctors and restoration by your dentist can be used to save a
            portion of the tooth.
          </p>
          <p className={styles.secondaryDescriptiont}>
            <b>Vertical Root Fracture:</b> A vertical root fracture begins at
            the root and extends towards the chewing surface of the tooth.
            Unfortunately, they show minimal symptoms and may go unnoticed.
            Treatment involves endodontic surgery if a portion of the tooth can
            be saved by removal of the fractured root. Otherwise, the tooth will
            have to be extracted.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuProcedures
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
