import React, { useState, useEffect } from "react";

import styles from "./Procedures.module.css";
import SideMenuProcedures from "./SideMenuProcedures";

export default function TMJ({ menuItem, menuItemName, dentistry }) {
  const oralsurgery = {
    textColor: "#d099d9",
    spanColor: "#644a68",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = oralsurgery;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            TMJ
          </h1>

          <p className={styles.mainDescription}>
            <b>TMJ (temporomandibular joint) </b>disorders are a family of
            problems related to your jaw joint. If you have had symptoms like
            pain or a “clicking” sound, you’ll be glad to know that these
            problems are more easily diagnosed and treated than they were in the
            past. These symptoms occur when the joints of the jaw and the
            chewing muscles do not work together correctly. TMJ stands for
            temporomandibular joint, which is the name for each joint (right and
            left) that connects your jaw to your skull. Since some types of TMJ
            problems can lead to more serious conditions, early detection and
            treatment are important.
          </p>

          <p className={styles.secondaryDescription}>
            No one treatment can resolve TMJ disorders completely and treatment
            takes time to become effective. Our team of Dentist can help you
            have a healthier, more comfortable jaw.
          </p>
          <h4 className={styles.secondaryQuestion}>Trouble with your jaw?</h4>
          <p className={styles.secondaryDescription}>
            TMJ disorders develop for many reasons. You might clench or grind
            your teeth, tightening your jaw muscles and stressing your TM joint.
            You may have a damaged jaw joint due to injury or disease. Injuries
            and arthritis can damage the joint directly or stretch or tear the
            muscle ligaments. As a result, the disk, which is made of cartilage
            and functions as the “cushion” of the jaw joint, can slip out of
            position. Whatever the cause, the results may include a misaligned
            bite, pain, clicking, or grating noise when you open your mouth, or
            trouble opening your mouth wide.
          </p>
          <h4
            className={styles.secondaryQuestion}
            style={{ marginBottom: "0.5rem" }}
          >
            Do You Have a TMJ Disorder?
          </h4>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>Are you aware of grinding or clenching your teeth?</li>
              <li>Do you wake up with sore, stiff muscles around your jaws?</li>
              <li>Do you have frequent headaches or neck aches?</li>
              <li>Does the pain get worse when you clench your teeth?</li>
              <li>Does stress make your clenching and pain worse?</li>
              <li>
                Does your jaw click, pop, grate, catch, or lock when you open
                your mouth?
              </li>
              <li>
                Is it difficult or painful to open your mouth, eat, or yawn?
              </li>
              <li>Have you ever injured your neck, head, or jaws?</li>
              <li>
                Have you had problems (such as arthritis) with other joints?
              </li>
              <li>Do you have teeth that no longer touch when you bite?</li>
              <li>Do your teeth meet differently from time to time?</li>
              <li>Is it hard to use your front teeth to bite or tear food?</li>
              <li>Are your teeth sensitive, loose, broken or worn?</li>
            </ul>
          </p>
          <p className={styles.secondaryDescription}>
            The more times you answered “yes”, the more likely it is that you
            have a TMJ disorder. Understanding TMJ disorders will also help you
            understand how they are treated.
          </p>
          <h4 className={styles.secondaryQuestion}>TMJ Treatment</h4>
          <p className={styles.secondaryDescription}>
            There are various <b>treatment options for TMJ </b>that Dentist can
            utilize to improve the harmony and function of your jaw. Once an
            evaluation confirms a diagnosis of TMJ disorder, Dentist will
            determine the proper course of treatment. It is important to note
            that treatment always works best with a team approach of self-care
            combined with professional care.
          </p>
          <p
            className={styles.secondaryDescription}
            style={{ marginBottom: "0.5rem" }}
          >
            The initial goals are to relieve the muscle spasms and joint pain.
            This is usually accomplished with a pain reliever,
            anti-inflammatory, or muscle relaxant. Steroids can be injected
            directly into the joints to reduce pain and inflammation. Self-care
            treatments can often be effective as well and include:
          </p>

          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>Resting your jaw</li>
              <li>
                Keeping your teeth apart when you are not swallowing or eating
              </li>
              <li>Eating soft foods</li>
              <li>Applying ice and heat</li>
              <li>Exercising your jaw</li>
              <li>Practicing good posture</li>
            </ul>
          </p>
          <p className={styles.secondaryDescription}>
            Stress management techniques such as biofeedback or physical therapy
            may also be recommended, as well as a temporary, clear plastic
            appliance known as a splint. A splint (or nightguard) fits over your
            top or bottom teeth and helps keep your teeth apart, thereby
            relaxing the muscles and reducing pain. There are different types of
            appliances used for different purposes. A nightguard helps you stop
            clenching or grinding your teeth and reduces muscle tension at
            night. It also helps to protect the cartilage and joint surfaces. An
            anterior positioning appliance moves your jaw forward, relieves
            pressure on parts of your jaw, and aids in disk repositioning. It
            may be worn 24 hours/day to help your jaw heal. An orthotic
            stabilization appliance is worn 24 hours/day, or just at night, to
            move your jaw into proper position. Appliances also help protect
            tooth wear.
          </p>
          <h4 className={styles.secondaryQuestion}>
            What about bite correction or surgery?
          </h4>
          <p className={styles.secondaryDescription}>
            If your TMJ disorder has caused problems with how your teeth fit
            together you may need treatment such as bite adjustment
            (equilibration), orthodontics with or without jaw reconstruction, or
            restorative dental work. Surgical options, such as arthroscopy and
            open joint repair restructuring, are sometimes needed, but are
            reserved for severe cases. Dentist do not consider TMJ surgery
            unless the jaw can’t open, is dislocated and non-reducible, has
            severe degeneration, or the patient has undergone appliance
            treatment unsuccessfully.
          </p>
        </div>

        {windowWidth > 730 && (
          <SideMenuProcedures
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
