import React, { useState, useEffect } from "react";

import styles from "../CosmeticDentistry/DentalBonding.module.css";
import SideMenuPreventativeCare from "./SideMenuPreventativeCare";

export default function ChildDentistry({ menuItem, menuItemName, dentistry }) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      //  style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            CHILDREN’S DENTISTRY
          </h1>
          <h4 className={styles.secondaryQuestion}>
            Your Child’s First Visit to the Dentist
          </h4>
          <p className={styles.mainDescription}>
            <b>
              The first “regular” dental visit should be just after your child’s
              first birthday.
            </b>{" "}
            The first dental visit is usually short and involves very little
            treatment. We may ask you to sit in the dental chair and hold your
            child during the examination. You may also be asked to wait in the
            reception area during part of the visit so that a relationship can
            be built between your child and your dentist.
          </p>
          <p className={styles.mainDescription}>
            We will gently examine your child’s teeth and gums. X-rays may be
            taken (to reveal decay and check on the progress of your child’s
            permanent teeth under the gums). We may clean your child’s teeth and
            apply topical fluoride to help protect the teeth against decay. We
            will make sure your child is receiving adequate fluoride at home.
            Most important of all, we will review with you how to clean and care
            for your child’s teeth.
          </p>
          <h4 className={styles.secondaryQuestion}>
            What should I tell my child about the first dental visit?
          </h4>
          <p className={styles.secondaryDescription}>
            We are asked this question many times. We suggest you prepare your
            child the same way you would before their first haircut or trip to
            the shoe store. Your child’s reaction to his first visit to the
            dentist may surprise you.
            <h4 className={styles.secondaryQuestion}>
              Here are some “First Visit” Tips:
            </h4>
            <ul className={styles.subDescription}>
              <li>Take your child for a “preview” of the office.</li>
              <li>Read books with them about going to the dentist.</li>
              <li>
                Review with them what the dentist will be doing at the time of
                the first visit.
              </li>
              <li>Speak positively about your own dental experiences.</li>
            </ul>
          </p>
          <h4 className={styles.secondaryQuestion}>
            During your first visit the dentist will:
          </h4>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>Examine your mouth, teeth and gums.</li>
              <li>Evaluate adverse habits like thumb sucking.</li>
              <li>Check to see if you need fluoride.</li>
              <li>Teach you about cleaning your teeth and gums.</li>
              <li>Suggest a schedule for regular dental visits.</li>
            </ul>
          </p>
          <h4 className={styles.secondaryQuestion}>
            What about preventative care?
          </h4>
          <p className={styles.secondaryDescription}>
            Tooth decay and children no longer have to go hand in hand. At our
            office we are most concerned with all aspects of preventive care. We
            use the latest in dental sealant technology to protect your child’s
            teeth. Dental sealants are space-age composites that are bonded to
            the chewing surfaces of decay-prone back teeth. This is just one of
            the ways we will set the foundation for your child’s lifetime of
            good oral health.
          </p>
          <h4 className={styles.secondaryQuestion}>Cavity Prevention</h4>
          <p className={styles.secondaryDescription}>
            <b>
              Most of the time cavities are due to a diet high in sugary foods
              and a lack of brushing.
            </b>{" "}
            Limiting sugar intake and brushing regularly, of course, can help.
            The longer it takes your child to chew their food and the longer the
            residue stays on their teeth, the greater the chances of getting
            cavities.
          </p>
          <h4 className={styles.secondaryQuestion}>
            Tips for Cavity Prevention
          </h4>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>Limit frequency of meals and snacks.</li>
              <li>Encourage brushing, flossing and rinsing.</li>
              <li>Watch what your child drinks.</li>
              <li>Avoid giving your child sticky foods.</li>
              <li>Make treats part of meals.</li>
              <li>Choose nutritious snacks.</li>
            </ul>
          </p>
          <p className={styles.secondaryDescription}>
            The first baby teeth that come into the mouth are the two bottom
            front teeth. You will notice this when your baby is about 6-8 months
            old. Next to follow will be the 4 upper front teeth and the
            remainder of your baby’s teeth will appear periodically. They will
            usually appear in pairs along the sides of the jaw until the child
            is about 2 1/2 years old.{" "}
          </p>
          <p className={styles.secondaryDescription}>
            {" "}
            At around 2 1/2 years old your child should have all 20 teeth.
            Between the ages of 5 and 6 the first permanent teeth will begin to
            erupt. Some of the permanent teeth replace baby teeth and some
            don’t. Don’t worry if some teeth are a few months early or late as
            all children are different.{" "}
          </p>
          <p className={styles.secondaryDescription}>
            Baby teeth are important as they not only hold space for permanent
            teeth but they are important to chewing, biting, speech and
            appearance. For this reason it is important to maintain a healthy
            diet and daily hygiene.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuPreventativeCare
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
