import React, { useState, useEffect } from "react";

import styles from "../PediatricMenu/PediatricMenu.module.css";
import SideMenuPediatricMenu from "./SideMenuPediatricMenu";

export default function DigitSucking({ menuItem, menuItemName, dentistry }) {
  const pediatricProperty = {
    textColor: "#9086a5",
    spanColor: "#776c8c",
    backgroundColor: "#e6e4eb",
  };
  const styleProperties = pediatricProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            DIGIT SUCKING
          </h1>

          <p className={styles.mainDescription}>
            Digit sucking is a habit that occurs in infants. Children usually
            give up digit sucking by the age of four. If the child continues
            past the age when their permanent teeth start to erupt, they may
            develop crooked teeth and a malformed roof of their mouth. This
            results from the frequency, duration, intensity, and position of the
            digit in the child’s mouth. This can also affect the position of the
            upper and lower jaw and can also affect speech.
          </p>
          <h4
            className={styles.secondaryQuestion}
            style={{ marginBottom: "0.5rem" }}
          >
            Suggestions to break the habit:
          </h4>

          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>Wait till the time is right (low stress).</li>
              <li>
                Motivate your child (show examples of what could happen to their
                teeth and fingers/thumbs).
              </li>
              <li>
                Use a reward system (small incentives will encourage your child
                to stick with it).
              </li>
            </ul>
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuPediatricMenu
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
