import React, { useState, useEffect } from "react";

import styles from "../CosmeticDentistry/DentalBonding.module.css";
import SideMenuPreventativeCare from "./SideMenuPreventativeCare";

export default function GumDiseaseTreatment({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            Gum Disease Treatment
          </h1>
          <p className={styles.mainDescription}>
            Our Dentists are proud to offer the latest technology available for{" "}
            <b
              className={styles.mainSpan}
              style={{ color: styleProperties.spanColor }}
            >
              periodontal therapy:
            </b>{" "}
            laser treatment. There are numerous advantages to using laser
            therapy as opposed to the traditional methods requiring a scalpel
            and sutures.
          </p>
          <h4 className={styles.secondaryQuestion}>
            {" "}
            Benefits of Laser Dentistry:
          </h4>
          <b
            style={{
              fontSize: "1.6rem",
              marginBottom: ".8rem",
              display: "inline-block",
            }}
          >
            Comfort:
          </b>
          <p className={styles.secondaryDescription}>
            With laser treatment, there is reduced or no bleeding, minimized
            swelling, no charring, and no drill noise or vibration. The stress
            and anxiety often associated with dental work is therefore
            eliminated when you experience quick, effective, and essentially
            pain-free laser procedures. Also, postoperative sensitivity is
            greatly limited by laser dentistry, and recovery time is much
            quicker than with traditional methods. In fact, due to the
            less-invasive nature of the laser technique, the mouth begins to
            heal immediately after work is completed!
          </p>
          <b
            style={{
              fontSize: "1.6rem",
              marginBottom: ".8rem",
              display: "inline-block",
            }}
          >
            Reduced Risk of Infection:
          </b>
          <p className={styles.secondaryDescription}>
            The laser’s high-energy light beam acts as a sterilizer on the area
            it is working, thereby reducing the risk of bacterial infections and
            relapses.
          </p>
          <b
            style={{
              fontSize: "1.6rem",
              marginBottom: ".8rem",
              display: "inline-block",
            }}
          >
            Anesthesia Limited or Eliminated:
          </b>
          <p className={styles.secondaryDescription}>
            Because laser dentistry is virtually painless, no longer will you
            suffer with fear of injections and numbness. Often, only a light
            anesthetic spray is required. Laser treatment eliminates the
            complications and cost associated with anesthesia.
          </p>

          <h4 className={styles.secondaryQuestion}>Uses of Dental Lasers:</h4>
          <p className={styles.secondaryDescription}>
            Our Dentist can perform a variety of procedures using laser
            technology, such as a frenectomy, osseous surgery, and gum grafting.
            Our Dentists are also able to use laser technology as a way to
            regenerate healthy gums and remove diseases areas in a virtually
            painless way. This method also encourages healthy gum to regenerate
            and attach to the teeth. There is a much lower chance of gum disease
            returning when laser therapy is used as opposed to traditional
            surgery.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuPreventativeCare
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
