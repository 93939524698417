import React from "react";

import styles from "./SideMenuProcedures.module.css";
import { IonIcon } from "@ionic/react";
import { caretForwardOutline } from "ionicons/icons";
export default function SideMenuProcedures({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const generalProperty = {
    backgroundColor: "#ffa07d",
  };
  const prosthodonticProperty = {
    backgroundColor: "#b6d6f5",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;

  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  return (
    <div
      className={styles.menuSection}
      style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <h2 className={styles.secondaryHeading}>Procedures</h2>
      <ul className={styles.main_nav_list}>
        <li className={styles.service}>
          {menuItemName === "RootCanal" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/endodontic/Procedures/RootCanal"
          >
            <p className={styles.serviceHeader}>Root Canal</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "GentleWaveUltraCleaning" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/endodontic/Procedures/GentleWaveUltraCleaning"
          >
            <p className={styles.serviceHeader}>Gentle Wave UltraCleaning</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "EndodonticRetreatment" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/endodontic/Procedures/EndodonticRetreatment"
          >
            <p className={styles.serviceHeader}>Endodontic Retreatment</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "Apicoectomy" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/endodontic/Procedures/Apicoectomy"
          >
            <p className={styles.serviceHeader}>Apicoectomy</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "CrackedTeeth" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/endodontic/Procedures/CrackedTeeth"
          >
            <p className={styles.serviceHeader}>Cracked Teeth</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "TraumaticInjuries" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/endodontic/Procedures/TraumaticInjuries"
          >
            <p className={styles.serviceHeader}>Traumatic Injuries</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "Navigation" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/endodontic/Procedures/Navigation"
          >
            <p className={styles.serviceHeader}>Dynamic 3D Navigation</p>
          </a>
        </li>
      </ul>
    </div>
  );
}
