// Home.js

import React, { useState } from "react";
import Footer from "../Home/Footer";
import Header2 from "../Home/Header2";
import Location from "../Home/Location";
import ScheduleCTA from "../Home/ScheduleCta";
import Welcome from "../Home/Welcome";
import FAQSection from "../PatientInformation/FAQSection";
import ContactHero from "./ContactHero";
import ContactInformation from "./ContactInformation";

const ContactHome = () => {
  const [service, setService] = useState(null);

  const handleService = (name) => {
    setService(name);
    console.log(service);
  };

  return (
    <>
      <Header2 />
      <ContactHero />
      <ContactInformation />
      <FAQSection />
      <Location />
      {/* <Schedule /> */}
      <ScheduleCTA dentistry="home" subService={"Contact Page"} />

      <Footer dentistry={"home"} />
    </>
  );
};

export default ContactHome;
