import React from "react";
import { useAppContext } from "../../AppContext";

import styles from "./GeneralHero.module.css";

export default function GeneralHero() {
  const { setDisableAnimations, setIsScrolling } = useAppContext();
  const handleScrollToSchedule = () => {
    setDisableAnimations(true);
    setIsScrolling(true);
    const scheduleSection = document.getElementById("schedule-section");
    if (scheduleSection) {
      scheduleSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <section className={styles.heroSection}>
      <div className={styles.container}>
        <div className={styles.heroContent}>
          <div className={styles.heroPositioning}>
            <p className={styles.heroDescription}>
              Get expert help and care at every visit.
            </p>
            <h2 className={styles.heroHeader}>
              At Dental Planet, we value and listen to our client's specific
              needs.
            </h2>
            <div className={styles.btnSection}>
              <a
                href="#schedule-section"
                onClick={handleScrollToSchedule}
                className={styles.btn}
              >
                Set An Appointment
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
