import React, { useEffect, useState } from "react";

import styles from "../../PeriodontalMenu/PeriodontalMenu.module.css";
import SideMenuImplantsMenu from "./SideMenuImplantsMenu";

export default function OverDenture({ menuItem, menuItemName, dentistry }) {
  const periodontalProperty = {
    textColor: "#fc7444",
    spanColor: "#978ab1",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = periodontalProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            IMPLANT SUPPORTED OVERDENTURE
          </h1>

          <p className={styles.mainDescription}>
            An <b>Implant Supported Overdenture </b>is a contemporary
            restoration that has revolutionized the way surgeons and dentists
            think of replacing a full set of teeth. Standard dentures are
            unsecured prostheses with inherent limitations. Most often, dentures
            are painful, inconvenient and unstable. Such dentures can make
            chewing foods difficult, limiting the foods that you once enjoyed.
            Modern dentistry can help with implant supported dentures.
          </p>
          <p className={styles.secondaryDescription}>
            <b>
              The Implant Supported Overdenture treatment concept replaces your
              missing teeth with a full dental bridge supported by dental
              implants.{" "}
            </b>
            Fewer implants are needed and overall treatment time and cost is
            reduced. An Implant Supported Overdenture solution also ensures
            greater stability in the bone, reducing the need for bone graft
            surgery to increase bone volume. Implant-supported overdentures stay
            connected with bar and clip attachment methods or use a variety of
            abutment-based attachments (ball, magnets, and resilient stud
            attachments such as Locators). The most appropriate attachment
            system for your individual needs relates to a variety of factors
            that is determined early in the treatment. Typically, a temporary
            set of teeth can be placed on the same day of surgery. The temporary
            teeth allow you to lead a normal life immediately after surgery.
            After a short healing period, your dentist will place the final
            bridge. Your quality of life is improved, and you can start enjoying
            your favorite foods again with renewed confidence.
          </p>
          <h4
            className={styles.secondaryQuestion}
            style={{ marginBottom: "0.5rem" }}
          >
            Implant Supported Overdentures offer you many advantages:
          </h4>

          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>
                <b>A cost effective solution. </b>When compared to some other
                implant supported restoration methods, your new replacement
                teeth require fewer implants for each jaw. With fewer implants
                required, the cost is lowered.
              </li>
              <li>
                <b>Reduced need for bone grafting. </b>AThe special angled
                placement of two of the implants ensures a secure and stable
                anchorage for the replaced arch, often making bone grafting
                unnecessary.
              </li>
              <li>
                <b>Faster treatment and healing time. </b>Your replacement arch
                can be attached to your implants immediately after insertion.
              </li>
              <li>
                <b>Scientifically proven and documented. </b>Implant Supported
                Overdentures have had good clinical outcomes from decade long
                studies with favorable results.
              </li>
            </ul>
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuImplantsMenu
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
