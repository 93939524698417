import React, { useState, useEffect } from "react";

import styles from "./PeriodontalMenu.module.css";
import SideMenuPeriodontalMenu from "./SideMenuPeriodontalMenu";

export default function PeriodontalMenu({ menuItem, menuItemName, dentistry }) {
  const periodontalProperty = {
    textColor: "#fc7444",
    spanColor: "#978ab1",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = periodontalProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            PERIODONTAL TREATMENT METHODS
          </h1>
          <p className={styles.mainDescription}>
            Our team provides a variety of surgical services for the treatment
            of periodontal issues. We pride ourselves on the fact that we are
            very conservative in our treatment recommendations and limit surgery
            to the areas where it is absolutely necessary.
          </p>

          <p className={styles.secondaryDescription}>
            Many times, the early stages of periodontal disease are best treated
            with non-surgical periodontal therapy. Even in severe cases,
            non-surgical periodontal therapy often precedes surgical therapy.
            This is done to improve the overall tissue quality prior to surgery
            and also to help limit the areas requiring surgery.
          </p>
          <ul className={styles.items}>
            {/* <li className={styles.item}>
              <a href="/periodontal/TreatmentMethods">Treatment Methods</a>
            </li> */}
            <li className={styles.item}>
              <a href="/periodontal/PeriodontalMaintenance">
                Periodontal Maintenance
              </a>
            </li>
            <li className={styles.item}>
              <a href="/periodontal/TeethCleaning">
                Professional Teeth Cleaning
              </a>
            </li>
            <li className={styles.item}>
              <a href="/periodontal/periodontalServices/Perioscopic">
                Perioscopic Treatment
              </a>
            </li>
            <li className={styles.item}>
              <a href="/periodontal/periodontalServices/Bruxism">
                Bruxism Treatment
              </a>
            </li>
            <li className={styles.item}>
              <a href="/periodontal/periodontalServices/Planing">
                Scaling and Root Planing
              </a>
            </li>
            <li className={styles.item}>
              <a href="/periodontal/periodontalServices/Crown">
                Crown Lengthening
              </a>
            </li>
            <li className={styles.item}>
              <a href="/periodontal/periodontalServices/LaserTherapy">
                Laser Therapy
              </a>
            </li>
            <li className={styles.item}>
              <a href="/periodontal/periodontalServices/BiteAdjustment">
                Bite Adjustment
              </a>
            </li>
            <li className={styles.item}>
              <a href="/periodontal/periodontalServices/ReductionSurgery">
                Osseous Surgery
              </a>
            </li>
            <li className={styles.item}>
              <a href="/periodontal/periodontalServices/Grafting">
                Gum Grafting
              </a>
            </li>
            <li className={styles.item}>
              <a href="/periodontal/periodontalServices/TissueRegeneration">
                Guided Bone & Tissue Regeneration
              </a>
            </li>
            <li className={styles.item}>
              <a href="/periodontal/periodontalServices/Protein">
                Bone Morphogenetic Protein
              </a>
            </li>
            <li className={styles.item}>
              <a href="/periodontal/periodontalServices/PeriodontalSurgery">
                Cosmetic Periodontal Surgery
              </a>
            </li>
            <li className={styles.item}>
              <a href="/periodontal/periodontalServices/OralCancer">
                Oral Cancer Exam
              </a>
            </li>

            <li className={styles.item}>
              <a href="/periodontal/periodontalServices/StemCell">
                Stem Cell recovery
              </a>
            </li>

            <li className={styles.item}>
              <a href="/periodontal/periodontalServices/ANESTHESIA">
                Anesthesia
              </a>
            </li>
          </ul>
        </div>
        {windowWidth > 730 && (
          <SideMenuPeriodontalMenu
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
