import React, { useState, useEffect } from "react";

import styles from "../PediatricMenu/PediatricMenu.module.css";
import SideMenuPediatricMenu from "./SideMenuPediatricMenu";

export default function AmalgamFillings({ menuItem, menuItemName, dentistry }) {
  const pediatricProperty = {
    textColor: "#9086a5",
    spanColor: "#776c8c",
    backgroundColor: "#e6e4eb",
  };
  const styleProperties = pediatricProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            DENTAL AMALGAM
          </h1>

          <p className={styles.mainDescription}>
            <b>Dental Amalgam </b>is a commonly used dental filling that has
            been used for over 150 years. It is a mixture of mercury with at
            least one other metal. Amalgam has many advantages over other
            restorative material, such as low cost, strength, durability, and
            bacteriostatic effects.
          </p>

          <p className={styles.mainDescription}>
            Amalgam is used in dentistry for a number of reasons. It is
            relatively easy to use and manipulate during placement; it remains
            soft for a short time so it can be packed to fill any irregular
            volume, and then forms a hard compound. Amalgam possesses greater
            longevity than other direct restorative materials, such as
            composite.{" "}
            <b>
              On average, most amalgam restorations serve for 10 to 12 years,
              whereas resin-based composites serve for about half that time.{" "}
            </b>
            However, with recent improvements in composite material science and
            a better understanding of the technique-sensitivity of placement, it
            should be noted that this difference is decreasing.
          </p>
          <p className={styles.mainDescription}>
            There are circumstances in which composite (white fillings) serves
            better than amalgam; when amalgam is not indicated, or when a more
            conservative preparation would be beneficial, composite is the
            recommended restorative material. These situations would include
            small occlusal restorations, in which amalgam would require the
            removal of a more sound tooth structure, as well as in “enamel sites
            beyond the height of contour.”
          </p>
          <p className={styles.mainDescription}>
            <b>
              The American Dental Association and Indian Dental Association
              Council on Scientific Affairs has concluded that both amalgam and
              composite materials are considered safe and effective for tooth
              restoration.
            </b>
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuPediatricMenu
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
