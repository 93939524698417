import React from "react";

import styles from "./SideMenuPeriodontalMenu.module.css";
import { IonIcon } from "@ionic/react";
import { caretForwardOutline } from "ionicons/icons";
export default function SideMenuPeriodontalMenu({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const periodontalProperty = {
    backgroundColor: "#ffa07d",
  };
  const styleProperties = periodontalProperty;

  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  return (
    <div
      className={styles.menuSection}
      style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <h2 className={styles.secondaryHeading}>Periodontal Services</h2>
      <ul className={styles.main_nav_list}>
        {/* <li className={styles.service}>
          {menuItemName === "TreatmentMethods" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/periodontal/TreatmentMethods"
          >
            <p className={styles.serviceHeader}>Treatment Methods</p>
          </a>
        </li> */}
        <li className={styles.service}>
          {menuItemName === "PeriodontalMaintenance" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/periodontal/PeriodontalMaintenance"
          >
            <p className={styles.serviceHeader}>Periodontal Maintenance</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "TeethCleaning" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a className={styles.main_nav_link} href="/periodontal/TeethCleaning">
            <p className={styles.serviceHeader}>Professional Teeth Cleaning</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "Perioscopic" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/periodontal/periodontalServices/Perioscopic"
          >
            <p className={styles.serviceHeader}>Perioscopic Treatment</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "Bruxism" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/periodontal/periodontalServices/Bruxism"
          >
            <p className={styles.serviceHeader}>Bruxism Treatment</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "Planing" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/periodontal/periodontalServices/Planing"
          >
            <p className={styles.serviceHeader}>Scaling and Root Planing</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "Crown" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/periodontal/periodontalServices/Crown"
          >
            <p className={styles.serviceHeader}>Crown Lengthening</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "LaserTherapy" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/periodontal/periodontalServices/LaserTherapy"
          >
            <p className={styles.serviceHeader}>Laser Therapy</p>
          </a>
        </li>

        <li className={styles.service}>
          {menuItemName === "BiteAdjustment" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/periodontal/periodontalServices/BiteAdjustment"
          >
            <p className={styles.serviceHeader}>Bite Adjustment</p>
          </a>
        </li>
        <li className={styles.service}>
          <a
            className={styles.main_nav_link}
            href="/periodontal/periodontalServices/ReductionSurgery"
          >
            <p className={styles.serviceHeader}>Osseous Surgery</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "Grafting" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/periodontal/periodontalServices/Grafting"
          >
            <p className={styles.serviceHeader}>Gum Grafting</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "TissueRegeneration" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/periodontal/periodontalServices/TissueRegeneration"
          >
            <p className={styles.serviceHeader}>
              Guided Bone & Tissue Regeneration
            </p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "Protein" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/periodontal/periodontalServices/Protein"
          >
            <p className={styles.serviceHeader}>Bone Morphogenetic Protein</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "PeriodontalSurgery" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/periodontal/periodontalServices/PeriodontalSurgery"
          >
            <p className={styles.serviceHeader}>Cosmetic Periodontal Surgery</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "OralCancer" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/periodontal/periodontalServices/OralCancer"
          >
            <p className={styles.serviceHeader}>Oral Cancer Exam</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "Stemcell" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/periodontal/periodontalServices/Stemcell"
          >
            <p className={styles.serviceHeader}>Stem Cell recovery</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "ANESTHESIA" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/periodontal/periodontalServices/ANESTHESIA"
          >
            <p className={styles.serviceHeader}>Anesthesia</p>
          </a>
        </li>
      </ul>
    </div>
  );
}
