import React, { useEffect, useState } from "react";

import styles from "../../PeriodontalMenu/PeriodontalMenu.module.css";
import SideMenuImplantsMenu from "./SideMenuImplantsMenu";

export default function TeethInAnHour({ menuItem, menuItemName, dentistry }) {
  const periodontalProperty = {
    textColor: "#fc7444",
    spanColor: "#978ab1",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = periodontalProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            TEETH-IN-AN-HOUR™
          </h1>

          <p className={styles.mainDescription}>
            <b>TEETH-IN-AN-HOUR™ </b>is a revolutionary concept providing
            patients with{" "}
            <b>
              fully functioning teeth on dental implants in a single procedure
              that takes about an hour.{" "}
            </b>
            This technology was developed by Nobel Biocare and utilizes
            collaboration between both the restorative doctor and the
            periodontist. This merging of knowledge and experience achieves not
            only increased safety, but also a more precise implant placement. In
            addition, the fabrication of a final prosthesis is completed prior
            to the surgery. The computer-guided implant surgery is done in an
            arthroscopic fashion without requiring any flap reflection. This
            benefits the patient in that there is less postoperative discomfort,
            less swelling, and less bruising. Patients can often resume their
            normal activities the next day.
          </p>
          <p className={styles.secondaryDescription}>
            The process begins when a CAT scan is taken of the patient’s jaw
            bone. This CAT scan allows for the generation of a three-dimensional
            model of the jaw bone that can then be used with virtual reality
            software to plan the implant placement without the presence of the
            patient. The results are a more accurate implant placement and less
            chair time for the patient.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuImplantsMenu
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
