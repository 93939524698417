import React, { useState, useEffect } from "react";
import SideMenuCosmeticProsthodontic from "../RestorativeProsthodontic/SideMenuCosmeticProsthodontic";
import styles from "./DentalBonding.module.css";
import SideMenuCosmeticDentistry from "./SideMenuCosmeticDentistry";

export default function E4DDentist({ menuItem, menuItemName, dentistry }) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            SAME DAY DENTISTRY WITH E4D DENTIST®
          </h1>
          <p className={styles.mainDescription}>
            Computer-aided design and computer-aided manufacturing (CAD/CAM)
            technology has been revolutionizing many industries, including
            dentistry. Remember when it used to take two separate appointments
            to get a{" "}
            <b
              className={styles.mainSpan}
              style={{ color: styleProperties.spanColor }}
            >
              dental crown?
            </b>{" "}
            Even just a few years ago, patients had to endure mouthfuls of messy
            impression materials and wearing temporaries for weeks at a time.
            Now, with our <b>E4D Dentist®</b> system, it is all a thing of the
            past!
          </p>

          <p className={styles.secondaryDescription}>
            Our Dentists can now complete beautiful restorations like crowns,
            inlays, onlays, and veneers in-office, in only one appointment.
          </p>
          <h4 className={styles.secondaryQuestion}>
            Benefits of Computer-aided restorations for our patients:
          </h4>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>Safe and simple procedure</li>
              <li>One appointment</li>
              <li>Advanced laser technology provides absolute precision</li>
              <li>No need for temporary restorations</li>
              <li>No unpleasant or messy impressions</li>
            </ul>
          </p>
          <p className={styles.secondaryDescription}>
            Our E4D Dentist® system allows our Dentist to replace conventional
            impression methods with a clean, fast digital scan that is obtained
            in just a few minutes through a series of photographic images taken
            in the mouth. Combining this scanned data with our advanced
            software, we’re able to design a custom, permanent restoration. A
            milling unit then fabricates your restorative design quickly and
            conveniently, right in our dental office.
          </p>
          <p className={styles.secondaryDescription}>
            Your final result is a natural-looking restoration of superior
            strength that perfectly matches the surrounding teeth, making your
            crown, veneer, or filling virtually undetectable. The elimination of
            impressions, temporaries, and second appointments enhances the
            entire dental experience for our patients. As an added bonus, many
            of our patients love watching their new tooth come to life in the
            milling unit right in front of their eyes!
          </p>
        </div>
        {windowWidth > 730 &&
          (dentistry == "general" ? (
            <SideMenuCosmeticDentistry
              menuItem={handleMenuClick}
              menuItemName={menuItemName}
              dentistry={dentistry}
            />
          ) : (
            <SideMenuCosmeticProsthodontic
              menuItem={handleMenuClick}
              menuItemName={menuItemName}
              dentistry={dentistry}
            />
          ))}
      </div>
    </section>
  );
}
