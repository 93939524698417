import React, { useState, useEffect } from "react";

import styles from "../PediatricMenu/PediatricMenu.module.css";
import SideMenuPediatricMenu from "./SideMenuPediatricMenu";

export default function OralCare({ menuItem, menuItemName, dentistry }) {
  const pediatricProperty = {
    textColor: "#9086a5",
    spanColor: "#776c8c",
    backgroundColor: "#e6e4eb",
  };
  const styleProperties = pediatricProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            ADOLESCENCE & ORAL CARE
          </h1>

          <p className={styles.mainDescription}>
            There is evidence that demonstrates how periodontal disease may
            increase during adolescence due to lack of motivation to practice
            oral hygiene. Children who maintain good oral health habits up until
            the teen years are more likely to continue brushing and flossing
            than children who were not taught proper oral care.
          </p>
          <h4 className={styles.secondaryQuestion}>Advice For Parents</h4>
          <p className={styles.secondaryDescription}>
            Early diagnosis is important for successful treatment of periodontal
            diseases. Therefore, it is important that children receive a
            periodontal examination as part of their routine dental visits. Be
            aware that if your child has an advanced form of periodontal
            disease, this may be an early sign of systemic disease. A general
            medical evaluation should be considered for children who exhibit
            severe periodontitis, especially if it appears resistant to therapy.
          </p>
          <p className={styles.secondaryDescription}>
            An important step in the fight against periodontal disease is to
            establish good oral health habits with your child early. When your
            child is about a year old, you can begin using toothpaste when
            brushing their teeth. However, only use a pea-sized portion on the
            brush and press it into the bristles so your child won’t eat it.
            And, when the gaps between your child’s teeth close, it’s important
            to start flossing.
          </p>
          <p className={styles.secondaryDescription}>
            Serve as a good role model by practicing good oral health care
            habits yourself and schedule regular dental visits for family
            check-ups, periodontal evaluations, and cleanings.
          </p>
          <p className={styles.secondaryDescription}>
            Check your child’s mouth for the signs of periodontal disease,
            including bleeding gums, swollen and bright red gums, gums that are
            receding away from the teeth, and bad breath.
          </p>
          <p className={styles.secondaryDescription}>
            If your child currently has poor oral health habits, work with your
            child to change these now. It’s much easier to modify these habits
            in a child than in an adult. Since your child models behavior after
            you, it follows that you should serve as a positive role model in
            your oral hygiene habits. A healthy smile, good breath, and strong
            teeth all contribute to a young person’s sense of personal
            appearance, as well as confidence and self-esteem.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuPediatricMenu
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
