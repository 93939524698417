import React, { useState, useEffect } from "react";

import styles from "./Procedures.module.css";
import SideMenuProcedures from "./SideMenuProcedures";

export default function DImaging({ menuItem, menuItemName, dentistry }) {
  const oralsurgery = {
    textColor: "#d099d9",
    spanColor: "#644a68",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = oralsurgery;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            3D IMAGING
          </h1>
          <h4 className={styles.secondaryQuestion}>Cone Beam CT Scan</h4>
          <p className={styles.mainDescription}>
            The CBCT system gives oral and maxillofacial practice
            state-of-the-art technology to help Dentist diagnose potential
            issues more accurately and provide treatment with unprecedented
            confidence. Unlike a traditional spiral CT scanner, this 3D system
            utilizes <b>cone beam CT </b>technology and provides precise,
            crystal-clear digital images while minimizing your exposure to
            radiation.
          </p>
          <p className={styles.mainDescription}>
            New 3D system enables us to perform a wider range of diagnoses and
            treatments in our office, helping to reduce multiple visits. The
            CBCT scanner allows to choose the field of view, or scanning area,
            that best suits your specific treatment needs. This helps to limit
            your radiation exposure because we are focusing specifically on your
            area of concern.
          </p>

          <p className={styles.secondaryDescription}>
            The CBCT system brings the latest 3D technology to Premier Care of
            Sonoma County, providing unmatched visualization of anatomical
            detail which aids in treatment planning and helps us to better
            explain the particulars of your case, as well as address any
            questions you may have. Our Dentist use this innovative technology
            to quickly and easily share 3D images of the area of concern with
            referring doctor – allowing the doctors to collaborate on your care,
            improving your experience, and delivering a positive treatment
            outcome.
          </p>
        </div>

        {windowWidth > 730 && (
          <SideMenuProcedures
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
