import React, { useState, useEffect } from "react";

import styles from "../PediatricMenu/PediatricMenu.module.css";
import SideMenuOrthodontic from "./SideMenuOrthodontic";

export default function OrthodonticsMenu({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const pediatricProperty = {
    textColor: "#9086a5",
    spanColor: "#776c8c",
    backgroundColor: "#e6e4eb",
  };
  const styleProperties = pediatricProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            Orthodontics
          </h1>

          <p className={styles.mainDescription}>
            Orthodontics is a dental specialty focused on aligning your bite and
            straightening your teeth. You might need to see an orthodontist if
            you have crooked, overlapped, twisted or gapped teeth. Common
            orthodontic treatments include traditional braces, clear aligners
            and removable retainers.
          </p>

          <ul className={styles.items}>
            <li className={styles.item}>
              <a href="/pediatric/Orthodontics/RemovableAppliances">
                Removable Appliances
              </a>
            </li>
            <li className={styles.item}>
              <a href="/pediatric/Orthodontics/Bites">Types of Bites</a>
            </li>
            <li className={styles.item}>
              <a href="/pediatric/Orthodontics/ClassificationOfTeeth">
                Classification Of the Teeth
              </a>
            </li>
          </ul>
        </div>
        {windowWidth > 730 && (
          <SideMenuOrthodontic
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
