import React, { useEffect, useState } from "react";

import styles from "../../PeriodontalMenu/PeriodontalMenu.module.css";
import SideMenuImplantsMenu from "./SideMenuImplantsMenu";

export default function BoneGraftingImplants({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const periodontalProperty = {
    textColor: "#fc7444",
    spanColor: "#978ab1",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = periodontalProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            BONE GRAFTING FOR IMPLANTS
          </h1>
          <h4 className={styles.secondaryQuestion}>
            Do I have enough bone for dental implants?
          </h4>
          <p className={styles.mainDescription}>
            After tooth extraction, if the walls of the socket are very thick,
            they will usually fill naturally with bone in two to three months.
            However, when the walls of your socket are very thin (such as in
            your upper and lower front teeth), this type of healing will not be
            as predictable. In these situations, a <b>bone graft </b>is often
            placed at the time of tooth extraction to help your body fill in the
            empty socket with bone. This step will maintain the width and volume
            of bone you will need for implant placement several months later.
          </p>
          <p className={styles.secondaryDescription}>
            There may be inadequate bone for implant placement if your tooth was
            removed many years ago and your bony ridge is extremely thin. In
            this case, a bone graft can be placed next to the thin bone and
            allowed to heal for up to six months. After the graft has fused to
            your pre-existing bone, the ridge will be re-entered and the implant
            placed. Bone grafting is usually a relatively comfortable office
            procedure. Many different bone-grafting materials are available,
            including your own bone.
          </p>
          <p className={styles.secondaryDescription}>
            You may also need bone grafting if the sinus cavities in your upper
            jaw are very large, or very low, and extend into the tooth-bearing
            areas. This often occurs when teeth in the back of a person’s upper
            jaw have been removed many years before, and the amount of bone
            available for implant placement is limited. A “sinus grafting / lift
            procedure ” is then required. Most often, it is performed in the
            office with local anesthesia and perhaps sedation. During this
            procedure, the membrane that lines the sinus will be located and
            elevated. Bone will then be added to restore the bone height and
            ensure that dental implants of an adequate length can be placed.
            This procedure often can be performed at the time of implant
            placement.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuImplantsMenu
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
