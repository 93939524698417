// Home.js
import React, { useState } from "react";
import OralSurgeryHeader from "./OralSurgeryHeader";
import OralSurgeryHero from "./OralSurgeryHero";
import OralSurgerySchedule from "./OralSurgerySchedule";
import OralSurgeryLocation from "./OralSurgeryLocation";
import OralSurgeryScheduleCta from "./OralSurgeryScheduleCta";
import OralSurgeryFooter from "./OralSurgeryFooter";
import OralSurgeryServices from "./OralSurgeryServices";
import OralSurgeryHeroMenu from "./OralSurgeryHeroMenu";

import OralSurgeryHeader3 from "./OralSurgeryheader3";
import OralSurgeryServices3 from "./OralSurgeryServices3";

import BeforeAnesthesia from "../Instructions/BeforeAnesthesia";
import SurgicalInstructions from "../Instructions/SurgicalInstructions";
import AfterDentalImplants from "../Instructions/DentalImpants";
import WisdomToothRemoval from "../Instructions/WisdomToothRemoval";
import ToothExposure from "../Instructions/ToothExposure";
import AfterExtractions from "../Instructions/AfterExtractions";
import MultipleExtractions from "../Instructions/MultipleExtractions";

import DentalImplants from "../RestorativeProsthodontic/DentalImplants";
import Procedures from "./Procedures/Procedures";
import BoneGrafting from "./Procedures/BoneGrafting";
import WisdomTeeth from "./Procedures/WisdomTeeth";
import JawSurgery from "./Procedures/JawSurgery";
import FacialTrauma from "./Procedures/FacialTrauma";
import ProcToothExtractions from "./Procedures/ToothExtractions";
import PreProsthodontic from "../RestorativeProsthodontic/PreProsthodontic";
import DistractionOsteogenesis from "./Procedures/DistractionOsteogenesis";
import Oralpathology from "./Procedures/Oralpathology";
import TMJ from "./Procedures/TMJ";
import SleepApnea from "./Procedures/SleepApnea";
import CleftLipAndPalate from "./Procedures/CleftLipAndPalate";
import Protein from "../PeriodontalMenu/BONEMORPHOGENETICPROTEIN";

import Stemcells from "./Procedures/Stemcells";
import RichPlasma from "./Procedures/RichPlasma";
import RichFibrin from "./Procedures/RichFibrin";
import ImpactedTeeth from "./Procedures/ImpactedTeeth";
import DImaging from "./Procedures/3DImaging";
import ScheduleCTA from "../Home/ScheduleCta";
import Schedule from "../Home/Schedule";
import Footer from "../Home/Footer";
import ANESTHESIA from "./Procedures/ANESTHESIA";

const OralSurgeryHome = (cosmeticData) => {
  const [dataMenu, setDataMenu] = useState(cosmeticData);

  const handleMenuItem = (data) => {
    setDataMenu(data);
  };

  return (
    <>
      {/* <OralSurgeryHeader menuItem={handleMenuItem} /> */}
      <OralSurgeryHeader3 generalMenuItem={handleMenuItem} />
      {dataMenu.cosmeticData === "OralSurgeryHome" ? (
        <OralSurgeryHero />
      ) : (
        <OralSurgeryHeroMenu />
      )}
      {dataMenu.cosmeticData === "OralSurgeryHome" && <OralSurgeryServices3 />}
      {dataMenu.cosmeticData === "SurgicalInstructions" && (
        <SurgicalInstructions
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="oralsurgery"
        />
      )}

      {dataMenu.cosmeticData === "BeforeAnesthesia" && (
        <BeforeAnesthesia
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="oralsurgery"
        />
      )}
      {dataMenu.cosmeticData === "DentalImplantSurgery" && (
        <AfterDentalImplants
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="oralsurgery"
        />
      )}
      {dataMenu.cosmeticData === "WisdomToothRemoval" && (
        <WisdomToothRemoval
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="oralsurgery"
        />
      )}
      {dataMenu.cosmeticData === "ToothExposure" && (
        <ToothExposure
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="oralsurgery"
        />
      )}
      {dataMenu.cosmeticData === "AfterExtractions" && (
        <AfterExtractions
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="oralsurgery"
        />
      )}
      {dataMenu.cosmeticData === "MultipleExtractions" && (
        <MultipleExtractions
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="oralsurgery"
        />
      )}

      {dataMenu.cosmeticData === "DentalImplants" && (
        <DentalImplants
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="oralsurgery"
        />
      )}
      {dataMenu.cosmeticData === "Procedures" && (
        <Procedures
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="oralsurgery"
        />
      )}
      {dataMenu.cosmeticData === "BoneGrafting" && (
        <BoneGrafting
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="oralsurgery"
        />
      )}
      {dataMenu.cosmeticData === "WisdomTeeth" && (
        <WisdomTeeth
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="oralsurgery"
        />
      )}
      {dataMenu.cosmeticData === "JawSurgery" && (
        <JawSurgery
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="oralsurgery"
        />
      )}
      {dataMenu.cosmeticData === "FacialTrauma" && (
        <FacialTrauma
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="oralsurgery"
        />
      )}
      {dataMenu.cosmeticData === "ToothExtractions" && (
        <ProcToothExtractions
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="oralsurgery"
        />
      )}
      {dataMenu.cosmeticData === "Preprosthetic" && (
        <PreProsthodontic
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="oralsurgery"
        />
      )}
      {dataMenu.cosmeticData === "DistractionOsteogenesis" && (
        <DistractionOsteogenesis
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="oralsurgery"
        />
      )}
      {dataMenu.cosmeticData === "OralPathology" && (
        <Oralpathology
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="oralsurgery"
        />
      )}
      {dataMenu.cosmeticData === "TMJ" && (
        <TMJ
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="oralsurgery"
        />
      )}
      {dataMenu.cosmeticData === "SleepApnea" && (
        <SleepApnea
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="oralsurgery"
        />
      )}
      {dataMenu.cosmeticData === "CleftLipAndPalate" && (
        <CleftLipAndPalate
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="oralsurgery"
        />
      )}
      {dataMenu.cosmeticData === "Protein" && (
        <Protein
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="oralsurgery"
        />
      )}
      {dataMenu.cosmeticData === "StemCells" && (
        <Stemcells
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="oralsurgery"
        />
      )}
      {dataMenu.cosmeticData === "RichPlasma" && (
        <RichPlasma
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="oralsurgery"
        />
      )}
      {dataMenu.cosmeticData === "RichFibrin" && (
        <RichFibrin
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="oralsurgery"
        />
      )}
      {dataMenu.cosmeticData === "ImpactedTeeth" && (
        <ImpactedTeeth
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="oralsurgery"
        />
      )}
      {dataMenu.cosmeticData === "3DImaging" && (
        <DImaging
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="oralsurgery"
        />
      )}

      {dataMenu.cosmeticData === "ANESTHESIA" && (
        <ANESTHESIA
          menuItem={handleMenuItem}
          menuItemName={dataMenu.cosmeticData}
          dentistry="oralsurgery"
        />
      )}

      <Schedule dentistry={"oralSurgery"} subService={dataMenu.cosmeticData} />
      <OralSurgeryLocation />
      <ScheduleCTA
        dentistry={"oralSurgery"}
        subService={dataMenu.cosmeticData}
      />
      <Footer dentistry={"oralSurgery"} />
    </>
  );
};

export default OralSurgeryHome;
