import React, { useState, useEffect } from "react";

import styles from "./PeriodontalMenu.module.css";
import SideMenuPeriodontalMenu from "./SideMenuPeriodontalMenu";

export default function OralCancer({ menuItem, menuItemName, dentistry }) {
  const periodontalProperty = {
    textColor: "#fc7444",
    spanColor: "#978ab1",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = periodontalProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            ORAL CANCER EXAM
          </h1>

          <p className={styles.mainDescription}>
            According to the Indian Cancer Society, over 30,000 cases of oral
            cancer are diagnosed each year, with over 7000 of these cases
            resulting in the death of the patient. Fortunately, oral cancer can
            be diagnosed with an annual cancer exam provided by your Dentist. If
            caught early, oral cancer can be effectively treated.
          </p>

          <p className={styles.secondaryDescription}>
            Oral cancer is a pathologic process, which begins by producing no
            symptoms making it hard to recognize without an exam. There are many
            types of oral cancer, including teratoma, adenocarcinoma and
            melanoma. The most common form of oral cancer is malignant squamous
            cell carcinoma, which typically originates in the lip and mouth
            tissue. There are many other places in which oral cancers occur,
            including: the tongue, salivary glands, throat, gums, and face.
          </p>

          <h4 className={styles.secondaryQuestion}>
            What to Expect from an Oral Cancer Exam
          </h4>
          <p className={styles.secondaryDescription}>
            The <b>oral cancer examination </b>is completely painless. Dentist
            will look for abnormalities and feel the face, glands, and neck for
            unusual bumps. Lasers may be used to highlight pathologic changes,
            and can “look” below the surface for spots and lesions invisible to
            the naked eye. Some of the signs that will be investigated are red
            patches and sores. Red patches on the floor of the mouth, or the
            front of the tongue, and bleeding sores which fail to heal easier,
            can be indicative of cancerous changes. Leukoplakia is a hardened
            white or gray, slightly raised lesion that can appear inside the
            mouth, and may be cancerous. Signs of these will be examined as
            well. Finally, soreness, lumps or the general thickening of tissue
            anywhere in the throat or mouth can signal pathologic signs, and
            will be examined.
          </p>

          <p className={styles.secondaryDescription}>
            If abnormalities, lesions, lumps, or leukoplakia are apparent,
            Dentist will implement a treatment plan that is right for you.
            Treatment options vary according to the precise diagnosis, but may
            include: excision, radiation therapy, and chemotherapy.
          </p>
          <p className={styles.secondaryDescription}>
            It is also important to note that over 75% of oral cancers are
            linked with avoidable behaviors such as smoking, tobacco use, and
            excessive alcohol consumption. Your Dentist can provide you with
            literature and options about quitting dangerous behaviors such as
            tobacco use.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuPeriodontalMenu
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
