import React, { useEffect, useState } from "react";

import styles from "../../PeriodontalMenu/PeriodontalMenu.module.css";
import SideMenuImplantsMenu from "./SideMenuImplantsMenu";

export default function LowerUpperTeeth({ menuItem, menuItemName, dentistry }) {
  const periodontalProperty = {
    textColor: "#fc7444",
    spanColor: "#978ab1",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = periodontalProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            MISSING ALL UPPER OR LOWER TEETH
          </h1>
          <h4 className={styles.secondaryQuestion}>Missing All Lower Teeth</h4>
          <p className={styles.mainDescription}>
            Although many patients have no problem wearing an upper denture,
            some people find it difficult to wear and eat with lower dentures.
            Several <b>implant-supported replacement options </b>are available
            if you are missing all of your lower teeth.
          </p>
          <h4 className={styles.secondaryQuestion}>
            <b>Ball Attachment Denture</b>
          </h4>
          <p className={styles.mainDescription}>
            One option is to have two implants placed in your lower jaw and a
            denture made that snaps onto these implants. This option allows your
            lower denture to be more stable while chewing than without implants.
            However, there will still be movement of your lower denture, and
            sore spots will occur if any food particles, especially seeds, are
            caught under it. As with all removable replacement teeth, you still
            will need periodic appointments for denture adjustment.
          </p>
          <h4 className={styles.secondaryQuestion}>
            <b>Bar Attachment Denture</b>
          </h4>
          <p className={styles.mainDescription}>
            Another option involves placing four to six implants, depending on
            your jaw size or shape, into your lower jaw. After healing is
            complete, the implants are connected with a custom-made support bar.
            Your denture will be made with special internal retention clips that
            attach onto the support bar, enabling the denture to snap firmly
            into place. This is called an “overdenture.” The advantage of this
            option is that it is much more stable than the first option and
            allows very little denture movement. Your denture is still removable
            for easy cleaning and maintenance.
          </p>
          <h4 className={styles.secondaryQuestion}>
            <b>Screw Retained Denture</b>
          </h4>
          <p className={styles.mainDescription}>
            A third option involves placing five or more implants in your jaw
            and attaching a permanent denture. Your denture is held in place by
            screws or clasps that secure it to the support posts or bar. It
            doesn’t touch the gum tissue, which allows you to clean under the
            denture without removing it. This denture will replace all your
            missing lower teeth and will not be removed except at maintenance
            visits. Although cleaning under your denture without removing it is
            more time consuming and requires more dexterity, many patients who
            want a permanent denture prefer this option.
          </p>
          <h4 className={styles.secondaryQuestion}>
            <b>Individual Implants</b>
          </h4>
          <p className={styles.mainDescription}>
            The final option is to have all your teeth individually replaced so
            that they will appear to be growing out of your gum tissue and will
            most closely resemble the appearance of your natural teeth. This
            option usually requires eight or more implants. Separate abutments
            or support posts for each one of these implants will be made and
            crowns for each missing tooth will be placed. The teeth are often
            joined together for sinus grafting to replace bone height strength
            and support. Overall, this is the most costly option, because it
            requires the most implants and individual replacement tooth
            fabrication. Your replacement options may also be limited by the
            current size and shape of your jawbone.
          </p>
          <h4 className={styles.secondaryQuestion}>
            What if I’m missing all of my upper teeth?
          </h4>
          <p
            className={styles.secondaryDescription}
            style={{ marginBottom: "0.5rem" }}
          >
            A similar range of treatment options is also available for your
            upper jaw. However, because the bone is not as hard as that in the
            lower jaw, people often need more implants to support their new
            replacement teeth. Depending upon the number of implants to be
            placed, it may be possible to eliminate the need for covering the
            roof of your mouth with a complete denture. This option allows you
            to fully taste your food and gives you a better sense of its
            temperature. Your denture will feel more natural. You will still
            have a removable denture, which makes cleaning the support bar and
            denture much easier.
          </p>

          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              {/* <li>
                <b>Implant Retained Upper Denture: </b>Depending upon the number
                of implants to be placed, it may be possible to eliminate the
                need for covering the roof of your mouth with a complete
                denture. This option allows you to fully taste your food and
                gives you a better sense of its temperature. Your denture will
                feel more natural. You will still have a removable denture,
                which makes cleaning the support bar and denture much easier.
              </li> */}
              <li>
                <b>Individual Upper Implants: </b>If you want a restoration that
                is similar to your natural teeth and therefore not removable,
                you probably will need eight to ten individual implants placed.
                This is followed after healing by the placement of the abutments
                and new replacement crowns.
              </li>
            </ul>
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuImplantsMenu
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
