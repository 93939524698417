import React, { useState, useEffect } from "react";

import styles from "./Procedures.module.css";
import SideMenuProcedures from "./SideMenuProcedures";

export default function Navigation({ menuItem, menuItemName, dentistry }) {
  const endodonticProperty = {
    textColor: "#6fcfff",
    spanColor: "#64bae6",
    backgroundColor: "#e7f1fca0",
  };
  const styleProperties = endodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            THE DYNAMIC DENTAL NAVIGATION SYSTEM
          </h1>
          <h4 className={styles.secondaryQuestion}>
            The Next Generation of Endodontics
          </h4>
          <p className={styles.mainDescription}>
            In today’s fast-moving, competitive world, we are always on the
            lookout for the quickest, most convenient, and most accurate fix. At
            Premier Care of Sonoma County, we understand the importance of
            treating a tooth infection right away. Our highly skilled doctors
            are trained and prepared to get you back out into the world where
            you can wear your smile with confidence.
          </p>

          <p className={styles.secondaryDescription}>
            Root canal therapy is a very common endodontic treatment that saves
            a tooth when nerves are affected by decay or infection. Recent
            technological advances have improved the accuracy of root canal
            therapy and increased the overall satisfaction of our patients. The
            Dynamic Dental Navigation System, the newest and most advanced 3D
            navigational system originally used for implant placement, allows
            our doctors to access root canals with an unsurpassed level of
            precision and control. Adding this latest form of digital scanning
            software to our current capabilities has significantly enhanced the
            effectiveness of our root canal treatment.
          </p>
          <h4 className={styles.secondaryQuestion}>The Benefits</h4>
          <p className={styles.secondaryDescription}>
            Dynamic 3D Navigation provides a variety of benefits for our
            endodontists and patients. This revolutionary protocol:
          </p>
          <p className={styles.secondaryDescription}>
            <li>Saves time—single visit treatment.</li>
            <li>Improves accuracy.</li>
            <li>Provides same-day surgery options.</li>
            <li>Allows for complete treatment in one location.</li>
            <li>
              Reduces cost—multiple guides and visits are no longer necessary.
            </li>
            <li>Supplies easier access to posterior teeth</li>
          </p>
          <h4 className={styles.secondaryQuestion}>
            The Navigation System Process
          </h4>
          <p className={styles.secondaryDescription}>
            Dynamic 3D Navigation is an interactive protocol which aids our
            doctors in improving precision and access. Our doctors confidently
            create and implement a 3D treatment plan by accessing the Navigation
            System’s live, 360-degree view of drill position and oral anatomy.
            This cutting-edge technology assists in our achieving consistent and
            desirable results, as it provides our doctors with one dynamic focus
            point: the screen.
          </p>
          <p className={styles.secondaryDescription}>
            The computer navigation software provides an alternative to static
            drill guides (CAD/CAM). Working in real-time, rather than requiring
            hours of planning and fabricating before a patient’s appointment,
            the Navigation System consists of an overhead 3D camera that focuses
            on the endodontist’s handpiece and the patient’s jaw. The computer
            calculates the positions of the handpiece relative to the jaw, and
            the doctor focuses on the screen which displays a target-like
            graphic.
          </p>
          <p className={styles.secondaryDescription}>
            This new technology eliminates numerous difficulties encountered
            when using static drill guides, and proves most effective in more
            complex cases, such as calcified pulp chambers and canals. Dynamic
            Navigation also has the potential for assisting our doctors in cases
            where apical surgery is indicated. This will positively impact the
            way we carry out root canal treatment, by helping to locate very
            calcified canals with much smaller access while preserving more
            tooth structure. In a single visit, thanks to X-NAV Technologies,
            our endodontists can precisely and confidently drill through solid
            dentin roots, disinfecting and restoring teeth, one smile at a time.
          </p>
          <p className={styles.secondaryDescription}>
            Computer-guided endodontics is a new and exciting field of dental
            surgery, and the Navigation System is a tool that allows our doctors
            to see – via a computer screen – into your mouth as they operate.
            Dynamic 3D Navigation scans, navigates, and – similar to a GPS –
            directs our doctors to exact locations as they plan and perform
            surgery.
          </p>
          <p className={styles.secondaryDescription}>
            Dynamic 3D Navigation is simply a tool that requires the skill and
            experience of an expert endodontist. Combining the latest advances
            in technology with the expertise of our doctors, we have truly seen
            an increase in overall patient satisfaction and happy, healthy
            smiles.{" "}
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuProcedures
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
