import React, { useState, useEffect } from "react";

import styles from "./Procedures.module.css";
import SideMenuProcedures from "./SideMenuProcedures";

export default function ProcToothExtractions({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const oralsurgery = {
    textColor: "#d099d9",
    spanColor: "#644a68",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = oralsurgery;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            TOOTH EXTRACTIONS
          </h1>

          <p className={styles.mainDescription}>
            You and your Dentist may determine that you need a tooth extraction
            for any number of reasons. Some teeth are extracted because they are
            severely decayed; others may have advanced periodontal disease, or
            have broken in a way that cannot be repaired. Other teeth may need
            removal because they are poorly positioned in the mouth (such as
            impacted teeth), or in preparation for orthodontic treatment.
          </p>

          <p className={styles.secondaryDescription}>
            The <b>removal of a single tooth </b>can lead to problems related to
            your chewing ability, problems with your jaw joint, and shifting
            teeth, which can have a major impact on your dental health.
          </p>

          <p className={styles.secondaryDescription}>
            To avoid these complications, in most cases, our Dentist will
            discuss alternatives to extractions as well as replacement of the
            extracted tooth.
          </p>
          <h4 className={styles.secondaryQuestion}>
            The Tooth Extraction Process
          </h4>
          <p className={styles.secondaryDescription}>
            At the time of extraction the doctor will need to numb your tooth,
            jaw bone and gums that surround the area with a local anesthetic.
          </p>
          <p className={styles.secondaryDescription}>
            During the extraction process you will feel a lot of pressure. This
            is from the process of firmly rocking the tooth in order to widen
            the socket for removal.
          </p>
          <p className={styles.secondaryDescription}>
            You feel the pressure without pain as the anesthetic has numbed the
            nerves stopping the transference of pain, yet the nerves that
            transmit pressure are not profoundly affected.
          </p>

          <p className={styles.secondaryDescription}>
            If you do feel pain at any time during the extraction please let us
            know right away.
          </p>

          <h4 className={styles.secondaryQuestion}>Sectioning a Tooth</h4>
          <p className={styles.secondaryDescription}>
            Some teeth require sectioning. This is a very common procedure done
            when a tooth is so firmly anchored in its socket or the root is
            curved and the socket can’t expand enough to remove it. The doctor
            simply cuts the tooth into sections then removes each section one at
            a time.
          </p>
          <h4 className={styles.secondaryQuestion}>After Tooth Extraction</h4>
          <p className={styles.secondaryDescription}>
            For details on home care after tooth extraction, see the page “After
            Extractions” under “Surgical Instructions”.
          </p>
        </div>

        {windowWidth > 730 && (
          <SideMenuProcedures
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
