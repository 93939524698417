import React, { useState, useEffect } from "react";

import styles from "./Procedures.module.css";
import SideMenuProcedures from "./SideMenuProcedures";

export default function SleepApnea({ menuItem, menuItemName, dentistry }) {
  const oralsurgery = {
    textColor: "#d099d9",
    spanColor: "#644a68",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = oralsurgery;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            SLEEP APNEA
          </h1>
          <p className={styles.mainDescription}>
            People with <b>obstructive sleep apnea </b>(OSA) have disrupted
            sleep and low blood oxygen levels. When obstructive sleep apnea
            occurs, the tongue is sucked against the back of the throat. This
            blocks the upper airway and airflow stops. When the oxygen level in
            the brain becomes low enough, the sleeper partially awakens, the
            obstruction in the throat clears, and the flow of air starts again,
            usually with a loud gasp.
          </p>
          <p className={styles.mainDescription}>
            <b>
              Repeated cycles of decreased oxygenation lead to very serious
              cardiovascular problems. Additionally, these individuals suffer
              from excessive daytime sleepiness, depression, and loss of
              concentration.{" "}
            </b>
          </p>
          <p className={styles.secondaryDescription}>
            Some patients have obstructions that are less severe called Upper
            Airway Resistance Syndrome (UARS). In either case, the individuals
            suffer many of the same symptoms.
          </p>
          <h4 className={styles.secondaryQuestion}>Sleep Apnea Treatment</h4>
          <p className={styles.secondaryDescription}>
            The first step in <b>treatment for sleep apnea </b>resides in
            recognition of the symptoms and seeking appropriate consultation.
            Oral and maxillofacial surgeons offer consultation and treatment
            options.
          </p>
          <p className={styles.secondaryDescription}>
            In addition to a detailed history, the doctors will assess the
            anatomic relationships in the maxillofacial region. With
            cephalometic (skull x-ray) analysis, the doctors can ascertain the
            level of obstruction. Sometimes a naso-pharyngeal exam is done with
            a flexible fiber-optic camera. To confirm the amount of
            cardiovascular compromise and decreased oxygenation levels, a sleep
            study may be recommended to monitor an individual overnight.
          </p>
          <h4 className={styles.secondaryQuestion}>
            Treatment Options for Sleep Apnea
          </h4>
          <p className={styles.secondaryDescription}>
            There are several treatment options available. An initial treatment
            may consist of using a <b>nasal CPAP machine </b>that delivers
            pressurized oxygen through a nasal mask to limit obstruction at
            night. One of the surgical options is an
            uvulo-palato-pharyngo-plasty (UPPP), which is performed in the back
            of the soft palate and throat. A similar procedure is sometimes done
            with the assistance of a laser and is called a laser assisted
            uvulo-palato-plasty (LAUPP). In other cases, a radio-frequency probe
            is utilized to tighten the soft palate. These procedures are usually
            performed under light IV sedation in the office.
          </p>
          <p className={styles.secondaryDescription}>
            In more complex cases, the bones of the upper and lower jaw may be
            repositioned to increase the size of the airway (orthognathic
            surgery). This procedure is done in the hospital under general
            anesthesia and requires a one to two day overnight stay in the
            hospital.{" "}
          </p>
          <p className={styles.secondaryDescription}>
            OSA is a very serious condition that needs careful attention and
            treatment. Most major medical plans offer coverage for diagnosis and
            treatment.
          </p>
        </div>

        {windowWidth > 730 && (
          <SideMenuProcedures
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
