import React, { useState, useEffect } from "react";

import styles from "./Procedures.module.css";
import SideMenuProcedures from "./SideMenuProcedures";

export default function JawSurgery({ menuItem, menuItemName, dentistry }) {
  const oralsurgery = {
    textColor: "#d099d9",
    spanColor: "#644a68",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = oralsurgery;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            ORTHOGNATHIC SURGERY
          </h1>
          <h4 className={styles.secondaryQuestion}>Corrective Jaw Surgery</h4>
          <p className={styles.mainDescription}>
            <b>Orthognathic surgery </b>is needed when the top and bottom jaws
            don’t meet correctly and/or teeth don’t adequately fit within the
            jaw. Teeth are straightened with orthodontics, and{" "}
            <b>corrective jaw surgery </b>repositions a misaligned jaw. This not
            only improves facial appearance, but also ensures that teeth meet
            correctly and function properly.
          </p>

          <h4 className={styles.secondaryQuestion}>
            Who needs orthognathic surgery?
          </h4>
          <p className={styles.secondaryDescription}>
            People who can benefit from orthognathic surgery include those with
            an improper bite, or jaws that are positioned incorrectly. Jaw
            growth is a gradual process and in some instances, the upper and
            lower jaws may grow at different rates. The result can be a host of
            problems that may affect chewing function, speech, or long-term oral
            health and appearance. Injury to the jaw and birth defects can also
            affect jaw alignment. Orthodontics alone can correct bite problems
            when only the teeth are involved. Orthognathic surgery may be
            required for the jaws when repositioning is necessary.
          </p>
          <h4
            className={styles.secondaryQuestion}
            style={{ marginBottom: "0.5rem" }}
          >
            <b> Difficulty in the following areas should be evaluated: </b>
          </h4>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>Difficulty in chewing, biting, or swallowing</li>
              <li>Speech problems</li>
              <li>Chronic jaw or TMJ pain</li>
              <li>Open bite</li>
              <li>Protruding jaw</li>
              <li>Breathing problems</li>
            </ul>
          </p>
          <p className={styles.secondaryDescription}>
            Any of these symptoms can exist at birth, be acquired after birth as
            a result of hereditary or environmental influences, or as a result
            of trauma to the face. Before any treatment begins, a consultation
            will be held to perform a complete examination with x-rays. During
            the pre-treatment consultation process, feel free to ask any
            questions that you have regarding your treatment. When you are fully
            informed about the aspects of your care, you and your dental team
            can make the decision to proceed with treatment together.
          </p>
          <h4 className={styles.secondaryQuestion}>
            Technology & Orthognathic Surgery
          </h4>
          <p className={styles.secondaryDescription}>
            Oral & Maxillofacial Surgeon use modern computer-aided techniques
            and three-dimensional models to show you exactly how your surgery
            will be approached. Using comprehensive facial x-rays and computer
            video imaging, we can show you how your bite will be improved and
            even give you an idea of how you’ll look after surgery. This helps
            you understand the surgical process and the extent of the proposed
            treatment.
          </p>
          <p className={styles.secondaryDescription}>
            If you are a case of <b>corrective jaw surgery, </b>Dentist will
            work closely with oral surgeon and orthodontist during your
            treatment. The actual surgery can move your teeth and jaws into a
            new position that results in a more attractive, functional, and
            healthy dental-facial relationship.
          </p>
          <h4
            className={styles.secondaryQuestion}
            style={{ marginBottom: "0.5rem" }}
          >
            What to Expect
          </h4>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>
                Corrective jaw surgery is a significant intervention requiring
                many months of preparation.
              </li>
              <li>
                The surgery is performed in the hospital and can last between
                one to four hours.
              </li>
              <li>Hospital stays of one to three days are normal.</li>
              <li>
                Braces are maintained during surgery and may need to be removed
                six to twelve months after surgery.
              </li>
              <li>
                The greatest impact of orthognathic surgery is the recovery
                phase.
              </li>
              <li>
                Patients typically may be off work/school from two weeks to one
                month after surgery.
              </li>

              <li>
                Return to normal chewing function may take 2 months and full
                function may take one year.
              </li>
              <li>
                Our goal is to ensure you are well taken care of during and
                after surgery.
              </li>
              <li>
                Weekly appointments are required for up to two months after
                surgery.
              </li>
            </ul>
          </p>
        </div>

        {windowWidth > 730 && (
          <SideMenuProcedures
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
