import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./Components/Home/Home";
import GeneralHome from "./Components/GeneralDentistry/GeneralHome";
import ProsthodonticHome from "./Components/ProsthodonticDentistry/ProsthodonticHome";

import OrthodonticHome from "./Components/OrthodonticDentistry/OrthodonticHome";
import PatientHome from "./Components/PatientInformation/PatientHome";

import PediatricHome from "./Components/PediatricDentistry/PediatricHome";
import EndodonticHome from "./Components/EndodonticDentistry/EndodonticHome";
import PeriodontalHome from "./Components/Periodontal/PeriodontalHome";
import OralSurgeryHome from "./Components/OralSurgeryDentistry/OralSurgeryHome";
import AboutHome from "./Components/About/AboutHome";
import ContactHome from "./Components/Contact/ContactHome";
import RegistrationForm from "./Components/Home/RegistrationForm";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/general"
          element={<GeneralHome cosmeticData={"GeneralHome"} />}
        />
        <Route
          path="/general/CosmeticDentistry"
          element={<GeneralHome cosmeticData={"CosmeticDentistry"} />}
        />
        <Route
          path="/general/CosmeticDentistry/DentalBonding"
          element={<GeneralHome cosmeticData={"DentalBonding"} />}
        />
        <Route
          path="/general/CosmeticDentistry/PorcelainVeins"
          element={<GeneralHome cosmeticData={"PorcelainVeins"} />}
        />
        <Route
          path="/general/CosmeticDentistry/InlaysOnlays"
          element={<GeneralHome cosmeticData={"InlaysOnlays"} />}
        />
        <Route
          path="/general/CosmeticDentistry/TeethWhitening"
          element={<GeneralHome cosmeticData={"TeethWhitening"} />}
        />
        <Route
          path="/general/CosmeticDentistry/E4DDentist"
          element={<GeneralHome cosmeticData={"E4DDentist"} />}
        />
        <Route
          path="/general/CosmeticDentistry/ClearBraces"
          element={<GeneralHome cosmeticData={"ClearBraces"} />}
        />
        <Route
          path="/general/CosmeticDentistry/CEREC"
          element={<GeneralHome cosmeticData={"CEREC"} />}
        />
        <Route
          path="/general/Restorative"
          element={<GeneralHome cosmeticData={"Restorative"} />}
        />
        <Route
          path="/general/Restorative/DentalFillings"
          element={<GeneralHome cosmeticData={"DentalFillings"} />}
        />
        <Route
          path="/general/Restorative/DentalImplants"
          element={<GeneralHome cosmeticData={"DentalImplants"} />}
        />
        <Route
          path="/general/Restorative/DentalBridges"
          element={<GeneralHome cosmeticData={"DentalBridges"} />}
        />
        <Route
          path="/general/Restorative/DentalCrowns"
          element={<GeneralHome cosmeticData={"DentalCrowns"} />}
        />
        <Route
          path="/general/Restorative/NonSurgicalRootCanal"
          element={<GeneralHome cosmeticData={"NonSurgicalRootCanal"} />}
        />
        <Route
          path="/general/Restorative/ToothExtractions"
          element={<GeneralHome cosmeticData={"ToothExtractions"} />}
        />
        <Route
          path="/general/Restorative/Dentures"
          element={<GeneralHome cosmeticData={"Dentures"} />}
        />
        <Route
          path="/general/Restorative/CEREC"
          element={<GeneralHome cosmeticData={"CEREC"} />}
        />
        {/* Preventative care - General Dentistry Sevice */}
        <Route
          path="/general/PreventativeCare"
          element={<GeneralHome cosmeticData={"PreventativeCare"} />}
        />
        <Route
          path="/general/PreventativeCare/TeethCleaningDentalCheckups"
          element={<GeneralHome cosmeticData={"TeethCleaningDentalCheckups"} />}
        />
        <Route
          path="/general/PreventativeCare/OralHygiene"
          element={<GeneralHome cosmeticData={"OralHygiene"} />}
        />
        <Route
          path="/general/PreventativeCare/ChildDentistry"
          element={<GeneralHome cosmeticData={"ChildDentistry"} />}
        />
        <Route
          path="/general/PreventativeCare/DentalSealants"
          element={<GeneralHome cosmeticData={"DentalSealants"} />}
        />
        <Route
          path="/general/PreventativeCare/Arestin"
          element={<GeneralHome cosmeticData={"Arestin"} />}
        />
        <Route
          path="/general/PreventativeCare/GumDiseaseTreatment"
          element={<GeneralHome cosmeticData={"GumDiseaseTreatment"} />}
        />
        <Route
          path="/general/General"
          element={<GeneralHome cosmeticData={"General"} />}
        />
        {/* ProsthodonticDentistry */}
        <Route
          path="/prosthodontic"
          element={<ProsthodonticHome cosmeticData={"ProsthodonticHome"} />}
        />
        <Route
          path="/prosthodontic/Restorative"
          element={<ProsthodonticHome cosmeticData={"Restorative"} />}
        />
        {/* Restorative */}
        <Route
          path="/prosthodontic/Restorative/DentalImplants"
          element={<ProsthodonticHome cosmeticData={"DentalImplants"} />}
        />
        <Route
          path="/prosthodontic/Restorative/DentalCrowns"
          element={<ProsthodonticHome cosmeticData={"DentalCrowns"} />}
        />
        <Route
          path="/prosthodontic/Restorative/ToothColoredFillings"
          element={<ProsthodonticHome cosmeticData={"ToothColoredFillings"} />}
        />
        <Route
          path="/prosthodontic/Restorative/FullMouthReconstruction"
          element={
            <ProsthodonticHome cosmeticData={"FullMouthReconstruction"} />
          }
        />

        <Route
          path="/prosthodontic/Restorative/DentalBrigdes"
          element={<ProsthodonticHome cosmeticData={"DentalBrigdes"} />}
        />
        <Route
          path="/prosthodontic/Restorative/DentalSealants"
          element={<ProsthodonticHome cosmeticData={"DentalSealants"} />}
        />
        <Route
          path="/prosthodontic/Restorative/Dentures"
          element={<ProsthodonticHome cosmeticData={"Dentures"} />}
        />
        <Route
          path="/prosthodontic/Restorative/PreProstheticSurgery"
          element={<ProsthodonticHome cosmeticData={"PreProstheticSurgery"} />}
        />
        <Route
          path="/general/Restorative/ToothExtractions"
          element={<GeneralHome cosmeticData={"ToothExtractions"} />}
        />
        <Route
          path="/general/Restorative/BruxismTreatment"
          element={<GeneralHome cosmeticData={"BruxismTreatment"} />}
        />
        <Route
          path="/prosthodontic/Restorative/CleftLipAndPalate"
          element={<ProsthodonticHome cosmeticData={"CleftLipAndPalate"} />}
        />
        <Route
          path="/prosthodontic/Cosmetic"
          element={<ProsthodonticHome cosmeticData={"Cosmetic"} />}
        />
        <Route
          path="/prosthodontic/Cosmetic/PorcelainVeneers"
          element={<ProsthodonticHome cosmeticData={"PorcelainVeneers"} />}
        />
        <Route
          path="/prosthodontic/Cosmetic/DentalBonding"
          element={<ProsthodonticHome cosmeticData={"DentalBonding"} />}
        />
        <Route
          path="/prosthodontic/Cosmetic/InlaysAndOnlays"
          element={<ProsthodonticHome cosmeticData={"InlaysAndOnlays"} />}
        />
        <Route
          path="/prosthodontic/Cosmetic/TeethWhitening"
          element={<ProsthodonticHome cosmeticData={"TeethWhitening"} />}
        />
        <Route
          path="/prosthodontic/Cosmetic/E4DDentist"
          element={<ProsthodonticHome cosmeticData={"E4DDentist"} />}
        />

        <Route
          path="/prosthodontic/Cosmetic/ClearBraces"
          element={<ProsthodonticHome cosmeticData={"ClearBraces"} />}
        />
        <Route
          path="/prosthodontic/Cosmetic/CEREC"
          element={<ProsthodonticHome cosmeticData={"CEREC"} />}
        />
        {/* General Prosthodontic Service */}
        <Route
          path="/prosthodontic/General"
          element={<ProsthodonticHome cosmeticData={"General"} />}
        />
        <Route
          path="/general/General/OralHygiene"
          element={<GeneralHome cosmeticData={"OralHygiene"} />}
        />
        <Route
          path="/general/General/TeethCleaning"
          element={<GeneralHome cosmeticData={"TeethCleaning"} />}
        />
        <Route
          path="/general/General/PeriodontalDisease"
          element={<GeneralHome cosmeticData={"PeriodontalDisease"} />}
        />
        <Route
          path="/general/General/WomenAndPeriodontalHealth"
          element={<GeneralHome cosmeticData={"WomenAndPeriodontalHealth"} />}
        />
        <Route
          path="/general/General/DentalSpecialties"
          element={<GeneralHome cosmeticData={"DentalSpecialties"} />}
        />
        <Route
          path="/orthodontic"
          element={<OrthodonticHome cosmeticData={"OrthodonticHome"} />}
        />
        {/* Braces */}
        <Route
          path="/orthodontic/Braces"
          element={<OrthodonticHome cosmeticData={"Braces"} />}
        />
        {/*Dropdown Braces */}
        <Route
          path="/orthodontic/Braces/OrthoBraces"
          element={<OrthodonticHome cosmeticData={"OrthoBraces"} />}
        />
        {/*Dropdown Retainers */}
        <Route
          path="/orthodontic/Braces/Retainers"
          element={<OrthodonticHome cosmeticData={"Retainers"} />}
        />
        {/*Dropdown Appliances */}
        <Route
          path="/orthodontic/Braces/Appliances"
          element={<OrthodonticHome cosmeticData={"Appliances"} />}
        />
        {/*Dropdown InOvationSystemBraces */}
        <Route
          path="/orthodontic/Braces/InOvationSystemBraces"
          element={<OrthodonticHome cosmeticData={"InOvationSystemBraces"} />}
        />
        {/*Clear Braces */}
        <Route
          path="/orthodontic/ClearBraces"
          element={<OrthodonticHome cosmeticData={"ClearBraces"} />}
        />
        {/*Dropdown Clear Aligners */}
        <Route
          path="/orthodontic/ClearBraces/ClearAligners"
          element={<OrthodonticHome cosmeticData={"ClearAligners"} />}
        />
        {/*Dropdown Clear Ceramic Braces */}
        <Route
          path="/orthodontic/ClearBraces/ClearCeramicBraces"
          element={<OrthodonticHome cosmeticData={"ClearCeramicBraces"} />}
        />
        {/*Dropdown Clear Aligners for teen */}
        <Route
          path="/orthodontic/ClearBraces/ClearAlignersForTeens"
          element={<OrthodonticHome cosmeticData={"ClearAlignersForTeens"} />}
        />
        {/*Living With Braces */}
        <Route
          path="/orthodontic/LivingWithBraces"
          element={<OrthodonticHome cosmeticData={"LivingWithBraces"} />}
        />
        {/*Dropdown Elastics */}
        <Route
          path="/orthodontic/LivingWithBraces/Elastics"
          element={<OrthodonticHome cosmeticData={"Elastics"} />}
        />
        {/*Dropdown Eating With Braces */}
        <Route
          path="/orthodontic/LivingWithBraces/EatingWithBraces"
          element={<OrthodonticHome cosmeticData={"EatingWithBraces"} />}
        />
        {/*Dropdown Pain And Soreness */}
        <Route
          path="/orthodontic/LivingWithBraces/PainAndSoreness"
          element={<OrthodonticHome cosmeticData={"PainAndSoreness"} />}
        />
        {/*Dropdown Brushing And Flossing */}
        <Route
          path="/orthodontic/LivingWithBraces/BrushingAndFlossing"
          element={<OrthodonticHome cosmeticData={"BrushingAndFlossing"} />}
        />
        {/*Dropdown Retainers And Appliances */}
        <Route
          path="/orthodontic/LivingWithBraces/RetainersAndAppliances"
          element={<OrthodonticHome cosmeticData={"RetainersAndAppliances"} />}
        />
        {/*Invisalign */}
        <Route
          path="/orthodontic/Invisalign"
          element={<OrthodonticHome cosmeticData={"Invisalign"} />}
        />
        {/*Dropdown Invisalign Info */}
        <Route
          path="/orthodontic/Invisalign/InvisalignInfo"
          element={<OrthodonticHome cosmeticData={"InvisalignInfo"} />}
        />
        {/*Dropdown Advantages Of Invisalign*/}
        <Route
          path="/orthodontic/Invisalign/AdvantagesOfInvisalign"
          element={<OrthodonticHome cosmeticData={"AdvantagesOfInvisalign"} />}
        />
        {/*Dropdown Invisalign Cost */}
        <Route
          path="/orthodontic/Invisalign/InvisalignCost"
          element={<OrthodonticHome cosmeticData={"InvisalignCost"} />}
        />
        {/*Dropdown Invisalign Teen */}
        <Route
          path="/orthodontic/Invisalign/InvisalignTeen"
          element={<OrthodonticHome cosmeticData={"InvisalignTeen"} />}
        />
        {/*Dropdown Invisalign Videos */}
        <Route
          path="/orthodontic/Invisalign/InvisalignVideos"
          element={<OrthodonticHome cosmeticData={"InvisalignVideos"} />}
        />
        {/* Pediatric */}
        <Route
          path="/pediatric"
          element={<PediatricHome cosmeticData={"PeidatricHome"} />}
        />
        {/* Pediatric Dentistry */}
        <Route
          path="/pediatric/pediatricdentistry"
          element={<PediatricHome cosmeticData={"pediatricdentistry"} />}
        />
        {/* ToothBrushing */}
        <Route
          path="/pediatric/pediatricdentistry/ToothBrushing"
          element={<PediatricHome cosmeticData={"ToothBrushing"} />}
        />
        {/* DigitSucking */}
        <Route
          path="/pediatric/pediatricdentistry/DigitSucking"
          element={<PediatricHome cosmeticData={"DigitSucking"} />}
        />
        {/* ToothEruption */}
        <Route
          path="/pediatric/pediatricdentistry/ToothEruption"
          element={<PediatricHome cosmeticData={"ToothEruption"} />}
        />
        {/* ToothDecay */}
        <Route
          path="/pediatric/pediatricdentistry/ToothDecay"
          element={<PediatricHome cosmeticData={"ToothDecay"} />}
        />
        {/* CavityPrevention */}
        <Route
          path="/pediatric/pediatricdentistry/CavityPrevention"
          element={<PediatricHome cosmeticData={"CavityPrevention"} />}
        />
        {/* GumDisease */}
        <Route
          path="/pediatric/pediatricdentistry/GumDisease"
          element={<PediatricHome cosmeticData={"GumDisease"} />}
        />
        {/* AdolescenceFillings & OralCare */}
        <Route
          path="/pediatric/pediatricdentistry/OralCare"
          element={<PediatricHome cosmeticData={"OralCare"} />}
        />
        {/* ToothFillings */}
        <Route
          path="/pediatric/pediatricdentistry/ToothFillings"
          element={<PediatricHome cosmeticData={"ToothFillings"} />}
        />
        {/* AmalgamFillings */}
        <Route
          path="/pediatric/pediatricdentistry/AmalgamFillings"
          element={<PediatricHome cosmeticData={"AmalgamFillings"} />}
        />
        {/* DentalSealants */}
        <Route
          path="/pediatric/pediatricdentistry/DentalSealants"
          element={<PediatricHome cosmeticData={"DentalSealants"} />}
        />
        {/* Tooth Extractions */}
        <Route
          path="/pediatric/pediatricdentistry/Extractions"
          element={<PediatricHome cosmeticData={"Extractions"} />}
        />
        {/* Bonding */}
        <Route
          path="/pediatric/pediatricdentistry/Bonding"
          element={<PediatricHome cosmeticData={"Bonding"} />}
        />
        {/* InlaysOnlays */}
        <Route
          path="/pediatric/pediatricdentistry/InlaysOnlays"
          element={<PediatricHome cosmeticData={"InlaysOnlays"} />}
        />
        <Route
          path="/pediatric/Orthodontics"
          element={<PediatricHome cosmeticData={"Orthodontics"} />}
        />
        {/* RemovableAppliances */}
        <Route
          path="/pediatric/Orthodontics/RemovableAppliances"
          element={<PediatricHome cosmeticData={"RemovableAppliances"} />}
        />
        {/* Bites */}
        <Route
          path="/pediatric/Orthodontics/Bites"
          element={<PediatricHome cosmeticData={"Bites"} />}
        />
        {/* ClassificationOfTeeth */}
        <Route
          path="/pediatric/Orthodontics/ClassificationOfTeeth"
          element={<PediatricHome cosmeticData={"ClassificationOfTeeth"} />}
        />

        {/*Endodontic */}
        <Route
          path="/endodontic"
          element={<EndodonticHome cosmeticData={"EndodonticHome"} />}
        />
        {/*Endodontic Instructions*/}
        <Route
          path="/endodontic/Instructions"
          element={<EndodonticHome cosmeticData={"Instructions"} />}
        />
        {/*Dropdown Instructions Before Endotontic Treatment */}
        <Route
          path="/endodontic/Instructions/BeforeEndotonticTreatment"
          element={
            <EndodonticHome cosmeticData={"BeforeEndotonticTreatment"} />
          }
        />
        {/* Dropdown Instructions General Instructions */}
        <Route
          path="/endodontic/Instructions/GeneralInstructions"
          element={<EndodonticHome cosmeticData={"GeneralInstructions"} />}
        />
        {/* Dropdown Instructions After Treatment FAQ */}
        <Route
          path="/endodontic/Instructions/AfterTreatmentFAQ"
          element={<EndodonticHome cosmeticData={"AfterTreatmentFAQ"} />}
        />
        {/*Endodontic Procedures*/}
        <Route
          path="/endodontic/Procedures"
          element={<EndodonticHome cosmeticData={"Procedures"} />}
        />
        {/* Dropdown Procedures GentleWave UltraCleaning */}
        <Route
          path="/endodontic/Procedures/GentleWaveUltraCleaning"
          element={<EndodonticHome cosmeticData={"GentleWaveUltraCleaning"} />}
        />
        <Route
          path="/endodontic/Procedures/EndodonticRetreatment"
          element={<EndodonticHome cosmeticData={"EndodonticRetreatment"} />}
        />
        <Route
          path="/endodontic/Procedures/Apicoectomy"
          element={<EndodonticHome cosmeticData={"Apicoectomy"} />}
        />
        <Route
          path="/endodontic/Procedures/CrackedTeeth"
          element={<EndodonticHome cosmeticData={"CrackedTeeth"} />}
        />
        <Route
          path="/endodontic/Procedures/RootCanal"
          element={<EndodonticHome cosmeticData={"RootCanal"} />}
        />
        <Route
          path="/endodontic/Procedures/TraumaticInjuries"
          element={<EndodonticHome cosmeticData={"TraumaticInjuries"} />}
        />
        <Route
          path="/endodontic/Procedures/Navigation"
          element={<EndodonticHome cosmeticData={"Navigation"} />}
        />
        <Route
          path="/periodontal"
          element={<PeriodontalHome cosmeticData={"periodontal"} />}
        />
        <Route
          path="/periodontal/periodontalServices"
          element={<PeriodontalHome cosmeticData={"periodontalServices"} />}
        />
        <Route
          path="/periodontal/TreatmentMethods"
          element={<PeriodontalHome cosmeticData={"TreatmentMethods"} />}
        />
        <Route
          path="/periodontal/PeriodontalMaintenance"
          element={<PeriodontalHome cosmeticData={"PeriodontalMaintenance"} />}
        />
        <Route
          path="/periodontal/TeethCleaning"
          element={<PeriodontalHome cosmeticData={"TeethCleaning"} />}
        />
        <Route
          path="/periodontal/periodontalServices/Perioscopic"
          element={<PeriodontalHome cosmeticData={"Perioscopic"} />}
        />
        <Route
          path="/periodontal/periodontalServices/Bruxism"
          element={<PeriodontalHome cosmeticData={"Bruxism"} />}
        />
        <Route
          path="/periodontal/periodontalServices/Planing"
          element={<PeriodontalHome cosmeticData={"Planing"} />}
        />
        <Route
          path="/periodontal/periodontalServices/Crown"
          element={<PeriodontalHome cosmeticData={"Crown"} />}
        />
        <Route
          path="/periodontal/periodontalServices/LaserTherapy"
          element={<PeriodontalHome cosmeticData={"LaserTherapy"} />}
        />
        <Route
          path="/periodontal/periodontalServices/BiteAdjustment"
          element={<PeriodontalHome cosmeticData={"BiteAdjustment"} />}
        />
        <Route
          path="/periodontal/periodontalServices/ReductionSurgery"
          element={<PeriodontalHome cosmeticData={"ReductionSurgery"} />}
        />
        <Route
          path="/periodontal/periodontalServices/Grafting"
          element={<PeriodontalHome cosmeticData={"Grafting"} />}
        />
        <Route
          path="/periodontal/periodontalServices/TissueRegeneration"
          element={<PeriodontalHome cosmeticData={"TissueRegeneration"} />}
        />
        <Route
          path="/periodontal/periodontalServices/Protein"
          element={<PeriodontalHome cosmeticData={"Protein"} />}
        />
        <Route
          path="/periodontal/periodontalServices/PeriodontalSurgery"
          element={<PeriodontalHome cosmeticData={"PeriodontalSurgery"} />}
        />

        <Route
          path="/periodontal/periodontalServices/OralCancer"
          element={<PeriodontalHome cosmeticData={"OralCancer"} />}
        />
        <Route
          path="/periodontal/periodontalServices/Stemcell"
          element={<PeriodontalHome cosmeticData={"Stemcell"} />}
        />
        <Route
          path="/periodontal/periodontalServices/ANESTHESIA"
          element={<PeriodontalHome cosmeticData={"ANESTHESIA"} />}
        />
        {/* Dental Implants */}
        <Route
          path="/periodontal/DentalImplants"
          element={<PeriodontalHome cosmeticData={"DentalImplants"} />}
        />
        {/* ReplacingMissingTeeth */}
        <Route
          path="/periodontal/DentalImplants/MissingTeeth"
          element={<PeriodontalHome cosmeticData={"MissingTeeth"} />}
        />
        {/* Overview of ImplantPlacement */}
        <Route
          path="/periodontal/DentalImplants/ImplantPlacement"
          element={<PeriodontalHome cosmeticData={"ImplantPlacement"} />}
        />
        {/* Missing All LowerUpperTeeth */}
        <Route
          path="/periodontal/DentalImplants/LowerUpperTeeth"
          element={<PeriodontalHome cosmeticData={"LowerUpperTeeth"} />}
        />
        {/* BoneGrafting of Implants */}
        <Route
          path="/periodontal/DentalImplants/BoneGrafting"
          element={<PeriodontalHome cosmeticData={"BoneGrafting"} />}
        />
        {/* IMPLANT SUPPORTED OVERDENTURE */}
        <Route
          path="/periodontal/DentalImplants/OverDenture"
          element={<PeriodontalHome cosmeticData={"OverDenture"} />}
        />
        {/* TeethInAnHour */}
        <Route
          path="/periodontal/DentalImplants/TeethInAnHour"
          element={<PeriodontalHome cosmeticData={"TeethInAnHour"} />}
        />
        {/* CeramicDentalImplants */}
        <Route
          path="/periodontal/DentalImplants/CeramicDentalImplants"
          element={<PeriodontalHome cosmeticData={"CeramicDentalImplants"} />}
        />
        {/* AfterImplantPlacement */}
        <Route
          path="/periodontal/DentalImplants/AfterImplantPlacement"
          element={<PeriodontalHome cosmeticData={"AfterImplantPlacement"} />}
        />
        {/* CostOfDentalImplants */}
        <Route
          path="/periodontal/DentalImplants/ImplantsCost"
          element={<PeriodontalHome cosmeticData={"ImplantsCost"} />}
        />
        {/* PerioBoneGrafting */}
        <Route
          path="/periodontal/DentalImplants/PerioBoneGrafting"
          element={<PeriodontalHome cosmeticData={"PerioBoneGrafting"} />}
        />
        {/* Oral Surgery */}
        <Route
          path="/oralsurgery"
          element={<OralSurgeryHome cosmeticData={"OralSurgeryHome"} />}
        />
        <Route
          path="/oralsurgery/SurgicalInstructions"
          element={<OralSurgeryHome cosmeticData={"SurgicalInstructions"} />}
        />
        {/* BeforeAnesthesia */}
        <Route
          path="/oralsurgery/SurgicalInstructions/BeforeAnesthesia"
          element={<OralSurgeryHome cosmeticData={"BeforeAnesthesia"} />}
        />
        {/* After Dental ImplantSurgery */}
        <Route
          path="/oralsurgery/SurgicalInstructions/DentalImplantSurgery"
          element={<OralSurgeryHome cosmeticData={"DentalImplantSurgery"} />}
        />
        {/* AFTER WISDOM TOOTH REMOVAL */}
        <Route
          path="/oralsurgery/SurgicalInstructions/WisdomToothRemoval"
          element={<OralSurgeryHome cosmeticData={"WisdomToothRemoval"} />}
        />
        {/* After Impacted ToothExposure */}
        <Route
          path="/oralsurgery/SurgicalInstructions/ToothExposure"
          element={<OralSurgeryHome cosmeticData={"ToothExposure"} />}
        />
        {/* After Extractions */}
        <Route
          path="/oralsurgery/SurgicalInstructions/AfterExtractions"
          element={<OralSurgeryHome cosmeticData={"AfterExtractions"} />}
        />
        {/* Multiple Extractions */}
        <Route
          path="/oralsurgery/SurgicalInstructions/MultipleExtractions"
          element={<OralSurgeryHome cosmeticData={"MultipleExtractions"} />}
        />
        {/* Procedures */}
        <Route
          path="/oralsurgery/Procedures"
          element={<OralSurgeryHome cosmeticData={"Procedures"} />}
        />
        {/* Dental Implants */}
        <Route
          path="/oralsurgery/Procedures/DentalImplants"
          element={<OralSurgeryHome cosmeticData={"DentalImplants"} />}
        />
        {/* Bone Grafting */}
        <Route
          path="/oralsurgery/Procedures/BoneGrafting"
          element={<OralSurgeryHome cosmeticData={"BoneGrafting"} />}
        />
        {/* WisdomTeeth */}
        <Route
          path="/oralsurgery/Procedures/WisdomTeeth"
          element={<OralSurgeryHome cosmeticData={"WisdomTeeth"} />}
        />
        {/* Jaw Surgery */}
        <Route
          path="/oralsurgery/Procedures/JawSurgery"
          element={<OralSurgeryHome cosmeticData={"JawSurgery"} />}
        />
        {/* Facial Trauma */}
        <Route
          path="/oralsurgery/Procedures/FacialTrauma"
          element={<OralSurgeryHome cosmeticData={"FacialTrauma"} />}
        />
        {/* Tooth Extractions */}
        <Route
          path="/oralsurgery/Procedures/ToothExtractions"
          element={<OralSurgeryHome cosmeticData={"ToothExtractions"} />}
        />
        {/* PreProstheticSurgery */}
        <Route
          path="/oralsurgery/Procedures/Preprosthetic"
          element={<OralSurgeryHome cosmeticData={"Preprosthetic"} />}
        />
        {/* DistractionOsteogenesis */}
        <Route
          path="/oralsurgery/Procedures/DistractionOsteogenesis"
          element={<OralSurgeryHome cosmeticData={"DistractionOsteogenesis"} />}
        />
        {/* Oralpathology */}
        <Route
          path="/oralsurgery/Procedures/OralPathology"
          element={<OralSurgeryHome cosmeticData={"OralPathology"} />}
        />
        {/* TMJ */}
        <Route
          path="/oralsurgery/Procedures/TMJ"
          element={<OralSurgeryHome cosmeticData={"TMJ"} />}
        />
        {/* SleepApnea */}
        <Route
          path="/oralsurgery/Procedures/SleepApnea"
          element={<OralSurgeryHome cosmeticData={"SleepApnea"} />}
        />
        {/* CleftLipAndPalate */}
        <Route
          path="/oralsurgery/Procedures/CleftLipAndPalate"
          element={<OralSurgeryHome cosmeticData={"CleftLipAndPalate"} />}
        />
        {/* Bone morphogenic protein */}
        <Route
          path="/oralsurgery/Procedures/Protein"
          element={<OralSurgeryHome cosmeticData={"Protein"} />}
        />
        {/* StemCells */}
        <Route
          path="/oralsurgery/Procedures/StemCells"
          element={<OralSurgeryHome cosmeticData={"StemCells"} />}
        />
        {/* RichPlasma */}
        <Route
          path="/oralsurgery/Procedures/RichPlasma"
          element={<OralSurgeryHome cosmeticData={"RichPlasma"} />}
        />
        {/* RichFibrin */}
        <Route
          path="/oralsurgery/Procedures/RichFibrin"
          element={<OralSurgeryHome cosmeticData={"RichFibrin"} />}
        />
        {/* ImpactedTeeth */}
        <Route
          path="/oralsurgery/Procedures/ImpactedTeeth"
          element={<OralSurgeryHome cosmeticData={"ImpactedTeeth"} />}
        />
        {/* 3DImaging */}
        <Route
          path="/oralsurgery/Procedures/3DImaging"
          element={<OralSurgeryHome cosmeticData={"3DImaging"} />}
        />
        {/* ANESTHESIA */}
        <Route
          path="/oralsurgery/Procedures/ANESTHESIA"
          element={<OralSurgeryHome cosmeticData={"ANESTHESIA"} />}
        />
        <Route
          path="/patient"
          element={<PatientHome cosmeticData={"PatientHome"} />}
        />
        <Route
          path="/patient/FirstVisit"
          element={<PatientHome cosmeticData={"FirstVisit"} />}
        />
        <Route
          path="/patient/NewPatients"
          element={<PatientHome cosmeticData={"NewPatients"} />}
        />
        <Route
          path="/patient/Scheduling"
          element={<PatientHome cosmeticData={"Scheduling"} />}
        />
        <Route
          path="/patient/PatientRegistration"
          element={<PatientHome cosmeticData={"PatientRegistration"} />}
        />
        <Route path="/about" element={<AboutHome />} />
        <Route path="/contact" element={<ContactHome />} />

        <Route
          path="/appointment/:dentistry/:subService"
          element={<RegistrationForm />}
        />
      </Routes>
    </Router>
  );
}

export default App;
