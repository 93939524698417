import React, { useState, useEffect } from "react";

import styles from "../CosmeticDentistry/DentalBonding.module.css";
import SideMenuGeneralProsthodonticDentistry from "./SideMenuGeneralProsthodonticDentistry";

export default function WomenAndPeriodontalHealth({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            WOMEN AND PERIODONTAL HEALTH
          </h1>
          <p className={styles.mainDescription}>
            Throughout a woman’s life, hormonal changes affect tissue throughout
            the body. Fluctuations in levels occur during puberty, pregnancy,
            and menopause. At these times, the chance of{" "}
            <b>periodontal disease</b> may increase, requiring special care of
            your oral health.
          </p>
          <h4 className={styles.secondaryQuestion}>Puberty</h4>
          <p className={styles.mainDescription}>
            During puberty, there is increased production of sex hormones. These
            higher levels increase gum sensitivity and lead to greater
            irritations from plaque and food particles. The gums can become
            swollen, turn red, and feel tender.
          </p>

          <h4 className={styles.secondaryQuestion}>Menstruation</h4>
          <p className={styles.secondaryDescription}>
            Similar symptoms occasionally appear several days before
            menstruation. There can be bleeding of the gums, bright red swelling
            between the teeth and gum, or sores on the inside of the cheek. The
            symptoms clear up once the period has started. As the amount of sex
            hormones decrease, so do these problems.
          </p>
          <p className={styles.secondaryDescription}>
            When you are done cleaning the outside surfaces of all your teeth,
            follow the same directions while cleaning the inside of the back
            teeth.
          </p>

          <h4 className={styles.secondaryQuestion}>Pregnancy</h4>
          <p className={styles.secondaryDescription}>
            Your gums and teeth are also affected during pregnancy. Between the
            second and eighth month, your gums may also swell, bleed, and become
            red or tender. Large lumps may appear as a reaction to local
            irritants. However, these growths are generally painless and not
            cancerous. They may require professional removal, but usually
            disappear after pregnancy.
          </p>
          <p className={styles.secondaryDescription}>
            Periodontal health should be part of your prenatal care. Any
            infections during pregnancy, including periodontal infections, can
            place a baby’s health at risk.
          </p>
          <p className={styles.secondaryDescription}>
            The best way to prevent periodontic infections is to begin with
            healthy gums and continue to maintain your oral health with proper
            home care and careful periodontic monitoring.
          </p>

          <h4 className={styles.secondaryQuestion}>Oral Contraceptives</h4>
          <p className={styles.secondaryDescription}>
            Swelling, bleeding, and tenderness of the gums may also occur when
            you are taking oral contraceptives, which are synthetic hormones.
          </p>

          <h4 className={styles.secondaryQuestion}>Menopause</h4>
          <p className={styles.secondaryDescription}>
            Changes in the look and feel of your mouth may occur if you are
            menopausal or post-menopausal. They include feeling pain and burning
            in your gum tissue and salty, peppery, or sour tastes.
          </p>
          <p className={styles.secondaryDescription}>
            Careful <b>oral hygiene</b> at home and{" "}
            <b>professional cleaning </b>
            may relieve these symptoms. There are also saliva substitutes to
            treat the effects of dry mouth.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuGeneralProsthodonticDentistry
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
