import React, { useEffect, useState } from "react";

import styles from "./PeriodontalMenu.module.css";
import SideMenuPeriodontalMenu from "./SideMenuPeriodontalMenu";

export default function TeethCleaning({ menuItem, menuItemName, dentistry }) {
  const periodontalProperty = {
    textColor: "#fc7444",
    spanColor: "#978ab1",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = periodontalProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            PROFESSIONAL TEETH CLEANING
          </h1>
          <h4 className={styles.secondaryQuestion}>
            Dental Prophylaxis / Cleaning
          </h4>
          <p className={styles.mainDescription}>
            A <b>dental prophylaxis / cleaning</b> is a cleaning treatment
            performed to thoroughly clean the teeth and gums. Prophylaxis /
            Cleaning is an important dental treatment for stopping the
            progression of gingivitis and periodontal disease.
          </p>

          <p
            className={styles.secondaryDescription}
            style={{ marginBottom: "0.5rem" }}
          >
            <b>Professional teeth cleaning</b> is an effective procedure in
            keeping the oral cavity in proper health and halting the progression
            of gum disease. <b>The benefits include:</b>
          </p>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>
                Plaque removal. Tartar (also referred to as calculus) and plaque
                buildup, both above and below the gum line, can result in
                serious periodontal problems. Unfortunately, even with a proper
                home brushing and flossing routine, it can be impossible to
                remove all debris, bacteria and deposits from gum pockets. The
                experienced eye of a dentist or hygienist using specialized
                dental equipment is necessary to catch potentially damaging
                buildup.
              </li>
              <li>
                A healthier looking smile. Stained and yellowed teeth can
                dramatically decrease the esthetics of a smile. Prophylaxis /
                Cleaning is an effective treatment in ridding the teeth of these
                unsightly stains.
              </li>
              <li>
                Fresher breath. Bad breath (or halitosis) is generally
                indicative of advancing periodontal disease. A combination of
                rotting food particles (possibly below the gum line) cause gum
                infection, results in bad breath. The routine removal of plaque,
                calculus and bacteria at our facility can noticeably improve
                halitosis and reduce infection.
              </li>
            </ul>
          </p>
          <p className={styles.secondaryDescription}>
            Dental prophylaxis / cleaning can be performed at our office or by
            your general dentist. It may be beneficial to have your regular
            cleanings done by your Dentist, who is more familiar with the full
            scope of your periodontal disease. We recommend that prophylaxis /
            cleaning be performed twice annually as a preventative measure, but
            should be completed every 3-4 months for periodontitis sufferers. It
            should be noted that gum disease cannot be completely reversed, but
            prophylaxis / cleaning is one of the tools we use to effectively
            halt its progression.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuPeriodontalMenu
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
