import React, { useState, useEffect } from "react";

import styles from "./Procedures.module.css";
import SideMenuProcedures from "./SideMenuProcedures";
import SideMenuPeriodontalMenu from "../../PeriodontalMenu/SideMenuPeriodontalMenu";

export default function ANESTHESIA({ menuItem, menuItemName, dentistry }) {
  const oralsurgery = {
    textColor: "#d099d9",
    spanColor: "#644a68",
    backgroundColor: "#f3f2f5",
  };
  const periodontalProperty = {
    textColor: "#fc7444",
    spanColor: "#978ab1",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties =
    dentistry == "periodontal" ? periodontalProperty : oralsurgery;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            ANESTHESIA
          </h1>

          <p className={styles.mainDescription}>
            Several methods of <b>anesthesia</b> are available. The method of
            anesthesia that is chosen for or by a patient depends upon the
            nature of the surgical procedure and the patient’s level of
            apprehension.
          </p>
          {/* <p className={styles.mainDescription}>
            To administer general anesthesia in the office, a dentist must have
            completed at least three months of hospital based anesthesia
            training. Qualified applicants will then undergo an in office
            evaluation by a state dental board appointed examiner. The examiner
            observes an actual surgical procedure during which general
            anesthesia is administered to the patient. The examiner also
            inspects all monitoring devices and emergency equipment and tests
            the doctor and the surgical staff on anesthesia related emergencies.
            If the examiner reports successful completion of the evaluation
            process, the state dental board will issue the doctor a license to
            perform general anesthesia. The license is renewable every two years
            if the doctor maintains the required amount of continuing education
            units related to anesthesia.
          </p> */}

          <p className={styles.secondaryDescription}>
            Again, when it comes to anesthesia, our first priority is the
            patient’s comfort and safety. If you have any concerns regarding the
            type of anesthesia that will be administered during your oral
            surgery procedure, please do not hesitate to discuss your concerns
            with your doctor at the time of your consultation.
          </p>

          {/* <h4 className={styles.secondaryQuestion}>
            Intravenous Sedation (“Twilight Sedation”)
          </h4>
          <p className={styles.mainDescription}>
            Our office offers our patients the option of{" "}
            <b>Intravenous Sedation</b> or Dental Intravenous Anesthesia or to
            some it is referred to as “Twilight Sedation” for their dental
            treatment. Intravenous Sedation or “twilight sleep” helps you to be
            comfortable and calm when undergoing dental procedures. Your
            treatment can be completed under intravenous sedation. Intravenous
            sedation or “IV sedation” (twilight sedation) is designed to better
            enable you to undergo your dental procedures while you are very
            relaxed; it will enable you to tolerate as well as not remember
            those procedures that may be very uncomfortable for you. IV sedation
            will essentially help alleviate the anxiety associated with your
            treatment. You may not always be asleep but you will be comfortable,
            calm and relaxed, drifting in and out of sleep – a “twilight sleep”.
          </p>
          <p className={styles.mainDescription}>
            If you choose the option of intravenous sedation your IV
            sedation/anesthesia is administered and monitored by the doctor
            therefore eliminating the costly expense of having your treatment
            carried out in an operating room or same day surgical facility.
          </p>

          <h4 className={styles.secondaryQuestion}>
            How is the IV sedation administered?
          </h4>
          <p className={styles.mainDescription}>
            A thin needle will be introduced into a vein in your arm or hand.
            The needle will be attached to an intravenous tube through which
            medication will be given to help you relax and feel comfortable. At
            times a patient’s vein may not be maintainable, in these situations
            the medications will be administered and the needle retrieved – both
            scenarios will achieve the same desired level of conscious sedation.
            Once again some patients may be asleep while others will slip in and
            out of sleep. Some patients with medical conditions and/or on
            specific drug regimens may only be lightly sedated and may not sleep
            at all.
          </p>
          <p className={styles.mainDescription}>
            The goal of IV sedation is to use as little medication as possible
            to get the treatment completed. It is very safe, much safer than
            oral sedation. With IV sedation a constant “drip” is maintained via
            the intravenous tube. At any time an antidote can be administered to
            reverse the effects of the medications if necessary. Along with IV
            sedation there are also other different “levels” of sedation
            available to you in our office. There is nitrous oxide analgesia.
          </p> */}

          <h4 className={styles.secondaryQuestion}>
            Nitrous Oxide (Laughing Gas)
          </h4>
          <p className={styles.mainDescription}>
            <b>Nitrous Oxide</b> is a sweet smelling, non irritating, colorless
            gas which you can breathe. Nitrous Oxide has been the primary means
            of sedation in dentistry for many years. Nitrous oxide is safe; the
            patient receives 50-70% oxygen with no less than 30% nitrous oxide.
            Patients are able to breathe on their own and remain in control of
            all bodily functions. The patient may experience mild amnesia and
            may fall asleep not remembering all of what happened during their
            appointment.
          </p>
          <h4 className={styles.secondaryQuestion}>
            There are many advantages to using Nitrous Oxide
          </h4>

          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>
                The depth of sedation can be altered at any time to increase or
                decrease sedation.
              </li>
              <li>There is no after effect such as a “hangover”.</li>
              <li>
                Inhalation sedation is safe with no side effects on your heart
                and lungs, etc.
              </li>
              <li>
                Inhalation sedation is very effective in minimizing gagging.
              </li>
              <li>
                It works rapidly as it reaches the brain within 20 seconds. In
                as few as 2-3 minutes its relaxation and pain killing properties
                develop.
              </li>
            </ul>
          </p>
        </div>

        {windowWidth > 730 &&
          (dentistry == "periodontal" ? (
            <SideMenuPeriodontalMenu
              menuItem={handleMenuClick}
              menuItemName={menuItemName}
              dentistry={dentistry}
            />
          ) : (
            <SideMenuProcedures
              menuItem={handleMenuClick}
              menuItemName={menuItemName}
              dentistry={dentistry}
            />
          ))}
      </div>
    </section>
  );
}
