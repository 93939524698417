// Home.js
import React, { useState } from "react";
import RootCanal from "../Procedures/RootCanal";
import EndodonticFooter from "./EndodonticFooter";
import EndodonticHeader from "./EndodonticHeader";
import EndodonticHero from "./EndodonticHero";
import EndodonticHeroMenu from "./EndodonticHeroMenu";
import EndodonticHow from "./EndodonticHow";
import EndodonticLocation from "./EndodonticLocation";
import EndodonticSchedule from "./EndodonticSchedule";
import EndodonticScheduleCTA from "./EndodonticScheduleCta";
import EndodonticServices3 from "./EndodonticServices3";

import EndodonticHeader3 from "./Endodonticheader3";
import ScheduleCTA from "../Home/ScheduleCta";
import EndodonticTreatment from "./Instructions/EndodonticTreatment";
import GeneralInstructions from "./Instructions/GeneralInstructions";
import TreatmentFAQ from "./Instructions/TreatmentFAQ";
import UltraCleaning from "../Procedures/UltraCleaning";
import Procedures from "../Procedures/Procedures";
import Instructions from "./Instructions/Instructions";
import Schedule from "../Home/Schedule";
import Footer from "../Home/Footer";
import CrackedTeeth from "../Procedures/CrackedTeeth";
import EndodonticRetreatment from "../Procedures/EndodonticRetreatment";

import TraumaticInjuries from "../Procedures/TraumaticInjuries";
import Navigation from "../Procedures/Navigation";
import APICOECTOMY from "../Procedures/APICOETOMY";

const EndodonticHome = (cosmeticData) => {
  const [cosmeticDataMenu, setCosmeticMenu] = useState(cosmeticData);

  const handleMenuItem = (data) => {
    setCosmeticMenu(data);
  };

  return (
    <>
      {/* <EndodonticHeader menuItem={handleMenuItem} /> */}
      <EndodonticHeader3 generalMenuItem={handleMenuItem} />
      {cosmeticDataMenu.cosmeticData === "EndodonticHome" ? (
        <EndodonticHero />
      ) : (
        <EndodonticHeroMenu />
      )}
      {cosmeticDataMenu.cosmeticData === "EndodonticHome" && (
        <EndodonticServices3 />
      )}
      {cosmeticDataMenu.cosmeticData === "RootCanal" && (
        <RootCanal
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="endodontic"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "Instructions" && (
        <Instructions
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="endodontic"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "BeforeEndotonticTreatment" && (
        <EndodonticTreatment
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="endodontic"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "GeneralInstructions" && (
        <GeneralInstructions
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="endodontic"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "AfterTreatmentFAQ" && (
        <TreatmentFAQ
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="endodontic"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "Procedures" && (
        <Procedures
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="endodontic"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "GentleWaveUltraCleaning" && (
        <UltraCleaning
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="endodontic"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "EndodonticRetreatment" && (
        <EndodonticRetreatment
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="endodontic"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "CrackedTeeth" && (
        <CrackedTeeth
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="endodontic"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "Apicoectomy" && (
        <APICOECTOMY
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="endodontic"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "TraumaticInjuries" && (
        <TraumaticInjuries
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="endodontic"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "Navigation" && (
        <Navigation
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="endodontic"
        />
      )}
      {/* {cosmeticDataMenu.cosmeticData === "UltraCleaning" && (
        <UltraCleaning
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="endodontic"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "Retreatment" && (
        <Retreatment
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="endodontic"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "Apicoectomy" && (
        <APICOECTOMY
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="endodontic"
        />
      )}
      
      {cosmeticDataMenu.cosmeticData === "TraumaticInjuries" && (
        <TraumaticInjuries
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="endodontic"
        />
      )}

      {cosmeticDataMenu.cosmeticData === "Navigation" && (
        <Navigation
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="endodontic"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "EndodonticTreatment" && (
        <EndodonticTreatment
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="endodontic"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "GeneralInstructions" && (
        <GeneralInstructions
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="endodontic"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "TreatmentFAQ" && (
        <TreatmentFAQ
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="endodontic"
        />
      )} */}

      {/* <EndodonticHow /> */}
      <Schedule dentistry={"endontic"} />
      <EndodonticLocation />
      <ScheduleCTA
        dentistry={"endontic"}
        subService={cosmeticDataMenu.cosmeticData}
      />

      <Footer dentistry={"endontic"} />
    </>
  );
};

export default EndodonticHome;
