import React from "react";

import styles from "./SideMenuRestorativeProsthodonticDentistry.module.css";
import { IonIcon } from "@ionic/react";
import { caretForwardOutline } from "ionicons/icons";
export default function SideMenuRestorativeProsthodonticDentistry({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const generalProperty = {
    backgroundColor: "#ffa07d",
  };
  const prosthodonticProperty = {
    backgroundColor: "#b6d2d7",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;

  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  return (
    <div
      className={styles.menuSection}
      style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <h2 className={styles.secondaryHeading}>Restorative Dentistry</h2>
      <ul className={styles.main_nav_list}>
        <li className={styles.service}>
          {menuItemName === "DentalImplants" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/prosthodontic/Restorative/DentalImplants"
          >
            <p className={styles.serviceHeader}>Dental Implants</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "DentalCrowns" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/prosthodontic/Restorative/DentalCrowns"
          >
            <p className={styles.serviceHeader}>Dental Crowns</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "ToothColoredFillings" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/prosthodontic/Restorative/ToothColoredFillings"
          >
            <p className={styles.serviceHeader}>Tooth Colored Fillings</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "FullMouthReconstruction" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/prosthodontic/Restorative/FullMouthReconstruction"
          >
            <p className={styles.serviceHeader}>Full Mouth Rehabilitation</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "DentalBrigdes" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/prosthodontic/Restorative/DentalBrigdes"
          >
            <p className={styles.serviceHeader}>Dental Bridges</p>
          </a>
        </li>
        {/* <li className={styles.service}>
          {menuItemName === "DentalSealants" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/prosthodontic/Restorative/DentalSealants"
          >
            <p className={styles.serviceHeader}>Dental Sealants</p>
          </a>
        </li> */}
        <li className={styles.service}>
          {menuItemName === "Dentures" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/prosthodontic/Restorative/Dentures"
          >
            <p className={styles.serviceHeader}>Dentures</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "PreProstheticSurgery" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/prosthodontic/Restorative/PreProstheticSurgery"
          >
            <p className={styles.serviceHeader}>Pre-Prosthetic Surgery</p>
          </a>
        </li>

        <li className={styles.service}>
          {menuItemName === "CleftLipAndPalate" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/prosthodontic/Restorative/CleftLipAndPalate"
          >
            <p className={styles.serviceHeader}>Cleft Lip and Palate</p>
          </a>
        </li>
      </ul>
    </div>
  );
}
