import React, { useState, useEffect } from "react";

import styles from "./RestorativeMenu.module.css";
import SideMenuRestorativeProsthodonticDentistry from "./SideMenuRestorativeProsthodonticDentistry";

export default function ToothColoredFillings({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            TOOTH FILLINGS
          </h1>
          <p className={styles.mainDescription}>
            Replacing silver fillings with tooth-colored composite materials is
            done by Dentist for both health and aesthetic reasons. To
            ensure optimal care for our patients, we are a mercury-free
            practice. You may find that you have silver/mercury fillings in your
            mouth, leftover from dental work done in the past. In addition to
            the less-desirable appearance of silver/mercury fillings, they also
            present structural problems, resulting in weaker teeth. By contrast,
            porcelain inlays and tooth colored restorations (onlays) are
            unnoticeable and actually add strength to weakened teeth. These
            restorations look great and are very strong thanks to new bonding
            technologies and the advanced skills of your prosthodontist.
          </p>
          <h4 className={styles.secondaryQuestion}>
            Disadvantages of Silver Fillings:
          </h4>
          <p className={styles.mainDescription}>
            Silver fillings have many drawbacks. The edges of the silver filling
            can wear down, become weak or break. This results in the tooth not
            being protected and lets cavities get started once again. With age,
            the metal of a silver filling expands, contracts, and can split.
          </p>
          <p className={styles.mainDescription}>
            Silver fillings contain 50% mercury. They can corrode, leak and
            cause stains on your teeth and gums.
          </p>
          <p className={styles.mainDescription}>
            <b>
              Fortunately, silver fillings can safely be replaced with
              tooth-colored restorations.
            </b>
          </p>
          <h4 className={styles.secondaryQuestion}>
            Advantages of Tooth-Colored Restorations:
          </h4>
          <p className={styles.secondaryDescription}>
            There are many advantages to tooth-colored restorations. Resin
            onlays are bonded to the teeth creating a tight, superior fit to the
            natural tooth. Such restorations can be used in instances where much
            of the tooth structure has been lost. The tooth remains intact and
            stronger.
          </p>
          <p className={styles.secondaryDescription}>
            Since the resin used in tooth-colored restorations contains
            fluoride, it can help prevent decay. The resin wears like natural
            teeth and does not require placement at the gum line, which is
            healthier for your gums!
          </p>
          <p className={styles.mainDescription}>
            <b>The result is a beautiful smile!</b>
          </p>
          <h4 className={styles.secondaryQuestion}>
            Replacing Silver Fillings with a Tooth Colored Restoration
          </h4>
          <p className={styles.secondaryDescription}>
            You can have your silver fillings replaced with tooth-colored
            restorations (onlays). This process requires two appointments.
          </p>
          <h4 className={styles.secondaryQuestion}>Your First Appointment:</h4>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>
                The old filling is removed along with any additional decay.
              </li>
              <li>
                An impression is made of your teeth. A model of your teeth is
                made and sent to the lab.
              </li>
              <li>A temporary onlay is placed on the tooth.</li>
            </ul>
          </p>
          <p className={styles.secondaryDescription}>
            <b> At the Lab:</b>A resin is carefully placed into the model of
            your teeth. It is then designed to look natural.
          </p>
          <h4 className={styles.secondaryQuestion}>Your Second Appointment:</h4>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>The temporary onlay is removed.</li>
              <li>
                A conditioning gel is placed on your tooth to prepare it for the
                new onlay.
              </li>
              <li>
                Bonding cement is placed on the tooth and a high intensity light
                bonds the resin to the tooth.
              </li>
              <li>The tooth is then polished.</li>
            </ul>
          </p>
          <p className={styles.secondaryDescription}>
            <b>
              {" "}
              Your teeth are restored to a natural look and feel, they are
              stronger and the tooth is protected!
            </b>
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuRestorativeProsthodonticDentistry
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
