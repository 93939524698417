import React, { useState, useRef, useEffect } from "react";

import { useInView } from "react-intersection-observer";

import ProsthodonitcDropDownHeader from "./ProsthodonticDropDownHeader";
import styles from "./ProsthodonticHeader3.module.css";

import { useAppContext } from "../../AppContext";
import DropDownHeader from "../Home/DropDownHeader";

export default function ProsthodonticHeader3({ generalMenuItem }) {
  const [ref, inView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  const { setDisableAnimations, setIsScrolling } = useAppContext();
  const [displayList, setDisplay] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const handleMenuItem = (data) => {
    setDisplay("none");
    generalMenuItem(data);
  };

  const handleHover = () => {
    setDisplay("flex");
    setIsHovered(true);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleScrollToSchedule = () => {
    setDisableAnimations(true);
    setIsScrolling(true);
    const scheduleSection = document.getElementById("schedule-section");
    if (scheduleSection) {
      scheduleSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section className={styles.header}>
      <div
        className={`${styles.headerContainer} ${inView ? styles.animate : ""}`}
        ref={ref}
      >
        <div className={styles.logoSection}>
          <a href="/">
            <h3>Dental Planet</h3>
          </a>
        </div>
        {windowWidth <= 730 ? (
          <DropDownHeader dentistry="prosthodontic" />
        ) : (
          <nav className={styles.navBar}>
            <ul className={styles.navList}>
              <li className={styles.navItem}>
                <div className={styles.navBlock}>Services</div>
                <ul className={styles.dropDown}>
                  <li className={styles.dropDownItem}>
                    <a href="/general" className={styles.dropDownLink}>
                      General
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/prosthodontic" className={styles.dropDownLink}>
                      Prosthodontic
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/orthodontic" className={styles.dropDownLink}>
                      Orthodontic
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/pediatric" className={styles.dropDownLink}>
                      Pediatric
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/endodontic" className={styles.dropDownLink}>
                      Endodontic
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/periodontal" className={styles.dropDownLink}>
                      Periodontal
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/oralsurgery" className={styles.dropDownLink}>
                      Oral Surgery
                    </a>
                  </li>
                </ul>
              </li>

              <li className={styles.navItem}>
                <div className={styles.navBlock}>
                  <a
                    className={styles.navBlock}
                    href="/prosthodontic/Restorative"
                  >
                    Restorative
                  </a>
                </div>
                <ul className={styles.dropDown}>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/prosthodontic/Restorative/DentalImplants"
                    >
                      Dental Implants
                    </a>
                  </li>

                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/prosthodontic/Restorative/DentalCrowns"
                    >
                      Dental Crowns
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/prosthodontic/Restorative/ToothColoredFillings"
                    >
                      Tooth Colored Fillings
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      href="/prosthodontic/Restorative/FullMouthReconstruction"
                      className={styles.dropDownLink}
                    >
                      Full Mouth Rehabilitation
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      href="/prosthodontic/Restorative/DentalBrigdes"
                      className={styles.dropDownLink}
                    >
                      Dental Bridges
                    </a>
                  </li>
                  {/* <li className={styles.dropDownItem}>
                    <a
                      href="/prosthodontic/Restorative/DentalSealants"
                      className={styles.dropDownLink}
                    >
                      Dental Sealants
                    </a>
                  </li> */}
                  <li className={styles.dropDownItem}>
                    <a
                      href="/prosthodontic/Restorative/Dentures"
                      className={styles.dropDownLink}
                    >
                      Dentures
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      href="/prosthodontic/Restorative/PreProstheticSurgery"
                      className={styles.dropDownLink}
                    >
                      Pre-Prosthetic Surgery
                    </a>
                  </li>

                  <li className={styles.dropDownItem}>
                    <a
                      href="/prosthodontic/Restorative/CleftLipAndPalate"
                      className={styles.dropDownLink}
                    >
                      Cleft Lip and Palate
                    </a>
                  </li>
                </ul>
              </li>
              <li className={styles.navItem}>
                <div className={styles.navBlock}>
                  <a className={styles.navBlock} href="/prosthodontic/Cosmetic">
                    Cosmetic
                  </a>
                </div>
                <ul className={styles.dropDown}>
                  <li className={styles.dropDownItem}>
                    <a
                      href="/prosthodontic/Cosmetic/PorcelainVeneers"
                      className={styles.dropDownLink}
                    >
                      Porcelain Veneers
                    </a>
                  </li>
                  {/* <li className={styles.dropDownItem}>
                    <a
                      href="/prosthodontic/Cosmetic/DentalBonding"
                      className={styles.dropDownLink}
                    >
                      Dental Bonding
                    </a>
                  </li> */}
                  <li className={styles.dropDownItem}>
                    <a
                      href="/prosthodontic/Cosmetic/InlaysAndOnlays"
                      className={styles.dropDownLink}
                    >
                      Inlays and Onlays
                    </a>
                  </li>
                  {/* <li className={styles.dropDownItem}>
                    <a
                      href="/prosthodontic/Cosmetic/TeethWhitening"
                      className={styles.dropDownLink}
                    >
                      Teeth Whitening
                    </a>
                  </li> */}
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/prosthodontic/Cosmetic/E4DDentist"
                    >
                      E4D Dentist
                    </a>
                  </li>
                  {/* <li className={styles.dropDownItem}>
                    <a
                      href="/prosthodontic/Cosmetic/ClearBraces"
                      className={styles.dropDownLink}
                    >
                      Clear Braces
                    </a>
                  </li> */}
                  <li className={styles.dropDownItem}>
                    <a
                      href="/prosthodontic/Cosmetic/CEREC"
                      className={styles.dropDownLink}
                    >
                      CEREC
                    </a>
                  </li>
                </ul>
              </li>

              {/* <li className={styles.navItem}>
                <div className={styles.navBlock}>
                  {" "}
                  <a className={styles.navBlock} href="/prosthodontic/General">
                    General
                  </a>
                </div>

                <ul className={styles.dropDown}>
                  <li className={styles.dropDownItem}>
                    <a
                      href="/prosthodontic/General/OralHygiene"
                      className={styles.dropDownLink}
                    >
                      Oral Hygiene
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      href="/prosthodontic/General/TeethCleaning"
                      className={styles.dropDownLink}
                    >
                      Teeth Cleaning
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      href="/prosthodontic/General/PeriodontalDisease"
                      className={styles.dropDownLink}
                    >
                      Periodontal Disease
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      href="/prosthodontic/General/TeethCleaning"
                      className={styles.dropDownLink}
                    >
                      Teeth Cleaning
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      href="/prosthodontic/General/WomenAndPeriodontalHealth"
                      className={styles.dropDownLink}
                    >
                      Women and Periodontal Health
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      href="/prosthodontic/General/DentalSpecialties"
                      className={styles.dropDownLink}
                    >
                      Dental Specialties
                    </a>
                  </li>
                </ul>
              </li> */}
              <li className={styles.navItem}>
                <a
                  href="#schedule-section"
                  onClick={handleScrollToSchedule}
                  className={styles.btnPrimary}
                >
                  Book An Appointment
                </a>
              </li>
            </ul>
          </nav>
        )}
      </div>
    </section>
  );
}
