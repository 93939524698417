import React, { useState, useEffect } from "react";

import { useInView } from "react-intersection-observer";
import styles from "./AboutDentists.module.css";
import profile1 from "../../images/EndodonticServices.png";
import Rajesh from "../../images/RajeshReddy.jpeg";
import Pavani from "../../images/PavaniProfile.jpeg";
export default function AboutDentists() {
  const [header, headerView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  const [service, serviceView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });

  const [ref, inView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className={styles.AboutDentists}>
      <div className={styles.AboutDentistsContainer}>
        {windowWidth <= 1250 && (
          <h2>
            <b>Dr.K.Rajesh Reddy</b>
          </h2>
        )}
        <div className={styles.DentistsImgSection}>
          <img className={styles.ProfileImg} src={Rajesh}></img>
        </div>
        <div className={styles.DentistsIntroSection}>
          {windowWidth > 1250 && (
            <h2>
              <b>Dr.K.Rajesh Reddy</b>
            </h2>
          )}
          <div className={styles.DentistIntro}>
            <span>Why dentistry?</span>
            <p className={styles.DentistDescription}>
              My motive to be a dentist is to help people achieve healthy,
              confident smiles, improving their overall well-being and quality
              of life. By providing compassionate care and utilizing advanced
              dental techniques, I aim to create a positive and lasting impact
              on each patient. I am dedicated to fostering a welcoming
              environment where patients feel valued and empowered to maintain
              their oral health.
            </p>
          </div>
          <div className={styles.DentistEnducation}>
            <span>Education</span>
            <ul>
              <li>BDS</li>
              <li>
                M.Sc,DGI (Germany), Sr.Consultant Oral & Maxillofacial
                Implantology
              </li>
            </ul>
            <span>Contact Details</span>
            <ul>
              <li>Email Address : heterodentalplanet@gmail.com</li>
              <li>Contact Number : +91-9876543210</li>
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.AboutDentistsContainer}>
        {windowWidth <= 1250 && (
          <h2>
            <b>Dr.K.Pavani Reddy</b>
          </h2>
        )}
        <div className={styles.DentistsImgSection}>
          <img className={styles.ProfileImg} src={Pavani}></img>
        </div>
        <div className={styles.DentistsIntroSection}>
          {windowWidth > 1250 && (
            <h2>
              <b>Dr.K.Pavani Reddy</b>
            </h2>
          )}
          <div className={styles.DentistIntro}>
            <span>Why dentistry?</span>
            <p className={styles.DentistDescription}>
              I believe that a healthy smile is a gateway to overall well-being,
              self-assurance, and improved quality of life. Through
              compassionate care, advanced dental techniques, and a
              patient-centered approach, I am dedicated to making a meaningful
              and positive impact on each individual's life.
            </p>
          </div>
          <div className={styles.DentistEnducation}>
            <span>Qualification</span>
            <ul>
              <li>
                Sr. Consultant Dental Surgeon Primary & Revision Endodontics
              </li>
            </ul>
            <span>Contact Details</span>
            <ul>
              <li>Email Address : pavani.rajesh02@gmail.com</li>
              <li>Contact Number : +91-7207504916</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
