import React, { useState, useEffect } from "react";

import styles from "../CosmeticDentistry/DentalBonding.module.css";
import SideMenuRestorativeDentistry from "../RestorativeDentistry/SideMenuRestorativeDentistry";

export default function ToothExtractions({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            Tooth Extractions
          </h1>
          <p className={styles.mainDescription}>
            You and your Dentist may determine that you need a tooth extraction
            for any number of reasons. Some teeth are extracted because they are
            severely decayed; others may have advanced periodontal disease, or
            have broken in a way that cannot be repaired. Other teeth may need
            removal because they are poorly positioned in the mouth (such as
            impacted teeth), or in preparation for orthodontic treatment.
          </p>
          <p className={styles.mainDescription}>
            The removal of a single tooth can lead to problems related to your
            chewing ability, problems with your jaw joint, and shifting teeth,
            which can have a major impact on your dental health.
          </p>
          <p className={styles.mainDescription}>
            To avoid these complications, in most cases your Dentist will
            discuss alternatives to extractions as well as{" "}
            <b>replacement of the extracted tooth.</b>
          </p>
          <h4 className={styles.secondaryQuestion}>The Extraction Process</h4>
          <p className={styles.mainDescription}>
            At the time of extraction, the doctor will need to numb your tooth,
            jaw bone and gums that surround the area with a local anesthetic.
          </p>
          <p className={styles.mainDescription}>
            During the extraction process you will feel a lot of pressure. This
            is from the process of firmly rocking the tooth in order to widen
            the socket for removal.
          </p>
          <p className={styles.mainDescription}>
            You feel the pressure without pain as the anesthetic has numbed the
            nerves stopping the transference of pain, yet the nerves that
            transmit pressure are not profoundly affected.
          </p>
          <p className={styles.mainDescription}>
            If you do feel pain at any time during the extraction please let us
            know right away.
          </p>
          <h4 className={styles.secondaryQuestion}>Sectioning a tooth</h4>
          <p className={styles.mainDescription}>
            Some teeth require sectioning. This is a very common procedure done
            when a tooth is so firmly anchored in its socket or the root is
            curved and the socket can’t expand enough to remove it. The doctor
            simply cuts the tooth into sections then removes each section one at
            a time.
          </p>
          <h4 className={styles.secondaryQuestion}>After Tooth Extraction</h4>
          <p className={styles.secondaryDescription}>
            After tooth extraction, it’s important for a blood clot to form to
            stop the bleeding and begin the healing process. Bite on a gauze pad
            for 30-45 minutes immediately after the appointment. If the bleeding
            or oozing still persists, place another gauze pad and bite firmly
            for another 30 minutes. You may have to do this several times to
            staunch the flow of blood.
          </p>
          <p className={styles.secondaryDescription}>
            After the blood clot forms it is important to not disturb or
            dislodge the clot. Do not rinse vigorously, suck on straws, smoke,
            drink alcohol or brush teeth next to the extraction site for 72
            hours. These activities may dislodge or dissolve the clot and hinder
            the healing process. Limit vigorous exercise for the next 24 hours,
            as this increases blood pressure and may cause more bleeding from
            the extraction site.
          </p>
          <p className={styles.secondaryDescription}>
            After the tooth is extracted you may feel some pain and experience
            some swelling. An ice pack or an unopened bag of frozen peas or corn
            applied to the area will keep swelling to a minimum. Take pain
            medications as prescribed. The swelling usually subsides after 48
            hours.
          </p>

          <p className={styles.secondaryDescription}>
            Use pain medication as directed. Call our office if the medication
            doesn’t seem to be working. If antibiotics are prescribed, continue
            to take them for the indicated length of time even if signs and
            symptoms of infection are gone. Drink lots of fluids and eat
            nutritious, soft food on the day of the extraction. You can eat
            normally as soon as you are comfortable.
          </p>
          <p className={styles.secondaryDescription}>
            It is important to resume your normal dental routine after 24 hours.
            This should include brushing and flossing your teeth at least once a
            day. This will speed healing and help keep your mouth fresh and
            clean.
          </p>
          <p className={styles.secondaryDescription}>
            After a few days you should feel fine and can resume your normal
            activities. If you have heavy bleeding, severe pain, continued
            swelling for 2-3 days, or a reaction to the medication, call our
            office immediately.
          </p>
          <h4 className={styles.secondaryQuestion}>
            After Extraction Home Care
          </h4>
          <p className={styles.mainDescription}>
            <b> Bleeding</b>
          </p>
          <p className={styles.mainDescription}>
            Some bleeding may occur. Placing a piece of moist gauze over the
            empty tooth socket and biting down firmly for 45 minutes can control
            this.
          </p>
          <p className={styles.mainDescription}>
            <b>Blood clots that form in the empty socket</b>
          </p>
          <p
            className={styles.mainDescription}
            style={{ marginBottom: "1.2rem" }}
          >
            This is an important part of the healing process and you must be
            careful not to dislodge the clot.
          </p>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>
                {" "}
                Avoid rinsing or spitting for 24 hours after the extraction.
              </li>
              <li>Avoid use of a straw, smoking or hot liquids.</li>
            </ul>
          </p>
          <p className={styles.mainDescription}>
            <b> Swelling</b>
          </p>
          <p className={styles.mainDescription}>
            If swelling occurs you can place ice on your face for 10 minutes and
            off for 20 minutes. Repeat this cycle as you feel necessary for up
            to 24 hours.
          </p>
          <p className={styles.mainDescription}>
            <b> Pain and Medications</b>
          </p>
          <p className={styles.mainDescription}>
            If you experience pain you may use non-prescription pain relief
            medications such as acetaminophen or ibuprofen.
          </p>
          <p className={styles.mainDescription}>
            <b>Eating</b>
          </p>
          <p className={styles.mainDescription}>
            For most extractions just make sure you do your chewing away from
            the extraction site. Stay away from hot liquids and alcoholic
            beverages for 24 hours. A liquid diet may be recommended for 24
            hours.
          </p>
          <p className={styles.mainDescription}>
            <b>Brushing and Cleaning</b>
          </p>
          <p className={styles.mainDescription}>
            After the extraction, avoid brushing the teeth near the extraction
            site for one day. After that, you can resume gentle cleaning. Avoid
            commercial mouth rinses, as they tend to irritate the site.
            Beginning 24 hours after the extraction you can rinse with salt
            water (1/2 teaspoon in a cup of water) after meals and before bed.
          </p>
          <p className={styles.mainDescription}>
            <b>Dry Socket</b>
          </p>
          <p className={styles.mainDescription}>
            Dry socket occurs when a blood clot fails to form in the socket
            where the tooth has been extracted or the clot has been dislodged,
            and the healing is significantly delayed.
          </p>
          <p className={styles.mainDescription}>
            Following the post extraction instructions will reduce the chances
            of developing dry socket. Dry sockets manifest themselves as a dull
            throbbing pain which doesn’t appear until three or four days after
            the extraction. The pain can be moderate to severe and radiate from
            the extraction area. Dry socket may cause a bad taste or bad breath
            and the extraction site appears dry. Your Dentist will apply a
            medicated dressing to the dry socket to soothe the pain.
          </p>
          <p className={styles.mainDescription}>
            <b>Healing</b>
          </p>
          <p className={styles.mainDescription}>
            After a tooth has been extracted, there will be a resulting hole in
            your jaw bone where the tooth was. In time, this will smooth and
            fill in with bone. This process can take many weeks or months.
            However, after 1-2 weeks you should no longer notice any
            inconvenience.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuRestorativeDentistry
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
