import React, { useState, useEffect } from "react";

import styles from "./Procedures.module.css";
import SideMenuProcedures from "./SideMenuProcedures";

export default function ImpactedTeeth({ menuItem, menuItemName, dentistry }) {
  const oralsurgery = {
    textColor: "#d099d9",
    spanColor: "#644a68",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = oralsurgery;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            EXPOSURE OF IMPACTED TEETH
          </h1>
          <h4 className={styles.secondaryQuestion}>
            What is an impacted tooth?
          </h4>
          <p className={styles.mainDescription}>
            An <b>impacted tooth </b>simply means that it is “stuck” and cannot
            erupt into function. Patients frequently develop problems with
            impacted third molar (wisdom) teeth. These teeth get “stuck” in the
            back of the jaw and can develop painful infections, among a host of
            other problems (see Wisdom Teeth under Procedures). Since there is
            rarely a functional need for wisdom teeth, they are usually
            extracted if they develop problems. The maxillary cuspid (upper
            eyetooth) is the second most common tooth to become impacted. The
            cuspid tooth is a critical tooth and plays an important role in your
            “bite”. The cuspid teeth are very strong biting teeth and have the
            longest roots of any human teeth. They are designed to be the first
            teeth that touch when your jaws close together so they guide the
            rest of the teeth into the proper bite.
          </p>

          <p className={styles.secondaryDescription}>
            Normally, the maxillary cuspid teeth are the last of the “front”
            teeth to erupt into place. They usually come into place around age
            13 and cause any space left between the upper front teeth to close
            tighter together. If a cuspid tooth gets impacted, every effort is
            made to get it to erupt into its proper position. The techniques
            involved to aid eruption can be applied to any impacted tooth in the
            upper or lower jaw, but most commonly they are applied to the
            maxillary cuspid (upper eye) teeth. Sixty percent of these impacted
            eyeteeth are located on the palatal (roof of the mouth) side of the
            dental arch. The remaining impacted eye teeth are found in the
            middle of the supporting bone, but are stuck in an elevated position
            above the roots of the adjacent teeth, or are out to the facial side
            of the dental arch.
          </p>
          <h4 className={styles.secondaryQuestion}>
            Early Recognition of Impacted Eyeteeth Is the Key to Successful
            Treatment
          </h4>
          <p className={styles.secondaryDescription}>
            The older the patient the more likely an impacted eyetooth will not
            erupt by natural forces alone, even if the space is available for
            the tooth to fit in the dental arch. A panoramic x-ray, along with a
            dental examination, will help determine whether all the adult teeth
            are present or if some adult teeth are missing.
          </p>
          <p className={styles.secondaryDescription}>
            Treatment may require referral to an oral surgeon for extraction of
            over-retained baby teeth and/or selected adult teeth that are
            blocking the eruption of the all-important eyeteeth. The oral
            surgeon will also need to remove any extra teeth (supernumerary
            teeth) or growths that are blocking the eruption of any adult teeth.
          </p>
          <p
            className={styles.secondaryDescription}
            style={{ marginBottom: "0.5rem" }}
          >
            <b>Impacted tooth success by patient’s age:</b>
          </p>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>
                11-12 years old – with space opened for eruption, good chance
                for success.
              </li>
              <li>
                13-14 years old – the impacted eyetooth will not erupt by
                itself, even with the space cleared for its eruption.
              </li>
              <li>
                Over 40 years old – much higher chance that the tooth will be
                fused in position. The only option is to extract the impacted
                tooth and replace it with a crown on a dental implant or a fixed
                bridge.
              </li>
            </ul>
          </p>
          <h4 className={styles.secondaryQuestion}>
            What happens if the eyetooth will not erupt when proper space is
            available?
          </h4>
          <p className={styles.secondaryDescription}>
            In cases where the eyeteeth will not erupt spontaneously, the
            orthodontist and oral surgeon will work together to get these teeth
            to erupt. Each case must be evaluated on an individual basis, but
            treatment will usually involve a combined effort between the
            orthodontist and the oral surgeon. The oral surgeon will expose and
            bracket the impacted eyetooth.
          </p>

          <p className={styles.secondaryDescription}>
            The goal is to erupt the impacted tooth and not to extract it. Once
            the tooth has moved into its final position, the gum around it will
            be evaluated. In some circumstances, there may be some minor “gum
            surgery” required.
          </p>
          <h4 className={styles.secondaryQuestion}>
            What to expect from surgery to expose & bracket an impacted tooth
          </h4>
          <p className={styles.secondaryDescription}>
            The <b>surgery to expose and bracket an impacted tooth </b>is a very
            straightforward surgical procedure that is performed in the oral
            surgeon’s office. For most patients, it is performed using laughing
            gas and local anesthesia. In selected cases it will be performed
            under IV sedation if the patient desires to be asleep, but this is
            generally not necessary for this procedure. If the procedure only
            requires exposing the tooth with no bracketing, the time required
            will be shortened by about one half. These issues will be discussed
            in detail at your preoperative consultation with your doctor.
          </p>
        </div>

        {windowWidth > 730 && (
          <SideMenuProcedures
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
