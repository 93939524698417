import React from "react";

import { useInView } from "react-intersection-observer";
import styles from "./PediatricServices.module.css";

export default function PediatricServices({ serviceName }) {
  const [header, headerView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  const [ref, inView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  return (
    <section className={styles.pediatricServiesSection}>
      <div
        className={`${styles.generalDescriptionCenter} ${
          headerView ? styles.animate : ""
        }`}
        ref={header}
      >
        <h1 className={styles.generalServiceHeader}>Our Pediatric Services</h1>

        <p className={styles.generalServiceHeaderDescription}>
          Our Pediatric dentistry offers all kinds of services and study new
          technology to add new custom services to the list
        </p>
      </div>
      <div
        className={`${styles.pediatricServices} ${
          inView ? styles.animate : ""
        }`}
        ref={ref}
      >
        <div className={styles.pediatricServiceCard}>
          <h3 className={styles.pediatricServiceCardHeader}>
            Pediatric dentistry
          </h3>
          <hr className={styles.horizontal}></hr>
          <p className={styles.pediatricServiceDescritpion}>
            Pediatric dentistry is a specialized branch of dentistry that
            focuses on the oral health of infants, children, adolescents, and
            individuals with special needs. Also known as pedodontics, pediatric
            dentistry is concerned with preventive and therapeutic dental care
            tailored to the unique needs of young patients.
          </p>
          <p className={styles.pediatricServiceDescritpion}>
            <b>Our Pediatric Dentistry Services include : </b>
          </p>
          <ul className={styles.pediatricOffering}>
            <li className={styles.pediatricOffering}>
              Tooth Brushing,tooth eruption
            </li>
            <li className={styles.pediatricOffering}>Cavity Prevention</li>
            <li className={styles.pediatricOffering}>
              Gum Disease,Cavity prevention
            </li>
            <li className={styles.pediatricOffering}>
              Early Childhood Tooth Decay
            </li>
            <li className={styles.pediatricOffering}>Inlays & Onlays</li>
            <li
              className={`${styles.marginBottom} ${styles.pediatricOffering}`}
            >
              Fillings and Restoration
            </li>
          </ul>
          <a href="/pediatric/pediatricdentistry" className={styles.btnPrimary}>
            Learn More
          </a>
        </div>
        <div className={styles.pediatricServiceCard}>
          <h4 className={styles.pediatricServiceCardHeader}>Orthodontics</h4>
          <hr className={styles.horizontal}></hr>
          <p className={styles.pediatricServiceDescritpion}>
            Orthodontic irregularities may be present at birth or develop during
            toddlerhood or early childhood. Crooked teeth hamper self-esteem and
            make good oral homecare difficult, whereas straight teeth help
            minimize the risk of tooth decay and childhood periodontal disease.
          </p>
          <p className={styles.pediatricServiceDescritpion}>
            Orthodontic treatment is primarily used to prevent and correct
            “bite” irregularities. Several factors may contribute to such
            irregularities, including genetic factors, the early loss of primary
            (baby) teeth, and damaging oral habits (such as thumb sucking and
            developmental problems).
          </p>
          <p className={styles.pediatricServiceDescritpion}>
            <b>Our Pediatric Orthodontic Services include : </b>
          </p>
          <ul className={styles.pediatricOffering}>
            <li className={styles.pediatricOffering}>Removable Appliances</li>
            <li className={styles.pediatricOffering}>
              Classification Of Teeth
            </li>
            <li className={styles.pediatricOffering}>Types Of Bites</li>
          </ul>
          <a href="/pediatric/Orthodontics" className={styles.btnPrimary}>
            Learn More
          </a>
        </div>
      </div>
    </section>
  );
}
