import React, { useEffect, useState } from "react";

import styles from "../../PeriodontalMenu/PeriodontalMenu.module.css";
import SideMenuImplantsMenu from "./SideMenuImplantsMenu";

export default function ImplantsCost({ menuItem, menuItemName, dentistry }) {
  const periodontalProperty = {
    textColor: "#fc7444",
    spanColor: "#978ab1",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = periodontalProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            CONSIDERING THE COST OF DENTAL IMPLANTS
          </h1>
          <p className={styles.mainDescription}>
            Dental implants have been available for several decades. However,
            for most patients, they are still a relatively new concept. When
            considering the <b>cost of dental implants </b>and comparing quotes,
            there are several important points that should be kept in mind.
          </p>
          <h4 className={styles.secondaryQuestion}>Longevity</h4>
          <p className={styles.secondaryDescription}>
            When comparing the cost of dental implants to other tooth
            replacement methods such as dentures and bridges, it is important to
            take into consideration the longevity offered by dental implants
            that is not always afforded by other, more traditional methods.
          </p>
          <p className={styles.secondaryDescription}>
            While dentures and bridges are initially less expensive, their
            affordability can be short-lived. Because these older methods
            require repair and replacement every 5-10 years, they are often more
            expensive over time. By contrast, dental implants, when properly
            placed and cared for, can last a lifetime.
          </p>
          <h4 className={styles.secondaryQuestion}>Jaw Bone Health</h4>
          <p className={styles.secondaryDescription}>
            Over time, when a tooth is missing, the jaw bone deteriorates. So
            while a denture or bridge may seem to function similarly to a tooth,
            underneath the surface damage is being done to the jaw bone and
            ultimately to the structure of the face. This can result in the
            distortion of the shape of a person’s face, leading to additional
            cosmetic costs down the road.
          </p>
          <p className={styles.secondaryDescription}>
            On the other hand, dental implants are made of titanium, which
            actually integrates with the jaw bone, strengthening it and
            stimulating bone growth. This preserves the natural strength and
            quality of the mouth, lessening problems in the future.
          </p>
          <h4 className={styles.secondaryQuestion}>Quality of Life</h4>
          <p className={styles.secondaryDescription}>
            Dental implants can be seen as a long-term investment not only in
            terms of money, but also in terms of quality of life. A dental
            implant is the closest thing to a natural tooth. In addition to
            allowing the patient to eat the same healthy foods he or she has
            always enjoyed, it also eliminates the day-to-day hassles and
            possible embarrassment that are frequently caused by dentures.
          </p>
          <h4 className={styles.secondaryQuestion}>
            Comparing Dental Implant Quotes
          </h4>
          <p
            className={styles.secondaryDescription}
            style={{ marginBottom: "0.5rem" }}
          >
            There are several steps (and often multiple professionals) involved
            in the placement of a dental implant. When comparing quotes, it’s
            important to factor in the cost of each of these steps:
          </p>

          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>Exams/office visits</li>
              <li>Tooth/root extraction</li>
              <li>Bone grafting</li>
              <li>Placement of the titanium root (the “dental implant”)</li>
              <li>Placement of the crown</li>
              <li>X-rays, pre/post operative care</li>
            </ul>
          </p>
          <h4 className={styles.secondaryQuestion}>
            Financing for Dental Implants
          </h4>
          <p className={styles.secondaryDescription}>
            First, check with your dental insurance carrier to see what portion,
            if any, of dental implants they cover.
          </p>
          <p className={styles.secondaryDescription}>
            There are health care credit companies that offer no-interest and
            low-interest loans for medical procedures including dental implants.
          </p>
          <p className={styles.secondaryDescription}>
            Dental Implants are an investment in your health as well as your
            appearance. A full set of teeth makes eating a pleasure again,
            making it easier to eat a balanced, healthy diet. A full set of
            teeth also preserves the contours of the face, keeping you from
            looking old before your time.
          </p>
          <h4 className={styles.secondaryQuestion}>
            Schedule Your Consultation
          </h4>
          <p className={styles.secondaryDescription}>
            The only way to receive an accurate price quote is to call us and
            come in for a consultation, and we can give you a specific price for
            your case. Please call us to schedule your consultation.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuImplantsMenu
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
