import React, { useState, useEffect } from "react";
import SideMenuCosmeticProsthodontic from "../RestorativeProsthodontic/SideMenuCosmeticProsthodontic";

import styles from "./PatientContent.module.css";
import SideMenuPatient from "./SideMenuPatient";

export default function FirstVisit({ menuItem, menuItemName, dentistry }) {
  const generalProperty = {
    textColor: "#545cd6",
    spanColor: "#656cda",
    backgroundColor: "#b3b3b3a9",
  };

  const styleProperties = generalProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            First Visit
          </h1>
          <p className={styles.mainDescription}>
            Your <b>initial appointment</b> will consist of a consultation
            explaining your diagnosis and treatment options. Occasionally,
            treatment can be done the same day as the <b>consultation.</b>{" "}
            However, a complex medical history or treatment plan will require an
            evaluation and a second appointment to provide treatment on another
            day.
          </p>
          <p
            className={styles.secondaryDescription}
            style={{ marginBottom: "1.2rem" }}
          >
            Please assist us by providing the following information at the time
            of your consultation:
          </p>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>Any x-rays if applicable.</li>
              <li>A list of medications you are currently taking, if any</li>
              <li>
                If you have medical or dental insurance, bring the necessary
                completed forms. This will save time and allow us to help you
                process any claims.
              </li>
            </ul>
          </p>
          <p className={styles.secondaryDescription}>
            <b>IMPORTANT:</b> All patients under the age of 18 must be
            accompanied by a parent or guardian at the consultation visit.
          </p>
          <p className={styles.secondaryDescription}>
            Please alert the office if you have a medical condition that may be
            of concern prior to surgery (i.e., diabetes, high blood pressure,
            artificial heart valves and joints, rheumatic fever, etc.) or if you
            are on any medication (i.e., heart medications, aspirin,
            anticoagulant therapy, etc.).
          </p>
          <h4
            className={styles.secondaryQuestion}
            style={{ color: styleProperties.textColor }}
          >
            X-Rays
          </h4>
          <p className={styles.secondaryDescription}>
            If your previous dentist has taken x-rays, you may request that they
            forward them to our office. If there is not enough time, please pick
            them up and bring them to our office for your consultation. If
            additional films are necessary, they can be taken at our facility.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuPatient
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
