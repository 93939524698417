import React, { useState, useEffect } from "react";
import SideMenuCosmeticProsthodontic from "../RestorativeProsthodontic/SideMenuCosmeticProsthodontic";

import styles from "./DentalBonding.module.css";
import SideMenuCosmeticDentistry from "./SideMenuCosmeticDentistry";

export default function TeethWhitening({ menuItem, menuItemName, dentistry }) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;

  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            Teeth Whitening
          </h1>
          <p className={styles.mainDescription}>
            <b>
              {" "}
              Having a beautiful smile may be even easier than you think. Many
              people achieve the look they’ve been dreaming of with our simple
              “whitening” procedure.
            </b>
          </p>
          <h4 className={styles.secondaryQuestion}>
            What are the details of teeth whitening?
          </h4>

          <p className={styles.secondaryDescription}>
            <b>It is safe, quick, and inexpensive.</b> At any appointment, just
            let us know if you would like to begin the bleaching process. You
            can lighten only your upper teeth or both the upper and lower
            depending on how much you show when you talk and smile.
          </p>
          <p className={styles.secondaryDescription}>
            In only a day or two your custom bleach splints will be ready for
            you. We provide a special bleaching agent that you put into the
            clear splints. With only a few hours of wear per day, our special
            bleaching agent bubbles stains right out of your enamel without
            altering tooth structure or existing dental work. When your teeth
            reach the desired brightness, only occasional treatment is needed to
            maintain your new smile. We will want to take “after” photos at your
            next appointment.{" "}
          </p>
          <p className={styles.secondaryDescription}>
            Dental whitening can be used to correct tooth discoloration.
            Discolorations can be caused by staining, aging, or chemical damage
            to teeth. Using the latest in whitening technology, we can offer a
            safe method for creating a beautiful, brilliant smile. In cases of
            extreme tooth discoloration, crowns or teeth veneers may be the only
            choice. But because of the low cost of bleaching treatments,{" "}
            <b>teeth whitening</b> is nearly always worth a try.
          </p>

          <h4 className={styles.secondaryQuestion}>
            How is teeth whitening done?
          </h4>
          <p className={styles.secondaryDescription}>
            An impression is taken to make a specialized mouth guard or stent to
            hold the bleaching solution against the teeth. The material is used
            each night for about three to four hours for a week or two. By the
            end of this time, significant whitening will occur. In some cases,
            the change is nothing short of brilliant. For confidence in
            appearance, this bleaching technology allows us to promise
            improvements in yellowing, aging, or stained teeth. For very
            severely stained teeth, crowns or veneers may be more appropriate.
          </p>
          <h4 className={styles.secondaryQuestion}>
            Considerations for Teeth Whitening
          </h4>
          <p className={styles.secondaryDescription}>
            Over-the-counter bleaching agents are available for purchase at drug
            stores and pharmacies. However, since these products can cause harm
            to the gums and teeth, it is better to use products that our
            practice recommends.
          </p>
        </div>
        {windowWidth > 730 &&
          (dentistry == "general" ? (
            <SideMenuCosmeticDentistry
              menuItem={handleMenuClick}
              menuItemName={menuItemName}
              dentistry={dentistry}
            />
          ) : (
            <SideMenuCosmeticProsthodontic
              menuItem={handleMenuClick}
              menuItemName={menuItemName}
              dentistry={dentistry}
            />
          ))}
      </div>
    </section>
  );
}
