import React, { useState, useEffect } from "react";

import styles from "./Braces.module.css";
import SideMenuBraces from "./SideMenuBraces";

export default function Appliances({ menuItem, menuItemName, dentistry }) {
  const orthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#8ba7c3",
    backgroundColor: "#e7f1fca0",
  };
  const styleProperties = orthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      //   style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            REMOVABLE APPLIANCES
          </h1>
          <p className={styles.mainDescription}>
            Appliances are used during various stages of the orthodontic
            process, either to retain the new position of teeth, or in some
            cases to influence the growth of the jaws for a better bite
            alignment. Appliances may be fixed or removable.
          </p>
          <h4 className={styles.secondaryQuestion}>Types of Appliances</h4>
          <p className={styles.secondaryDescription}>
            <ul
              className={styles.subDescription}
              style={{ fontSize: "1.4rem", paddingTop: "0rem" }}
            >
              <li>
                <b> Retainers: </b>Used after braces are removed to hold teeth
                in their new positions. You must wear your retainer as
                prescribed to prevent regression of your treatment.
              </li>
              <li>
                <b> Herbst® Appliance: </b>Used to correct overbites, this fixed
                orthodontic appliance is typically used on younger patients.
              </li>
              <li>
                <b> Palatal Expander: </b>Designed to widen your upper jaw, a
                palatal expander applies outward pressure on your upper molars
                to slowly expand your palate.
              </li>
              <li>
                <b> Spacers (Separators): </b>Spacers are plastic rings that are
                inserted between back teeth before you have braces, providing
                space for your teeth to shift. If a spacer comes out, call our
                office to see if you need a replacement. Do not chew sticky
                foods with or floss teeth that have spacers in them.
              </li>
              <li>
                <b> Elastics (Rubber Bands): </b>Rubber bands are used with
                fixed metal braces. They must be worn at all times and changed
                daily per our instructions.
              </li>
              <li>
                <b> Headgear: </b>For a severe overbite or underbite, headgear
                is sometimes used to continuously pull on your teeth,
                restricting further growth forward.
              </li>
              <li>
                <b> Positioners: </b>Designed to complete the final tooth
                necessary movements, positioners are usually the last step in
                the orthodontic process.
              </li>
            </ul>
          </p>

          <h4 className={styles.secondaryQuestion}>Care of Appliances</h4>
          <p className={styles.secondaryDescription}>
            As with retainers, all orthodontic appliances should be worn as
            prescribed. They should be cleaned with toothpaste once a day, and
            if removed for eating or sports, they should be placed in their
            container, never in a pocket or napkin where they may be broken or
            thrown away.
          </p>
          <h4 className={styles.secondaryQuestion}>Pain and Soreness</h4>
          <p className={styles.secondaryDescription}>
            It is normal for new or recently adjusted appliances to cause
            soreness or a slight alteration of speech for a few days following
            your appointment. If you experience a sore spot on the gums,
            however, please call our office so that we can make an appropriate
            adjustment.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuBraces
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
