import React from "react";

import styles from "./PeriodontalMenu.module.css";
import SideMenuPeriodontalMenu from "./SideMenuPeriodontalMenu";

export default function BiteAdjustment({ menuItem, menuItemName, dentistry }) {
  const periodontalProperty = {
    textColor: "#fc7444",
    spanColor: "#978ab1",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = periodontalProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  return (
    <section
      className={styles.mainSection}
    //  style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            BITE ADJUSTMENT
          </h1>

          <p
            className={styles.mainDescription}
            style={{ marginBottom: "0.5rem" }}
          >
            A bite is considered to be healthy when all or most of the teeth are
            present and not destroyed by normal daily usage.
          </p>

          <p className={styles.secondaryDescription}>
            It is destructive when teeth show wear, looseness or when TMJ (jaw
            joint) damage is seen. <b>Bite therapy </b>helps restore a bite that
            can function without damage and destruction.
          </p>
          <p
            className={styles.secondaryDescription}
            style={{ marginBottom: "0.5rem" }}
          >
            <b>The therapy may include:</b>
          </p>

          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>
                Reshaping the biting surfaces of the teeth and eliminating spots
                of excessive pressures where the teeth are brought into contact.
                This is done by carefully dividing bite pressures evenly across
                all of the teeth.
              </li>
              <li>
                Bite splint therapy using a custom-fitted and adjusted plastic
                bite guard to keep the teeth apart, worn during the day, at
                night, or both.
              </li>
              <li>Braces to reposition mal-aligned or drifted teeth.</li>
              <li>Replacement of old, worn out, or damaged fillings.</li>
              <li>Reconstruction of badly worn and damaged teeth.</li>
            </ul>
          </p>
        </div>
        <SideMenuPeriodontalMenu
          menuItem={handleMenuClick}
          menuItemName={menuItemName}
          dentistry={dentistry}
        />
      </div>
    </section>
  );
}
