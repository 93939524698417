import React, { useState, useEffect } from "react";

import styles from "../Braces/Braces.module.css";
import SideMenuLivingBraces from "./SideMenuLivingBraces";

export default function LivingWithBraces({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const orthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#8ba7c3",
    backgroundColor: "#e7f1fca0",
  };
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            LIVING WITH BRACES
          </h1>
          <p className={styles.mainDescription}>
            Congratulations on your new braces! You are on your way to a
            happier, healthier smile! Here are some topics that you may find
            useful as you become accustomed to living with braces:
          </p>
          <ul className={styles.items}>
            <li className={styles.item}>
              <a href="/orthodontic/LivingWithBraces/Elastics">Elastics</a>
            </li>
            <li className={styles.item}>
              <a href="/orthodontic/LivingWithBraces/EatingWithBraces">
                Eating With Braces
              </a>
            </li>
            <li className={styles.item}>
              <a href="/orthodontic/LivingWithBraces/PainAndSoreness">
                Pain And Soreness
              </a>
            </li>
            <li className={styles.item}>
              <a href="/orthodontic/LivingWithBraces/BrushingAndFlossing">
                Brushing And Flossing
              </a>
            </li>
            <li className={styles.item}>
              <a href="/orthodontic/LivingWithBraces/RetainersAndAppliances">
                Retainers And Appliances
              </a>
            </li>
          </ul>
        </div>
        {windowWidth > 730 && (
          <SideMenuLivingBraces
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
