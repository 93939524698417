import React, { useState, useEffect } from "react";

import styles from "./ContactInformation.module.css";

import { useInView } from "react-intersection-observer";
import { IonIcon } from "@ionic/react";
import { call, location, mail } from "ionicons/icons";

export default function ContactInformation() {
  const [contact, contactView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  const [map, mapView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  return (
    <section className={styles.ContactInformationSection}>
      <div className={styles.ContactInformationContainer}>
        <div
          className={`${styles.ContactInformationRow} ${
            contactView ? styles.animate : ""
          }`}
          ref={contact}
        >
          <h2>Get In Touch</h2>
          <p className={styles.ContactText}>
            Contact us today to schedule your appointment and experience
            exceptional dental care!
          </p>
          <div className={styles.callContent}>
            <IonIcon icon={call} className={styles.timeIcon} />
            <p>(+91) 9908873366</p>
          </div>
          <div className={styles.ContactCards}>
            <div className={styles.ContactCard}>
              <IonIcon icon={location} className={styles.CardIcon} />
              <h6>Address</h6>
              <p>
                1-37/1/125, Old Mumbai Highway, Vinayak Nagar
                <br></br>
                Gachibowli, Hyderabad, Telangana, 500032
              </p>
            </div>
            <div className={styles.ContactCard}>
              <IonIcon icon={call} className={styles.CardIcon} />
              <h6>Contact</h6>
              <p>
                heterodentalplanet@gmail.com
                <br></br>
              </p>
            </div>
          </div>
        </div>
        <div
          className={`${styles.ContactLocation} ${
            mapView ? styles.animate : ""
          }`}
          ref={map}
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.3821201914784!2d78.35611387515607!3d17.441415501246517!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9395b95ec16d%3A0xbedbf17b1d91821!2sDental%20Planet%20Elite%20%7C%20Invisalign%20%26%20Clear%20Aligners%20%7C%20Root%20Canal%20Treatment%20%26%20Teeth%20Cleaning%2FWhitening!5e0!3m2!1sen!2sin!4v1713536368939!5m2!1sen!2sin"
            loading="lazy"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </section>
  );
}
