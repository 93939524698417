import React, { useState, useEffect } from "react";

import styles from "../CosmeticDentistry/DentalBonding.module.css";
import SideMenuRestorativeDentistry from "./SideMenuRestorativeDentistry";

export default function RestorativeDentistry({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            Restorative Dentistry
          </h1>
          <p className={styles.mainDescription}>
            Today’s advanced dental treatments and materials can give you a
            healthier, more complete smile that can last you a lifetime. Your
            Dentist, can help revitalize and maintain your teeth and gums,
            giving you the smile you’ve always wanted.
          </p>

          <h4
            className={styles.secondaryQuestion}
            style={{ color: styleProperties.textColor }}
          >
            Restorative Dental Treatments
          </h4>
          <ul className={styles.items}>
            <li className={styles.item}>
              <a href="/general/Restorative/DentalFillings">Dental Fillings</a>
            </li>
            <li className={styles.item}>
              <a href="/general/Restorative/DentalImplants">Dental Implants</a>
            </li>
            <li className={styles.item}>
              <a href="/general/Restorative/DentalBridges">Dental Bridges</a>
            </li>
            <li className={styles.item}>
              <a href="/general/Restorative/DentalCrowns">Dental Crowns</a>
            </li>
            <li className={styles.item}>
              <a href="/general/Restorative/NonSurgicalRootCanal">
                Non-Surgical Root Canal
              </a>
            </li>
            <li className={styles.item}>
              <a href="/general/Restorative/ToothExtractions">
                Tooth Extractions
              </a>
            </li>
            <li className={styles.item}>
              <a href="/general/Restorative/BruxismTreatment">
                Bruxism Treatment
              </a>
            </li>
            {/* <li className={styles.item}>
              <a href="/general/Restorative/ToothExtractions">
                Tooth Extractions
              </a>
            </li> */}
            <li className={styles.item}>
              <a href="/general/Restorative/Dentures">Dentures</a>
            </li>
          </ul>
        </div>
        {windowWidth > 730 && (
          <SideMenuRestorativeDentistry
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
