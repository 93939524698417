import React, { useState, useEffect } from "react";

import styles from "../Braces/Braces.module.css";
import SideMenuLivingBraces from "./SideMenuLivingBraces";

export default function PainAndSoreness({ menuItem, menuItemName, dentistry }) {
  const orthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#8ba7c3",
    backgroundColor: "#e7f1fca0",
  };
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            PAIN AND SORENESS
          </h1>
          <h4 className={styles.secondaryQuestion}>How Much Pain is Normal?</h4>
          <p className={styles.mainDescription}>
            It is normal for teeth to be sore after braces are placed or
            adjusted for 2-3 days. You may also feel irritation on the cheeks,
            lips and tongue lasting for 1-2 weeks as they toughen and become
            accustomed to the braces rubbing against them.
          </p>
          <h4 className={styles.secondaryQuestion}>Pain Relief</h4>
          <p className={styles.mainDescription}>
            Gargling with lukewarm salt water (1 tsp. salt to 8oz of water) can
            help with tender teeth. You can also try over the counter pain
            relievers such as ibuprofen and acetaminophen to relieve soreness.
            For irritation to the soft tissues in your mouth, we can supply you
            with wax to create a protective barrier on the outside of the
            braces.
          </p>
          <h4 className={styles.secondaryQuestion}>Loose Teeth</h4>
          <p className={styles.mainDescription}>
            While a bit unsettling, it is also normal for teeth to begin to feel
            loose. In fact, this is reassurance that your braces are doing their
            job! They must first loosen your teeth to move them. Don’t worry,
            they will not always feel loose.
          </p>
          <h4 className={styles.secondaryQuestion}>Sports Mouth Guards</h4>
          <p className={styles.mainDescription}>
            If you play sports, we can provide you with a special mouth guard to
            wear for protection during play.
          </p>
          <h4 className={styles.secondaryQuestion}>When to Call</h4>
          <p className={styles.mainDescription}>
            If you feel a sharp pain or a sore begins to develop on your gums,
            cheeks or lips, please call us. If you suspect that you have a loose
            or broken band, brace or wire, please call us so that we can remedy
            the situation as soon as possible.
          </p>
          <p className={styles.mainDescription}>
            Don’t worry; your teeth won’t hurt forever! If you have any
            questions about the duration or intensity of soreness and pain in
            your mouth, please give us a call!
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuLivingBraces
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
