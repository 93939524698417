import React, { useState, useEffect } from "react";

import styles from "./Instructions.module.css";

import SideMenuInstructions from "./SideMenuInstructions";

export default function Instructions({ menuItem, menuItemName, dentistry }) {
  const endodonticProperty = {
    textColor: "#6fcfff",
    spanColor: "#64bae6",
    backgroundColor: "#e7f1fca0",
  };
  const styleProperties = endodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            Instructions
          </h1>

          <p className={styles.mainDescription}>
            Using advanced Instructions, we can make a real difference. The
            skill, experience, and commitment of our practice — using a unique
            combination of science and artistry — can literally redesign your
            smile
          </p>

          <p className={styles.secondaryDescription}>
            Even a subtle change in your smile helps you to project an image of
            self-confidence and high personal esteem. When you feel good about
            yourself, it shows in your appearance.
          </p>
          <ul className={styles.items}>
            <li className={styles.item}>
              <a href="/endodontic/Instructions/BeforeEndotonticTreatment">
                Before Endotontic Treatment
              </a>
            </li>
            <li className={styles.item}>
              <a href="/endodontic/Instructions/GeneralInstructions">
                General Instructions
              </a>
            </li>
            <li className={styles.item}>
              <a href="/endodontic/Instructions/AfterTreatmentFAQ">
                After Treatment FAQ
              </a>
            </li>
          </ul>
        </div>
        {windowWidth > 730 && (
          <SideMenuInstructions
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
