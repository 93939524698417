import React, { useState, useEffect } from "react";

import styles from "./Procedures.module.css";
import SideMenuProcedures from "./SideMenuProcedures";

export default function CleftLipAndPalate({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const oralsurgery = {
    textColor: "#d099d9",
    spanColor: "#644a68",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = oralsurgery;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            CLEFT LIP & PALATE
          </h1>
          <h4 className={styles.secondaryQuestion}>Cleft Lip</h4>
          <p className={styles.mainDescription}>
            During early pregnancy separate areas of a child’s face develop
            individually and then join together, including the left and right
            sides of the roof of the mouth and lips. However, if the sections
            don’t meet the result is a cleft. If the separation occurs in the
            upper lip, the child is said to have a <b>cleft lip. </b>
          </p>

          <p className={styles.secondaryDescription}>
            A completely formed lip is important not only for a normal facial
            appearance but also for sucking and to form certain sounds made
            during speech. A cleft lip is a condition that creates an opening in
            the upper lip between the mouth and nose. It looks as though there
            is a split in the lip. It can range from a slight notch in the
            colored portion of the lip to complete separation in one or both
            sides of the lip extending up and into the nose. A cleft on one side
            is called a unilateral cleft. If a cleft occurs on both sides, it is
            called a bilateral cleft.
          </p>

          <p className={styles.secondaryDescription}>
            A cleft in the gum may occur in association with a cleft lip. This
            may range from a small notch in the gum to a complete division of
            the gum into separate parts. A similar defect in the roof of the
            mouth is called a cleft palate.
          </p>
          <h4 className={styles.secondaryQuestion}>Cleft Palate</h4>
          <p className={styles.secondaryDescription}>
            The palate is the roof of your mouth. It is made of bone and muscle
            and is covered by a thin, wet skin that forms the covering inside
            the mouth. You can feel your own palate by running your tongue over
            the top of your mouth. Its purpose is to separate your nasal cavity
            from your mouth. The palate has an extremely important role during
            speech because when you talk it prevents air from blowing out of
            your nose instead of your mouth. The palate is also very important
            when eating; it prevents food and liquids from going up into the
            nose.
          </p>

          <p className={styles.secondaryDescription}>
            As in cleft lip, <b>a cleft palate </b>occurs in early pregnancy
            when separate areas of the face develop individually and do not join
            together properly. A cleft palate occurs when there is an opening in
            the roof of the mouth. The back of the palate is called the soft
            palate and the front is known as the hard palate. A cleft palate can
            range from just an opening at the back of the soft palate to a
            nearly complete separation of the roof of the mouth (soft and hard
            palate).
          </p>
          <p className={styles.secondaryDescription}>
            Sometimes a baby with a cleft palate may have a small chin and a few
            babies with this combination may have difficulties breathing easily.
            This condition may be called Pierre Robin sequence.
          </p>
          <p className={styles.secondaryDescription}>
            Since the lip and palate develop separately, it is possible for a
            child to be born with a cleft lip, palate or both. Cleft defects
            occur in about 1 out of every 800 babies.
          </p>
          <p className={styles.secondaryDescription}>
            Children born with one or both of these conditions usually need the
            skills of several professionals to manage the problems associated
            with the defect such as feeding, speech, hearing, and psychological
            development. In most cases, surgery is recommended. When surgery is
            done by an experienced, qualified oral and maxillofacial surgeon,
            the results can be quite positive.
          </p>
          <h4 className={styles.secondaryQuestion}>Cleft Lip Treatment</h4>
          <p className={styles.secondaryDescription}>
            <b>Cleft lip surgery </b>is usually performed when the child is
            about ten years old. The goal of surgery is to close the separation,
            restore muscle function, and provide a normal shape to the mouth.
            The nostril deformity may be improved as a result of the procedure,
            or may require a subsequent surgery.
          </p>
          <h4 className={styles.secondaryQuestion}>Cleft Palate Treatment</h4>
          <p className={styles.secondaryDescription}>
            A cleft palate is initially treated with surgery safely when the
            child is between 7 to 18 months old. This depends upon the
            individual child and his/her own situation. For example, if the
            child has other associated health problems, it is likely that the
            surgery will be delayed.
          </p>
          <h4
            className={styles.secondaryQuestion}
            style={{ marginBottom: "0.5rem" }}
          >
            <b>The major goals of cleft palate surgery are to:</b>
          </h4>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>
                Close the gap or hole between the roof of the mouth and the nose
              </li>
              <li>Reconnect the muscles that make the palate work</li>
              <li>
                Make the repaired palate long enough so that it can perform its
                function properly
              </li>
            </ul>
          </p>
          <p className={styles.secondaryDescription}>
            There are many different techniques that surgeons will use to
            accomplish these goals. The choice of techniques may vary between
            surgeons and should be discussed between the parents and the surgeon
            prior to surgery.
          </p>
          <p className={styles.secondaryDescription}>
            The cleft hard palate is generally repaired between the ages of 8
            and 12, when the cuspid teeth begin to develop. The procedure
            involves placement of bone from the hip into the bony defect, and
            closure of the communication from the nose to the gum tissue in
            three layers. It may also be performed in teenagers and adults as an
            individual procedure, or combined with corrective jaw surgery.
          </p>
          <h4 className={styles.secondaryQuestion}>
            What can be expected after the surgery?
          </h4>
          <p className={styles.secondaryDescription}>
            After the palate has been fixed children will immediately have an
            easier time swallowing food and liquids. However, in about one out
            of every five children that have the cleft palate repaired, a
            portion of the repair will split, causing a new hole to form between
            the nose and mouth. If small, this hole may result in only an
            occasional minor leakage of fluids into the nose. If large however,
            it can cause significant eating problems, and most importantly, can
            even affect how the child speaks. This hole is referred to as a
            “fistula,” and may need further surgery to correct.
          </p>
        </div>

        {windowWidth > 730 && (
          <SideMenuProcedures
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
