import React from "react";
import { useAppContext } from "../../AppContext";
import styles from "./EndodonticHero.module.css";
export default function EndodonticHero() {
  const { setDisableAnimations, setIsScrolling } = useAppContext();
  const handleScrollToSchedule = () => {
    setDisableAnimations(true);
    setIsScrolling(true);
    const scheduleSection = document.getElementById("schedule-section");
    if (scheduleSection) {
      scheduleSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <section className={styles.heroSection}>
      <div className={styles.heroContainer}>
        <div className={styles.innerHeader}>
          <h3 className={styles.heroHeader}>
            <span>Take your dental,</span>
            <span>health seriously</span>
          </h3>
          <p className={styles.heroDescription}>
            We offer a wide range of services for<br></br> your every dental
            need.
          </p>
          <div className={styles.ctaBtn}>
            <a
              href="#schedule-section"
              onClick={handleScrollToSchedule}
              className={styles.btnPrimary}
            >
              Book An Appointment
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
