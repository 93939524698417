import React from "react";
import { useAppContext } from "../../AppContext";
import styles from "./ProsthodonticHero.module.css";

export default function ProsthodonticHeroComponent() {
  const { setDisableAnimations, setIsScrolling } = useAppContext();
  const handleScrollToSchedule = () => {
    setDisableAnimations(true);
    setIsScrolling(true);
    const scheduleSection = document.getElementById("schedule-section");
    if (scheduleSection) {
      scheduleSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <section className={styles.heroSection}>
      <div className={styles.container}>
        <div className={styles.heroContent}>
          <div className={styles.heroPositioning}>
            <h2 className={styles.heroHeader}>Rediscover your smile</h2>
            <p1 className={styles.heroDescription}>
              We don't just restore smiles, we meticulously craft personalized
              dental masterpieces
            </p1>

            <div className={styles.btnSection}>
              <a
                href="#schedule-section"
                onClick={handleScrollToSchedule}
                className={styles.btn}
              >
                Book An Appointment
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
