import React, { useState, useEffect } from "react";

import styles from "../CosmeticDentistry/DentalBonding.module.css";
import SideMenuPediatricMenu from "../PediatricDentistry/PediatricMenu/SideMenuPediatricMenu";
import SideMenuPreventativeCare from "./SideMenuPreventativeCare";

export default function DentalSealants({ menuItem, menuItemName, dentistry }) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const pediatricProperty = {
    textColor: "#9086a5",
    spanColor: "#776c8c",
    backgroundColor: "#e6e4eb",
  };

  const styleProperties =
    dentistry === "general"
      ? generalProperty
      : "pediatric"
      ? pediatricProperty
      : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            DENTAL SEALANTS
          </h1>
          <p className={styles.secondaryDescription}>
            As experts in both the aesthetic and functional qualities of the
            mouth, dentist know that healthy teeth are the first step in
            creating a beautiful smile. Dental sealants are highly effective in
            preventing decay on the biting surfaces of your chewing teeth,
            dental sealants are a simple procedure in which a tooth-colored
            composite "coating" is painted onto the surface of the tooth.
          </p>
          <p className={styles.secondaryDescription}>
            Dental sealants effectively "seals" the deep grooves, acting as a
            barrier and protecting enamel from plaque and acids.{" "}
          </p>
          <p className={styles.secondaryDescription}>
            <b>
              {" "}
              Dental sealants protect the depressions and grooves of your teeth
              from food particles and plaque that brushing and flossing can't
              reach.
            </b>
          </p>
          <p className={styles.secondaryDescription}>
            {" "}
            Easy to apply, dental sealants take only a few minutes to seal each
            tooth. Sealants hold up well under the force of normal chewing and
            can last several years before a reapplication is needed.
          </p>
          <p className={styles.secondaryDescription}>
            <b>
              {" "}
              Children and adults can benefit from dental sealants in the fight
              against tooth decay.
            </b>
          </p>
        </div>
        {windowWidth > 730 &&
          (dentistry == "pediatric" ? (
            <SideMenuPediatricMenu
              menuItem={handleMenuClick}
              menuItemName={menuItemName}
              dentistry={dentistry}
            />
          ) : (
            <SideMenuPreventativeCare
              menuItem={handleMenuClick}
              menuItemName={menuItemName}
              dentistry={dentistry}
            />
          ))}
      </div>
    </section>
  );
}
