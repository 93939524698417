import React, { useState, useEffect } from "react";

import styles from "../PediatricMenu/PediatricMenu.module.css";
import SideMenuPediatricMenu from "./SideMenuPediatricMenu";

export default function Bonding({ menuItem, menuItemName, dentistry }) {
  const pediatricProperty = {
    textColor: "#9086a5",
    spanColor: "#776c8c",
    backgroundColor: "#e6e4eb",
  };
  const styleProperties = pediatricProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            BONDING
          </h1>

          <p className={styles.mainDescription}>
            <b>
              Bonding is an alternative to veneers and can be used as a
              restorative procedure for teeth that are chipped, cracked,
              discolored or misarranged.{" "}
            </b>
          </p>
          <h4 className={styles.secondaryQuestion}>How does it work?</h4>
          <p className={styles.secondaryDescription}>
            The tooth is prepared for the procedure by lightly etching the
            surface and applying a bonding liquid. Once the liquid sets,
            composite is applied and sculpted into the desired shape by the
            dentist. Once set, the resin is trimmed, smoothed and polished to a
            natural appearance.
          </p>

          <h4 className={styles.secondaryQuestion}>Considerations</h4>
          <p className={styles.secondaryDescription}>
            The bonding procedure can often be completed in a single office
            visit, and can improve the appearance of a tooth significantly.
            However, since the composite used is not as strong as your natural
            tooth enamel, it is more likely to stain, chip or break than natural
            teeth. Bonding typically lasts three to five years before need of
            repair.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuPediatricMenu
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
