import React, { useState, useEffect } from "react";

import styles from "../CosmeticDentistry/DentalBonding.module.css";
import SideMenuGeneralProsthodonticDentistry from "./SideMenuGeneralProsthodonticDentistry";

export default function PERIODONTALDISEASE({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            PERIODONTAL DISEASE
          </h1>
          <h4 className={styles.secondaryQuestion}>
            What is Periodontal Disease?
          </h4>
          <p className={styles.mainDescription}>
            Periodontal treatment is necessary when various conditions affect
            the health of your gums and the regions of your jaw bone that hold
            your teeth in place. Retaining your teeth is directly dependent on
            proper periodontal care and maintenance. Healthy gums enhance the
            appearance of your teeth, like a frame around a beautiful painting.
            When your gums become unhealthy, they can either recede or become
            swollen and red. In later stages, the supporting bone is destroyed
            and your teeth will shift, loosen, or fall out. These changes not
            only affect your ability to chew and speak. They also spoil your
            smile.
          </p>
          <p className={styles.secondaryDescription}>
            <b>Periodontal diseases </b>are ongoing infections of the gums that
            gradually destroy the support of your natural teeth. Periodontal
            disease affects one or more of the periodontal tissues: alveolar
            bone, periodontal ligament, cementum, or gingiva. While there are
            many diseases which affect the tooth-supporting structures,
            plaque-induced inflammatory lesions make up the majority of
            periodontal issues, and are divided into two categories: gingivitis
            and periodontitis. While gingivitis, the less serious of the
            diseases, may never progress into periodontitis, it always precedes
            periodontitis.
          </p>
          <h4 className={styles.secondaryQuestion}>Gingivitis</h4>
          <p className={styles.secondaryDescription}>
            Dental plaque is the primary cause of gingivitis in
            genetically-susceptible individuals. Plaque is a sticky tooth color
            biofilm , composed primarily of food particles and various types of
            bacteria, which adhere to your teeth at and below the gum line.
            Plaque constantly forms on your teeth, even minutes after cleaning.
            Bacteria found in plaque produce toxins that irritate the gums. Gums
            may become inflamed, red, swollen, and bleed easily. If this
            irritation is prolonged, the gums separate from the teeth causing
            pockets (spaces) to form. If daily brushing and flossing is
            neglected, plaque can also harden into a rough, porous substance
            known as calculus (or tartar). This can occur both above and below
            the gum line.
          </p>
          <h4 className={styles.secondaryQuestion}>Periodontitis</h4>

          <p className={styles.secondaryDescription}>
            If gingivitis progresses into periodontitis, the supporting gum
            tissue and bone that holds teeth in place deteriorates. The
            progressive loss of this bone, the alveolar, can lead to loosening
            and subsequent loss of teeth. Periodontitis is affected by bacteria
            that adhere to the tooth’s surface, along with an overly aggressive
            immune response to these bacteria.
          </p>
          <p className={styles.secondaryDescription}>
            Periodontal disease is dangerous in that it is often painless and
            symptomless. Eighty percent 80% of Indians will be afflicted with
            periodontal disease by age 45, and 4 out of 5 patients with the
            disease are unaware they have it. It is important to maintain proper
            home oral care and regular dentist visits to reduce the risk of
            obtaining this disease.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuGeneralProsthodonticDentistry
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
