import React, { useState, useEffect } from "react";

import styles from "./Instructions.module.css";

import SideMenuInstructions from "./SideMenuInstructions";

export default function EndodonticTreatment({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const endodonticProperty = {
    textColor: "#6fcfff",
    spanColor: "#64bae6",
    backgroundColor: "#e7f1fca0",
  };
  const styleProperties = endodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            BEFORE ENDODONTIC TREATMENT
          </h1>

          <p className={styles.mainDescription}>
            This procedure will be performed using local anesthesia. There are
            usually no restrictions after the procedure concerning driving or
            returning to work. A doctor is available for consultation at all
            times should a problem arise after your treatment.
          </p>

          <p className={styles.secondaryDescription}>
            Continue all medications for blood pressure, diabetes, thyroid
            problems and any other conditions as recommended by your physician.
            If there is a question, please call our office prior to your
            appointment.
          </p>

          <p className={styles.secondaryDescription}>
            <b>Please eat a full breakfast or lunch as applicable.</b>
          </p>
          <p className={styles.secondaryDescription}>
            If you have been advised by your physician or dentist to use
            antibiotic premedication because of mitral valve prolapse (MVP),
            heart murmur, hip, knee, cardiac or other prosthesis, or if you have
            rheumatic heart disease, please make sure you are on the appropriate
            antibiotic on the day of your appointment. If there is a question,
            please call our office prior to your appointment.
          </p>

          <p className={styles.secondaryDescription}>
            If you can take ibuprofen or naproxen sodium, it
            does help reduce inflammation when taken pre-operatively. We
            recommend 2 tablets of either medication 2-4 hours before endodontic
            therapy.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuInstructions
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
