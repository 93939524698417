import React, { useState, useRef, useEffect } from "react";

import { useInView } from "react-intersection-observer";
import OrthodonticDropDownHeader1 from "./OrthodonticDropDownHeader1";
import styles from "./OrthodonticHeader3.module.css";
import { useAppContext } from "../../AppContext";
import DropDownHeader from "../Home/DropDownHeader";

export default function OrthodonticHeader3({ generalMenuItem }) {
  const { setDisableAnimations, setIsScrolling } = useAppContext();
  const [ref, inView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  const [displayList, setDisplay] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const handleMenuItem = (data) => {
    setDisplay("none");
    generalMenuItem(data);
  };

  const handleHover = () => {
    setDisplay("flex");
    setIsHovered(true);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleScrollToSchedule = () => {
    setDisableAnimations(true);
    setIsScrolling(true);
    const scheduleSection = document.getElementById("schedule-section");
    if (scheduleSection) {
      scheduleSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <section className={styles.header}>
      <div
        className={`${styles.headerContainer} ${inView ? styles.animate : ""}`}
        ref={ref}
      >
        <div className={styles.logoSection}>
          <a href="/">
            <h3>Dental Planet</h3>
          </a>
        </div>
        {windowWidth <= 730 ? (
          <DropDownHeader dentistry={"orthodontic"} />
        ) : (
          <nav className={styles.navBar}>
            <ul className={styles.navList}>
              <li className={styles.navItem}>
                <div className={styles.navBlock}>Services</div>
                <ul className={styles.dropDown}>
                  <li className={styles.dropDownItem}>
                    <a href="/general" className={styles.dropDownLink}>
                      General
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/prosthodontic" className={styles.dropDownLink}>
                      Prosthodontic
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/orthodontic" className={styles.dropDownLink}>
                      Orthodontic
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/pediatric" className={styles.dropDownLink}>
                      Pediatric
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/endodontic" className={styles.dropDownLink}>
                      Endodontic
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/periodontal" className={styles.dropDownLink}>
                      Periodontal
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/oralsurgery" className={styles.dropDownLink}>
                      Oral Surgery
                    </a>
                  </li>
                </ul>
              </li>

              <li className={styles.navItem}>
                <div className={styles.navBlock}>
                  <a className={styles.navBlock} href="/orthodontic/Braces">
                    Braces
                  </a>
                </div>

                <ul
                  className={styles.dropDown}
                  style={{ display: displayList }}
                >
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/orthodontic/Braces/OrthoBraces"
                    >
                      Braces
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/orthodontic/Braces/Retainers"
                    >
                      Retainers
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/orthodontic/Braces/Appliances"
                    >
                      Appliances
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/orthodontic/Braces/InOvationSystemBraces"
                    >
                      In-Ovation System Braces
                    </a>
                  </li>
                </ul>
              </li>

              <li className={styles.navItem}>
                <div className={styles.navBlock}>
                  <a
                    className={styles.navBlock}
                    href="/orthodontic/ClearBraces"
                  >
                    Clear Braces
                  </a>
                </div>

                <ul
                  className={styles.dropDown}
                  style={{ display: displayList }}
                >
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/orthodontic/ClearBraces/ClearAligners"
                    >
                      Clear Aligners
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/orthodontic/ClearBraces/ClearCeramicBraces"
                    >
                      Clear Ceramic Braces
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/orthodontic/ClearBraces/ClearAlignersForTeens"
                    >
                      Clear Aligners For Teens
                    </a>
                  </li>
                </ul>
              </li>

              <li className={styles.navItem}>
                <div className={styles.navBlock}>
                  <a
                    className={styles.navBlock}
                    href="/orthodontic/LivingWithBraces"
                  >
                    Living with Braces
                  </a>
                </div>
                <ul
                  className={styles.dropDown}
                  style={{ display: displayList }}
                >
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/orthodontic/LivingWithBraces/Elastics"
                    >
                      Elastics
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/orthodontic/LivingWithBraces/EatingWithBraces"
                    >
                      Eating With Braces
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/orthodontic/LivingWithBraces/PainAndSoreness"
                    >
                      Pain And Soreness
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/orthodontic/LivingWithBraces/BrushingAndFlossing"
                    >
                      Brushing And Flossing
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/orthodontic/LivingWithBraces/RetainersAndAppliances"
                    >
                      Retainers And Appliances
                    </a>
                  </li>
                </ul>
              </li>
              <li className={styles.navItem}>
                <div className={styles.navBlock}>
                  <a className={styles.navBlock} href="/orthodontic/Invisalign">
                    Invisalign
                  </a>
                </div>
                <ul className={styles.dropDown}>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/orthodontic/Invisalign/InvisalignInfo"
                    >
                      Invisalign Information
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/orthodontic/Invisalign/AdvantagesOfInvisalign"
                    >
                      Advantages of Invisalign
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/orthodontic/Invisalign/InvisalignCost"
                    >
                      Invisalign Cost
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/orthodontic/Invisalign/InvisalignTeen"
                    >
                      Invisalign Teen
                    </a>
                  </li>
                  {/* <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/orthodontic/Invisalign/InvisalignVideos"
                    >
                      Invisalign Videos
                    </a>
                  </li> */}
                </ul>
              </li>
              <li className={styles.navItem}>
                <a
                  href="#schedule-section"
                  onClick={handleScrollToSchedule}
                  className={styles.btnPrimary}
                >
                  Book An Appointment
                </a>
              </li>
            </ul>
          </nav>
        )}
      </div>
    </section>
  );
}
