import React, { useState, useEffect } from "react";

import styles from "./Instructions.module.css";

import SideMenuInstructions from "./SideMenuInstructions";

export default function TreatmentFAQ({ menuItem, menuItemName, dentistry }) {
  const endodonticProperty = {
    textColor: "#6fcfff",
    spanColor: "#64bae6",
    backgroundColor: "#e7f1fca0",
  };
  const styleProperties = endodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            AFTER COMPLETION OF ENDODONTIC TREATMENT FAQ
          </h1>
          <h4 className={styles.secondaryQuestion}>
            What should I expect after my endodontic treatment?
          </h4>
          <p className={styles.mainDescription}>
            <b> Endodontic treatment has now been completed. </b>The root canal
            system has been permanently sealed. However, the outer surface is
            sealed with a temporary restoration. A follow-up restoration must be
            placed to protect your tooth against fracture and decay. Please
            telephone your restorative dentist for an appointment. A complete
            report of treatment will be sent to your restorative dentist.
            Included in your treatment is a follow-up examination to evaluate
            the progress of healing. This appointment will require only a few
            minutes and no additional fee will be charged for the first check-up
            visit.{" "}
            <b>Please call for an appointment during the following month. </b>
          </p>

          <p className={styles.secondaryDescription}>
            <b>
              Your tooth is more prone to fracture immediately after endodontic
              treatment. You should chew on the other side until your
              restorative dentist has placed a core build-up and a protective
              restoration, usually a crown.{" "}
            </b>{" "}
            If your tooth’s strength is seriously compromised, the Restorative
            dentist may place a post and core build-up inside the tooth. Your
            restorative dentist and endodontist will determine the appropriate
            restoration to best protect your tooth.
          </p>
          <h4 className={styles.secondaryQuestion}>
            Are there any potential problems after treatment?
          </h4>

          <p className={styles.secondaryDescription}>
            <li>
              <b>Lower teeth and nerve injury.</b> There is a slight possibility
              that nerve injury can occur during root canal treatment to the
              lower posterior teeth. Our Dentists are trained to assess this
              possibility prior to treatment and will advise you accordingly.
              For lower posterior teeth, the root tips may be near a nerve that
              supplies feeling to the lip, chin and gums. Your endodontist is
              trained to design your surgery to minimize the chances of damaging
              this nerve. Rarely, this nerve can become irritated during the
              process of surgery. In these cases, when the local anesthesia
              wears off, you may experience tingling, altered sensation or, in
              rare cases a complete lack of feeling in the affected tissues.
              Should this occur, it is usually temporary and will resolve over a
              period of days, weeks or months. In rare cases, these changes can
              be permanent and/or painful.
            </li>
            <li>
              <b>Upper teeth and sinus communication. </b>The upper teeth are
              situated near your sinuses, and root canal treatment can result in
              a communication between your mouth and the adjacent sinus. Should
              this complication occur, it will usually heal spontaneously. We
              will give you special instructions if this is apparent at the time
              of root canal treatment. We prefer that you don’t blow your nose
              for two to three days after root canal treatment. If you have to
              sneeze, you should sneeze with an open mouth into a tissue. You
              should not create any pressure in the sinus area. If you sense a
              complication after root canal treatment, please contact us.
            </li>
            <li>
              <b>Post-operative infections. </b>Post-operative infections
              occasionally occur. This usually requires just an office visit and
              examination. Many times placing you on an antibiotic for one week
              will take care of the infection Occasionally, other follow-up
              procedures will be needed.
            </li>
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuInstructions
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
