import React, { useState, useEffect } from "react";

import styles from "../Braces/Braces.module.css";
import SideMenuAligners from "./SideMenuAligners";

export default function ClearBraces({ menuItem, menuItemName, dentistry }) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            CLEAR BRACES
          </h1>
          <p className={styles.mainDescription}>
            Clear braces can give you the smile you want without anyone knowing!
            We offer several clear braces options to get you started on the road
            to a happier, healthier smile!. Clear brace solutions use a series
            of clear removable appliances to straighten your teeth without metal
            wires or brackets.
          </p>
          <p>
            The appliances are made through a combination of our Dentist
            expertise and 3-D computer imaging technology.You wear each set of
            appliances for almost 2 weeks, removing them only to eat, drink,
            brush, and floss.
          </p>
          <p>
            As you replace each appliance with the next in the prescribed
            series, your teeth will incrementally move, week by week – until
            they have straightened to the final position the dentist has
            prescribed.
          </p>
          <h4 className={styles.secondaryQuestion}>
            CLEAR BRACES DENTAL TREATMENT
          </h4>
          <ul className={styles.items}>
            <li className={styles.item}>
              <a href="/orthodontic/ClearBraces/ClearAligners">
                Clear Aligners
              </a>
            </li>
            <li className={styles.item}>
              <a href="/orthodontic/ClearBraces/ClearCeramicBraces">
                Clear Creamic Braces
              </a>
            </li>
            <li className={styles.item}>
              <a href="/orthodontic/ClearBraces/ClearAlignersForTeens">
                Clear Aligners For Teens
              </a>
            </li>
          </ul>
        </div>
        {windowWidth > 730 && (
          <SideMenuAligners
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
