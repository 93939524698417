import React, { useState, useEffect } from "react";

import styles from "../CosmeticDentistry/DentalBonding.module.css";
import SideMenuGeneralProsthodonticDentistry from "./SideMenuGeneralProsthodonticDentistry";

export default function TeethCleaning({ menuItem, menuItemName, dentistry }) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            TEETH CLEANING
          </h1>
          {/* <h4 className={styles.secondaryQuestion}>ORAL PROPHYLAXIS</h4> */}
          <p className={styles.mainDescription}>
            It is a procedure done to remove stains and deposits accumulated to
            prevent gum diseases. If not removed by daily brushing, flossing,
            your gums can get infected in flamed leading to gum disease. Oral
            prophylaxis is done to prevent gum diseases.
          </p>
          <p className={styles.secondaryDescription}>
            Gum disease is a common problem that can lead to tooth loss.
          </p>
          <p className={styles.secondaryDescription}>
            <b>
              The mildest form of gum disease is called gingivitis common
              symptoms associated with gingivitis are:
            </b>
          </p>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>Red and swollen gums.</li>
              <li>Gums that bleed when your brush.</li>
              <li>Bad breadth.</li>
              <li>Yellow stains/ smoking stains.</li>
            </ul>
          </p>

          <p className={styles.secondaryDescription}>
            <b>
              Gingivitis if left untreated leads to periodontitis which is an
              advanced form of gum diseases.
            </b>
          </p>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>Reducing gums.</li>
              <li>
                Pockets (gums pull away from the teeth where food gets
                accumulated).
              </li>
              <li>Tooth mobility.</li>
              <li>Pus between teeth and gum.</li>
            </ul>
          </p>

          <h4 className={styles.secondaryQuestion}> Oral Health Tips:</h4>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>Brush your teeth twice a day with Fluoride toothpaste.</li>
              <li>Clean between your teeth once a day.</li>
              <li>Eat a healthy diet and limit snacks.</li>
              <li>Visit your dentist regularly.</li>
            </ul>
          </p>
          <h4 className={styles.secondaryQuestion}>Procedure</h4>

          <p className={styles.secondaryDescription}>
            Ultrasonic scalar is used to with a frequency of 20-45 KHZ to remove
            adherent deposits from teeth & gum line using jet spray of water.
            Inter dental scalar tip is used to remove deposits around tooth &
            gum line. Surface scalar is needed to remove stains on the tooth
            surface.
          </p>
          <h4 className={styles.secondaryQuestion}>Benefits</h4>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>Healthy teeth & gum.</li>
              <li>Prevent tooth decay.</li>
              <li>No malodor.</li>
            </ul>
          </p>
          <h4 className={styles.secondaryQuestion}>
            YOUR ORAL HEALTH IS IMPORTANT FOR YOUR OVERALL HEALTH
          </h4>
        </div>
        {windowWidth > 730 && (
          <SideMenuGeneralProsthodonticDentistry
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
