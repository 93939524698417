import React from "react";

import styles from "./PeriodontalMenu.module.css";
import SideMenuPeriodontalMenu from "./SideMenuPeriodontalMenu";

export default function Grafting({ menuItem, menuItemName, dentistry }) {
  const periodontalProperty = {
    textColor: "#fc7444",
    spanColor: "#978ab1",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = periodontalProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            GUM GRAFTING
          </h1>

          <p className={styles.mainDescription}>
            When recession of the gingiva occurs, the body loses a natural
            defense against both bacterial penetration and trauma. When gum
            recession is a problem,{" "}
            <b>gum reconstruction using grafting techniques </b>
            is an option.
          </p>

          <p className={styles.secondaryDescription}>
            When there is only minor recession, some healthy gingiva often
            remains and protects the tooth, so that no treatment other than
            modifying home care practices is necessary. However, when recession
            reaches the mucosa, the first line of defense against bacterial
            penetration is lost.
          </p>
          <p className={styles.secondaryDescription}>
            In addition, gum recession often results in root sensitivity to hot
            and cold foods as well as an unsightly appearance of the gum and
            tooth. When significant, gum recession can predispose to worsening
            recession and expose the root surface, which is softer than enamel,
            leading to root caries and root gouging.
          </p>

          <p className={styles.secondaryDescription}>
            A <b>gingival graft</b> is designed to solve these problems. A thin
            piece of tissue is taken from the roof of the mouth or gently moved
            over from adjacent areas to provide a stable band of attached
            gingiva around the tooth. The gingival graft may be placed in such a
            way as to cover the exposed portion of the root.
          </p>

          <p className={styles.secondaryDescription}>
            The gingival graft procedure is highly predictable and results in a
            stable, healthy band of attached tissue around the tooth.
          </p>
        </div>
        <SideMenuPeriodontalMenu
          menuItem={handleMenuClick}
          menuItemName={menuItemName}
          dentistry={dentistry}
        />
      </div>
    </section>
  );
}
