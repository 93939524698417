import React, { useState, useEffect } from "react";

import styles from "../PediatricMenu/PediatricMenu.module.css";
import SideMenuPediatricMenu from "./SideMenuPediatricMenu";

export default function ToothFilling({ menuItem, menuItemName, dentistry }) {
  const pediatricProperty = {
    textColor: "#9086a5",
    spanColor: "#776c8c",
    backgroundColor: "#e6e4eb",
  };
  const styleProperties = pediatricProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            TOOTH FILLINGS
          </h1>

          <p className={styles.mainDescription}>
            We are a mercury-free practice. However, many people still have
            silver/mercury fillings in their mouths from years past. These
            fillings are not particularly pleasing to the eye, and we know that
            by unavoidable design, silver/mercury fillings ultimately result in
            a weaker tooth structure. Porcelain inlays and Tooth Colored
            Restorations (onlays) create fillings that are not only beautiful
            (or unnoticeable) but also add strength to weakened teeth.{" "}
            <b>
              These restorations are esthetically pleasing and very strong
              thanks to new bonding technologies.{" "}
            </b>
          </p>
          <h4 className={styles.secondaryQuestion}>
            Disadvantages of Silver fillings:
          </h4>

          <p className={styles.secondaryDescription}>
            Silver fillings have many drawbacks. The edges of the silver filling
            can wear down, become weak or break. This results in the tooth not
            being protected and lets cavities get started once again. With age,
            the metal of a silver filling expands, contracts, and can split.
          </p>
          <p className={styles.secondaryDescription}>
            Silver fillings contain 50 percent mercury. They can corrode, leak
            and cause stains on your teeth and gums.
          </p>
          <p className={styles.secondaryDescription}>
            <b>
              Fortunately, silver fillings can safely be replaced with
              Tooth-Colored Restorations.{" "}
            </b>
          </p>
          <h4 className={styles.secondaryQuestion}>
            Advantages of Tooth-Colored Restorations
          </h4>
          <p className={styles.secondaryDescription}>
            There are many advantages to tooth-colored restorations. Resin
            onlays are bonded to the teeth creating a tight, superior fit to the
            natural tooth. Such restorations can be used in instances where much
            of the tooth structure has been lost. The tooth remains intact and
            stronger.
          </p>
          <p className={styles.secondaryDescription}>
            Since the resin used in tooth-colored restorations contain fluoride
            this can help prevent decay. The resin wears like natural teeth and
            does not require placement at the gum line, which is healthier for
            your gums!
          </p>
          <p className={styles.secondaryDescription}>
            <b>The result is a beautiful smile!</b>
          </p>
          <h4 className={styles.secondaryQuestion}>
            Replacing Silver Fillings with a Tooth Colored Restoration
          </h4>
          <p className={styles.secondaryDescription}>
            You can have your silver fillings replaced with tooth-colored
            restorations (onlays). This process requires two appointments.
          </p>
          <h4
            className={styles.secondaryDescription}
            style={{ marginBottom: "0.5rem" }}
          >
            <b>Your First Appointment:</b>
          </h4>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>
                The old filling is removed along with any additional decay.
              </li>
              <li>
                An impression is made of your teeth. A model of your teeth is
                made and sent to the lab.
              </li>
              <li>A temporary onlay is placed on the tooth.</li>
            </ul>
          </p>
          <p className={styles.secondaryDescription}>
            <b>At the Lab: </b>A resin is carefully placed into the model of
            your teeth. It is then designed to look natural.
          </p>

          <h4
            className={styles.secondaryDescription}
            style={{ marginBottom: "0.5rem" }}
          >
            <b>Your Second Appointment:</b>
          </h4>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>The temporary onlay is removed.</li>
              <li>
                A conditioning gel is placed on your tooth to prepare it for the
                new onlay.
              </li>
              <li>
                Bonding cement is placed on the tooth and a high intensity light
                bonds the resin to the tooth.
              </li>
              <li>The tooth is then polished.</li>
            </ul>
          </p>
          <p className={styles.secondaryDescription}>
            <b>
              Your teeth are restored to a natural look and feel, they are
              stronger and the tooth is protected!{" "}
            </b>
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuPediatricMenu
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
