import React, { useState, useRef, useEffect } from "react";

import { useInView } from "react-intersection-observer";
import GeneralDropDownHeader1 from "./GeneralDropDownHeader1";
import styles from "./GeneralHeader3.module.css";
import { useAppContext } from "../../AppContext";
import DropDownHeader from "../Home/DropDownHeader";
export default function GeneralHeader3({ generalMenuItem }) {
  const [ref, inView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  const { setDisableAnimations, setIsScrolling } = useAppContext();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleScrollToSchedule = () => {
    setDisableAnimations(true);
    setIsScrolling(true);
    const scheduleSection = document.getElementById("schedule-section");
    if (scheduleSection) {
      scheduleSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <section className={styles.header}>
      <div
        className={`${styles.headerContainer} ${inView ? styles.animate : ""}`}
        ref={ref}
      >
        <div className={styles.logoSection}>
          <a href="/">
            <h3>Dental Planet</h3>
          </a>
        </div>
        {windowWidth <= 730 ? (
          <DropDownHeader dentistry={"general"} />
        ) : (
          <nav className={styles.navBar}>
            <ul className={styles.navList}>
              <li className={styles.navItem}>
                <div className={styles.navBlock}>Services</div>
                <ul className={styles.dropDown}>
                  <li className={styles.dropDownItem}>
                    <a href="/general" className={styles.dropDownLink}>
                      General
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/prosthodontic" className={styles.dropDownLink}>
                      Prosthodontic
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/orthodontic" className={styles.dropDownLink}>
                      Orthodontic
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/pediatric" className={styles.dropDownLink}>
                      Pediatric
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/endodontic" className={styles.dropDownLink}>
                      Endodontic
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/periodontal" className={styles.dropDownLink}>
                      Periodontal
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/oralsurgery" className={styles.dropDownLink}>
                      Oral Surgery
                    </a>
                  </li>
                </ul>
              </li>

              <li className={styles.navItem}>
                <div className={styles.navBlock}>
                  {" "}
                  <a className={styles.navBlock} href="/general/General">
                    General
                  </a>
                </div>

                <ul className={styles.dropDown}>
                  <li className={styles.dropDownItem}>
                    <a
                      href="/general/General/OralHygiene"
                      className={styles.dropDownLink}
                    >
                      Oral Hygiene
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      href="/general/General/TeethCleaning"
                      className={styles.dropDownLink}
                    >
                      Teeth Cleaning
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      href="/general/General/PeriodontalDisease"
                      className={styles.dropDownLink}
                    >
                      Periodontal Disease
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      href="/general/General/TeethCleaning"
                      className={styles.dropDownLink}
                    >
                      Teeth Cleaning
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      href="/general/General/WomenAndPeriodontalHealth"
                      className={styles.dropDownLink}
                    >
                      Women and Periodontal Health
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      href="/general/General/DentalSpecialties"
                      className={styles.dropDownLink}
                    >
                      Dental Specialties
                    </a>
                  </li>
                </ul>
              </li>

              <li className={styles.navItem}>
                <div className={styles.navBlock}>
                  {" "}
                  <a
                    className={styles.navBlock}
                    href="/general/PreventativeCare"
                  >
                    Preventative Care
                  </a>
                </div>
                <ul className={styles.dropDown}>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/general/PreventativeCare/TeethCleaningDentalCheckups"
                    >
                      Teeth Cleaning & Dental Checkups
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      href="/general/PreventativeCare/OralHygiene"
                      className={styles.dropDownLink}
                    >
                      Oral Hygiene
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      href="/general/PreventativeCare/ChildDentistry"
                      className={styles.dropDownLink}
                    >
                      Child Dentistry
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      href="/general/PreventativeCare/DentalSealants"
                      className={styles.dropDownLink}
                    >
                      Dental Sealants
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      href="/general/PreventativeCare/Arestin"
                      className={styles.dropDownLink}
                    >
                      Arestin
                    </a>
                  </li>

                  <li className={styles.dropDownItem}>
                    <a
                      href="/general/PreventativeCare/GumDiseaseTreatment"
                      className={styles.dropDownLink}
                    >
                      Gum Disease Treatment
                    </a>
                  </li>
                </ul>
              </li>

              <li className={styles.navItem}>
                <div className={styles.navBlock}>
                  {" "}
                  <a className={styles.navBlock} href="/general/Restorative">
                    Restorative
                  </a>
                </div>
                <ul className={styles.dropDown}>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/general/Restorative/DentalFillings"
                    >
                      Dental Fillings
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/general/Restorative/DentalImplants"
                    >
                      Dental Implants
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/general/Restorative/DentalBridges"
                    >
                      Dental bridges
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/general/Restorative/DentalCrowns"
                    >
                      Dental Crowns
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/general/Restorative/NonSurgicalRootCanal"
                    >
                      Non-surgical Root Canal
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      href="/general/Restorative/ToothExtractions"
                      className={styles.dropDownLink}
                    >
                      Tooth Extraction
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      href="/general/Restorative/BruxismTreatment"
                      className={styles.dropDownLink}
                    >
                      Bruxism Treatment
                    </a>
                  </li>
                  {/* <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/general/Restorative/ToothExtractions"
                    >
                      Tooth Extractions
                    </a>
                  </li> */}
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/general/Restorative/Dentures"
                    >
                      Dentures
                    </a>
                  </li>
                </ul>
              </li>

              <li className={styles.navItem}>
                <div className={styles.navBlock}>
                  <a
                    className={styles.navBlock}
                    href="/general/CosmeticDentistry"
                  >
                    Cosmetic
                  </a>
                </div>
                <ul className={styles.dropDown}>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/general/CosmeticDentistry/DentalBonding"
                    >
                      Dental Bonding
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/general/CosmeticDentistry/PorcelainVeins"
                    >
                      Porcelain Veneers
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/general/CosmeticDentistry/InlaysOnlays"
                    >
                      Inlays & Onlays
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/general/CosmeticDentistry/TeethWhitening"
                    >
                      Teeth Whitening
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/general/CosmeticDentistry/E4DDentist"
                    >
                      E4D Dentist
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/general/CosmeticDentistry/ClearBraces"
                    >
                      Aligners
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a
                      className={styles.dropDownLink}
                      href="/general/CosmeticDentistry/CEREC"
                    >
                      CEREC
                    </a>
                  </li>
                </ul>
              </li>

              <li className={styles.navItem}>
                <a
                  href="#schedule-section"
                  onClick={handleScrollToSchedule}
                  className={styles.btnPrimary}
                >
                  Book An Appointment
                </a>
              </li>
            </ul>
          </nav>
        )}
      </div>
    </section>
  );
}
