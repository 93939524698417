import React from "react";

import styles from "./RegistrationFormHero.module.css";
export default function RegistrationFormHero() {
  return (
    <section className={styles.heroSection}>
      <div className={styles.heroContainer}>
        <div className={styles.innerHeader}>
          <h3 className={styles.heroDescription}>
            <span>Schedule Appointment</span>
          </h3>
          <hr className={styles.contactHorizontal}></hr>
        </div>
      </div>
    </section>
  );
}
