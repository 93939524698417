import React, { useState, useEffect } from "react";

import styles from "../CosmeticDentistry/DentalBonding.module.css";
import SideMenuRestorativeDentistry from "./SideMenuRestorativeDentistry";

export default function DentalFillings({ menuItem, menuItemName, dentistry }) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      //  style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            Dental Fillings
          </h1>

          <h4 className={styles.secondaryQuestion}>
            Enjoy a More Natural Smile with Composite Dental Fillings
          </h4>
          <p className={styles.mainDescription}>
            Tooth decay is one of the most common chronic health conditions.
            Fortunately, there are ways to prevent it, and in cases where decay
            is unavoidable, a dental filling is an affordable, natural-looking,
            and safe solution. Not only are our fillings at Dental Planet aare
            some of the most aesthetic restorations available today, but they
            are strong and long-lasting as well.
          </p>
          <h4 className={styles.secondaryQuestion}>
            Dental Fillings—The Best Solution for Tooth Restoration
          </h4>
          <p className={styles.secondaryDescription}>
            At Dental, we rely on high-quality materials, latest technology, and
            advanced techniques to create restorations for our patients that
            last a lifetime. Our goal is to create results that are designed to
            be virtually undetectable. We use tooth-colored fillings as
            restorations when our patients have:
            <ul className={styles.subDescription}>
              <li>Tooth decay</li>
              <li>Chipped teeth</li>
              <li>Cracked teeth</li>
            </ul>
          </p>
          <h4 className={styles.secondaryQuestion}>
            How do I know if I need a filling?
          </h4>
          <p className={styles.secondaryDescription}>
            If you are experiencing discomfort in your teeth, you may be a good
            case for a filling. Teeth sensitivity is an effective indicator of
            decay or fracture. When you come in for an evaluation, Dentist will
            direct you to the appropriate dental treatment for your situation.
            We typically recommend fillings as the best solution for restoring
            teeth that have severe tooth decay, cracks, chips and cavities.
          </p>
          <h4 className={styles.secondaryQuestion}>
            Cavity Prevention — What can I do to prevent the need for a filling?
          </h4>
          <p className={styles.secondaryDescription}>
            The best way to fight cavities and maintain healthy gums and teeth
            is to keep up on your daily habit of proper oral hygiene. Here are 5
            ways to ward off tooth decay and prevent cavities:
            <ul className={styles.subDescription}>
              <li>
                Come in to our office for a comprehensive oral exam and regular
                cleanings.
              </li>
              <li>Brush after meals and use an antimicrobial mouthwash.</li>
              <li>
                Drink more water and cut back on sugary and acidic drinks.
              </li>
              <li>Develop the habit of flossing every night.</li>
              <li>Avoid smoking.</li>
            </ul>{" "}
          </p>
          <h4 className={styles.secondaryQuestion}>
            Dental Fillings—The Process
          </h4>
          <p className={styles.secondaryDescription}>
            The procedure for placing a composite resin dental filling is a
            routine treatment that we do every day at Dental Planet. Here’s how
            it works:
            <ol className={styles.suborderDescription}>
              <li>
                Your Dentist will isolate the tooth from saliva to keep it dry.
              </li>
              <li>Any dental decay is removed.</li>
              <li>
                An etchant and a bonding agent are applied, followed by the
                composite resin.
              </li>
              <li>
                A special light is used to harden each layer of composite resin
                material.
              </li>
              <li>
                After the tooth is filled, the composite resin is shaped to
                resemble a real tooth.
              </li>
              <li>
                Finally, the restoration is smoothed and polished to accommodate
                your bite.
              </li>
            </ol>
          </p>
          <h4 className={styles.secondaryQuestion}>
            What’s the difference between a filling and an inlay/onlay?
          </h4>
          <p className={styles.secondaryDescription}>
            A filling does what it says it does—it is filling in a cavity, a
            hole that has been created by decay or trauma, essentially restoring
            normal tooth anatomy. When we create a filling from composite resin,
            we shade the white material so that it blends naturally with your
            surrounding tooth structure. The result is a restoration that is
            practically invisible.{" "}
            <b
              className={styles.mainSpan}
              style={{ color: styleProperties.spanColor }}
            >
              Inlays and onlays{" "}
            </b>{" "}
            are used when the damage or decay to a tooth requires more than a
            filling—but less than a crown. In other words, if your tooth does
            not have enough tooth structure for a filling but it is not so
            severely damaged that it warrants a crown, you may be an excellent
            case for an inlay or onlay. Made of porcelain, an inlay is crafted
            at a dental lab by an expert ceramist and cemented into or onto the
            center of the tooth, inside the cusp tips of the tooth. An onlay
            covers the top chewing surface of the tooth. We use porcelain
            because it very closely mimics enamel and offers decades of strength
            and security.
          </p>
          <h4 className={styles.secondaryQuestion}>
            What are composite resin fillings and restorations?
          </h4>
          <p className={styles.secondaryDescription}>
            Composite resin fillings are the most common alternative to dental
            amalgam. They are sometimes called “tooth-colored” or “white”
            fillings because of their color. Composite resin fillings are made
            of a type of plastic (an acrylic resin) reinforced with powdered
            glass filler. Composite resin is often placed in layers to build up
            the final restoration, then cured with an “ultra-violet light”. This
            light energy speeds up a chemical reaction of the components causing
            the soft material to harden and bond to the tooth. This material is
            considered safe. The color (shade) of composite resins can be
            customized to closely match surrounding teeth. This plastic and
            glass mixture contains no metal and can be shaped to resemble a real
            tooth. It is usually hard to tell that a tooth has even been filled!
            Composite resin fillings have several advantages. The “white” color
            is preferred by many patients due to its ability to blend in with
            surrounding teeth. It is also possible to make a more conservative
            preparations when using composite material, thus enabling the
            dentist to preserve more natural tooth structure.
            <ul className={styles.subDescription}>
              <li>Benefits of Composite Resin Restorations</li>
              <li>Preferred white/natural-looking color</li>
              <li>Better for smaller fillings</li>
              <li>Preservation of more tooth structure</li>
            </ul>
            <br></br>
            Since the resin used in tooth-colored restorations contains
            fluoride, it can help prevent decay. The resin wears like natural
            teeth and does not require placement at the gum line, another
            advantage that reinforces gum health.
          </p>
          <h4 className={styles.secondaryQuestion}>
            What’s wrong with metal/amalgam fillings?
          </h4>
          <p className={styles.secondaryDescription}>
            In addition to being more aesthetically pleasing, composite fillings
            are completely safe and non-toxic. However, some believe that none
            of these advantages ring true for metal fillings. Some research
            suggests that amalgam fillings—made with mercury, silver, tin,
            copper and other metals—may lead to health problems. The American
            Dental Association Council on Scientific Affairs has concluded that
            both amalgam and composite materials are considered safe and
            effective for tooth restoration. We are excited to be able to
            restore decayed teeth to their optimal appearance and function with
            fillings, inlays, and onlays. At Dental Planet, we use dental
            fillings to help adult and pediatric patients preserve their
            healthy, beautiful teeth and avoid additional damage.
          </p>
          <p className={styles.secondaryDescription}>
            The result of high-quality composite fillings is a beautiful,
            healthy smile. For more information about our dental fillings, call
            our office today!
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuRestorativeDentistry
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
