import React from "react";

import styles from "./SideMenuCosmeticDentistry.module.css";
import { IonIcon } from "@ionic/react";
import { caretForwardOutline } from "ionicons/icons";
export default function SideMenuCosmeticDentistry({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const generalProperty = {
    backgroundColor: "#ffa07d",
  };
  const prosthodonticProperty = {
    backgroundColor: "#b6d2d7",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;

  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  return (
    <div
      className={styles.menuSection}
      style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <h2 className={styles.secondaryHeading}>Cosmetic Dentistry</h2>
      <ul className={styles.main_nav_list}>
        <li className={styles.service}>
          {menuItemName === "DentalBonding" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/general/CosmeticDentistry/DentalBonding"
          >
            <p className={styles.serviceHeader}>Dental Bonding</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "PorcelainVeins" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/general/CosmeticDentistry/PorcelainVeins"
          >
            <p className={styles.serviceHeader}>Porcelain Veneers</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "InlaysOnlays" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/general/CosmeticDentistry/InlaysOnlays"
          >
            <p className={styles.serviceHeader}>Inlays & Onlays</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "TeethWhitening" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/general/CosmeticDentistry/TeethWhitening"
          >
            <p className={styles.serviceHeader}>Teeth Whitening</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "E4DDentist" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/general/CosmeticDentistry/E4DDentist"
          >
            <p className={styles.serviceHeader}>E4D Dentist</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "ClearBraces" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/general/CosmeticDentistry/ClearBraces"
          >
            <p className={styles.serviceHeader}>Aligners</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "CEREC" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/general/CosmeticDentistry/CEREC"
          >
            <p className={styles.serviceHeader}>CEREC</p>
          </a>
        </li>
      </ul>
    </div>
  );
}
