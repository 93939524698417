import React, { useState, useEffect } from "react";

import styles from "../PediatricMenu/PediatricMenu.module.css";
import SideMenuPediatricMenu from "./SideMenuPediatricMenu";

export default function CavityPrevention({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const pediatricProperty = {
    textColor: "#9086a5",
    spanColor: "#776c8c",
    backgroundColor: "#e6e4eb",
  };
  const styleProperties = pediatricProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            CAVITY PREVENTION
          </h1>

          <p className={styles.mainDescription}>
            Most of the time cavities are due to a diet high in sugary foods and
            a lack of brushing.
          </p>

          <p className={styles.mainDescription}>
            Limiting sugar intake and brushing regularly, of course, can help.
            The longer it takes your child to chew their food, the longer the
            residue stays on their teeth and the greater the chances of getting
            cavities.
          </p>
          <p className={styles.mainDescription}>
            Every time someone eats, an acid reaction occurs inside their mouth
            as the bacteria digests the sugars. This reaction lasts
            approximately 20 minutes. During this time the acid environment can
            destroy the tooth structure, eventually leading to cavities.
          </p>
          <p className={styles.mainDescription}>
            Consistency of a person’s saliva also makes a difference as thinner
            saliva breaks up and washes away food more quickly. When a person
            eats diets high in carbohydrates and sugars, they tend to have
            thicker saliva, which in turn produces more of the acid-producing
            bacteria that causes cavities.
          </p>

          <h4
            className={styles.secondaryQuestion}
            style={{ marginBottom: "0.5rem" }}
          >
            Some tips for cavity prevention:
          </h4>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>Limit frequency of meals and snacks.</li>
              <li>Encourage brushing, flossing, and rinsing.</li>
              <li>Watch what you drink.</li>
              <li>Avoid sticky foods.</li>
              <li>Make treats part of meals.</li>
              <li>Choose nutritious snacks.</li>
            </ul>
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuPediatricMenu
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
