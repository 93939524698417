import React, { useState, useEffect } from "react";

import styles from "../PediatricMenu/PediatricMenu.module.css";
import SideMenuPediatricMenu from "./SideMenuPediatricMenu";

export default function InlaysOnlays({ menuItem, menuItemName, dentistry }) {
  const pediatricProperty = {
    textColor: "#9086a5",
    spanColor: "#776c8c",
    backgroundColor: "#e6e4eb",
  };
  const styleProperties = pediatricProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            INLAYS & ONLAYS
          </h1>

          <p className={styles.mainDescription}>
            <b>
              When more than half of the tooth’s biting surface is damaged, a
              dentist will often use an inlay or onlay.
            </b>
          </p>
          <h4 className={styles.secondaryQuestion}>
            What are inlays and onlays?
          </h4>
          <p className={styles.secondaryDescription}>
            Inlays and onlays can be made of porcelain, gold, or composite
            resin. These pieces are bonded to the damaged area of the tooth. An
            inlay, which is similar to a filling, is used inside the cusp tips
            of the tooth. An onlay is a more substantial reconstruction, similar
            to the inlay but extending out over one or more of the cusps of the
            tooth.
          </p>
          <p className={styles.secondaryDescription}>
            Traditionally, gold has been the material of choice for inlays and
            onlays. In recent years, however, porcelain has become increasingly
            popular due to its strength and color, which can potentially match
            the natural color of your teeth.
          </p>
          <h4 className={styles.secondaryQuestion}>
            How are inlays and onlays applied?
          </h4>
          <p className={styles.secondaryDescription}>
            <b>
              Inlays and onlays require two appointments to complete the
              procedure.{" "}
            </b>
            During the first visit, the filling being replaced or the damaged or
            decaying area of the tooth is removed, and the tooth is prepared for
            the inlay or onlay. To ensure proper fit and bite, an impression of
            the tooth is made by the dentist, and sent to a lab for fabrication.
            The dentist will then apply a temporary filling on the tooth and
            schedule the next appointment.
          </p>
          <p className={styles.secondaryDescription}>
            At the second appointment, the temporary filling is removed.Dentist will then make sure that the inlay or onlay fits
            correctly. If the fit is satisfactory, the inlay or onlay will be
            bonded to the tooth with a strong resin and polished to a smooth
            finish.
          </p>

          <h4 className={styles.secondaryQuestion}>
            Considerations for inlays and onlays
          </h4>
          <p className={styles.secondaryDescription}>
            Traditional fillings can reduce the strength of a natural tooth by
            up to 50 percent. As an alternative, inlays and onlays, which are
            bonded directly onto the tooth using special high-strength resins,
            can actually increase the strength of a tooth by up to 75 percent.
            As a result, they can last from 10 to 30 years. In some cases, where
            the damage to the tooth is not extensive enough to merit an entire
            crown, onlays can provide a very good alternative.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuPediatricMenu
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
