import React, { useState, useEffect } from "react";

import styles from "../PediatricMenu/PediatricMenu.module.css";
import SideMenuPediatricMenu from "./SideMenuPediatricMenu";

export default function ToothBrushing({ menuItem, menuItemName, dentistry }) {
  const pediatricProperty = {
    textColor: "#9086a5",
    spanColor: "#776c8c",
    backgroundColor: "#e6e4eb",
  };
  const styleProperties = pediatricProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            TOOTH BRUSHING
          </h1>
          <h4 className={styles.secondaryQuestion}>
            Brushing & Flossing Instructions
          </h4>
          <p className={styles.mainDescription}>
            Children’s hands and mouths are different than adults. They need to
            use toothbrushes designed for children. Both adults and children
            should use brushes with soft, rounded bristles for gentle cleaning.
            Change to a new brush about every three months.
          </p>

          <p className={styles.secondaryDescription}>
            Wipe infant’s teeth gently with a moist, soft cloth or gauze square.
            As babies grow, use a child’s toothbrush with a small, pea-sized dab
            of toothpaste. By age two or three begin to teach your child to
            brush. You will still need to brush where they miss. Dentists and
            hygienists often advise children to use a gentle, short, back and
            forth motion to remove plaque. When children are older, they can
            switch to this method.
          </p>
          <p
            className={styles.secondaryDescription}
            style={{ marginBottom: "0.5rem" }}
          >
            Hold the brush at a 45 degrees angle towards teeth and gums. Move
            brush back and forth with short strokes, about a half tooth wide.
          </p>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>
                Brush the inside and outside surfaces of each tooth, top and
                bottom.
              </li>
              <li>
                Hold the brush flat on top of the teeth and brush the chewing
                surfaces.
              </li>
              <li>Gently brush the tongue to remove debris.</li>
              <li>Floss between teeth daily.</li>
            </ul>
          </p>
          <h4 className={styles.secondaryQuestion}>When To Begin Brushing</h4>
          <p className={styles.mainDescription}>
            Once your child’s teeth begin erupting, you can begin cleaning them
            by wiping them with a moist washcloth. As your child gets more
            teeth, you can begin to use a soft child’s toothbrush. You should
            use just a pea-sized amount of toothpaste (such as Baby OraGel)
            until your child is able to spit it out (too much fluoride can stain
            their teeth).
          </p>
          <p
            className={styles.secondaryDescription}
            style={{ marginBottom: "0.5rem" }}
          >
            For most toddlers, getting them to brush their teeth can be quite a
            challenge. Some suggestions for making tooth brushing less of a
            battle can include:
          </p>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>Let your child brush your teeth at the same time.</li>
              <li>
                Let your child pick out a few toothbrushes with his favorite
                characters and giving him a choice of which one he wants to use
                each time (this will give him some feeling of control over the
                situation).
              </li>
              <li>
                Let your child brush his own teeth first (you will likely have
                to “help out”).
              </li>
              <li>
                Read your child some children’s books about tooth brushing.
              </li>
              <li>Have everyone brush their teeth at the same time.</li>
            </ul>
          </p>
          <p className={styles.secondaryDescription}>
            To help your child understand the importance of brushing, it can be
            sometimes fun and helpful to let them eat or drink something that
            will “stain“ their teeth temporarily and then brush them clean.
          </p>
          <p className={styles.secondaryDescription}>
            It can also be a good idea to create a “tooth brushing routine”. And
            stick to the same routine each day.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuPediatricMenu
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
