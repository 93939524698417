import React from "react";
import { useAppContext } from "../../AppContext";
import styles from "./OrthodonticHeroMenu.module.css";

export default function OrthodonticHeroMenu() {
  const { setDisableAnimations, setIsScrolling } = useAppContext();
  const handleScrollToSchedule = () => {
    setDisableAnimations(true);
    setIsScrolling(true);
    const scheduleSection = document.getElementById("schedule-section");
    if (scheduleSection) {
      scheduleSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <section className={styles.heroSection}>
      <div className={styles.heroContainer}>
        <div className={styles.innerHeader}>
          <h1 className={styles.heroHeader}>Orthodontic Dentistry</h1>
          <a
            href="#schedule-section"
            onClick={handleScrollToSchedule}
            className={styles.btnSchedule}
          >
            Schedule An Appointment
          </a>
        </div>
      </div>
    </section>
  );
}
