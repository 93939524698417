import React, { useState, useRef, useEffect } from "react";

import { useInView } from "react-intersection-observer";
import DropDownHeader from "./DropDownHeader";
import styles from "./Header2.module.css";
import { useAppContext } from "../../AppContext";

export default function Header2({ serviceName }) {
  const { setDisableAnimations, setIsScrolling } = useAppContext();
  const [ref, inView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleScrollToSchedule = () => {
    setDisableAnimations(true);
    setIsScrolling(true);
    const scheduleSection = document.getElementById("schedule-section");
    if (scheduleSection) {
      scheduleSection.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  return (
    <section className={styles.header}>
      <div
        className={`${styles.headerContainer} ${inView ? styles.animate : ""}`}
        ref={ref}
      >
        <div className={styles.logoSection}>
          <a href="/">
            <h3>Dental Planet</h3>
          </a>
        </div>
        {windowWidth <= 730 ? (
          <DropDownHeader dentistry={"home"} />
        ) : (
          <nav className={styles.navBar}>
            <ul className={styles.navList}>
              <li className={styles.navItem}>
                <a href="/about" className={styles.navLink}>
                  About
                </a>
              </li>
              <li className={styles.navItem}>
                <a href="/patient">
                  {" "}
                  <div className={styles.navBlock}>Patient</div>
                  <ul className={styles.dropDown}>
                    <li className={styles.dropDownItem}>
                      <a
                        href="/patient/FirstVisit"
                        className={styles.dropDownLink}
                      >
                        First Visit
                      </a>
                    </li>
                    <li className={styles.dropDownItem}>
                      <a
                        href="/patient/NewPatients"
                        className={styles.dropDownLink}
                      >
                        New Patients
                      </a>
                    </li>
                    <li className={styles.dropDownItem}>
                      <a
                        href="/patient/Scheduling"
                        className={styles.dropDownLink}
                      >
                        Scheduling
                      </a>
                    </li>
                    <li className={styles.dropDownItem}>
                      <a
                        href="/patient/PatientRegistration"
                        className={styles.dropDownLink}
                      >
                        Patient Registration
                      </a>
                    </li>
                  </ul>
                </a>
              </li>
              <li className={styles.navItem}>
                <div className={styles.navBlock}>Services</div>
                <ul className={styles.dropDown}>
                  <li className={styles.dropDownItem}>
                    <a href="/general" className={styles.dropDownLink}>
                      General
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/prosthodontic" className={styles.dropDownLink}>
                      Prosthodontic
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/orthodontic" className={styles.dropDownLink}>
                      Orthodontic
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/pediatric" className={styles.dropDownLink}>
                      Pediatric
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/endodontic" className={styles.dropDownLink}>
                      Endodontic
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/periodontal" className={styles.dropDownLink}>
                      Periodontal
                    </a>
                  </li>
                  <li className={styles.dropDownItem}>
                    <a href="/oralsurgery" className={styles.dropDownLink}>
                      Oral Surgery
                    </a>
                  </li>
                </ul>
              </li>

              <li className={styles.navItem}>
                <a href="/contact" className={styles.navLink}>
                  Contact
                </a>
              </li>
              <li className={styles.navItem}>
                <a
                  href="#schedule-section"
                  onClick={handleScrollToSchedule}
                  className={styles.btnPrimary}
                >
                  Book An Appointment
                </a>
              </li>
            </ul>
          </nav>
        )}
      </div>
    </section>
  );
}
