import React, { useEffect, useState } from "react";

import styles from "./PeriodontalMenu.module.css";
import SideMenuPeriodontalMenu from "./SideMenuPeriodontalMenu";

export default function Bruxism({ menuItem, menuItemName, dentistry }) {
  const periodontalProperty = {
    textColor: "#fc7444",
    spanColor: "#978ab1",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = periodontalProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            BRUXISM TREATMENT
          </h1>

          <p className={styles.mainDescription}>
            <b>Bruxism </b>is an oral parafunctional activity that commonly
            occurs in most people at some point in their lives. The two main
            characteristics of this condition are grinding of the teeth and
            clenching of the jaw. These actions usually occur during a person’s
            sleeping hours, but occasionally they occur during the day.
          </p>

          <p className={styles.secondaryDescription}>
            Bruxism is one of the most common known sleep disorders. Chewing is
            a neuromuscular activity controlled by a subconscious process, but
            more highly controlled by the brain. During sleep, the subconscious
            process may become active, while the higher control is inactive
            (asleep), resulting in bruxism. The most common symptoms are
            earaches, headaches, depression, eating disorders, anxiety, and
            chronic stress.
          </p>
          <p className={styles.secondaryDescription}>
            A BiteStrip® is a device used to diagnose bruxism at home. The
            device itself is a small electromyography, which can sense and
            monitor activity in the jaw muscles during sleep. The frequency and
            severity of the condition can then be assessed and a plan of
            treatment can be determined.
          </p>
          <h4
            className={styles.secondaryQuestion}
            style={{ marginBottom: "0.5rem" }}
          >
            Why should I seek treatment for Bruxism?
          </h4>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>
                <b>Gum recession.</b>
                Bruxism is a leading cause of gum recession and tooth loss.
                Grinding can damage the soft tissue directly and lead to loose
                teeth and deep pockets where bacteria are able to colonize and
                decay the supporting bone.
              </li>
              <li>
                <b>Facial pain.</b>
                Grinding can eventually shorten and blunt the teeth. This can
                lead to muscle pain in the myofascial region and in severe
                cases, incapacitating headaches.
              </li>
              <li>
                <b>Occlusal trauma.</b> The abnormal wear patterns on the
                occlusal (chewing) surfaces of the teeth can lead to fractures,
                which, if left untreated, may require restorative treatment at a
                later time.
              </li>
              <li>
                <b>Arthritis.</b> In the most severe cases, bruxism can
                eventually lead to painful arthritis in the temporomandibular
                (TMJ) joints that allow the jaw to open and close smoothly.
              </li>
            </ul>
          </p>
          <h4 className={styles.secondaryQuestion}>
            Bruxism Treatment Options
          </h4>
          <p
            className={styles.secondaryDescription}
            style={{ marginBottom: "0.5rem" }}
          >
            Though there is no known cure for bruxism,
            <b>
              {" "}
              there are a variety of devices and services available through our
              office to help treat bruxism:
            </b>
          </p>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>
                <b>Mouthguards.</b> An acrylic mouthguard can be designed from
                teeth impressions to minimize the abrasive grinding action
                during normal sleep. Mouthguards must be worn on a long-term
                basis to help prevent tooth damage.
              </li>
              <li>
                <b> NTI-tss device: </b> This device only covers the front teeth
                and must be fitted at our office. The idea behind the NTI-tss is
                to prevent grinding the rear molars by limiting the contraction
                of the temporalis muscle in the jaw.
              </li>
              {/* <li>
                <b>Botox®: </b>Botox® can be injected into the muscles
                responsible for bruxing by disabling them enough to prevent
                grinding, but not enough to disrupt normal functions like
                speaking and chewing.
              </li> */}
            </ul>
          </p>
          <p className={styles.secondaryDescription}>
            Once bruxing is under control, we can perform a variety of dental
            procedures to restore the pleasant aesthetic appearance to your
            smile such as crown application, gum grafts, and crown lengthening.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuPeriodontalMenu
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
