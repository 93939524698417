import React from "react";

import styles from "./SideMenuPediatricMenu.module.css";
import { IonIcon } from "@ionic/react";
import { caretForwardOutline } from "ionicons/icons";
export default function SideMenuPediatricMenu({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const pediatricProperty = {
    backgroundColor: "#cec9d7",
  };
  const styleProperties = pediatricProperty;

  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  return (
    <div
      className={styles.menuSection}
      style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <h2 className={styles.secondaryHeading}>Pediatric Dentistry</h2>
      <ul className={styles.main_nav_list}>
        <li className={styles.service}>
          {menuItemName === "ToothBrushing" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/pediatric/pediatricdentistry/ToothBrushing"
          >
            <p className={styles.serviceHeader}>Tooth Brushing</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "DigitSucking" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/pediatric/pediatricdentistry/DigitSucking"
          >
            <p className={styles.serviceHeader}>Digit Sucking</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "ToothEruption" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/pediatric/pediatricdentistry/ToothEruption"
          >
            <p className={styles.serviceHeader}>Tooth Eruption</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "ToothDecay" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/pediatric/pediatricdentistry/ToothDecay"
          >
            <p className={styles.serviceHeader}>Early Childhood Tooth Decay</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "CavityPrevention" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/pediatric/pediatricdentistry/CavityPrevention"
          >
            <p className={styles.serviceHeader}>Cavity Prevention</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "GumDisease" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/pediatric/pediatricdentistry/GumDisease"
          >
            <p className={styles.serviceHeader}>Gum Disease</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "OralCare" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/pediatric/pediatricdentistry/OralCare"
          >
            <p className={styles.serviceHeader}> Adolescence and Oral Care</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "ToothFillings" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/pediatric/pediatricdentistry/ToothFillings"
          >
            <p className={styles.serviceHeader}> Fillings / Restorations</p>
          </a>
        </li>
        {/* <li className={styles.service}>
          {menuItemName === "AmalgamFillings" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/pediatric/pediatricdentistry/AmalgamFillings"
          >
            <p className={styles.serviceHeader}> Amalgam Fillings</p>
          </a>
        </li> */}
        <li className={styles.service}>
          {menuItemName === "DentalSealants" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/pediatric/pediatricdentistry/DentalSealants"
          >
            <p className={styles.serviceHeader}> Dental Sealants</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "Extractions" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/pediatric/pediatricdentistry/Extractions"
          >
            <p className={styles.serviceHeader}> Tooth Extractions</p>
          </a>
        </li>
        {/* <li className={styles.service}>
          {menuItemName === "Bonding" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/pediatric/pediatricdentistry/Bonding"
          >
            <p className={styles.serviceHeader}> Bonding</p>
          </a>
        </li> */}
        <li className={styles.service}>
          {menuItemName === "InlaysOnlays" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/pediatric/pediatricdentistry/InlaysOnlays"
          >
            <p className={styles.serviceHeader}> Inlays & Onlays</p>
          </a>
        </li>
      </ul>
    </div>
  );
}
