import React, { useState, useEffect } from "react";

import styles from "../CosmeticDentistry/DentalBonding.module.css";
import SideMenuPreventativeCare from "./SideMenuPreventativeCare";

export default function Arestin({ menuItem, menuItemName, dentistry }) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            ARESTIN
          </h1>

          <p className={styles.mainDescription}>
            <b>Periodontal disease </b>
            is a serious infection under your gum line.
          </p>
          <p className={styles.secondaryDescription}>
            ARESTIN® (minocycline hydrochloride) is an antibiotic that kills the
            bacteria that causes the infection.
          </p>
          <p
            className={styles.secondaryDescription}
            style={{ marginTop: "-2.2rem" }}
          >
            <ul className={styles.subDescription}>
              <li>
                It’s placed directly in the infected areas—or “pockets”—in your
                gums.
              </li>
              <li>
                It’s applied right after scaling and root planing, the dental
                procedure that disrupts stubborn plaque and bacteria below your
                gum line—where brushing and flossing can’t reach.
              </li>
            </ul>
          </p>
          <p className={styles.secondaryDescription}>
            ARESTIN® starts working quickly, right at the source of infection,
            and keeps fighting bacteria long after you leave the dental office.
          </p>

          <p className={styles.secondaryDescription}>
            ARESTIN® fights infection and inflammation for 30 days, and provides
            significantly better results than scaling and root planing alone for
            up to 90 days.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuPreventativeCare
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
