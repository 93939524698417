import React, { useEffect, useState } from "react";

import styles from "./Instructions.module.css";
import SideMenuInstructions from "./SideMenuInstructions";

export default function MultipleExtractions({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const oralsurgery = {
    textColor: "#d099d9",
    spanColor: "#644a68",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = oralsurgery;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
    //  style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            AFTER THE REMOVAL OF MULTIPLE TEETH
          </h1>
          <h4 className={styles.secondaryQuestion}>
            Home Instructions After the Removal of Multiple Teeth
          </h4>
          <p className={styles.mainDescription}>
            A small amount of bleeding is to be expected following the operation
            to
            <b
              className={styles.mainSpan}
              style={{ color: styleProperties.spanColor }}
            >
              remove multiple teeth.
            </b>
            If bleeding occurs, place a gauze pad directly over the bleeding
            socket and apply biting pressure for 30 minutes. If bleeding
            continues, bite on a moistened black tea bag for thirty minutes. The
            tannic acid in the black tea helps to form a clot by contracting
            blood vessels. If bleeding occurs, avoid hot liquids, exercise, and
            elevate the head. If bleeding persists, call our office immediately.
            Do not remove the immediate denture unless the bleeding is severe.
            Expect some oozing around the side of the denture.
          </p>

          <p className={styles.secondaryDescription}>
            Use ice packs (externally) on the cheek near the surgical site.
            Apply ice for the first 36 hours only. Apply ice continuously while
            you are awake.
          </p>

          <p className={styles.secondaryDescription}>
            For mild discomfort use aspirin, or any similar medication;
            two tablets every 3-4 hours. Two to three tablets of Ibuprofen
             can be taken every 3-4 hours.
          </p>

          <p className={styles.secondaryDescription}>
            For severe pain, use the prescription given to you. If the pain does
            not begin to subside after 2 days, or increases after 2 days, please
            call our office. If an antibiotic has been prescribed, make sure to
            finish your prescription unless you have an allergic reaction.
          </p>

          <p className={styles.secondaryDescription}>
            Drink plenty of fluids. If many teeth have been extracted, the blood
            lost at this time needs to be replaced. Drink at least six glasses
            of liquid the first day.
          </p>
          <p className={styles.secondaryDescription}>
            Do not rinse your mouth for the first post-operative day, or while
            there is bleeding. After the first day, use a warm salt water rinse
            every 4 hours and following meals to flush out particles of food and
            debris that may lodge in the operated area. (One teaspoon of salt in
            one cup of warm water). After you have seen your dentist for denture
            adjustment, take out the denture and rinse 3 to 4 times a day.
          </p>

          <p className={styles.secondaryDescription}>
            Restrict your diet to liquids and soft foods that are comfortable
            for you to eat. As the wounds heal, you will be able to resume your
            normal diet.
          </p>
          <p
            className={styles.secondaryDescription}
            style={{ marginBottom: "0.5rem" }}
          >
            The removal of many teeth at one time is quite different from the
            extraction of just one or two teeth. Because the bone must be shaped
            and smoothed prior to the insertion of a denture, the following
            conditions may occur, all of which are considered normal:
          </p>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>
                The area operated on will swell, reaching a maximum in two days.
                Swelling and discoloration around the eyes may occur. The
                application of a moist warm towel will help eliminate the
                discoloration. The towel should be applied continuously for as
                long as is tolerable, beginning 36 hours after surgery.
                (Remember: ice packs are used for the first 36 hours only).
              </li>
              <li>
                A sore throat may develop. The muscles of the throat are near
                the extraction sites. Swelling into the throat muscles can cause
                pain. This is normal and should subside in 2-3 days.
              </li>
              <li>
                If the corners of the mouth are stretched, they may dry out and
                crack. Your lips should be kept moist with an ointment like
                Vaseline. There may be a slight elevation of temperature for
                24-48 hours. If your temperature continues to rise, notify our
                office.
              </li>
            </ul>
          </p>
          <p className={styles.secondaryDescription}>
            If immediate dentures have been inserted, sore spots may develop. In
            most cases, your dentist will see you within 24-48 hours after
            surgery to make the necessary adjustments and relieve those sore
            spots. Failure to do so may result in severe denture sores, which
            may prolong the healing process.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuInstructions
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
