import React, { useState, useEffect } from "react";

import styles from "./FAQSection.module.css";
import Accordion from "./Accordion";

export default function FAQSection({ serviceName }) {
  const [open, setOpen] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const togggle = (index) => {
    if (open === index) {
      return setOpen(null);
    }
    setOpen(index);
  };
  const qaMap = [
    {
      question: "How often should I brush my teeth?",
      answer:
        "It's recommended to brush your teeth twice a day for two to three minutes each time.",
    },
    {
      question: "How often should I floss?",
      answer:
        "Flossing once a day helps remove plaque and debris from between teeth.",
    },
    {
      question: "Which toothpaste should I use?",
      answer:
        "Look for a toothpaste with fluoride for cavity prevention.  You can discuss specific options with your dentist.",
    },
    {
      question: "Do I need mouthwash?",
      answer:
        "Mouthwash can be a helpful addition to your routine, but it's not a substitute for brushing and flossing.",
    },
    {
      question: "Why do my gums bleed when I floss?",
      answer:
        "Bleeding gums can be a sign of gingivitis, the early stage of gum disease.  Let your dentist know if you experience this.",
    },
  ];
  return (
    <section className={styles.FAQSection}>
      <div className={styles.FAQContainer}>
        <div className={styles.FAQHeader}>
          <h1 className={styles.FAQHeaderContent}>Frequently Asked Question</h1>
          <p className={styles.FAQDescription}>
            {windowWidth > 730 ? (
              <>
                We now have an FAQ list that we hope will help you answer
                <br /> some of the most common ones.
              </>
            ) : (
              <>
                We now have an FAQ list that we hope will help you answer some
                of the most common ones.
              </>
            )}
          </p>
          {/* <hr className={styles.FAQhr}></hr> */}
        </div>
        <div className={styles.FAQQuestions}>
          {qaMap.map((data, index) => {
            return (
              <Accordion
                key={index}
                open={index === open}
                ques={data.question}
                ans={data.answer}
                toggle={() => togggle(index)}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
}
