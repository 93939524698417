import React, { useState, useEffect } from "react";

import styles from "./Instructions.module.css";

import SideMenuInstructions from "./SideMenuInstructions";

export default function GeneralInstructions({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const endodonticProperty = {
    textColor: "#6fcfff",
    spanColor: "#64bae6",
    backgroundColor: "#e7f1fca0",
  };
  const styleProperties = endodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            HOME CARE INSTRUCTIONS
          </h1>
          <h4 className={styles.secondaryQuestion}>
            After Endodontic Treatment
          </h4>
          <p className={styles.mainDescription}>
            Your tooth and surrounding gum tissue may be slightly tender for
            several days as a result of manipulation during treatment and
            previous condition of your tooth. This tenderness is normal and is
            no cause for alarm. Do not chew food on the affected side until your
            endodontic therapy is completed and your tooth is covered with a
            protective restoration provided by your restorative dentist. You may
            continue your regular dental hygiene regimen. Discomfort may be
            alleviated by taking ibuprofen, aspirin, or acetaminophen as
            directed.
          </p>

          <p className={styles.secondaryDescription}>
            <b>NOTE: </b>
            Alcohol intake is not advised while taking any of these medications.
            Should you experience discomfort that cannot be controlled with the
            above listed medications, or should swelling develop, please contact
            this office immediately.
          </p>
          <b className={styles.secondaryDescription}>
            The office telephone is answered day and night. If you need to call
            after hours, please have your pharmacy number available.
          </b>
          <p>
            {" "}
            <b className={styles.secondaryDescription}>
              If you have any questions, please call our office Dental Planet.
              Phone Number +91 9908873366
            </b>
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuInstructions
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
