import React from "react";

import { useInView } from "react-intersection-observer";
import styles from "./GeneralServices.module.css";

import cosmeticSeviceImg from "../../images/cosmeticDentistry.png";
import restorativeImg from "../../images/ClearBraces.png";
import preventativeImg from "../../images/PreventiveCare1.jpg";
import generalImage from "../../images/CosmeticGeneral.png";
import general from "../../images/EndodonticServices.png";

import { IonIcon } from "@ionic/react";

import { arrowForward } from "ionicons/icons";
export default function GeneralServices({ serviceName }) {
  const [header, headerView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  const [service, serviceView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  return (
    <section className={styles.generalServiesSection}>
      <div
        className={`${styles.generalDescriptionCenter} ${
          headerView ? styles.animate : ""
        }`}
        ref={header}
      >
        <h1 className={styles.generalServiceHeader}>Our General Services</h1>

        <p className={styles.generalServiceHeaderDescription}>
          Our General dentistry offers all kinds of services and study new
          technology to add new custom services to the list
        </p>
      </div>

      <div
        className={`${styles.generalServices} ${
          serviceView ? styles.animate : ""
        }`}
        ref={service}
      >
        <div className={styles.generalserviceCard}>
          <img
            src={preventativeImg}
            className={styles.generalserviceCardImage}
          ></img>
          <div className={styles.generalserviceCardContent}>
            <h3 className={styles.generalserviceCardHeader}>
              General Dentistry
            </h3>
            <p className={styles.generalserviceCardDescription}>
              Our interests include all aspects of your oral health and any
              aesthetic concerns you may have as well. We know that a beautiful
              smile starts with a healthy smile.
            </p>
          </div>

          <div className={styles.generalBtnBorder}>
            <a
              className={styles.generalserviceCardNavigation}
              href="/general/General"
            >
              <span>Learn more</span>
              <IonIcon icon={arrowForward} className={styles.sideArrow} />
            </a>
          </div>
        </div>

        <div className={styles.generalserviceCard}>
          <img
            src={restorativeImg}
            className={styles.generalserviceCardImage}
          ></img>
          <div className={styles.generalserviceCardContent}>
            <h3 className={styles.generalserviceCardHeader}>
              Preventative Care
            </h3>
            <p className={styles.generalserviceCardDescription}>
              Prioritize your smile's longevity with our preventive dental care
              services, offering routine cleanings and thorough examinations to
              safeguard against potential issues.
            </p>
          </div>

          <div className={styles.generalBtnBorder}>
            <a
              className={styles.generalserviceCardNavigation}
              href="/general/PreventativeCare"
            >
              <span>Learn more</span>
              <IonIcon icon={arrowForward} className={styles.sideArrow} />
            </a>
          </div>
        </div>

        <div className={styles.generalserviceCard}>
          <img
            src={cosmeticSeviceImg}
            className={styles.generalserviceCardImage}
          ></img>
          <div className={styles.generalserviceCardContent}>
            <h3 className={styles.generalserviceCardHeader}>
              Restorative Dentistry
            </h3>
            <p className={styles.generalserviceCardDescription}>
              Rediscover the joy of complete and healthy smile through our
              expert restorative dentists.We specialize in restoring both
              functionality and aesthetics of patient's smile
            </p>
          </div>

          <div className={styles.generalBtnBorder}>
            <a
              className={styles.generalserviceCardNavigation}
              href="/general/Restorative"
            >
              <span>Learn more</span>
              <IonIcon icon={arrowForward} className={styles.sideArrow} />
            </a>
          </div>
        </div>

        <div className={styles.generalserviceCard}>
          <img
            src={generalImage}
            className={styles.generalserviceCardImage}
          ></img>
          <div className={styles.generalserviceCardContent}>
            <h3 className={styles.generalserviceCardHeader}>
              Cosmetic Dentistry
            </h3>
            <p className={styles.generalserviceCardDescription}>
              We focus on enhancing the aesthetic appearance of teeth and smiles
              through various procedures such as teeth whitening, veneers, and
              dental implants.
            </p>
          </div>

          <div className={styles.generalBtnBorder}>
            <a
              className={styles.generalserviceCardNavigation}
              href="/general/CosmeticDentistry"
            >
              <span>Learn more</span>
              <IonIcon icon={arrowForward} className={styles.sideArrow} />
            </a>
          </div>
        </div>
      </div>
      <div className={styles.regularCheckup}>
        <span>
          Don’t skip your regular check-up for teeth.
          <a href="#">Book an appointment!</a>
        </span>
      </div>
    </section>
  );
}
