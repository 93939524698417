import React, { useState, useEffect } from "react";
import { useAppContext } from "../../AppContext";

import styles from "./OralSurgeryHero.module.css";
export default function OralSurgeryHero() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const { setDisableAnimations, setIsScrolling } = useAppContext();
  const handleScrollToSchedule = () => {
    setDisableAnimations(true);
    setIsScrolling(true);
    const scheduleSection = document.getElementById("schedule-section");
    if (scheduleSection) {
      scheduleSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <section className={styles.heroSection}>
      <div className={styles.heroContainer}>
        <div className={styles.innerHeader}>
          <h3 className={styles.heroHeader}>
            {windowWidth > 730 ? (
              <>
                <span>Take your dental,</span>
                <span>health seriously</span>
              </>
            ) : (
              <>
                <span>Take your dental,health seriously</span>
              </>
            )}
          </h3>
          <p className={styles.heroDescription}>
            {windowWidth > 730 ? (
              <>
                We offer a wide range of services for<br></br> your every dental
                need.
              </>
            ) : (
              <>We offer a wide range of services for your every dental need.</>
            )}
          </p>
          <div className={styles.ctaBtn}>
            <a
              href="#schedule-section"
              onClick={handleScrollToSchedule}
              className={styles.btnPrimary}
            >
              Book Appointment
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
