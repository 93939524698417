import React from "react";

import styles from "./PeriodontalMenu.module.css";
import SideMenuPeriodontalMenu from "./SideMenuPeriodontalMenu";

export default function PeriodontalSurgery({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const periodontalProperty = {
    textColor: "#fc7444",
    spanColor: "#978ab1",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = periodontalProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  return (
    <section
      className={styles.mainSection}
      style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            COSMETIC PERIODONTAL SURGERY
          </h1>

          <p className={styles.mainDescription}>
            <b>Cosmetic periodontal procedures </b>are a conventional way to
            cover unsightly, sensitive, or exposed root surfaces and to prevent
            future gum recession. If you are unhappy with the appearance of
            short, unsightly teeth, this can be greatly improved by a
            combination of periodontal procedures and cosmetic dentistry by your
            dentist.
          </p>
          <h4
            className={styles.secondaryQuestion}
            style={{ marginBottom: "0.5rem" }}
          >
            Cosmetic Periodontal Surgery Procedures
          </h4>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>Crown Lengthening</li>
              <li>Gum Grafting</li>
              <li>Bone Grafting</li>
            </ul>
          </p>
          <h4 className={styles.secondaryQuestion}>
            <b>Crown Lengthening</b>
          </h4>
          <p className={styles.secondaryDescription}>
            Although your teeth appear short, they may actually be the proper
            length. The teeth may be covered with too much gum tissue. We can
            correct this by performing the periodontal plastic surgery
            procedure, crown lengthening. During this procedure, excess gum and
            bone tissue are reshaped to expose more of the natural tooth. This
            can be done to more than one tooth, to even your gum line, and to
            create a beautiful smile.
          </p>
          <h4 className={styles.secondaryQuestion}>
            <b>Gum Grafting</b>
          </h4>
          <p className={styles.secondaryDescription}>
            Another cosmetic procedure is the soft tissue graft. It is used to
            cover unattractive tooth roots, reduce gum recession, and protect
            the roots from decay and eventual loss.
          </p>
          <h4 className={styles.secondaryQuestion}>
            <b>Bone Grafting</b>
          </h4>
          <p className={styles.secondaryDescription}>
            Tooth loss causes the jaw bone to recede and can lead to an
            unnatural looking indentation in your gums and jaw, and appearance
            of a general aging. The original look of your mouth may not be
            recaptured because of spaces remaining under and between replacement
            teeth. They may appear too long compared to nearby teeth.
          </p>
          <p className={styles.secondaryDescription}>
            Bone grafting following tooth loss can preserve the socket/ridge and
            minimize gum and bone collapse. There is less shrinkage and a more
            aesthetic tooth replacement for either an implant crown or fixed
            bridge around the replacement teeth.
          </p>
        </div>
        <SideMenuPeriodontalMenu
          menuItem={handleMenuClick}
          menuItemName={menuItemName}
          dentistry={dentistry}
        />
      </div>
    </section>
  );
}
