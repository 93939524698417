import React, { useState, useEffect } from "react";

import styles from "./Procedures.module.css";
import SideMenuProcedures from "./SideMenuProcedures";

export default function RootCanal({ menuItem, menuItemName, dentistry }) {
  const endodonticProperty = {
    textColor: "#6fcfff",
    spanColor: "#64bae6",
    backgroundColor: "#e7f1fca0",
  };
  const styleProperties = endodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            ROOT CANAL TREATMENT
          </h1>
          <h4 className={styles.secondaryQuestion}>What is a root canal?</h4>
          <p className={styles.mainDescription}>
            A <b>root canal </b>is one of the most common dental procedures
            performed, well over 14 million every year.{" "}
            <b>
              This simple treatment can save your natural teeth and prevent the
              need of dental implants or bridges.{" "}
            </b>
          </p>

          <p className={styles.secondaryDescription}>
            At the center of your tooth is pulp. Pulp is a collection of blood
            vessels that helps to build the surrounding tooth. Infection of the
            pulp can be caused by trauma to the tooth, deep decay, cracks and
            chips, or repeated dental procedures. Symptoms of the infection can
            be identified as visible injury or swelling of the gum near tooth,
            sensitivity to temperature or pain in the tooth and gums.
          </p>
          <p className={styles.secondaryDescription}>
            If you experience any of these symptoms, your dentist will most
            likely recommend non-surgical treatment to eliminate the diseased
            pulp. This injured pulp is removed and the root canal system is
            thoroughly cleaned and sealed. Root canal therapy usually involves
            local anesthesia and may be completed in one or more visits
            depending on the treatment required. Success for this type of
            treatment occurs in about 90% of cases. If your tooth is not
            amenable to endodontic treatment or the chance of success is
            unfavorable, you will be informed at the time of consultation or
            when a complication becomes evident during or after treatment.Dr.K.Pavani Reddy use local anesthesia to eliminate discomfort. In
            addition, we will provide nitrous oxide analgesia, if indicated. You
            will be able to drive home after your treatment, and you probably
            will be comfortable returning to your normal routine.
          </p>
          <h4 className={styles.secondaryQuestion}>
            What happens after treatment?
          </h4>
          <p className={styles.secondaryDescription}>
            When your root canal therapy has been completed, a record of your
            treatment will be sent to your restorative dentist. You should
            contact their office for a follow-up restoration within a few weeks
            of completion at our office. Your restorative dentist will decide on
            what type of restoration is necessary to protect your tooth. It is
            rare for endodontic patients to experience complications after
            routine endodontic treatment or microsurgery. If a problem does
            occur, however, we are available at all times to respond. To prevent
            further decay, continue to practice good dental hygiene.
          </p>
          <h4 className={styles.secondaryQuestion}>
            How much will a root canal cost?
          </h4>
          <p className={styles.secondaryDescription}>
            The cost associated with root canals can vary depending on factors
            such as the severity of damage to the affected tooth and which tooth
            is affected. In general, endodontic treatment is much less expensive
            than tooth removal and replacement with an artificial tooth.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuProcedures
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
