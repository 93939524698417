import React from "react";
import { useInView } from "react-intersection-observer";

import styles from "./PeriodontalSchedule.module.css";
function PeriodontalSchedule() {
  const [ref, inView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  return (
    <section className={styles.scheduleSection}>
      <div
        className={`${styles.scheduleContainer} ${
          inView ? styles.animate : ""
        }`}
        ref={ref}
      >
        <h2 className={styles.boxHeader}>Schedule Your Visit</h2>
        <hr />
        <p className={styles.boxContent}>
          Are you in pain or have an urgent dental concern? Call 040-40 24 7676
          / (+91) 9908873366 to schedule an emergency appointment.
        </p>
        <a href="#section-how" className={styles.btnPrimary}>
          Schedule Appointment
        </a>
      </div>
    </section>
  );
}

export default PeriodontalSchedule;
