// Home.js
import React, { useState } from "react";
import PediatricHeader from "./PediatricHeader";
import PediatricServices from "./PediatricServices";
import PediatricHero from "./PediatricHero";
import WhyPediatricDentistry from "./WhyPediatricDentistry";
import PediatricFooter from "./PediatricFooter";
import PediatricSchedule from "./PediatricSchedule";
import PediatricLocation from "./PediatricLocation";
import PediatricScheduleCTA from "./PediatricScheduleCta";
import PediatricHeroMenu from "./PediatricHeroMenu";
import RootCanal from "./PediatricMenu/RootCanal";

import PediatricHeader3 from "./Pediatricheader3";
import ScheduleCTA from "../Home/ScheduleCta";
import PediatricMenu from "./PediatricMenu/PediatricMenu";
import ToothBrushing from "./PediatricMenu/ToothBrushing";
import DigitSucking from "./PediatricMenu/DigitSucking";
import ToothEruption from "./PediatricMenu/ToothEruption";
import ToothDecay from "./PediatricMenu/ToothDecay";
import CavityPrevention from "./PediatricMenu/CavityPrevention";
import GumDisease from "./PediatricMenu/GumDisease";
import OralCare from "./PediatricMenu/OralCare";
import ToothFilling from "./PediatricMenu/ToothFilling";
import AmalgamFillings from "./PediatricMenu/AmalgamFillings";
import DentalSealants from "../PreventiveCareDentistry/DentalSealants";
import Extractions from "./PediatricMenu/Extractions";
import Footer from "../Home/Footer";
import Bonding from "./PediatricMenu/Bonding";
import InlaysOnlays from "./PediatricMenu/Inlays&Onlays";
import RemovableAppliances from "./Orthodontics/RemovableAppliances";
import OrthodonticsMenu from "./Orthodontics/OrthodonticsMenu";
import Bites from "./Orthodontics/Bites";
import ClassificationOfTeeth from "./Orthodontics/ClassificationOfTeeth";

const PediatricHome = (cosmeticData) => {
  const [cosmeticDataMenu, setCosmeticMenu] = useState(cosmeticData);

  const handleMenuItem = (data) => {
    setCosmeticMenu(data);
  };
  return (
    <>
      <PediatricHeader3 generalMenuItem={handleMenuItem} />
      {cosmeticDataMenu.cosmeticData === "PeidatricHome" ? (
        <PediatricHero />
      ) : (
        <PediatricHeroMenu />
      )}
      {cosmeticDataMenu.cosmeticData === "PeidatricHome" && (
        <PediatricServices />
      )}
      {cosmeticDataMenu.cosmeticData === "pediatricdentistry" && (
        <PediatricMenu
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="pediatric"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "ToothBrushing" && (
        <ToothBrushing
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="pediatric"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "DigitSucking" && (
        <DigitSucking
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="pediatric"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "ToothEruption" && (
        <ToothEruption
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="pediatric"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "ToothDecay" && (
        <ToothDecay
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="pediatric"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "CavityPrevention" && (
        <CavityPrevention
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="pediatric"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "GumDisease" && (
        <GumDisease
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="pediatric"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "OralCare" && (
        <OralCare
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="pediatric"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "ToothFillings" && (
        <ToothFilling
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="pediatric"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "AmalgamFillings" && (
        <AmalgamFillings
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="pediatric"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "DentalSealants" && (
        <DentalSealants
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="pediatric"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "Extractions" && (
        <Extractions
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="pediatric"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "Bonding" && (
        <Bonding
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="pediatric"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "InlaysOnlays" && (
        <InlaysOnlays
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="pediatric"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "RemovableAppliances" && (
        <RemovableAppliances
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="pediatric"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "Orthodontics" && (
        <OrthodonticsMenu
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="pediatric"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "Bites" && (
        <Bites
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="pediatric"
        />
      )}
      {cosmeticDataMenu.cosmeticData === "ClassificationOfTeeth" && (
        <ClassificationOfTeeth
          menuItem={handleMenuItem}
          menuItemName={cosmeticDataMenu.cosmeticData}
          dentistry="pediatric"
        />
      )}

      <WhyPediatricDentistry />
      {/* <PediatricSchedule /> */}
      <PediatricLocation />

      <ScheduleCTA
        dentistry={"pediatric"}
        subService={cosmeticDataMenu.cosmeticData}
      />
      <Footer dentistry={"pediatric"} />
    </>
  );
};

export default PediatricHome;
