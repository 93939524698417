import React from "react";

import styles from "./OrthodonticHero1.module.css";
import { useAppContext } from "../../AppContext";

export default function OrthodonticHero1() {
  const { setDisableAnimations, setIsScrolling } = useAppContext();
  const handleScrollToSchedule = () => {
    setDisableAnimations(true);
    setIsScrolling(true);
    const scheduleSection = document.getElementById("schedule-section");
    if (scheduleSection) {
      scheduleSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <section className={styles.heroSection}>
      <div className={styles.container}>
        <div className={styles.heroContent}>
          <div className={styles.heroPositioning}>
            <p1 className={styles.heroDescription}>
              We love seeing happy smiles.
            </p1>
            <h2 className={styles.heroHeader}>
              Let's make your dental health a priority!
            </h2>
            <div className={styles.btnSection}>
              <a
                href="#schedule-section"
                onClick={handleScrollToSchedule}
                className={styles.btn}
              >
                Book An Appointment
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
