import React from "react";

import styles from "./SideMenuLivingBraces.module.css";
import { IonIcon } from "@ionic/react";
import { caretForwardOutline } from "ionicons/icons";
export default function SideMenuLivingBraces({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const generalProperty = {
    backgroundColor: "#ffa07d",
  };
  const prosthodonticProperty = {
    backgroundColor: "#b6d6f5",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;

  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  return (
    <div
      className={styles.menuSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <h2 className={styles.secondaryHeading}>Living with Braces</h2>
      <ul className={styles.main_nav_list}>
        <li className={styles.service}>
          {menuItemName === "Elastics" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/orthodontic/LivingWithBraces/Elastics"
          >
            <p className={styles.serviceHeader}>Elastics</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "EatingWithBraces" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/orthodontic/LivingWithBraces/EatingWithBraces"
          >
            <p className={styles.serviceHeader}>Eating With Braces</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "PainAndSoreness" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/orthodontic/LivingWithBraces/PainAndSoreness"
          >
            <p className={styles.serviceHeader}>Pain And Soreness</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "BrushingAndFlossing" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/orthodontic/LivingWithBraces/BrushingAndFlossing"
          >
            <p className={styles.serviceHeader}>Brushing And Flossing</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "RetainersAndAppliances" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/orthodontic/LivingWithBraces/RetainersAndAppliances"
          >
            <p className={styles.serviceHeader}>Retainers And Appliances</p>
          </a>
        </li>
      </ul>
    </div>
  );
}
