import React, { useState, useEffect } from "react";
import SideMenuCosmeticProsthodonticDentistry from "../CosmeticProsthodonticDentistry/SideMenuCosmeticProsthodonticDentistry";
import SideMenuCosmeticProsthodontic from "../RestorativeProsthodontic/SideMenuCosmeticProsthodontic";

import styles from "./DentalBonding.module.css";
import SideMenuCosmeticDentistry from "./SideMenuCosmeticDentistry";

export default function PorcelainVein({ menuItem, menuItemName, dentistry }) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;

  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            Porcelain Veneers{" "}
          </h1>
          <h4 className={styles.secondaryQuestion}>What are teeth veneers?</h4>
          <p className={styles.secondaryDescription}>
            <span
              className={styles.secondaryBold}
              style={{ color: styleProperties.spanColor }}
            >
              Porcelain veneers are thin shells of ceramic that bond directly to
              the front surfaces of the teeth.
            </span>
            They are an ideal choice for improving your smile and have become
            increasingly popular due to their simplicity and versatility.
            Placing custom{" "}
            <span
              className={styles.secondaryBold}
              style={{ color: styleProperties.spanColor }}
            >
              teeth veneers
            </span>{" "}
            requires a high degree of technical skill as well as attention to
            cosmetic detail. We place veneers routinely, and design each case
            individually to match and enhance the characteristics of each
            patient’s smile.
          </p>
          <h4 className={styles.secondaryQuestion}>
            Will dental veneers look like normal teeth?
          </h4>
          <p className={styles.secondaryDescription}>
            When bonded to the teeth, the ultra-thin porcelain veneers are
            virtually undetectable and highly resistant to coffee, tea, or even
            cigarette stains. For strength and appearance, their resemblance to
            healthy, white tooth enamel is unsurpassed by other restorative
            options.
          </p>
          <h4 className={styles.secondaryQuestion}>
            How durable are porcelain veneers?
          </h4>
          <p className={styles.secondaryDescription}>
            With proper care, porcelain veneers will brighten your smile for
            well over a decade. Our team will ensure that your veneers are
            crafted from the highest quality porcelains and are bonded with the
            most advanced and proven materials available. Refraining from using
            your veneers as tools to open and cut things will prolong their
            life, and if accidental breakage or damage occurs, it is usually
            possible to replace only the veneer involved.
          </p>
        </div>
        {windowWidth > 730 &&
          (dentistry == "general" ? (
            <SideMenuCosmeticDentistry
              menuItem={handleMenuClick}
              menuItemName={menuItemName}
              dentistry={dentistry}
            />
          ) : (
            <SideMenuCosmeticProsthodontic
              menuItem={handleMenuClick}
              menuItemName={menuItemName}
              dentistry={dentistry}
            />
          ))}
      </div>
    </section>
  );
}
