import React, { useEffect, useState } from "react";

import styles from "./PeriodontalMenu.module.css";
import SideMenuPeriodontalMenu from "./SideMenuPeriodontalMenu";

export default function Crown({ menuItem, menuItemName, dentistry }) {
  const periodontalProperty = {
    textColor: "#fc7444",
    spanColor: "#978ab1",
    backgroundColor: "#f3f2f5",
  };
  const styleProperties = periodontalProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      //  style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            CROWN LENGTHENING
          </h1>
          <h4 className={styles.secondaryQuestion}>
            Why would I need crown lengthening?
          </h4>
          <p className={styles.mainDescription}>
            <b>Crown lengthening</b> is usually performed to improve the health
            of the gum tissue, prepare the mouth for a procedure, or correct a
            “gummy smile”. A <b>“gummy smile”</b> is used to describe an
            instance where teeth are covered with excess gum tissue resulting in
            a less esthetically-pleasing smile. The procedure involves reshaping
            or recontouring the gum tissue and bone around the tooth in question
            to create a new gum-to–tooth relationship. Crown lengthening can be
            performed on a single tooth, many teeth, or the entire gum line.
          </p>

          <p className={styles.secondaryDescription}>
            Crown lengthening is often required when your tooth needs a new
            crown or other restoration. The edge of that restoration is deep
            below the gum tissue and not immediately accessible. It is also
            usually too close to the bone or below the bone
          </p>
          <p className={styles.secondaryDescription}>
            Crown lengthening allows us to reach the edge of the restoration,
            ensuring a proper fit to the tooth. It should also provide enough
            tooth structure so the new restoration will not come loose in the
            future. This allows you to clean the edge of the restoration when
            you brush and floss to prevent decay and gum disease.
          </p>
          <h4 className={styles.secondaryQuestion}>
            About the Crown Lengthening Procedure
          </h4>
          <p className={styles.secondaryDescription}>
            Crown lengthening takes approximately one hour but will largely
            depend on the amount of teeth involved and if any amount of bone
            will need to be removed. The procedure is usually performed under
            local anesthetic and involves a series of small incisions around the
            tissue to separate the gums from the teeth. Even if only one tooth
            requires the procedure, it will probably be necessary to adjust the
            surrounding teeth to enable a more even reshaping. In some cases,
            extraction of a small amount of bone will be necessary as well.
          </p>

          <p className={styles.secondaryDescription}>
            When your Dentist is satisfied that the teeth have sufficient
            exposure and the procedure is completed, the incisions will be
            cleaned with sterile water. Sutures and a protective bandage are
            then placed to help secure the new gum-to-tooth relationship. Your
            teeth will look noticeably longer immediately after surgery because
            the gums have now been repositioned. You will need to be seen in one
            or two weeks to remove the sutures and evaluate your healing. The
            surgical site should be completely healed in approximately two to
            three months following the procedure.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuPeriodontalMenu
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
