import React, { useState, useEffect } from "react";

import styles from "../CosmeticDentistry/DentalBonding.module.css";
import SideMenuRestorativeDentistry from "./SideMenuRestorativeDentistry";

export default function Dentures({ menuItem, menuItemName, dentistry }) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            Dentures
          </h1>
          <p className={styles.mainDescription}>
            <b>
              A denture or a complete denture as it is often called, is an
              appliance that is inserted in the mouth, replaces natural teeth
              and provides support for the cheeks and lips.
            </b>
          </p>
          <p className={styles.secondaryDescription}>
            Most dentures are made of acrylic and can be fabricated two
            different ways.
          </p>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>
                A <b>conventional denture</b> is made after all teeth have been
                extracted and the tissues (gums) have healed.
              </li>
              <li>
                An{" "}
                <b
                  className={styles.mainSpan}
                  style={{ color: styleProperties.spanColor }}
                >
                  immediate denture{" "}
                </b>
                is fabricated and inserted immediately after the teeth are
                extracted and the tissues are allowed to heal under the denture.
              </li>
              <li>
                An <b> upper denture </b> has acrylic, usually gum colored, that
                covers the palate (roof of the mouth).
              </li>
              <li>
                A <b> lower denture </b> is shaped like a horseshoe to leave
                room for the tongue.
              </li>
            </ul>
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuRestorativeDentistry
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
