import React, { useState, useEffect } from "react";

import styles from "../Braces/Braces.module.css";
import SideMenuLivingBraces from "./SideMenuLivingBraces";

export default function RetainersAndAppliances({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            RETAINERS AND APPLIANCES
          </h1>
          <p className={styles.mainDescription}>
            The removable appliance is carefully designed to move or to hold
            your teeth. It is important that it is worn according to
            instructions and brought to each appointment.
          </p>
          <p className={styles.mainDescription}>
            Wear your appliance at all times, even while you are asleep. It may
            be removed while eating, swimming, or playing vigorous sports
            activities. In two or three days your speech will return to normal
            with the appliance in place. Avoid flipping your appliance with your
            tongue. This can cause damage to your teeth or breakage of your
            appliance.
          </p>
          <p className={styles.mainDescription}>
            Your new appliance may make your teeth sore for a day or two,
            especially after an adjustment. If you have a sore spot on your gums
            call our office at Dental Planet Phone Number +91 9908873366 so that
            we can adjust the appliance.
          </p>
          <p className={styles.mainDescription}>
            Clean your appliance by brushing it daily with toothpaste. Denture
            cleaner can also be used for a more thorough cleaning. If you are
            wearing a bonded wire retainer behind your front teeth, be extra
            careful to clean the wire and the area around it. Flossing should be
            done every day.
          </p>
          <p className={styles.mainDescription}>
            Never wrap your appliance in a paper napkin or tissue and set it
            down on the table. You or someone else may throw it away. Don’t put
            it in your pocket when playing or you may break or lose it. Whenever
            it is not in your mouth it should be in its plastic appliance case.
          </p>
          <p className={styles.mainDescription}>
            Keep your appliance away from dogs or cats as they love to chew on
            them. Avoid storing them near any source of heat. Do not boil your
            retainer to sterilize it! If needed, bring it to the office and we
            will be happy to disinfect it for you.
          </p>
          <p className={styles.mainDescription}>
            An additional charge will be incurred for lost or broken appliances.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuLivingBraces
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
