import React, { useState, useEffect } from "react";

import styles from "../Braces/Braces.module.css";
import SideMenuLivingBraces from "./SideMenuLivingBraces";

export default function Elastics({ menuItem, menuItemName, dentistry }) {
  const orthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#8ba7c3",
    backgroundColor: "#e7f1fca0",
  };
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            ELASTICS
          </h1>
          <p className={styles.mainDescription}>
            Elastics are rubber bands that are attached to your braces. They are
            designed to create the exact amount of pressure on your teeth that
            is needed to move them. In order for the elastics to keep a steady
            force, they must be worn all the time and they must be changed every
            day. Please Note: If you choose not to wear your elastics as
            prescribed, your treatment will take longer.
          </p>
          <h4 className={styles.secondaryQuestion}>
            Here are some tips for the proper care of your elastics:
          </h4>
          <ul
            className={styles.subDescription}
            style={{ fontSize: "1.4rem", paddingTop: "0rem" }}
          >
            <li>
              It is your responsibility to change the elastics every day. This
              is very important as the rubber will lose its elasticity rapidly,
              resulting in inadequate force on your teeth.
            </li>
            <li>
              You may remove your elastics while brushing your teeth after
              meals. Then put them back on immediately.
            </li>
            <li>
              Keep extra elastics with you wherever you go so that if one breaks
              you can replace it right away.
            </li>
            <li>
              As your supply gets low, call us so that you don’t experience a
              gap in treatment time.
            </li>
            <li>
              If you find that your elastics are breaking frequently, call us,
              as we may need to make an adjustment to your braces.
            </li>
          </ul>
        </div>
        {windowWidth > 730 && (
          <SideMenuLivingBraces
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
