import React, { useState, useEffect } from "react";

import styles from "./RestorativeMenu.module.css";
import SideMenuRestorativeProsthodonticDentistry from "./SideMenuRestorativeProsthodonticDentistry";
import SideMenuProcedures from "../OralSurgeryDentistry/Procedures/SideMenuProcedures";

export default function PreProsthodontic({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const oralsurgery = {
    textColor: "#d099d9",
    spanColor: "#644a68",
    backgroundColor: "#f3f2f5",
  };
  // const styleProperties =
  //   dentistry === "general" ? generalProperty : prosthodonticProperty;

  const styleProperties =
    dentistry === "general"
      ? generalProperty
      : "oralsurgery"
      ? oralsurgery
      : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            PRE-PROSTHETIC SURGERY
          </h1>
          <p className={styles.mainDescription}>
            The preparation of your mouth before the placement of a prosthesis
            is referred to as <b>pre-prosthetic surgery.</b>
          </p>
          <p className={styles.mainDescription}>
            In order to ensure the maximum level of comfort, some patients
            require minor oral surgical procedures before receiving a{" "}
            <b>partial</b> or <b>complete denture.</b> A denture sits on the
            bone ridge, so it is very important that the bone be the proper
            shape and size. If a <b>tooth needs to be extracted,</b> the
            underlying bone might be left sharp and uneven. For the best fit of
            a denture, the bone might need to be smoothed out or reshaped.
            Occasionally, excess bone would need to be removed prior to denture
            insertion.
          </p>
          <p
            className={styles.mainDescription}
            style={{ marginBottom: "1.2rem" }}
          >
            <b>
              One or more of the following procedures might need to be performed
              in order to prepare your mouth for a denture:
            </b>
          </p>
          <p className={styles.secondaryDescription}>
            <ul className={styles.subDescription}>
              <li>Bone smoothing and reshaping</li>
              <li>Removal of excess bone.</li>
              <li>Bone ridge reduction.</li>
              <li>Removal of excess gum tissue.</li>
              <li>Exposure of impacted teeth.</li>
            </ul>
          </p>
          <p className={styles.mainDescription}>
            We will review your particular needs with you during your
            appointment.
          </p>
        </div>
        {windowWidth > 730 &&
          (dentistry == "oralsurgery" ? (
            <SideMenuProcedures
              menuItem={handleMenuClick}
              menuItemName={menuItemName}
              dentistry={dentistry}
            />
          ) : (
            <SideMenuRestorativeProsthodonticDentistry
              menuItem={handleMenuClick}
              menuItemName={menuItemName}
              dentistry={dentistry}
            />
          ))}
      </div>
    </section>
  );
}
