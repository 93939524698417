import React from "react";

import styles from "./SideMenuProcedures.module.css";
import { IonIcon } from "@ionic/react";
import { caretForwardOutline } from "ionicons/icons";
export default function SideMenuProcedures({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const periodontalProperty = {
    backgroundColor: "#d8ceda",
  };
  const styleProperties = periodontalProperty;

  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  return (
    <div
      className={styles.menuSection}
      style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <h2 className={styles.secondaryHeading}>Procedures</h2>
      <ul className={styles.main_nav_list}>
        <li className={styles.service}>
          {menuItemName === "DentalImplants" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/oralsurgery/Procedures/DentalImplants"
          >
            <p className={styles.serviceHeader}>Dental Implants</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "BoneGrafting" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/oralsurgery/Procedures/BoneGrafting"
          >
            <p className={styles.serviceHeader}>Bone Grafting</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "WisdomTeeth" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/oralsurgery/Procedures/WisdomTeeth"
          >
            <p className={styles.serviceHeader}>Wisdom Teeth</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "JawSurgery" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/oralsurgery/Procedures/JawSurgery"
          >
            <p className={styles.serviceHeader}>Jaw Surgery</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "FacialTrauma" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/oralsurgery/Procedures/FacialTrauma"
          >
            <p className={styles.serviceHeader}>Facial Trauma</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "ToothExtractions" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/oralsurgery/Procedures/ToothExtractions"
          >
            <p className={styles.serviceHeader}>Tooth Extractions</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "Preprosthetic" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/oralsurgery/Procedures/Preprosthetic"
          >
            <p className={styles.serviceHeader}>Pre-Prosthetic Surgery</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "DistractionOsteogenesis" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/oralsurgery/Procedures/DistractionOsteogenesis"
          >
            <p className={styles.serviceHeader}>Distraction Osteogenesis</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "OralPathology" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/oralsurgery/Procedures/OralPathology"
          >
            <p className={styles.serviceHeader}>Oral Cancer</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "TMJ" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/oralsurgery/Procedures/TMJ"
          >
            <p className={styles.serviceHeader}>TMJ</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "SleepApnea" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/oralsurgery/Procedures/SleepApnea"
          >
            <p className={styles.serviceHeader}>Sleep Apnea</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "CleftLipAndPalate" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/oralsurgery/Procedures/CleftLipAndPalate"
          >
            <p className={styles.serviceHeader}>Cleft Lip & Palate</p>
          </a>
        </li>
        {/* <li className={styles.service}>
          {menuItemName === "Protein" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/oralsurgery/Procedures/Protein"
          >
            <p className={styles.serviceHeader}>Bone Morphogenetic Protein</p>
          </a>
        </li> */}
        <li className={styles.service}>
          {menuItemName === "StemCells" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/oralsurgery/Procedures/StemCells"
          >
            <p className={styles.serviceHeader}>Stem Cells</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "RichPlasma" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/oralsurgery/Procedures/RichPlasma"
          >
            <p className={styles.serviceHeader}>
              Platelet Rich Plasma and Platelet Rich Fibrin
            </p>
          </a>
        </li>
        {/* <li className={styles.service}>
          {menuItemName === "RichFibrin" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/oralsurgery/Procedures/RichFibrin"
          >
            <p className={styles.serviceHeader}>Platelet Rich Fibrin</p>
          </a>
        </li> */}
        <li className={styles.service}>
          {menuItemName === "ImpactedTeeth" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/oralsurgery/Procedures/ImpactedTeeth"
          >
            <p className={styles.serviceHeader}>Exposure of Impacted Teeth</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "3DImaging" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/oralsurgery/Procedures/3DImaging"
          >
            <p className={styles.serviceHeader}>3D Imaging</p>
          </a>
        </li>
        <li className={styles.service}>
          {menuItemName === "ANESTHESIA" && (
            <span className={styles.active}>
              <IonIcon
                icon={caretForwardOutline}
                className={styles.sideArrow}
              />
            </span>
          )}
          <a
            className={styles.main_nav_link}
            href="/oralsurgery/Procedures/ANESTHESIA"
          >
            <p className={styles.serviceHeader}>Anesthesia</p>
          </a>
        </li>
      </ul>
    </div>
  );
}
