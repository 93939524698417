import React, { useState, useEffect } from "react";

import styles from "../Braces/Braces.module.css";

import SideMenuInvisalign from "./SideMenuInvisalign";

export default function AdvantagesOfInvisalign({
  menuItem,
  menuItemName,
  dentistry,
}) {
  const generalProperty = {
    textColor: "#ff7745",
    spanColor: "#ff8558",
    backgroundColor: "#c7cacf",
  };
  const prosthodonticProperty = {
    textColor: "#a4c7cd",
    spanColor: "#94b3b9",
    backgroundColor: "#f6f9fa",
  };
  const styleProperties =
    dentistry === "general" ? generalProperty : prosthodonticProperty;
  const handleMenuClick = (menuName) => {
    menuItem(menuName);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={styles.mainSection}
      // style={{ backgroundColor: styleProperties.backgroundColor }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.contentSection}>
          <h1
            className={styles.mainHeader}
            style={{ color: styleProperties.textColor }}
          >
            THE CONVENIENCE OF INVISALIGN® AND INVISALIGN® TEEN
          </h1>
          <h4 className={styles.secondaryQuestion}>
            Why Over One Million Patients Have Used Invisalign®
          </h4>
          <ul
            className={styles.subDescription}
            style={{ fontSize: "1.4rem", paddingTop: "0rem" }}
          >
            <li>
              Invisalign® works with your schedule. There is minimal time
              required in our office for treatment as there are no wires and
              brackets to fix.
            </li>
            <li>
              You can keep up with your traditional oral health routine.
              Traditional braces often cause tooth decay around the brackets
              because it’s hard to floss and brush, especially between teeth.
              Invisalign® allows you to completely remove your aligners for easy
              and thorough cleaning.
            </li>
            <li>
              You can occasionally remove them to enjoy a special night out.
              Life happens, and you want to have the chance to look your best
              when you need it!
            </li>
            <li>
              You can eat or drink whatever you would like. With Invisalign®,
              there is no risk of breakage or loosening of wires while you are
              enjoying crunchy or sticky foods. No stress at meal and snack
              times.
            </li>
            <li>
              You can feel like yourself. No need to hide your smile or worry
              that food is caught in your braces. With Invisalign®, you can feel
              confident in knowing that you look good and that your breath is
              fresh.
            </li>
            <li>
              You can see what your teeth will look like before you even begin.
              With Invisalign® advanced 3D imaging computer technology, you can
              get a sneak peek at what your teeth will look like during each
              phase of treatment, all the way to the final dazzling result!
            </li>
            <li>
              It’s comfortable. Aligners are made of smooth plastic that is
              custom made for your teeth. There is no risk of poking, bleeding,
              or irritation when you participate in sports or play a musical
              instrument.
            </li>
            {/* <li>
              Invisalign® is affordable and may be covered by your dental
              insurance.
            </li> */}
          </ul>
          <p className={styles.mainDescription}>
            Invisalign® has all of these advantages for the same cost as
            traditional braces! Call us to set up your Invisalign® consultation.
          </p>
        </div>
        {windowWidth > 730 && (
          <SideMenuInvisalign
            menuItem={handleMenuClick}
            menuItemName={menuItemName}
            dentistry={dentistry}
          />
        )}
      </div>
    </section>
  );
}
